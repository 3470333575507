import React, { useState, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import SnipperModal from "../../modal/SnipperModal";
import MasterDataService from '../../../service/master-data/MasterDataService';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useAuth } from '../../../utilities/authContext';

const PassportType = () => {
    let emptyPassportType = {
        id: null,
        name: '',
        description: '',
        recordStatus: null,
    };
    const passportBody = {
        name: "",
        description: "",
    };
    const activateDeactivateBody = {
        status: 0,
    };

    let [loading, setLoading] = useState(false);

    const [passportTypes, setPassportTypes] = useState(null);

    const [passportUpdateDialog, setPassportUpdateDialogDialog] = useState(false);
    const [passportDialog, setPassportDialog] = useState(false);
    const [deletePassportDialog, setDeletePassportDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);

    const [passportType, setPassportType] = useState(emptyPassportType);
    const [selectedPassports, setSelectedPassports] = useState(null);

    const [performingAction, setPerformingAction] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });
    const masterDataService = new MasterDataService();

    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        setLoading(true);
        masterDataService.getPassportType("PassportType-View")
            .then(res => {
                setPassportTypes(res.data)
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Error while fetching passport types", life: 4000 });
            }).finally(() => {
                setLoading(false)
            })
    }, []);

    const openNew = () => {
        setPassportType(emptyPassportType);
        setSubmitted(false);
        setPassportDialog(true);
    }
    const hidePassportDialog = () => {
        setSubmitted(false);
        setPassportDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPassportUpdateDialogDialog(false);
    }

    const hideDeletePassportDialog = () => {
        setDeletePassportDialog(false);
    }
    const hideActivateDeactivatePassportDialog = () => {
        setActivateDeactivateDialog(false);
    }

    const UpdatePassportType = () => {
        setPerformingAction(true);
        setSubmitted(true);

        if (passportType.name.trim()) {
            let _passportTypes = [...passportTypes];
            let _passportType = { ...passportType };
            if (passportType.id) {
                const index = findIndexById(passportType.id);

                _passportTypes[index] = _passportType;
                passportBody.name = _passportType.name;
                passportBody.description = _passportType.description;

                masterDataService.updatePassportType(passportBody, _passportType.id, "PassportType-Edit")
                    .then(() => {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Passport Type added', life: 3000 });
                    })
                    .catch(() => {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Sorry,Passport Type already Registered ", life: 3000 });
                    }).finally(() => {
                        setPerformingAction(false);
                        setPassportTypes(_passportTypes);
                        setPassportUpdateDialogDialog(false);
                        setPassportType(emptyPassportType);
                    })
            }
        }
    }

    const SubmitPassport = () => {
        setSubmitted(true);
        setPerformingAction(true);

        let _passportTypes = [...passportTypes];
        let _passportType = { ...passportType };

        passportBody.name = _passportType.name;
        passportBody.description = _passportType.description;

        masterDataService.createPassportType(passportBody, "PassportType-Add")
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Passport Updated', life: 3000 });
                refresh();
            })
            .catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Passport Type already Registered ", life: 3000 });
            }).finally(() => {
                setPassportTypes(_passportTypes);
                setPassportDialog(false);
                setPassportType(emptyPassportType);
                setPerformingAction(false);
            })
    }
    const editPassport = (passportType) => {
        setPassportType({ ...passportType });
        setPassportUpdateDialogDialog(true);
    }
    const confirmDeletePassport = (passportType) => {
        setPassportType(passportType);
        setDeletePassportDialog(true);
    }
    const confirmActivateDeactivatePassport = (passportType) => {
        setPassportType(passportType);
        setActivateDeactivateDialog(true);
    }
    const deletePassport = () => {
        setPerformingAction(true);
        let _passportTypes = passportTypes.filter(val => val.id !== passportType.id);

        masterDataService.deletePassportType(passportType.id, "PassportType-Delete")
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Passport type deleted', life: 3000 });
            }).catch(() => {
                toast.current.show({ severity: 'success', summary: 'Error', detail: 'Error while deleting passport type', life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setPassportTypes(_passportTypes);
                setDeletePassportDialog(false);
                setPassportType(emptyPassportType);
            });

    }
    const activateDeactivatePassport = () => {
        setPerformingAction(true)
        setSubmitted(true);

        let _passportTypes = [...passportTypes];
        let _passportType = { ...passportType };
        if (passportType.id) {
            const index = findIndexById(passportType.id);

            _passportTypes[index] = _passportType;
            const masterDataService = new MasterDataService();


            if (passportType.recordStatus === 1) {
                activateDeactivateBody.status = 2;

            }
            else {
                activateDeactivateBody.status = 1;
                setLoading(true)
            }

            masterDataService.activateDeactivatePassportType(passportType.id, activateDeactivateBody, "PassportType-ChangeStatus")
                .then((res) => {
                    setLoading(false)
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Passport type Activated" : "Passport type Deactivated", life: 3000 });
                    refresh();
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error updating passport type status", life: 3000 });
                }).finally(() => {
                    setPerformingAction(false)
                    setPassportTypes(_passportTypes);
                    setActivateDeactivateDialog(false);
                    setPassportType(emptyPassportType);
                });
        }
    }
    const refresh = () => {
        const masterDataService = new MasterDataService();
        masterDataService.getPassportType("PassportType-View")
            .then((res) => {
                setPassportTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < passportTypes.length; i++) {
            if (passportTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _passportType = { ...passportType };
        _passportType[`${name}`] = val;
        setPassportType(_passportType);
    }
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("PassportType-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Passport-Type</span>
                {rowData.name}
            </>
        );
    }
    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("PassportType-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editPassport(rowData)} /> : <></>}
                {roles.privileges.includes("PassportType-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeletePassport(rowData)} /> : <></>}
                {roles.privileges.includes("PassportType-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmActivateDeactivatePassport(rowData)} /> : <></>}
            </div>
        );
    }

    const statuses = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Passport Types</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const updatePassportDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdatePassportType} />}
        </>
    );

    const passportDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hidePassportDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitPassport} />}
        </>
    );

    const deletePassportDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeletePassportDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deletePassport} />}
        </>
    );

    const activateDeactivatePassportDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivatePassportDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivatePassport} />}
        </>
    );



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput} ></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable ref={dt} filters={filters} value={passportTypes} selection={selectedPassports} onSelectionChange={(e) => setSelectedPassports(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Passports" //header={header}
                        globalFilter={globalFilter} emptyMessage="No Passports found." scrollable responsiveLayout="scroll">

                        <Column field="name" header="Name" sortable body={nameBodyTemplate} style={{ minWidth: "8rem" }} headerStyle={{ minWidth: "8rem" }}></Column>
                        <Column field="description" header="Description" sortable body={descriptionBodyTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable style={{ minWidth: "5rem" }} filter filterElement={statusFilterTemplate} headerStyle={{ minWidth: "5rem" }}></Column>

                        {(roles.privileges.includes("PassportType-Edit") === true ||
                            roles.privileges.includes("PassportType-Delete") === true ||
                            roles.privileges.includes("PassportType-ChangeStatus") === true) ? <Column header="Actions" body={actionBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column> : <></>}
                    </DataTable>


                    <Dialog visible={passportDialog} style={{ width: "30vw", height: "45vh" }} header="Create Passport Type"
                        modal className="p-fluid" footer={passportDialogFooter} onHide={hidePassportDialog}>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={passportType.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !passportType.name })} />
                            {submitted && !passportType.name && <small className="p-error">Passport type name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={passportType.description} onChange={(e) => onInputChange(e, 'description')} required
                                rows={5} cols={20} className={classNames({ 'p-invalid': submitted && !passportType.description })} />
                            {submitted && !passportType.description && <small className="p-error text-red">Passport type description is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={passportUpdateDialog} style={{ width: "30vw", height: "45vh" }} header="Update Passport Type"
                        modal className="p-fluid" footer={updatePassportDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={passportType.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !passportType.name })} />
                            {submitted && !passportType.name && <small className="p-invalid">Passport type name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={passportType.description} onChange={(e) => onInputChange(e, 'description')} required rows={5} cols={20} />
                        </div>
                    </Dialog>

                    <Dialog visible={deletePassportDialog} header="Confirm" modal footer={deletePassportDialogFooter} onHide={hideDeletePassportDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {passportType && <span>Are you sure you want to <b>Delete {passportType.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: '450px' }} header="Confirm" modal footer={activateDeactivatePassportDialogFooter} onHide={hideActivateDeactivatePassportDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {passportType && <span>Are you sure you want to <b>{passportType.recordStatus === 1 ? "Activate" : "Deactivate"}</b> <b>{passportType.name}</b>?</span>}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

export default PassportType;