import React from "react";
import { Route, Redirect } from "react-router-dom";

const GuardedRoute = ({ component: Component, priv, redirect, ...rest }) => {
    const roles = localStorage.getItem("roles")?.split(",")

    const privileges = []
    roles?.map((value) => {
        privileges.push({
            action: value
        })
    })

    // Revision required on the below code

    if (privileges.length > 0) {
        privileges.push({ action: "Dashboard-View" })
        privileges.push({ action: "CompanyApplication-Detail" })
        privileges.push({ action: "VisaApplication-Detail" })
        privileges.push({ action: "VisaExtension-Detail" })
    }

    const isAuthorized = privileges.filter((route) => route.action === priv).length >= 1

    return <Route {...rest} exact render={(props) => (isAuthorized ? <Component {...props} /> : <Redirect to="#/access" />)} />;
};

export default GuardedRoute;
