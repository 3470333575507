import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import eVisaService from "../../service/eVisaService/eVisaService";
import { useAuth } from "../../utilities/authContext";
import { Message } from "primereact/message";

const ApprovedApplication = () => {
    let emptyCompany = {
        firstName: "",
        surName: "",
        visaType: "",
        passportNumber: "",
        email: "",
        arrivalDate: "",
        citizenship: "",
        birthCountry: "",
        companyReferenceNumber: "",
        visaStatus: 0,
        visaStatusDescription: "",
        referenceNumber: "",
        birthCountryDescription: "",
        requestId: 0,
        visaTypeValidity: [],
    };
    const activateDeactivateBody = {
        status: 0,
        id: 0,
        requestId: 0,
    };
    const visaStatus = {
        0: "New",
        1: "Validated",
        2: "Incompelete",
        3: "APPROVED",
        4: "Denied",
    };

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = prevMonth === 11 ? year - 1 : year;
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;

    let minDate = new Date();
    minDate.setMonth(prevMonth);
    minDate.setFullYear(prevYear);

    let maxDate = new Date();
    maxDate.setMonth(nextMonth);
    maxDate.setFullYear(nextYear);

    const [companyTypes, setcompanyTypes] = useState(null);
    const [displayResponsive, setDisplayResponsive] = useState(false);
    const [selectedcompanies, setselectedcompanies] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dates1, setDates1] = useState(null);
    const [dates2, setDates2] = useState(null);
    const [passport, setPassport] = useState("");
    const [reference, setReference] = useState("");
    const [traveller, setTraveller] = useState("");
    const [email, setEmail] = useState("");
    const [OrderCode, setOrderCode] = useState("");
    const toast = useRef(null);
    const [arrivalDate, setArrivalDate] = useState(null);
    const dt = useRef(null);
    const roles = useAuth();

    useEffect(() => {
        const visaService = new eVisaService();
        setLoading(true);
        visaService
            .getApprovedRequests("ApprovedVisaApplication-View")
            .then((res) => {
                setLoading(false);
                setcompanyTypes(res.data);
            })
            .catch((error) => {
                if (error?.response.status !== 404) {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching approved visa request.", life: 3000 });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    const Options = [
        { name: "Passport Number", value: 0 },
        { name: "Reference Number", value: 1 },
        { name: "Traveller Name", value: 2 },
        { name: "Email", value: 3 },
    ];

    // const sendVisaApplicationmanual = () => {
    //     setSubmitted(true);

    //     let _companytypes = [...companyTypes];
    //     let _companytype = { ...companyType };
    //     if (companyType.id) {
    //         const index = findIndexById(companyType.id);
    //         _companytypes[index] = _companytype;
    //         const visaService = new eVisaService();
    //         activateDeactivateBody.id = _companytype.requestId;
    //         if (companyType.approvalStatus === 2) {
    //             visaService
    //                 .sendVisaApplication(activateDeactivateBody, "clienttoken", "accesstoken", "")
    //                 .then((res) => {
    //                     toast.current.show({
    //                         severity: "success",
    //                         summary: "Successful",
    //                         detail: res.data.approvalStatus === 2 ? "Email sent Successfuly" : "Email sent Successfuly" + emptyCompany.email,
    //                         life: 3000,
    //                     });
    //                 })
    //                 .catch((err) => {
    //                     // console.log(err);
    //                 });
    //         }
    //     }
    //     setcompanyTypes(_companytypes);
    //     setActivateDeactivateDialog(false);
    //     setcompanyType(emptyCompany);
    // };

    const FormatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };
    const dialogFuncMap = {
        displayResponsive: setDisplayResponsive,
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < companyTypes.length; i++) {
            if (companyTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };
    // const statusBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Approval Status </span>
    //             {approvalStatus[rowData.approvalStatus]}
    //         </>
    //     );
    // }

    const passportNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Passport Number</span>
                {rowData.passportNumber}
            </>
        );
    };
    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    };

    const arrivalDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Arrival Date</span>
                {rowData.arrivalDate.split("T")[0]}
            </>
        );
    };
    const requestDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Arrival Date</span>
                {rowData.startDate.split("T")[0]}
            </>
        );
    };
    const birthCountryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nationality</span>
                {rowData.citizenshipDescription}
            </>
        );
    };
    const visaTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Visa Type</span>
                {rowData?.visaTypeValidity?.name}
            </>
        );
    };
    const companyReferenceNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ReferenceNumber</span>
                {rowData.referenceNumber}
            </>
        );
    };
    const OrderCodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ReferenceNumber</span>
                {rowData.orderCode}
            </>
        );
    };

    const openDetail = (rowData) => {
        localStorage.setItem("selectedVisaId", rowData?.id);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/* <Button icon={rowData.recordStatus && rowData.recordStatus === 1  } label="Approve"  className="p-button-rounded p-button-danger mt-2" onClick={() => confirmActivateDeactivateCountry(rowData)} /> */}
                {roles?.privileges.includes("ApprovedVisaApplication-Detail") === true ? (
                    <Link
                        to={{ pathname: "/visa-requests/visa-detail", state: { selected: rowData } }}
                        onClick={() => {
                            openDetail(rowData);
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button label="Detail" style={{ backgroundColor: "#0D4D99" }} onClick={() => localStorage.setItem("DetailClaim", "ApprovedVisaApplication-Detail")} />
                    </Link>
                ) : (
                    <></>
                )}
            </div>
        );
    };
    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Request Status </span>
                {visaStatus[rowData?.visaStatus]}
            </>
        );
    };
    const travellrNameTemplate = (rowData) => {
        return <>{rowData.travelerName}</>;
    };

    const header = (
        <div className="flex ">
            {/* <h5 className="m-0">New Visa Application</h5> */}
            <div className="inline-flex flex-wrap flex-gap">
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="startDate" className="mb-2 mt-2">
                            Start Date
                        </label>
                        <Calendar
                            id="startDate"
                            value={dates1}
                            onChange={(e) => {
                                setDates1(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            maxDate={dates2}
                        />
                    </span>
                </span>
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            value={dates2}
                            onChange={(e) => {
                                setDates2(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            minDate={dates1}
                        />
                    </span>
                </span>
                <span style={{ minWidth: 300 }} className="">
                    <span className="flex flex-column gap-2 ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Traveller Name
                        </label>
                        <InputText value={traveller} onChange={(e) => setTraveller(e.target.value)} />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2 ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Passport Number
                        </label>
                        <InputText value={passport} onChange={(e) => setPassport(e.target.value)} />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Reference Number
                        </label>
                        <InputText value={reference} onChange={(e) => setReference(e.target.value)} />
                    </span>
                </span>

                <span style={{ minWidth: 300 }} className="">
                    <span className="flex flex-column gap-2">
                        <label className="mb-2 mt-2">Email</label>
                        <InputText value={email} onChange={(e) => setEmail(e.target.value)} />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2">
                        <label className="mb-2 mt-2">Order Code</label>
                        <InputText value={OrderCode} onChange={(e) => setOrderCode(e.target.value)} />
                    </span>
                </span>
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="arrivalDate" className="mb-2 mt-2 ">
                            Arrival Date
                        </label>
                        <Calendar
                            id="arrivalDate"
                            value={arrivalDate}
                            onChange={(e) => {
                                setArrivalDate(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                        />
                    </span>
                </span>

                <Button label="Search" icon="pi pi-search" className="mt-5  " style={{ minWidth: 200, height: 42, backgroundColor: "#0D4D99" }} onClick={(e) => SearchAll(dates1, dates2, passport, traveller, reference, email, OrderCode, arrivalDate)} />
            </div>
        </div>
    );
    const renderFootersendvisa = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                {/* <Button label="Yes" icon="pi pi-check" onClick={() => sendVisaApplicationmanual()} autoFocus /> */}
            </div>
        );
    };

    const SearchAll = (dates1, dates2, passport, traveller, reference, email, orderCode, dates3) => {
        const visaService = new eVisaService();
        let startDate = FormatDate(dates1);
        let endDate = FormatDate(dates2);
        let arrivalDate = FormatDate(dates3);
        setLoading(true);

        visaService
            .searchVisa(startDate === "1970-01-01" ? "" : startDate, endDate === "1970-01-01" ? "" : endDate, passport, traveller, reference, email, orderCode, arrivalDate === "1970-01-01" ? "" : arrivalDate, "3", "ApprovedVisaApplication-View")
            .then((res) => {
                setLoading(false);
                setcompanyTypes(res.data);
            })
            .catch((err) => {
                setLoading(false);
                setcompanyTypes(null);
            });
    };

    return (
        <div>
            <Dialog header="Send e-Visa " visible={displayResponsive} onHide={() => onHide("displayResponsive")} breakpoints={{ "960px": "75vw" }} style={{ width: "50vw" }} footer={renderFootersendvisa("displayResponsive")}>
                <h5>Are you sure you want to send e-Visa to the applicant? </h5>
            </Dialog>
            <div className="grid crud-demo">
                <h4>
                    <Message severity="info" text="Passport Number, Reference Number, Traveler Name, Email, or Request Date are all valid search terms." />
                </h4>

                <div className="col-12">
                    {/* {loading ? <SnipperModal /> : <> {null}</>} */}
                    <Toast ref={toast} />
                    <DataTable
                        ref={dt}
                        value={companyTypes}
                        selection={selectedcompanies}
                        onSelectionChange={(e) => setselectedcompanies(e.value)}
                        dataKey="id"
                        paginator
                        rows={5}
                        loading={loading}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Approved Visa Requests"
                        emptyMessage="No Approved Visa Requests found."
                        header={header}
                        scrollable
                        responsiveLayout="scroll"
                    >
                        <Column field="travelerName" header="Traveller Name" body={travellrNameTemplate} sortable frozen filter filterPlaceholder="Search by name" alignFrozen="left" style={{ minWidth: "14rem" }}></Column>
                        <Column field="passportNumber" header="Passport Number" body={passportNumberBodyTemplate} sortable frozen filter filterPlaceholder="Search by Passport Number" style={{ minWidth: "14rem" }}></Column>
                        <Column field="startDate" header="Request Date" body={requestDateBodyTemplate} sortable filter filterPlaceholder="Search by request date " style={{ minWidth: "14rem" }}></Column>
                        <Column field="arrivalDate" header="Arrival Date" body={arrivalDateBodyTemplate} sortable filter filterPlaceholder="Search by arrival date " style={{ minWidth: "14rem" }}></Column>

                        <Column field="citizenshipDescription" header="Nationality" body={birthCountryBodyTemplate} sortable filter filterPlaceholder="Search by Nationality" style={{ minWidth: "14rem" }}></Column>
                        <Column field="visaTypeValidity.name" header="Visa Type" body={visaTypeBodyTemplate} filter filterPlaceholder="Search by visa type " sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="referenceNumber" header="Reference Number" body={companyReferenceNumberBodyTemplate} sortable filter filterPlaceholder="Search by Reference Number" style={{ minWidth: "14rem" }}></Column>
                        <Column field="orderCode" header="Order Code" body={OrderCodeBodyTemplate} sortable filter filterPlaceholder="Search by order code" style={{ minWidth: "14rem" }}></Column>
                        <Column field="visaStatus" header="Request Status" body={statusBodyTemplate} style={{ minWidth: "14rem" }}></Column>

                        {roles?.privileges.includes("ApprovedVisaApplication-Detail") === true ? <Column header="Actions" body={actionBodyTemplate} frozen alignFrozen="right"></Column> : <></>}
                    </DataTable>
                </div>
            </div>
        </div>
    );
};
export default ApprovedApplication;
