import HttpService from "../shared/HttpService";
export default class AccountService {
    constructor() {
        this.roleBasePath = "/identity-server/api/v1/Role";
        this.userBasePath = "/identity-server/api/v1/User";
    }

    getroles = (claim) => {
        let relativePath = this.roleBasePath + "/GetAll";
        return HttpService.getService(relativePath, "", claim);
    };
    getRoleClaims = (claim) => {
        let relativePath = "/identity-server/api/v1/ClientClaim/GetAll";
        return HttpService.getService(relativePath, "", claim);
    };
    getSingleRole = (id, claim) => {
        let relativePath = this.roleBasePath + "/GetById?id=" + id;
        return HttpService.getService(relativePath, "", claim);
    };
    createRole = (requestData, claim) => {
        let relativePath = this.roleBasePath + "/Create";
        return HttpService.postService(requestData, relativePath, "", "", "", claim);
    };
    resetAccount = (requestData, claim) => {
        let relativePath = "/identity-server/api/v1/Password/ResetPassword";
        return HttpService.postService(requestData, relativePath, "", "", "", claim);
    };

    updateRole = (requestData, id, claim) => {
        let relativePath = this.roleBasePath + "/Update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", "", claim);
    };

    deleteRole = (id, claim) => {
        const relativePath = this.roleBasePath + "/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim);
    };

    activateDeactivateRole = (requestData, id, claim) => {
        const relativePath = this.roleBasePath + "/UpdateStatus";
        return HttpService.putService(requestData, relativePath, "", "", "", claim);
    };

    // User Service
    getUsers = (claim) => {
        let relativePath = this.userBasePath + "/GetAll?recordStatus=2";
        return HttpService.getService(relativePath, "", claim);
    };
    getCompanyUsers = (claim) => {
        let relativePath = this.userBasePath + "/GetCompanyUsers?recordStatus=2";
        return HttpService.getService(relativePath, "", claim);
    };

    getSingleUser = (id, claim) => {
        const relativePath = this.userBasePath + "/GetById?id=" + id;
        return HttpService.getService(relativePath, "", claim);
    };

    updateUser = (requestData, claim) => {
        const relativePath = this.userBasePath + "/Update";
        return HttpService.putService(requestData, relativePath, "", "", "", claim);
    };

    createUser = (requestData, claim) => {
        let relativePath = this.userBasePath + "/Create";
        return HttpService.postService(requestData, relativePath, "", "", "", claim);
    };

    deleteUser = (id, claim) => {
        let relativePath = this.userBasePath + "/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim);
    };

    activateDeactivateUser = (requestData, claim) => {
        let relativePath = this.userBasePath + "/ActivateDeactivateUser";
        return HttpService.putService(requestData, relativePath, "", "", "", claim);
    };
}
