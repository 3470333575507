import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useHistory, useLocation } from "react-router-dom";
import VisaService from "../../service/eVisaService/eVisaService";
import "../EvisaApplication/VisaDetail.css";
import SnipperModal from "../modal/SnipperModal";
import { Toast } from "primereact/toast";
import { Document } from "react-pdf";
import { Page } from "@react-pdf/renderer";
import byteToFile from "byte-to-file";
import { useAuth } from "../../utilities/authContext";

const VisaDetail = () => {
    const [displayResponsive, setDisplayResponsive] = useState(false);
    const [remark, setRemark] = useState("");
    const [PdfRecieptResponse, setPdfRecieptResponse] = useState();
    const [pdfResponsevisa, setPdfResponsevisa] = useState();
    const [approvalStatusValue, setApprovalStatusValue] = useState(0);
    const [detailData, setDetailData] = useState([]);
    const [attachements, setAttachments] = useState([]);
    const [historyDialog, setHistoryDialog] = useState(false);
    const [AirportHisDialog, setAirportHisDialog] = useState(false);
    const [ProfilePath, setProfilePath] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [AirportHisData, setAirportHisData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const toast = useRef(null);

    const roles = useAuth();
    const history = useHistory();

    if (location.state !== null && location.state !== undefined) {
        localStorage.removeItem("selectedVisaId");
        localStorage.setItem("selectedVisaId", location.state.selected.id);
    }
    const approvalStatus = {
        0: "Default",
        1: "Requested",
        2: "Approved",
        3: "Incomplete",
        4: "Deny",
    };

    const payementStatus =
    {
        0: "NOT PAID",
        1: "PENDING",
        2: "APPROVED",
        3: "CANCELED",
        4: "ERROR",
        5: "UNAUTHORIZED",
        6: "REJECTED",
        7: "REDIRECTED"
    }

    useEffect(() => {
        const visaService = new VisaService();
        visaService
            .getDetail(localStorage.selectedVisaId)
            .then((res) => {
                setDetailData(res.data);
            })
            .catch((error) => {
                // if (error?.response.status === 403) {
                //     history.pushState("/access");
                // }
            });
    }, []);

   
    const formatString = (string) => {
        const arr = string?.split(" ");

        for (var i = 0; i < arr?.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const formattedString = arr?.join(" ");
        return formattedString;
    };

    const formatDate = (date) => {
        return new Date(date).toDateString();
    };

    const dialogFuncMap = {
        displayResponsive: setDisplayResponsive,
    };

    const onClick = (name, value) => {
        setApprovalStatusValue(value);
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const showHistory = () => {
        setHistoryDialog(true);
    };
    const showAirportHistory = () => {
        setAirportHisDialog(true);
    };
    const hideHistoryDialog = () => {
        setHistoryDialog(false);
    };
    const hideAirportHistoryDialog = () => {
        setAirportHisDialog(false);
    };
    const inCompleteApplication = (body) => {
        const visaService = new VisaService();
        const activateDeactivateBody = {
            approvalStatus: approvalStatusValue,
            remark: remark,
            id: Number.parseInt(localStorage.selectedCompanyId),
        };
        setIsLoading(true);
        // console.log(activateDeactivateBody);

        visaService
            .incompeleteVisaApplication(body.id, "clienttoken", "accesstoken", body)
            .then((res) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "success", summary: "This visa Marked As Incomplete Successfully", detail: res.message, life: 3000 });
                window.location.reload();
            })
            .catch((err) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };
    const validateApplication = (body) => {
        const visaService = new VisaService();
        visaService
            .ValidateVisaApplication(body.id, "clienttoken", "accesstoken", body)
            .then((res) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "success", summary: "This visa is Request Validated Successfully and Sent For Approval", detail: res.message, life: 3000 });
                window.location.reload();
            })
            .catch((err) => {
                setRemark("");
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };
    const approveApplication = (body) => {
        const visaService = new VisaService();
        visaService
            .approveVisaApplication(body.id, "clienttoken", "accesstoken", body)
            .then((res) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "success", summary: "This visa is Request Approved Successfully", detail: res.message, life: 3000 });
                window.location.reload();
            })
            .catch((err) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };
    const denyApplication = (body) => {
        const visaService = new VisaService();
        visaService
            .denyVisaApplication(body.id, "clienttoken", "accesstoken", body)
            .then((res) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "success", summary: "This visa is Request Denied Successfully", detail: res.message, life: 3000 });
                window.location.reload();
            })
            .catch((err) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };
    const sendVisaManually = (body) => {
        const visaService = new VisaService();
        visaService
            .sendVisaApplication(body.id, "clienttoken", "accesstoken", body)
            .then((res) => {
                setIsLoading(false);
                toast.current.show({ severity: "success", summary: "This visa Attacment sent  Successfully ", detail: res.message, life: 3000 });
            })
            .catch((err) => {
                setIsLoading(false);
                //setRemark("");
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };

    const changeApplication = () => {
        dialogFuncMap[`displayResponsive`](false);
        const activateDeactivateBody = {
            // approvalStatus: approvalStatusValue,
            remark: remark,
            id: Number.parseInt(localStorage.selectedVisaId),
        };

        setIsLoading(true);
        // console.log(activateDeactivateBody);
        switch (approvalStatusValue) {
            case 0:
                validateApplication(activateDeactivateBody);
                break;
            case 1:
                validateApplication(activateDeactivateBody);
                break;
            case 2:
                inCompleteApplication(activateDeactivateBody);
                break;
            case 3:
                approveApplication(activateDeactivateBody);
                break;
            case 4:
                denyApplication(activateDeactivateBody);
                break;
            case 5:
                sendVisaManually(activateDeactivateBody);
                break;
            default:
                console.log("Unknown Status");
        }
    };
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-raised" />
                <Button label="Yes" icon="pi pi-check" onClick={() => changeApplication()} className="p-button-raised" />
            </div>
        );
    };
    return (
        <div>
            {detailData?.visaStatus === 3 ? (
                <Dialog header="Send e-Visa" visible={displayResponsive} onHide={() => onHide("displayResponsive")} breakpoints={{ "960px": "75vw" }} style={{ width: "40vw" }} footer={renderFooter("displayResponsive")}>
                    <h5>Are you sure you want to send e-Visa to the applicant?</h5>
                </Dialog>
            ) : detailData?.visaStatus !== 3 ? (
                <Dialog header="Update Status Of Visa Application" visible={displayResponsive} onHide={() => onHide("displayResponsive")} breakpoints={{ "960px": "75vw" }} style={{ width: "35vw", height: "40vh" }} footer={renderFooter("displayResponsive")}>
                    <h5>Remark</h5>
                    <InputTextarea value={remark} onChange={(e) => setRemark(e.target.value)} rows={8} cols={70} autoResize />
                </Dialog>
            ) : null}
            <Dialog visible={historyDialog} style={{ width: "600px", minHeight: "300px" }} header="Approval History " modal className="p-fluid" onHide={hideHistoryDialog}>
                {historyData?.map((data) => (
                    <div className="card">
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">Status</div>
                                    <div className="text-cyan-500 font-bold mb-3">{data?.status}</div>
                                    <div className="company-name">Remark</div>
                                    <div className="text-cyan-500 font-bold ">{data?.remark}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    {data?.approvalStatus === 1 ? (
                                        <div className="company-name">Validated Date</div>
                                    ) : data?.approvalStatus === 2 ? (
                                        <div className="company-name">InCompleted Date</div>
                                    ) : data?.approvalStatus === 3 ? (
                                        <div className="company-name">Approved Date</div>
                                    ) : data?.approvalStatus === 4 ? (
                                        <div className="company-name">Denied Date</div>
                                    ) : null}
                                    <div className="text-cyan-500 font-bold ">{data.registeredDate?.split("T").join(" | ").split(".")[0]} GMT </div>
                                    <div className="company-name">Issued by</div>
                                    <div className="text-cyan-500 font-bold ">{data?.registeredBy}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Dialog>
            <Dialog visible={AirportHisDialog} style={{ width: "600px", minHeight: "300px" }} header="Airport Verification History " modal className="p-fluid" onHide={hideAirportHistoryDialog}>
                {detailData?.entryStatusHistories?.length === 0}
                ?
                (<div className="field col">
                    <div className="invoice-company">
                        <div className="company-name"><h4>No Data Found!</h4></div>
                    </div>
                </div>)
                :

                {detailData?.entryStatusHistories?.map((data) => (
                    <div className="card">
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">Entry Status</div>
                                    <div className="text-cyan-500 font-bold mb-3">{data?.entryStatusDescription}</div>
                                    <div className="company-name">Remark</div>
                                    <div className="text-cyan-500 font-bold ">{data?.remark}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">Registered Date</div>
                                    <div className="text-cyan-500 font-bold ">{data.registeredDate?.split("T").join(" | ").split(".")[0]} GMT </div>

                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Dialog>
            <div className="grid">
                {isLoading ? <SnipperModal /> : <> {null}</>}
                <Toast ref={toast} />

                <div className="col-12">
                    <div className="card">
                        <h4>
                            <b>Traveller Profile</b>
                        </h4>
                        <div className="formgrid grid">
                            {detailData?.attachments?.map((attach) =>
                                attach.isProfilePhoto ? (
                                    <div className="field lg:col-2 md:col-4 sm:col-12">
                                        <Image className="pp" src={attach?.attachmentPath} width="100%" alt="Profile Photo" preview />
                                    </div>
                                ) : null
                            )}

                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Name</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">
                                        {formatString(detailData?.firstName)} {formatString(detailData?.surName)}
                                    </div>
                                    {/* <div className="company-name">Sur Name</div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.surName}</div> */}
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Gender</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatString(detailData?.gender)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Email</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.email}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Address City</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.cityAddress}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Birth Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.birthDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Citizenship</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.citizenshipDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Country of Birth</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.birthCountryDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Address Country </p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.countryAdressDescription}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Place of Birth</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.birthPlace}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Occupation</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.occupation}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Street Address</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.streetAddress}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Phone Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.phoneNumber}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h4>
                            <b>Request Details</b>
                        </h4>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Request Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.startDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Arrival Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.arrivalDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Visa Type</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaTypeValidity?.name}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Airlines</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.airlines}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Reference Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.referenceNumber}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Payment Status</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.paymentStatusDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Entry Status Description</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.entryStatusDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Request Status </p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.visaStatusDescription}</div>
                                    
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Last Departure Country</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.departureCountryDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Last Departure City</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.departureCity}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0"> Flight Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.flightNumber}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0"> Visa Fee</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.visaFee} USD</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h4>
                            <b>Passport Details</b>
                        </h4>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Passport Type</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.passportTypeDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Passport Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.passportNumber}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Country of Issue</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.issuingCountryDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Issuing Authority</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.issuingAuthority}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Passport Issue Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.passportIssueDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Passport Expiration Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{formatDate(detailData?.passportExpireDate)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h4>
                            <b>Address in Ethiopia</b>
                        </h4>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Hotel Accomodation Name</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.accommodationName}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Hotel Accomodation type</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.accommodationTypeDescription}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Accommodation Adress</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.accommodationAdress}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Accomodation City</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.accommodationCity}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Phone Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.accommodationPhonenumber}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {detailData?.visaStatus === 3 && detailData?.paymentStatus === 2 ? (
                <div className="card">
                    <h4>
                        <b>e-Visa Detail</b>
                    </h4>
                    <div className="formgrid grid">
                        <div className="field col">
                            <div className="invoice-company">
                                <div className="company-name">
                                    <p className="text-700 text-lg p-0">e-Visa Number</p>
                                </div>
                                <div className="text-cyan-500 font-bold ">{detailData?.accomodationName}</div>
                            </div>
                        </div>
                        <div className="field col">
                            <div className="invoice-company">
                                <div className="company-name">
                                    <p className="text-700 text-lg p-0">Issue Date</p>
                                </div>
                                <div className="text-cyan-500 font-bold ">{formatDate(detailData?.visaIssueDate)}</div>
                            </div>
                        </div>
                        <div className="field col">
                            <div className="invoice-company">
                                <div className="company-name">
                                    <p className="text-700 text-lg p-0">Valid From</p>
                                </div>
                                <div className="text-cyan-500 font-bold ">{formatDate(detailData?.visaIssueDate)}</div>
                            </div>
                        </div>
                        <div className="field col">
                            <div className="invoice-company">
                                <div className="company-name">
                                    <p className="text-700 text-lg p-0">Valid Until</p>
                                </div>
                                <div className="text-cyan-500 font-bold ">{formatDate(detailData?.visaExpiryDate)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="card">
                <div className="formgrid grid">
                    <div className="field col-7">
                        <div className="scrollpanel-demo">
                            <h4>
                                <b>Visa Documents</b>
                            </h4>
                            <ScrollPanel style={{ height: "300px" }} className="custombar1">
                                {detailData?.attachments?.map((attach) =>
                                    attach?.isProfilePhoto ? null : (
                                        <div className="col-12" key={attach?.attachmentPath}>
                                            <div className="mb-4">
                                                {attach.fileType === ".pdf" ? (
                                                    <div className="card">
                                                        <h4>{attach?.attachmentType}</h4>
                                                        <a href={attach.attachmentPath} download="passport">
                                                            <Button className="bg-pink-700" icon="pi pi-file-pdf  " iconPos="left" tooltip={attach.attachmentType}></Button>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="card">
                                                            <h4>{attach?.attachmentType}</h4>
                                                            <Image src={attach?.attachmentPath} alt="attachment" width={250} preview />{" "}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                                {detailData?.visaStatus === 3 ? <div>{/* <h4>Attachements </h4> */}</div> : null}
                            </ScrollPanel>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(VisaDetail, comparisonFn);
