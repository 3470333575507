import HttpService from "../shared/HttpService";

export default class VisaService {

  getVisaValidity = (claim) => {
    let relativePath = "visaservice/api/v1/VisaValidity/GetAll";
    return HttpService.getService(relativePath, "", claim)
  }
  GetAllVisaTypeValidities = (claim) => {
    let relativePath = "visaservice/api/v1/VisaType/GetAllVisaTypeValidities";
    return HttpService.getService(relativePath, "", claim)
  }
  getVisaType = (claim) => {
    let relativePath = "visaservice/api/v1/VisaType/GetAll";
    return HttpService.getService(relativePath, "", claim)
  }
  getSingleVisatype = (id, claim) => {
    let relativePath = "visaservice/api/v1/VisaType/GetById?id=" + id;
    return HttpService.getService(relativePath, "", claim)
  }


  updateVisaType = (requestData, claim) => {
    let relativePath = "visaservice/api/v1/VisaType/Update";
    return HttpService.putService(requestData, relativePath, "", "", "", claim)
  }
  activateDeactivateVisatype = (VisaActivateBody, claim) => {
    const relativePath = "visaservice/api/v1/VisaType/UpdateStatus";
    return HttpService.activateDeactivateService(VisaActivateBody, relativePath, "", "", "", claim)
  }

  deleteVisaType = (id, claim) => {
    let relativePath = "visaservice/api/v1/VisaType/Delete?id=" + id;
    return HttpService.deleteService(relativePath, "", claim)
  }
  createVisaValidity = (requestData, claim) => {
 
    let relativePath = "visaservice/api/v1/VisaValidity/Create";
    return HttpService.postService(requestData, relativePath, "", "", "", claim)
  }
  deleteVisaValidity = (id, claim) => {

    let relativePath = "visaservice/api/v1/VisaValidity/Delete?id=" + id
    return HttpService.deleteService(relativePath, "", claim)
  }
  editVisaValidity = (requestData, id, claim) => {
    let relativePath = "visaservice/api/v1/VisaValidity/Update?id=" + id;
    return HttpService.putService(requestData, relativePath, "", "", "", claim)
  }
  activateDeactivateVisaValidity = (id, visaValidityActivateBody, claim) => {
    let relativePath = "visaservice/api/v1/VisaValidity/updatestatus?id=" + id;
    return HttpService.activateDeactivateService(visaValidityActivateBody, relativePath, "", "", "", claim)
  }

  // not used
  updateVisa = (id, visaValidityActivateBody, clientToken, idToken, payload) => {
    let relativePath = "visaservice/api/v1/VisaValidity/updatestatus?id=" + id;
    var tokenCollection = JSON.parse(localStorage.getItem("tokenCollection"));
    return HttpService.activateDeactivateService(visaValidityActivateBody, relativePath, tokenCollection?.clientToken, tokenCollection?.idToken, payload)
  }

  createVisaType = (requestData, claim) => {
    let relativePath = "visaservice/api/v1/VisaType/Create";
    return HttpService.postService(requestData, relativePath, "", "", "", claim)
  }
  getExtensionVisaValidity = (claim) => {
    let relativePath = "visaservice/api/v1/VisaType/GetAllVisaTypeValidities";
    return HttpService.getService(relativePath, "", claim);
  };

}
