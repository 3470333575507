import HttpService from "../shared/HttpService";


export default class MasterDataService {

    getnationality = (claim) => {
        let relativePath = "masterdataservice/api/v1/Nationality/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    getnationalityReport = (claim) => {
        let relativePath = "masterdataservice/api/v1/Nationality/GetAll?recordStatus=2";
        return HttpService.getService(relativePath, "", claim)
    }
    getBulkMasterData = (claim) => {
        let relativePath = "masterdataservice/api/v1/BulkMasterData/ApplyVisaBulkData";
        return HttpService.getService(relativePath, "", claim)
    }
    deleteNationality = (id, claim) => {
        let relativePath = "masterdataservice/api/v1/Nationality/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }
    createNationality = (requestData, payload, claim) => {
        let relativePath = "masterdataservice/api/v1/Nationality/Create";
        return HttpService.postService(requestData, relativePath, "", "", payload, claim)
    }
    updateNationalities = (requestData, id, payload, claim) => {
        let relativePath = "masterdataservice/api/v1/Nationality/Update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", payload, claim)
    }
    activateDeactivateNationality = (id, countryActivateBody, payload, claim) => {
        let relativePath = "masterdataservice/api/v1/Nationality/UpdateStatus?id=" + id;
        return HttpService.activateDeactivateService(countryActivateBody, relativePath, "", "", payload, claim)
    }
    getregion = (claim) => {
        let relativePath = "masterdataservice/api/v1/Region/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    createRegion = (requestData, payload, claim) => {
        let relativePath = "masterdataservice/api/v1/Region/Create";
        return HttpService.postService(requestData, relativePath, "", "", payload, claim)
    }
    deleteRegion = (id, claim) => {
        let relativePath = "masterdataservice/api/v1/Region/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }
    editRegion = (requestData, id, payload, claim) => {
        let relativePath = "masterdataservice/api/v1/Region/Update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", payload, claim)
    }
    activateDeactivateRegion = (id, countryActivateBody, claim) => {
        let relativePath = "masterdataservice/api/v1/Region/UpdateStatus?id=" + id;
        return HttpService.activateDeactivateService(countryActivateBody, relativePath, "", "", "", claim)
    }
    getCountry = (claim) => {
        let relativePath = "masterdataservice/api/v1/Country/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }

    deleteCountry = (id, claim) => {
        let relativePath = "masterdataservice/api/v1/Country/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }
    activateDeactivateCountry = (id, countryActivateBody, payload, claim) => {
        let relativePath = "masterdataservice/api/v1/Country/UpdateStatus?id=" + id;
        return HttpService.activateDeactivateService(countryActivateBody, relativePath, "", "", payload, claim)
    }
    activateDeactivateAccommodationType = (id, accommodationActivateBody, payload, claim) => {
        let relativePath = "masterdataservice/api/v1/AccommodationType/UpdateStatus?id=" + id;
        return HttpService.activateDeactivateService(accommodationActivateBody, relativePath, "", "", payload, claim)
    }
    updateCountries = (requestData, id, payload, claim) => {
        let relativePath = "masterdataservice/api/v1/Country/Update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", payload, claim)
    }
    getVisaType = (claim) => {
        let relativePath = "visaservice/api/v1/VisaType/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    getVisaTypeReport = (claim) => {
        let relativePath = "visaservice/api/v1/VisaType/GetAll?recordStatus=2";
        return HttpService.getService(relativePath, "", claim)
    }
    activateDeactivateVisatype = (id, VisaActivateBody, payload, claim) => {
        let relativePath = "visaservice/api/v1/VisaType/UpdateStatus?id=" + id;
        return HttpService.activateDeactivateService(VisaActivateBody, relativePath, "", "", payload, claim)
    }
    deleteVisaType = (id, claim) => {
        let relativePath = "visaservice/api/v1/VisaType/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }

    // not used
    editVisaType = (requestData, clientToken, idToken, payload) => {
        let relativePath = "masterdataservice/api/v1/VisaType/Update";
        return HttpService.putService(requestData, relativePath, "", "", payload)
    }
    createCountry = (requestData, payload, claim) => {
        let relativePath = "masterdataservice/api/v1/Country/Create";
        return HttpService.postService(requestData, relativePath, "", "", payload, claim)

    }

    // not used
    editCountry = (requestData, clientToken, idToken, payload, id) => {
        let relativePath = "masterdataservice/api/v1/Country/Update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", payload)
    }

    createVisaType = (requestData, payload, claim) => {
        let relativePath = "visaservice/api/v1/VisaType/Create";
        return HttpService.postService(requestData, relativePath, "", "", payload, claim)
    }
    getAttachementType = (claim) => {
        let relativePath = "masterdataservice/api/v1/AttachementType/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }

    createAttachementType = (requestData, claim) => {
        let relativePath = "masterdataservice/api/v1/AttachementType/Create";
        return HttpService.postService(requestData, relativePath, "", "", "", claim)
    }
    updateAttachementType = (requestData, id, claim) => {

        let relativePath = "masterdataservice/api/v1/attachementType/update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    deleteAttachementType = (id, claim) => {

        let relativePath = "masterdataservice/api/v1/attachementType/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }
    activateDeactivateAttachementType = (id, activateDeactivateBody, claim) => {
        let relativePath = "masterdataservice/api/v1/AttachementType/UpdateStatus?id=" + id;
        return HttpService.activateDeactivateService(activateDeactivateBody, relativePath, "", "", "", claim)
    }
    getPassportType = (claim) => {

        let relativePath = "masterdataservice/api/v1/PassportType/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    createPassportType = (requestData, claim) => {
        let relativePath = "masterdataservice/api/v1/PassportType/Create";
        return HttpService.postService(requestData, relativePath, "", "", "", claim)
    }
    updatePassportType = (requestData, id, claim) => {
        let relativePath = "masterdataservice/api/v1/PassportType/update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    deletePassportType = (id, claim) => {

        let relativePath = "masterdataservice/api/v1/PassportType/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }
    activateDeactivatePassportType = (id, activateDeactivateBody, claim) => {
        let relativePath = "masterdataservice/api/v1/PassportType/UpdateStatus?id=" + id;
        return HttpService.activateDeactivateService(activateDeactivateBody, relativePath, "", "", "", claim)
    }
    getAccommodationType = (claim) => {

        let relativePath = "masterdataservice/api/v1/AccommodationType/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    createAccommodationType = (requestData, claim) => {
        let relativePath = "masterdataservice/api/v1/AccommodationType/Create";
        return HttpService.postService(requestData, relativePath, "", "", "", claim)
    }
    updateAccommodationType = (requestData, id, claim) => {
        let relativePath = "masterdataservice/api/v1/AccommodationType/update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    deleteAccommodationType = (id, claim) => {

        let relativePath = "masterdataservice/api/v1/AccommodationType/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }

    // not used
    getCurreny = (claim) => {
        let relativePath = "masterdataservice/api/v1/Currency/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    getAllFaq = (claim) => {
        let relativePath = "masterdataservice/api/v1/Faq/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    deleteFaq = (id, claim) => {
        let relativePath = "masterdataservice/api/v1/Faq/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }
    createFaq = (data, claim) => {
        let relativePath = "masterdataservice/api/v1/Faq/Create";
        return HttpService.postService(data, relativePath, "", "", "", claim)
    }
    updateFaq = (data, id, claim) => {
        let relativePath = "masterdataservice/api/v1/Faq/Update?id=" + id;
        return HttpService.putService(data, relativePath, "", "", "", claim)
    }

    getIncompeleteReasons = (messageType,status, claim) => {
        debugger
        let relativePath = "masterdataservice/api/v1/NotificationMessages/GetByMessageType?Status=" + status + "&messageType=" + messageType;
        return HttpService.getService(relativePath, "", claim)
    }
}
