import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import extensionService from "../../service/extensionService/extensionService";
import "../EvisaApplication/VisaDetail.css";
import SnipperModal from "../modal/SnipperModal";
import { Divider } from "primereact/divider";

import { Toast } from "primereact/toast";
import { useAuth } from "../../utilities/authContext";

const Invoice = () => {
    const [detailData, setDetailData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const toast = useRef(null);
    const [profilePath, setProfilePath] = useState("assets/layout/images/avatar/passPP.png");
    const roles = useAuth();
    const history = useHistory();

    if (location.state !== null && location.state !== undefined) {
        // console.log("ssss", location.state.selected);
        localStorage.removeItem("selectedTravellerId");
        localStorage.setItem("selectedTravellerId", location.state.selected.id);
    }

    const applicationService = new extensionService();

    useEffect(() => {
        applicationService
            .getDetail(localStorage.selectedTravellerId)
            .then((res) => {
                setDetailData(res.data);
            })
            .catch((error) => {
                // if (error?.response?.status === 403) {
                //     history.pushState('/access')
                // }
            });

    }, []);

    const formatString = (string) => {
        const arr = string?.split(" ");

        for (var i = 0; i < arr?.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const formattedString = arr?.join(" ");
        return formattedString;
    };

    const formatDate = (date) => {
        return new Date(date).toDateString();
    };

    return (
        <div>

            <div className="grid">
                {isLoading ? <SnipperModal /> : <> {null}</>}
                <Toast ref={toast} />

                <div className="col-12">
                    <div className="card">
                        <div className="formgrid grid">

                            {detailData?.attachments?.map((attach) => (
                                attach.isProfilePhoto ? <div className="field lg:col-2 md:col-4 sm:col-12">
                                    <Image className="pp" src={attach?.attachmentPath} alt="Profile Photo" width="100%" height="100%" preview />
                                </div> : null
                            ))}
                            <div className="field col-5">
                                <h3 className="font-bold mt-4 ml-2">Traveller Profile</h3>
                                <div className="invoice-company ml-2">
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Name</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">
                                        {formatString(detailData?.visaRequest?.firstName)} {formatString(detailData.visaRequest?.surName)}
                                    </div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Nationality</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaRequest?.birthCountryDescription}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Birth Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.visaRequest?.birthDate)}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Gender</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold">{formatString(detailData?.visaRequest?.gender)}</div>
                                </div>
                            </div>
                            <div className="field col-5 mt-8">
                                <div className="invoice-company ">
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Occupation</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaRequest?.occupation}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Phone Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaRequest?.phoneNumber}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Email</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold">{detailData?.visaRequest?.email}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h3 className="font-bold mt-4 ml-2">Request Details</h3>
                        <div className="formgrid grid">
                            <div className="field col-6">
                                <div className="invoice-company ml-2">
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Request Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.startDate)}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Reference Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.referenceNumber}</div>
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Payment Status</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold">{detailData?.paymentStatusDescription}</div>
                                </div>
                            </div>
                            <div className="field col-5">
                                <div className="invoice-company">
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Extended Days</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.noofExtendedDays}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Visa Extension Status</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaStatusDescription}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Visa Extension Fee</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaExtensionFee}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company"></div>
                            </div>
                        </div>
                    </div>
                    <div className="card col-12">
                        <div className="form-grid grid">
                            <div className="field lg:col-7 col-12">
                                <div className="scrollpanel-demo">
                                    <h3 className="font-bold mt-4 ml-2">Traveller Documents</h3>

                                    <ScrollPanel style={{ height: "300px" }} className="custombar1">
                                        {detailData?.attachments?.map((attach) => (
                                            <div className="col-12">
                                                <div className="shadow-1">
                                                    {attach?.fileType === ".pdf" ? (
                                                        <div className="card">
                                                            <h4>{attach?.attachmentType}</h4>
                                                            <a href={attach?.attachmentPath} download="passport">
                                                                <Button className="bg-pink-700" icon="pi pi-file-pdf  " iconPos="left">
                                                                    {" "}
                                                                </Button>
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {attach?.isProfilePhoto ? null : (
                                                                <div className="card">
                                                                    <h4>{attach?.attachmentType}</h4>
                                                                    <Image src={attach?.attachmentPath} alt="attachment" width={250} preview />{" "}
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </ScrollPanel>
                                </div>
                            </div>
                            <div className="field col">
                                <h3 className="font-bold mt-4 ml-2">Passport Details</h3>
                                <div className="row flex">
                                    <div className="col">
                                        <div>
                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Passport Number</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold mb-3">{detailData?.visaRequest?.passportNumber}</div>
                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Passport Type</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold ">{detailData?.visaRequest?.passportTypeDescription
                                            }</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div>
                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Issuer Country</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold mb-3">{detailData?.visaRequest?.issuingCountryDescription
                                            }</div>

                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Issuing Authority</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold">{detailData?.visaRequest?.issuingAuthority}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row flex">
                                    <div className="col">
                                        <div>
                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Issue Date</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold ">{formatDate(detailData?.visaRequest?.passportIssueDate)}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div>
                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Passport Expiration Date</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold ">{formatDate(detailData?.visaRequest?.passportExpireDate)}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Invoice, comparisonFn);
