import React, { useState, useEffect, useRef } from "react";

import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import SnipperModal from "../modal/SnipperModal";
import MasterDataService from "../../service/master-data/MasterDataService";
import VisaAttachmentService from "../../service/master-data/VisaAttachmentService";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useAuth } from "../../utilities/authContext";


const VisaAttachment = () => {
    let emptyrequiredAttachement = {
        id: 0,
        visaType: { id: 0 },
        attachementTypeId: 0,
        description: '',
        suffix: '',
        name: '',
        visamodeDescription: '',
        visamode: 0,


    };
    let emptyrequiredAttachement1 = {
        id: 0,
        visaTypeId: 49,
        attachementTypeId: 0,
        description: '',
        suffix: '',
        name: '',
        visamodeDescription: '',
        visamode: 0,


    };
    const requestBody = {

        visaType: { id: 0 },
        attachementTypeId: 0,
        description: '',
        visamode: 0

    };
    const visamode = [
        { name: "New", value: 0 },
        { name: "Extension", value: 1 },
    ];

    const activateDeactivateBody = {
        status: 0,
    };

    let [loading, setLoading] = useState(false);
    const [visaFees, setvisaFees] = useState(null);
    const [attachmentTypes, setAttachmentTypes] = useState(null);
    const [visaTypes, setvisaTypes] = useState(null);
    const [selectedAttachmentType, SetSelectedAttachmentType] = useState({ id: 0, name: "" });
    const [selectedCompanyCategory, SetSelectedCompanyCategory] = useState({ id: 0, name: "" });
    const [accommodationUpdateDialog, setAccommodationUpdateDialogDialog] = useState(false);
    const [visaFeeDialog, setvisaFeeDialog] = useState(false);
    const [deletevisaFeeDialog, setDeletevisaFeeDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);
    const [visaFee, setvisaFee] = useState(emptyrequiredAttachement);
    const [visaFee1, setvisaFee1] = useState(emptyrequiredAttachement1);
    const [selectedAccommodations, setSelectedAccommodations] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [selectedVisaMode, setSelectReason] = useState(null);
    const [selectedReasonEnum, setSelectReasonEnum] = useState(0);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'visaType.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        const visaAttachmentService = new VisaAttachmentService();
        setLoading(true)
        visaAttachmentService.getAll("VisaAttachment-View")
            .then((res) => {
                setLoading(false)
                setvisaFees(res.data);
             

            })
            .catch((error) => {
                setLoading(false)
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching visa attachment.", life: 3000 });
            });
        const commonService = new MasterDataService();
        commonService
            .getAttachementType("VisaAttachment-View")
            .then((res) => {
                setAttachmentTypes(res.data);
            })
        const visaTypeService = new MasterDataService();
        visaTypeService.getVisaType("VisaAttachment-View")
            .then((res) => {
                setvisaTypes(res.data);

            })

    }, []);
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        const _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value)
    }
    const openNew = () => {
        setvisaFee1(emptyrequiredAttachement1);
        setSubmitted(false);
        setvisaFeeDialog(true);
    };
   

    const hidevisaFeeDialog = () => {
        setSubmitted(false);
        setvisaFeeDialog(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setAccommodationUpdateDialogDialog(false);
    };
    const editAccommodation = (visaFee) => {
        setvisaFee({ ...visaFee });


        setAccommodationUpdateDialogDialog(true);
    };
    const hideDeletevisaFeeDialog = () => {
        setDeletevisaFeeDialog(false);
    };
    const hideActivateDeactivatevisaFeeDialog = () => {
        setActivateDeactivateDialog(false);
    };
    const SubmitVisaFee = () => {
        setSubmitted(true);
        let _visaFees = [...visaFees];
        let _vsiaFee = { ...visaFee1 };
        setLoading(true)
        const visaAttachmentService = new VisaAttachmentService();
        visaAttachmentService.create(_vsiaFee, "VisaAttachment-Add")
            .then((res) => {
                setLoading(false)
                toast.current.show({ severity: "success", summary: "Successful", detail: "Visa Attachements Created", life: 3000 });
                refreshVisaFee()
            })
            .catch((err) => {
                setLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Visa already Attachements Created", life: 3000 });
            });
        setvisaFees(_visaFees);
        setvisaFeeDialog(false);
        setvisaFee(emptyrequiredAttachement);
    };
    const UpdatevisaFee = () => {

        setSubmitted(true);
        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };

        if (visaFee.id) {
            const index = findIndexById(visaFee.id);

            _visaFees[index] = _visaFee;
            const visaAttachmentService = new VisaAttachmentService();
            requestBody.visamode = _visaFee.visamode
            requestBody.visaTypeId = _visaFee.visaType.id;
            requestBody.attachementTypeId = _visaFee.attachementTypeId;
            requestBody.description = _visaFee.description;

            visaAttachmentService.edit(requestBody, _visaFee.id, "VisaAttachment-Edit")
                .then((res) => {
                    //setLoading(false)
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Attachment Updated', life: 3000 });
                    refreshVisaFee();

                })
                .catch((err) => {
                    //setLoading(false)
                    toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Attachment already exist", life: 3000 });
                });
        }
        else {

        }
        setvisaFees(_visaFees);
        setAccommodationUpdateDialogDialog(false);
        setvisaFee(emptyrequiredAttachement);

    }

    const confirmDeleteVisaFee = (visaFee) => {
        setvisaFee(visaFee);
        setDeletevisaFeeDialog(true);
    };
    const confirmactivateDeactivateVisaFee = (visaFee) => {
        setvisaFee(visaFee);
        setActivateDeactivateDialog(true);
    };
    const deleteVisaFee = () => {
        let _visaFees = visaFees.filter((val) => val.id !== visaFee.id);

        const masterDataService = new VisaAttachmentService();
        setLoading(true)

        masterDataService.delete(visaFee.id, "VisaAttachment-Delete").then((res) => {
            setLoading(false)
            setvisaFees(_visaFees);
            setDeletevisaFeeDialog(false);
            setvisaFee(emptyrequiredAttachement);
            toast.current.show({ severity: "success", summary: "Successful", detail: "Attachement Deleted", life: 3000 });
        }).catch((err) => {
            setLoading(false)
            toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Attachement Already Registered", life: 3000 });

        });
    };
    const refreshVisaFee = () => {
        const masterDataService = new VisaAttachmentService();
        masterDataService
            .getAll("VisaAttachment-View")
            .then((res) => {
                setvisaFees(res.data);

            })
            .catch((error) => { });
    };

    const activateDeactivateVisaFee = () => {

        const masterDataService = new VisaAttachmentService();
        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };

        if (visaFee.id) {
            const index = findIndexById(visaFee.id)
            _visaFees[index] = _visaFee;
            activateDeactivateBody.id = visaFee.id;
            if (visaFee.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            } else activateDeactivateBody.status = 1;
            masterDataService.activateDeactivate(activateDeactivateBody, "VisaAttachment-ChangeStatus").then((res) => {
                toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Visa Attachment Activated" : "Visa  Attachment Deactivated", life: 3000 });
                refreshVisaFee();
            })
                .catch((err) => {
                    setLoading(false)
                    toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Error While Updating Visa Attachment status", life: 3000 });
                });
        }
        setvisaFees(_visaFees);
        setActivateDeactivateDialog(false);
        setvisaFee(emptyrequiredAttachement);
    }
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < visaFees.length; i++) {
            if (visaFees[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = val;
        setvisaFee(_visaFee);
    };
    const onInputChange1 = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _visaFee = { ...visaFee1 };
        _visaFee[`${name}`] = val;
        setvisaFee1(_visaFee);
    };
    const onAttachmentTypeChange = (e, name) => {
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = e.value;
        setvisaFee(_visaFee);
        let selectedValue = attachmentTypes.find((c) => c.id === e.value);
        SetSelectedAttachmentType(selectedValue);

    };
    const onAttachmentTypeChange1 = (e, name) => {
        let _visaFee = { ...visaFee1 };
        _visaFee[`${name}`] = e.value;
        setvisaFee1(_visaFee);
        let selectedValue = attachmentTypes.find((c) => c.id === e.value);
        SetSelectedAttachmentType(selectedValue);

    };
    const onVisaTypeChange = (e, name) => {
        let _visaFee = { ...visaFee };

        _visaFee.visaType.id = e.value;
        setvisaFee(_visaFee);
        let selectedValue = visaTypes.find((c) => c.id === e.value);
        SetSelectedCompanyCategory(selectedValue);



    };
    const onVisaTypeChange1 = (e, name) => {
        let _visaFee = { ...visaFee1 };


        _visaFee.visaTypeId = e.value;
        setvisaFee1(_visaFee);
        let selectedValue = visaTypes.find((c) => c.id === e.value);
        SetSelectedCompanyCategory(selectedValue);



    };
    const onVisaModeChange = (e) => {
        setSelectReason(e.value);
        setSelectReasonEnum(e.value);
    };
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("VisaAttachment-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    };
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText  value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search Vis Attachment" />
            </span>
        )
    }
    const visaTypeBodyTemplate = (rowData) => {
        
        return (
            <>
                <span className="p-column-title">Visa  Type</span>
                {rowData?.visaType?.name}
            </>
        );
    };

    const attachementTypenameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Attachement Type</span>
                {rowData?.attachementType?.name}
            </>
        );
    };
    const visaModeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Attachement Type</span>
                {rowData.visamodeDescription}
            </>
        );
    };
    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Attachement Type</span>
                {rowData?.description}
            </>
        );
    };
    const status = [
        { name: "Active", value: '1' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={status} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }


    const actionBodyTemplate = (rowData) => {

        return (
            <div className="actions">
                {roles.privileges.includes("VisaAttachment-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editAccommodation(rowData)} /> : <></>}
                {roles.privileges.includes("VisaAttachment-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteVisaFee(rowData)} /> : <></>}
                {roles.privileges.includes("VisaAttachment-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info mr-2" onClick={() => confirmactivateDeactivateVisaFee(rowData)} /> : <></>}
            </div>
        );
    };
    
    const updatevisaFeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdatevisaFee} />
        </>
    );

    const visaFeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hidevisaFeeDialog} />
            <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitVisaFee} />
        </>
    );

    const deletevisaFeeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeletevisaFeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteVisaFee} />
        </>
    );

    const activateDeactivatevisaFeeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivatevisaFeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateVisaFee} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable
                        ref={dt}
                        value={visaFees}
                        selection={selectedAccommodations}
                        onSelectionChange={(e) => setSelectedAccommodations(e.value)}
                        dataKey="name"
                        paginator
                        filter={filters}
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        scrollable
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Attachments"
                         globalFilter={globalFilterValue}
                        emptyMessage="No Visa attachement found."
                        responsiveLayout="scroll">
                        <Column field="visaType.name" header="Visa Type Name" sortable body={visaTypeBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                        <Column field="visamodeDescription" header="Visa Mode"  sortable body={visaModeBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                        <Column field="description" header="Visa Attachement Description" sortable body={descriptionBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                        <Column field="attachementType.name" sortable header="Attachement type" body={attachementTypenameBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} style={{ minWidth: "5rem" }} filter filterElement={statusFilterTemplate} headerStyle={{ minWidth: "5rem" }}></Column>
                        <Column header="Actions" body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={visaFeeDialog} style={{ width: "450px" }} header="Create Visa Attachment " modal className="p-fluid" footer={visaFeeDialogFooter} onHide={hidevisaFeeDialog}>
                        <div className="field">
                            <label htmlFor="name">Description</label>
                            <InputText id="description" value={visaFee1.description} onChange={(e) => onInputChange1(e, "description")} required autoFocus className={classNames({ "p-invalid": submitted && !visaFee.description })} />
                            {submitted && !visaFee.description && <small className="p-error">Description is required.</small>}
                        </div>
                        <div className="field">

                            <div className="field">
                                <Dropdown optionLabel="name" value={visaFee1.visamode} options={visamode}
                                    onChange={(e) => onAttachmentTypeChange1(e, "visamode")}
                                    placeholder="Select Visa Mode" />
                            </div>
                        </div>
                        <div className="field">
                            <Dropdown optionValue="id" optionLabel="name" value={selectedAttachmentType?.id} optionName={visaFee1.attachementTypeId} options={attachmentTypes} onChange={(e) => onAttachmentTypeChange1(e, "attachementTypeId")} placeholder="Select Attachement Type" />
                        </div>
                        <div className="field">
                            <Dropdown optionValue="id" optionLabel="name" value={visaFee1?.visaTypeId} optionName={visaFee1?.visaTypeId} options={visaTypes} onChange={(e) => onVisaTypeChange1(e, "visaTypeId")} placeholder="Select Select Visa types" />
                        </div>
                       
                    </Dialog>


                    {/* Update Dialog */}
                    <Dialog visible={accommodationUpdateDialog} style={{ width: "450px" }} header="Update  visa Attachment" modal className="p-fluid" footer={updatevisaFeeDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Description</label>
                            <InputText id="description" value={visaFee.description} onChange={(e) => onInputChange(e, "description")} required autoFocus className={classNames({ "p-invalid": submitted && !visaFee.description })} />
                            {submitted && !visaFee.description && <small className="p-error">Description is required.</small>}
                        </div>
                        <div className="field">

                            <Dropdown optionLabel="name" value={visaFee.visamode} options={visamode}
                                onChange={(e) => onVisaModeChange(e, "visamode")}
                                placeholder="Select Visa Mode" />
                        </div>
                        <div className="field">
                            <Dropdown optionValue="id" optionLabel="name" value={visaFee.attachementTypeId} optionName={visaFee.attachementTypeId} options={attachmentTypes} onChange={(e) => onAttachmentTypeChange(e, "attachementTypeId")} placeholder="Select Attachement Type" />
                        </div>
                        <div className="field">
                            <Dropdown optionValue="id" optionLabel="name" value={visaFee?.visaType?.id} optionName={visaFee?.visaType?.name} options={visaTypes} onChange={(e) => onVisaTypeChange(e, "visaTypeId")} placeholder="Select Visa types" />
                        </div>
                    </Dialog>
                  

                    <Dialog visible={deletevisaFeeDialog} style={{ width: "450px" }} header="Confirm" modal footer={deletevisaFeeDialogFooter} onHide={hideDeletevisaFeeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {visaFee && <span>Are you sure you want to delete <b>{visaFee?.attachementType?.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: "450px" }} header="Confirm" modal footer={activateDeactivatevisaFeeDialogFooter} onHide={hideActivateDeactivatevisaFeeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {visaFee && (
                                <span>
                                    Are you sure you want to activate attachement <b>{visaFee.description}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default VisaAttachment;
