import React, { useState, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import  NotificationService from '../../../service/master-data/NotificationService';
import SnipperModal from "../../modal/SnipperModal";
import { useAuth } from '../../../utilities/authContext';

const Notification = () => {
    let emptynotificationType = {
        id: 0,
        text: '',
        messageType: '',
        messageTypeDescription: '',
        recordStatus: 0,
    };
    const notificationBody = {
        text: '',
        messageType: '',
     
    };
    const activateDeactivateBody = {
        status: 0,
    };
    const deleteBody = {
        status: 3,
    };

    const messageType = [
        { name: "Visa", value: 1 },
        { name: "VisaExtension", value: 2},
        { name: "Company", value: 3},  
    ];

    let [loading, setLoading] = useState(false);
    const [notificationTypes, setnotificationTypes] = useState(null);
    const [notificationUpdateDialog, setnotificationUpdateDialog] = useState(false);
    const [notificationDialog, setnotificationDialog] = useState(false);
    const [deleteNotificationDialog, setNotificationDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);
    const [selectednotificationType, SetSelectednotificationType] = useState({ id: 0, name: "" });
    const [performingAction, setPerformingAction] = useState(false);
    const [notificationType, setnotificationType] = useState(emptynotificationType);
    const [selectedcountries, setSelectedcountries] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        
        const notificationService = new NotificationService();
        setLoading(true)
         
        notificationService.getAll("MarkAsIncomplete-View")
            .then(res => {
                setLoading(false)
                setnotificationTypes(res.data)
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching Notifications.", life: 3000 });
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    const openNew = () => {
        setnotificationType(emptynotificationType);
        setSubmitted(false);
        setnotificationDialog(true);
    }
    const hideCountryDialog = () => {
        setSubmitted(false);
        setnotificationDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setnotificationUpdateDialog(false);
    }

    
    const hideActivateDeactivateCountryDialog = () => {
        setActivateDeactivateDialog(false);
    }

    
    const deleteNotifications = () => {
        let _notificationTypes = notificationTypes.filter((val) => val.id !== notificationType.id);
        deleteBody.id = notificationType?.id
        const notificationService = new NotificationService();
        setLoading(false);
        setPerformingAction(true);
        notificationService.deleteByactivateDeactivate(deleteBody, "MarkAsIncomplete-Delete")
            .then((res) => {
                setnotificationTypes(_notificationTypes);
                setActivateDeactivateDialog(false);
                setnotificationType(emptynotificationType);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Message Notifications  Deleted", life: 3000 });
               refresh()
            })
            .catch((err) => {
                setLoading(false);
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setNotificationDialog(false);
            });
    };
    const SubmitCountry = () => {
        setPerformingAction(true);
        setSubmitted(true);
         
        let _notificationTypes = [...notificationTypes];
        let _notificationType = { ...notificationType };
        notificationBody.text = _notificationType.text;
        notificationBody.messageType = _notificationType.messageType;
        const notificationService = new NotificationService();
        
        notificationService.create(notificationBody,  "MarkAsIncomplete-Add")
            .then((res) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Notification Message Created', life: 3000 });
                refresh();
            })
            .catch((err) => {
                
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Notification Message already Registered ", life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                //setnotificationTypes(_notificationTypes);
                setnotificationDialog(false);
                setnotificationType(emptynotificationType);
            })
    }
    const UpdateCountry = () => {
        setPerformingAction(true);
        setSubmitted(true);
 
        if (notificationType.text.trim()) {
            let _notificationTypes = [...notificationTypes];
            let _notificationType = { ...notificationType };
            if (notificationType.id) {
                const index = findIndexById(notificationType.id);

                _notificationTypes[index] = _notificationType;
                const notificationService = new NotificationService(); 

                //notificationBody.id=_notificationType?.id
                notificationBody.text = _notificationType?.text;
                notificationBody.messageType = _notificationType?.messageType;
                  
                notificationService.edit(notificationBody, _notificationType.id, "MarkAsIncomplete-Edit")
                    .then((res) => {
                      
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Notification Message Updated', life: 3000 });
                        refresh();
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
                    }).finally(() => {
                        setPerformingAction(false);
                        setnotificationTypes(_notificationTypes);
                        setnotificationUpdateDialog(false);
                        setnotificationType(emptynotificationType);
                    });
            }
        }
    }
    const editCountry = (notificationType) => {
        setnotificationType({ ...notificationType });
        setnotificationUpdateDialog(true);
    }

    const confirmNotificationCountry = (notificationType) => {
        setnotificationType(notificationType);
        setNotificationDialog(true);
    }
    const hideNotificationDialog = () => {
        setNotificationDialog(false);
    };
    const hideDeleteNotificationDialog = () => {
        setNotificationDialog(false);
    }
    const confirmActivateDeactivateCountry = (notificationType) => {
        setnotificationType(notificationType);
        setActivateDeactivateDialog(true);
    }
   
    
    const activateDeactivateCountryname = () => {
        setPerformingAction(true);
        setSubmitted(true);
             debugger
        let _notificationTypes = [...notificationTypes];
        let _notificationType = { ...notificationType };
        if (notificationType.id) {
            const index = findIndexById(notificationType.id);

            _notificationTypes[index] = _notificationType;
            const notificationService = new NotificationService();

            if (notificationType.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            }
            else
                activateDeactivateBody.status = 1; 
                debugger
                notificationService.activateDeactivate(notificationType.id, activateDeactivateBody, "MarkAsIncomplete-ChangeStatus")
                    .then((res) => {

                        toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Notification Message Activated" : "Notification Message Deactivated", life: 3000 });
                        refresh();
                    })
                    .catch((err) => {

                    }).finally(() => {
                        setPerformingAction(false);
                        setnotificationTypes(_notificationTypes);
                        setActivateDeactivateDialog(false);
                        setnotificationType(emptynotificationType);
                    });

        }
    }
    const refresh = () => {
        const notificationService = new NotificationService();
        notificationService.getAll("MarkAsIncomplete-View")
            .then((res) => {
                setnotificationTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < notificationTypes.length; i++) {
            if (notificationTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _notificationType = { ...notificationType };
        _notificationType[`${name}`] = val;
        setnotificationType(_notificationType);
    }
    const OnmessageChangeType = (e, name) => {
        let _notificationType = { ...notificationType };
        _notificationType[`${name}`] = e.value;
        setnotificationType(_notificationType);
        let selectedValue = notificationTypes.find((c) => c.id === e.value);
        SetSelectednotificationType(selectedValue);

    };
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("MarkAsIncomplete-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Notification Name</span>
                {rowData?.text}
            </>
        );
    }
    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Notification Description</span>
                {rowData?.messageTypeDescription}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("MarkAsIncomplete-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editCountry(rowData)} /> : <></>}
               {roles.privileges.includes("MarkAsIncomplete-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmNotificationCountry(rowData)} /> : <></>}
                {roles.privileges.includes("MarkAsIncomplete-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmActivateDeactivateCountry(rowData)} /> : <></>}
            </div>
        );
    }

    const status = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={status} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }
    
    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Notification</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const updateCountryDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {/* {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> : */}
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdateCountry} />
        </>
    );
    const deleteNotificationDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeleteNotificationDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteNotifications} />
        </>
    );
    const counrtyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideCountryDialog} />
            {/* {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> : */}
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitCountry} />
        </>
    );

    const activateDeactivatenotificationDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivateCountryDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateCountryname} />}
        </>
    );
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable ref={dt} filters={filters} value={notificationTypes} selection={selectedcountries} onSelectionChange={(e) => setSelectedcountries(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Countries" // header={header}
                        globalFilter={globalFilter} emptyMessage="No Countries found." scrollable responsiveLayout="scroll">
                        <Column field="messageTypeDescription" header="Notification Type Description " sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>

                        <Column field="text" header="Notification" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        
                        <Column field="recordStatus" header="Record Status" body={recordStatusBody} sortable   filter filterElement={statusFilterTemplate}style={{ width: "8rem" }} headerStyle={{ width: "8rem" }}></Column>

                        {(roles.privileges.includes("MarkAsIncomplete-Edit") === true ||
                            roles.privileges.includes("MarkAsIncomplete-Delete") === true ||
                            roles.privileges.includes("MarkAsIncomplete-ChangeStatus") === true) ? <Column header="Actions" body={actionBodyTemplate}></Column> : <></>}
                    </DataTable>

                    <Dialog visible={notificationDialog} style={{ width: '450px' }} header="Create Notification"
                        modal className="p-fluid" footer={counrtyDialogFooter} onHide={hideCountryDialog}>
                          <div className="field">

                            <div className="field">
                                <Dropdown optionLabel="name" value={notificationType.messageType} options={messageType}
                                    onChange={(e) => OnmessageChangeType(e, "messageType")}
                                    placeholder="Select Message Type" />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="name">Notification Description</label>
                            <InputTextarea id="name" value={notificationType.text} onChange={(e) => onInputChange(e, 'text')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !notificationType.text })} />
                            {submitted && !notificationType.text && <small className="p-error"> Country name is required.</small>}
                        </div>

                    </Dialog>

                    <Dialog visible={notificationUpdateDialog} style={{ width: '450px' }} header="Update Notification"
                        modal className="p-fluid" footer={updateCountryDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <Dropdown optionLabel="name" value={notificationType.messageType} options={messageType}
                                onChange={(e) => OnmessageChangeType(e, "messageType")}
                                placeholder="Select Message Type" />
                        </div>
                        <div className="field">
                            <label htmlFor="name">Notification Description</label>
                            <InputTextarea id="name" value={notificationType.text} onChange={(e) => onInputChange(e, 'text')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !notificationType.text })} />
                            {submitted && !notificationType.text && <small className="p-error"> Country name is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: '450px' }} header="Confirm" modal footer={activateDeactivatenotificationDialogFooter} onHide={hideActivateDeactivateCountryDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {notificationType && <span>
                                Are you sure you want to <b>{notificationType.recordStatus === 1 ? "Activate" : "Deactivate"}</b> <b>{notificationType.name}</b>?
                            </span>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteNotificationDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteNotificationDialogFooter} onHide={hideNotificationDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {notificationType && (
                                <span>
                                    Are you sure you want to delete  Notification Messages <b>{notificationType.text}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog> 

                </div>
            </div>
        </div>
    );
}

export default Notification;