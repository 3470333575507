import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { toggleAuth, useAuth } from "../utilities/authContext";
import logo from '../assets/apollo_logo.png';
import eVisaService  from "../service/eVisaService/eVisaService";
const AppTopbar = (props) => {
    const history = useHistory();
    const cookies = new Cookies();
    const auth = useAuth();
    const visaService = new eVisaService();
    const [emailCount, setemailCount] = useState(0);

    const onTopbarItemClick = (event, item) => {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item,
            });
        }
    };
    const onTopbarNotificationMenuButtonClick = (event, item) => {
        if (props.onTopbarNotificationMenuButtonClick) {
            props.onTopbarNotificationMenuButtonClick({
                originalEvent: event,
                item: item,
            });
        }
    };

    const handleChangePassword = () => {
        history.push("/account-management/change-password");
    };
    const IncompleteApplicationEmail = () => {
       // history.push('/EmailNotification/All-Notification?ptn=' + props.visaDetail?.passportNumber + "&rfn=" + props.visaDetail?.referenceNumber);
        history.push('/EmailNotification/All-Notification');

    };
      const continuePaymentEmail = () => {
        history.push('/EmailNotification/All-Notification?ptn=' + props.visaDetail?.passportNumber + "&rfn=" + props.visaDetail?.referenceNumber);
      };
      const aprrovalEmail = () => {
        history.push('/EmailNotification/All-Notification?ptn=' + props.visaDetail?.passportNumber + "&rfn=" + props.visaDetail?.referenceNumber);
      };
      const applicationEmail = () => {
        history.push('/EmailNotification/All-Notification?ptn=' + props.visaDetail?.passportNumber + "&rfn=" + props.visaDetail?.referenceNumber);
      };
    const handleLogoutEvent = () => {
        localStorage.removeItem("userRefreashToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("tokenCollection");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("idToken");
        localStorage.removeItem("fullname");
        localStorage.removeItem("username");
        localStorage.removeItem("roles")

        auth.togglePrivileges();
        auth.toggleUsername();
        auth.toggleFullname();
        toggleAuth();
        history.push("/");
    };
    let topbarItemsClassName = classNames("topbar-menu fadeInDown", { "topbar-menu-visible": props.topbarMenuActive });
    let topbarNotificationItemsClassName = classNames("topbar-menu fadeInDown", { "topbar-menu-visible": props.topbarNotificationMenuActive });

    const formatString = (string) => {
        const arr = string?.split(" ");

        for (var i = 0; i < arr?.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const formattedString = arr?.join(" ");
        return formattedString;
    }
    useEffect(() => {
        getUnsentEmailCount();
    }, []);
 const getUnsentEmailCount=()=>{
    visaService.getUnsenteMailcounts()
    .then((res) => {
        setemailCount(res?.data?.payload);
    });
    return emailCount;
 };
    return (
        <div className="topbar clearfix">
            <div className="logo-link">
                <img className="logo" alt="apollo-layout" src={logo} />
            </div>
            <button type="button" className="p-link menu-button" id="menu-button" onClick={props.onMenuButtonClick}>
                <i className="pi pi-align-left"></i>
            </button>
            
            <button value="5"  type="button" className="p-link profile" onClick={props.onTopbarNotificationMenuButtonClick} >
              <span style={{ color:"red", backgroundColor: 'white'}} className="text-danger">{emailCount}</span>
                <br />
                <i className="pi pi-fw pi-bell"> </i>
            </button>
            <ul className={topbarNotificationItemsClassName}>
                 <li  onClick={() => IncompleteApplicationEmail()}>
                    <button type="button" className="p-link">
                        <i className="pi pi-fw pi-bell"></i>
                        <span>Unsent Emails</span>
                    </button>
                </li> 
            </ul>
            <button type="button" className="p-link profile" onClick={props.onTopbarMenuButtonClick}>
                <br />
                <span className="username">Wellcome {formatString(auth?.fullname)}</span>
                <i className="pi pi-user"></i>
            </button>
            <ul className={topbarItemsClassName}>
                {auth.privileges.includes("Users-ChangePassword") === true ? <li className={classNames({ "menuitem-active": props.activeTopbarItem === "settings" })} onClick={() => handleChangePassword()}>
                    <button type="button" className="p-link">
                        <i className="pi pi-fw pi-user-edit"></i>
                        <span>Change Password</span>
                    </button>
                </li> : <></>}
                <li className={classNames({ "menuitem-active": props.activeTopbarItem === "messages" })} onClick={() => handleLogoutEvent()}>
                    <button type="button" className="p-link">
                        <i className="pi pi-fw pi-power-off"></i>
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default AppTopbar;
