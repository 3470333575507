import HttpService from "../shared/HttpService";


export default class VisaAttachmentService {

    getAll = (claim) => {
        let relativePath = "visaservice/api/v1/RequiredAttachement/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    delete = (id, claim) => {
        let relativePath = "visaservice/api/v1/RequiredAttachement/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }
    create = (requestData, claim) => {
        let relativePath = "visaservice/api/v1/RequiredAttachement/Create";
        return HttpService.postService(requestData, relativePath, "", "", "", claim)
    }
    edit = (requestData, id, claim) => {
        let relativePath = "visaservice/api/v1/RequiredAttachement/Update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    activateDeactivate = (requestData, claim) => {
        let relativePath = "visaservice/api/v1/RequiredAttachement/UpdateStatus";
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
}