import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";

import { InputText } from "primereact/inputtext";
import CompanyApplicationService from "../../service/Company/CompanyApplicationService";

import { useForm } from "react-hook-form";
import SnipperModal from "../modal/SnipperModal";
import { useAuth } from "../../utilities/authContext";
//import { data } from 'cypress/types/jquery';

const DeniedCompany = () => {
    let emptyCompany = {
        id: null,
        approvalRemark: "",
        companyReferenceCode: "",
        companyProfileId: 0,
        companyCategoryId: 0,
        approvalStatus: 0,
        name: "",
        category: "",
        companyManagerEmailAddress: "",
        telephone: "",
        startDate: "",
    };
    const activateDeactivateBody = {
        status: 0,
        id: 0,
    };
    const approvalStatus = {
        0: "Default",
        1: "Requested",
        2: "Approved",
        3: "Incomplete",
        4: "Deny",
    };
    const applicationStatus = {
        approvalStatus: 0,
        approvalRemark: "",
        id: 0,
    };
    const colors = {
        EXPIRED: 'red'
        //ACTIVE:'green'
        
    };
    const [companyTypes, setcompanyTypes] = useState(null);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);
    const [companyType, setcompanyType] = useState(emptyCompany);
    const [selectedcompanies, setselectedcompanies] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [companyapplication, setcompanyapplication] = useState(false);
    const [companyprofileid, setcompanyprofileid] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [searchOptions, setSearchOptions] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);
    const [dates1, setDates1] = useState(null);
    const [dates2, setDates2] = useState(null);
    const [reference, setReference] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const roles = useAuth();

    useEffect(() => {
        ///const companyservice = new CompanyService();
        setLoading(true);
        const ApplicationService = new CompanyApplicationService();

        ApplicationService.filterDeniedCompanies("DeniedCompany-View")
            .then((res) => {
                setcompanyTypes(res.data);
            })
            .catch((error) => {
                if (error.response?.status !== 404) {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching denied company applications.", life: 3000 });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const hideActivateDeactivateCountryDialog = () => {
        setActivateDeactivateDialog(false);
    };
    const confirmActivateDeactivateCountry = (companyType) => {
        setcompanyType(companyType);
        setActivateDeactivateDialog(true);
    };
    const changeApplicationStatus = () => {
        setSubmitted(true);

        let _companytypes = [...companyTypes];
        let _companytype = { ...companyType };
        if (companyType.id) {
            const index = findIndexById(companyType.id);
            _companytypes[index] = _companytype;
            const visaService = new CompanyApplicationService();
            activateDeactivateBody.id = _companytype.id;
            activateDeactivateBody.approvalStatus = _companytype.approvalStatus;
            activateDeactivateBody.approvalRemark = _companytype.approvalRemark;
            if (companyType.approvalStatus === 0) {
                activateDeactivateBody.approvalStatus = 2;
            }
            if (companyType.approvalStatus === 0) {
                activateDeactivateBody.approvalStatus = 2;
            } else activateDeactivateBody.approvalStatus = 0;
            visaService
                .UpdateCompanyApplication(activateDeactivateBody, "clienttoken", "accesstoken", "")
                .then((res) => {
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: res.data.approvalStatus === 2 ? "Company Approved" : "Company Approved",
                        life: 3000,
                    });
                })
                .catch((err) => {
                    // console.log(err);
                });
        }
        setcompanyTypes(_companytypes);
        setActivateDeactivateDialog(false);
        setcompanyType(emptyCompany);
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < companyTypes.length; i++) {
            if (companyTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };
    const RequestTypes = {
        0: "companyName",
        1: "referenceNumber",
        2: "email",
    };
    const Options = [
        { name: "Company Name", value: 0 },
        { name: "Reference Number", value: 1 },
        { name: "Email", value: 2 },
    ];
    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Approval Status </span>
                {approvalStatus[rowData.approvalStatus].toUpperCase()}
            </>
        );
    };
     
    const businessLicenseTemplate = (rowData) => {
        const expiryDate = rowData.companyProfile?.businessLicenseExpiryDate?.split("T")[0];
        const today = new Date().toISOString().split("T")[0];
    
        const status = expiryDate < today ? 'EXPIRED' : 'ACTIVE';
        const color = colors[status];
    
        return (
            <>
                <span className="p-column-title">Business License Expiry Date</span>
                <span style={{ color }}>{expiryDate} ({status.charAt(0).toUpperCase() + status.slice(1)})</span>
            </>
        );
    };
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Company Name </span>
                {rowData.companyProfile.name}
            </>
        );
    };
    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"> Company Category</span>
                {rowData.companyProfile.companyCategory.category}
            </>
        );
    };
    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Company Email</span>
                {rowData.companyProfile.companyManagerEmailAddress}
            </>
        );
    };
    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone Number</span>
                {rowData.companyProfile.telephone}
            </>
        );
    };
    const registrationDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Registration Date</span>
                {rowData?.registeredDate?.split("T")[0]}
            </>
        );
    };
    const referenceCodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">companyReferenceCode</span>
                {rowData.companyReferenceCode}
            </>
        );
    };

    const openDetail = (rowData) => {
        localStorage.setItem("selectedCompanyId", rowData?.id);
        localStorage.setItem("selectedCompanyProfileId", rowData?.companyProfile?.id);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles?.privileges.includes("DeniedCompany-Detail") === true ? (
                    <Link
                        to={{ pathname: "/company-application/Detail", state: { selected: rowData } }}
                        onClick={() => {
                            openDetail(rowData);
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button label="Detail" style={{ backgroundColor: "#0d4d99" }} onClick={() => localStorage.setItem("DetailClaim", "DeniedCompany-Detail")} />
                    </Link>
                ) : (
                    <></>
                )}
            </div>
        );
    };
    const FormatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    const header = (
        <div className="flex ">
            {/* <h5 className="m-0">New Visa Application</h5> */}
            <div className="inline-flex flex-wrap flex-gap">
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="startDate" className="mb-2 mt-2">
                            Start Date
                        </label>
                        <Calendar
                            id="startDate"
                            value={dates1}
                            onChange={(e) => {
                                setDates1(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            maxDate={dates2}
                        />
                    </span>
                </span>
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            value={dates2}
                            onChange={(e) => {
                                setDates2(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            minDate={dates1}
                        />
                    </span>
                </span>
                <span style={{ minWidth: 300 }} className="">
                    <span className="flex flex-column gap-2 ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Company Name
                        </label>
                        <InputText value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                    </span>
                </span>

                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Reference Number
                        </label>
                        <InputText value={reference} onChange={(e) => setReference(e.target.value)} />
                    </span>
                </span>

                <span style={{ minWidth: 300 }} className="">
                    <span className="flex flex-column gap-2">
                        <label className="mb-2 mt-2">Email</label>
                        <InputText value={email} onChange={(e) => setEmail(e.target.value)} />
                    </span>
                </span>

                <Button label="Search" icon="pi pi-search" className="mt-5  " style={{ minWidth: 200, height: 42, backgroundColor: "#0D4D99" }} onClick={(e) => SearchAll(dates1, dates2, reference, companyName, email)} />
            </div>
        </div>
    );
    const SearchAll = (dates1, dates2, reference, companyName, email) => {
        const visaService = new CompanyApplicationService();
        let startDate = FormatDate(dates1);
        let endDate = FormatDate(dates2);
        console.log("data", reference);
        setLoading(true);
        setLoading(true);
        visaService
            .searchVisa(startDate === "1970-01-01" ? "" : startDate, endDate === "1970-01-01" ? "" : endDate, reference, companyName, email, "4", "DeniedCompany-View")
            .then((res) => {
                setLoading(false);

                setcompanyTypes(res.data);
            })
            .catch((err) => {
                setLoading(false);
                setcompanyTypes(null);
            });
    };
    const activateDeactivatecountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideActivateDeactivateCountryDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={changeApplicationStatus} />
        </>
    );
    return (
        <>
            {/* {loading ? <SnipperModal /> : <> {null}</>} */}
            <div className="grid crud-demo">
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <DataTable
                            ref={dt}
                            value={companyTypes}
                            selection={selectedcompanies}
                            onSelectionChange={(e) => setselectedcompanies(e.value)}
                            dataKey="id"
                            paginator
                            rows={5}
                            loading={loading}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Denied Company Applications"
                            globalFilter={globalFilter}
                            emptyMessage="No companies found."
                            header={header}
                            responsiveLayout="scroll"
                        >
                            <Column field="companyProfile.name" header="Company name" sortable body={nameBodyTemplate} filter filterPlaceholder="Search by name" headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="companyProfile.companyCategory.category" header="Category" sortable filter filterPlaceholder="Search by category" body={categoryBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            {/* <Column field="companyManagerEmailAddress" header="Email" sortable body={emailBodyTemplate} style={{ minWidth: '30rem' }} headerStyle={{ minWidth: '30rem' }}></Column> */}
                            <Column field="registeredDate" header="Request Date" sortable body={registrationDateBodyTemplate} filter filterPlaceholder="Search by Registration Date" headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="companyProfile.telephone" header="Phone Number" sortable body={phoneBodyTemplate} filter filterPlaceholder="Search by phone number" headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="companyReferenceCode" header="Reference Code" sortable body={referenceCodeBodyTemplate} filter filterPlaceholder="Search by reference code" headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                            <Column field="companyProfile.businessLicenseIssuedDate" header="Business License Expiry Date" sortable body={businessLicenseTemplate} filter filterPlaceholder="Search by phone number" headerStyle={{ width: "35%", minWidth: "22rem" }}></Column>
                            <Column field="approvalStatus" header="Status" body={statusBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>

                            {roles?.privileges.includes("DeniedCompany-Detail") === true ? <Column header="Actions" body={actionBodyTemplate}></Column> : <></>}
                        </DataTable>
                        <Dialog visible={activateDeactivateDialog} style={{ width: "450px" }} header="Confirm" modal footer={activateDeactivatecountryDialogFooter} onHide={hideActivateDeactivateCountryDialog}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                {companyType && (
                                    <span>
                                        Are you sure you want to Approve <b>{companyType.name}</b>?
                                    </span>
                                )}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DeniedCompany;
