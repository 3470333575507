import React, { useState, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import MasterDataService from '../../../service/master-data/MasterDataService';
import SnipperModal from "../../modal/SnipperModal";
import { useAuth } from '../../../utilities/authContext';

const Country = () => {
    let emptyCountriesType = {
        id: 0,
        name: '',
        code: '',
        description: '',
        recordStatus: 0,
    };
    const countryBody = {
        id: 0,
        name: '',
        code: '',
    };
    const activateDeactivateBody = {
        status: 0,
    };

    let [loading, setLoading] = useState(false);
    const [countryTypes, setcountryTypes] = useState(null);
    const [countryUpdateDialog, setCountryUpdateDialogDialog] = useState(false);
    const [countryDialog, setcountryDialog] = useState(false);
    const [deleteCountryDialog, setDeleteCountryDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);

    const [performingAction, setPerformingAction] = useState(false);
    const [countrytype, setcountrytype] = useState(emptyCountriesType);
    const [selectedcountries, setSelectedcountries] = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {

        const masterDataService = new MasterDataService();
        setLoading(true)
        masterDataService.getCountry("Country-View")
            .then(res => {
                setLoading(false)
                setcountryTypes(res.data)
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching countries.", life: 3000 });
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    const openNew = () => {
        setcountrytype(emptyCountriesType);
        setSubmitted(false);
        setcountryDialog(true);
    }


    const hideCountryDialog = () => {
        setSubmitted(false);
        setcountryDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCountryUpdateDialogDialog(false);
    }

    const hideDeleteCountryDialog = () => {
        setDeleteCountryDialog(false);
    }
    const hideActivateDeactivateCountryDialog = () => {
        setActivateDeactivateDialog(false);
    }

    const SubmitCountry = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _countrytypes = [...countryTypes];
        let _countrytype = { ...countrytype };
        countryBody.name = _countrytype.name;
        countryBody.code = _countrytype.code;
        const masterDataService = new MasterDataService();

        masterDataService.createCountry(countryBody, "", "Country-Add")
            .then((res) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Country Created', life: 3000 });
                refresh();
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Country already Registered ", life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setcountryTypes(_countrytypes);
                setcountryDialog(false);
                setcountrytype(emptyCountriesType);
            })
    }
    const UpdateCountry = () => {
        setPerformingAction(true);
        setSubmitted(true);

        if (countrytype.name.trim()) {
            let _countrytypes = [...countryTypes];
            let _countrytype = { ...countrytype };
            if (countrytype.id) {
                const index = findIndexById(countrytype.id);

                _countrytypes[index] = _countrytype;
                const masterDataService = new MasterDataService();

                countryBody.name = _countrytype.name;
                countryBody.code = _countrytype.code;
                masterDataService.updateCountries(countryBody, _countrytype.id, "", "Country-Edit")
                    .then((res) => {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Country Updated', life: 3000 });
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
                    }).finally(() => {
                        setPerformingAction(false);
                        setcountryTypes(_countrytypes);
                        setCountryUpdateDialogDialog(false);
                        setcountrytype(emptyCountriesType);
                    });
            }
        }
    }
    const editCountry = (countrytype) => {
        setcountrytype({ ...countrytype });
        setCountryUpdateDialogDialog(true);
    }

    const confirmDeleteCountry = (countrytype) => {
        setcountrytype(countrytype);
        setDeleteCountryDialog(true);
    }

    const confirmActivateDeactivateCountry = (countrytype) => {
        setcountrytype(countrytype);
        setActivateDeactivateDialog(true);
    }

    const deleteCounrty = () => {
        setPerformingAction(true);
        let _countrytypes = countryTypes.filter(val => val.id !== countrytype.id);

        const masterDataService = new MasterDataService();
        masterDataService.deleteCountry(countrytype.id, "Country-Delete").then(res => {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Country Deleted', life: 3000 });
        }).finally(() => {
            setPerformingAction(false);
            setcountryTypes(_countrytypes);
            setDeleteCountryDialog(false);
            setcountrytype(emptyCountriesType);
        });

    }
    const activateDeactivateCountryname = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _countrytypes = [...countryTypes];
        let _countrytype = { ...countrytype };
        if (countrytype.id) {
            const index = findIndexById(countrytype.id);

            _countrytypes[index] = _countrytype;
            const masterDataService = new MasterDataService();

            if (countrytype.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            }
            else
                activateDeactivateBody.status = 1; masterDataService.activateDeactivateCountry(countrytype.id, activateDeactivateBody, "", "Country-ChangeStatus")
                    .then((res) => {

                        toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Country Activated" : "Country Deactivated", life: 3000 });
                        refresh();
                    })
                    .catch((err) => {

                    }).finally(() => {
                        setPerformingAction(false);
                        setcountryTypes(_countrytypes);
                        setActivateDeactivateDialog(false);
                        setcountrytype(emptyCountriesType);
                    });

        }
    }
    const refresh = () => {
        const masterDataService = new MasterDataService();
        masterDataService.getCountry("Country-View")
            .then((res) => {
                setcountryTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < countryTypes.length; i++) {
            if (countryTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _countrytype = { ...countrytype };
        _countrytype[`${name}`] = val;
        setcountrytype(_countrytype);
    }
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("Country-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Country Name</span>
                {rowData.name}
            </>
        );
    }
    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Country Code</span>
                {rowData.code}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("Country-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editCountry(rowData)} /> : <></>}
                {roles.privileges.includes("Country-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteCountry(rowData)} /> : <></>}
                {roles.privileges.includes("Country-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmActivateDeactivateCountry(rowData)} /> : <></>}
            </div>
        );
    }

    const status = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={status} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Country</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const updateCountryDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdateCountry} />}
        </>
    );

    const counrtyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideCountryDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitCountry} />}
        </>
    );

    const deleteCountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeleteCountryDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteCounrty} />}
        </>
    );

    const activateDeactivatecountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivateCountryDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateCountryname} />}
        </>
    );



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable ref={dt} filters={filters} value={countryTypes} selection={selectedcountries} onSelectionChange={(e) => setSelectedcountries(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Countries" // header={header}
                        globalFilter={globalFilter} emptyMessage="No Countries found." scrollable responsiveLayout="scroll">

                        <Column field="name" header="Name" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="code" header="Code" sortable body={codeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable filter filterElement={statusFilterTemplate} style={{ width: "8rem" }} headerStyle={{ width: "8rem" }}></Column>

                        {(roles.privileges.includes("Country-Edit") === true ||
                            roles.privileges.includes("Country-Delete") === true ||
                            roles.privileges.includes("Country-ChangeStatus") === true) ? <Column header="Actions" body={actionBodyTemplate}></Column> : <></>}
                    </DataTable>

                    <Dialog visible={countryDialog} style={{ width: '450px' }} header="Create Country"
                        modal className="p-fluid" footer={counrtyDialogFooter} onHide={hideCountryDialog}>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={countrytype.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !countrytype.name })} />
                            {submitted && !countrytype.name && <small className="p-error"> Country name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="code">Country Code</label>
                            <InputTextarea id="code" value={countrytype.code} onChange={(e) => onInputChange(e, 'code')} required
                                rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !countrytype.code })} />
                            {submitted && !countrytype.code && <small className="p-error text-red">Country  code is required.</small>}
                        </div>

                    </Dialog>

                    <Dialog visible={countryUpdateDialog} style={{ width: '450px' }} header="Update Country"
                        modal className="p-fluid" footer={updateCountryDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="name">Country Name</label>
                            <InputText id="name" value={countrytype.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !countrytype.name })} />
                            {submitted && !countrytype.name && <small className="p-invalid"> Country name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="code">Country Code</label>
                            <InputTextarea id="code" value={countrytype.code} onChange={(e) => onInputChange(e, 'code')} required rows={3} cols={20} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCountryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCountryDialogFooter} onHide={hideDeleteCountryDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {countrytype && <span>Are you sure you want to delete <b>{countrytype.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: '450px' }} header="Confirm" modal footer={activateDeactivatecountryDialogFooter} onHide={hideActivateDeactivateCountryDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {countrytype && <span>
                                Are you sure you want to <b>{countrytype.recordStatus === 1 ? "Activate" : "Deactivate"}</b> <b>{countrytype.name}</b>?
                            </span>}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

export default Country;