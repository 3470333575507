import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import eVisaService from "../../service/eVisaService/eVisaService";

import MasterDataService from "../../service/master-data/MasterDataService";
import { useAuth } from "../../utilities/authContext";
import { Message } from "primereact/message";

const VisaReport = () => {
    let emptyVisa = {
        approved:0,
        dateRange:'',
        denied:0,
        incompelete:0,
        pending:0,
        requsted:0,
        validated:0,
        ReportType:"Evisa Approval Report",
        id:0,
        visaTypeId:'',
        Nationality:''

    };
    
    
    

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = prevMonth === 11 ? year - 1 : year;
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;

    let minDate = new Date();
    minDate.setMonth(prevMonth);
    minDate.setFullYear(prevYear);

    let maxDate = new Date();
    maxDate.setMonth(nextMonth);
    maxDate.setFullYear(nextYear);

    const [companyTypes, setcompanyTypes] = useState(null);

  
    const [visaFee1, setvisaFee1] = useState(emptyVisa);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedNationality, setselectedNationality] = useState({ id: 0, name: "" });
    const [dates1, setDates1] = useState(null);
    const [dates2, setDates2] = useState(null);
    const [attachmentTypes, setAttachmentTypes] = useState(null);
    const [selectedCompanyCategory, SetSelectedCompanyCategory] = useState({ id: 0, name: "" });
    const [visaTypes, setvisaTypes] = useState(null);
    const [VisaType, setVisaType] = useState("");
    const [arrivalDate, setArrivalDate] = useState(null);

    const [Nationality, setNationality] = useState("");
    const toast = useRef(null);
    const dt = useRef(null);
    const roles = useAuth();

    let [loading, setLoading] = useState(false);

    useEffect(() => {
        const commonService = new MasterDataService();
        commonService
            .getnationalityReport("VisaReport-View")
            .then((res) => {
                setAttachmentTypes(res.data);
            })
        const visaTypeService = new MasterDataService();
        visaTypeService.getVisaTypeReport("VisaReport-View")
            .then((res) => {
                setvisaTypes(res.data);

            })
       
    }, []);
    const cols = [
        
        { field: 'dateRange', header: 'Date Interval' },
        { field: 'approved', header: 'Approved' },
        { field: 'denied', header: 'Denied' },
        { field: 'incompelete', header: 'Incompelete' },
        { field: 'pending', header: 'Pending' },
        { field: 'requsted', header: 'Requested' },
        { field: 'validated', header: 'Validated' },
        
    ];
   
    const exportCSV = () => {
        dt.current.exportCSV();
    };
    
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
   
    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, companyTypes);
                doc.save('EvisaReport.pdf');
            });
        });
    };

    const onAttachmentTypeChange1 = (e, name) => {
        let _visaFee = { ...visaFee1 };
        _visaFee[`${name}`] = e.value;
        setvisaFee1(_visaFee);
        let selectedValue = attachmentTypes.find((c) => c.id === e.value);
        setselectedNationality(selectedValue);

    };
    const onVisaTypeChange1 = (e, name) => {
        let _visaFee = { ...visaFee1 };


        _visaFee.visaTypeId = e.value;
        setvisaFee1(_visaFee);
        let selectedValue = visaTypes.find((c) => c.id === e.value);
        SetSelectedCompanyCategory(selectedValue);


    };

    
    const approvedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">approved</span>
                {rowData.approved}
            </>
        );
    };
    const deniedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">denied</span>
                {rowData.denied}
            </>
        );
    };
    const incompeleteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">incompelete</span>
                {rowData.incompelete}
            </>
        );
    };
    const pendingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">pending</span>
                {rowData.pending}

                
            </>
        );
    };
    const requstedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">requsted</span>
                {rowData.requsted}
            </>
        );
    };
    const validatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">validated</span>
                {rowData.validated}
            </>
        );
    };

    
    const dateRangeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">dateRange Date</span>
                {rowData.dateRange?.split("T")[0]}
            </>
        );
    };
  
    const FormatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };
    const header = (
        <div className="flex ">
            {/* <h5 className="m-0">New Visa Application</h5> */}
            <div className="inline-flex flex-wrap flex-gap">
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="startDate" className="mb-2 mt-2">
                            Start Date
                        </label>
                        <Calendar
                            id="startDate"
                            value={dates1}
                            onChange={(e) => {
                                setDates1(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            maxDate={dates2}
                        />
                    </span>
                </span>
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            value={dates2}
                            onChange={(e) => {
                                setDates2(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            minDate={dates1}
                        />
                    </span>
                </span>
                <span className="flex flex-column gap-2  ">
                        <label htmlFor="arrivalDate" className="mb-2 mt-2 ">
                            Arrival Date
                        </label>
                        <Calendar
                            id="arrivalDate"
                            value={arrivalDate}
                            onChange={(e) => {
                                setArrivalDate(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                        />
                    </span>
                <span style={{ minWidth: 300 }} className="">
                    <span className="flex flex-column gap-2 ">
                    <label htmlFor="arrivalDate" className="mb-2 mt-2 ">
                            Nationality
                        </label>
                        <Dropdown optionValue="id" optionLabel="name" value={selectedNationality?.id} optionName={visaFee1.id} options={attachmentTypes} onChange={(e) => onAttachmentTypeChange1(e, "id")} placeholder="Select Nationality Type" />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2">
                        <label className="mb-2 mt-2">VisaType</label>
                    
                        <Dropdown optionValue="id" optionLabel="name" value={visaFee1?.visaTypeId} optionName={visaFee1?.visaTypeId} options={visaTypes} onChange={(e) => onVisaTypeChange1(e, "visaTypeId")} placeholder="Select Select Visa types" />

                    </span>
                </span>
                <span>                  
                </span>
                <Button label="Search" icon="pi pi-search" className="mt-5" style={{ minWidth: 200, height: 42, backgroundColor: "#0D4D99" }} onClick={(e) => SearchAll(dates1, dates2, Nationality, VisaType, arrivalDate)} />
                <Button label="export pdf"  type="button" icon="pi pi-file-pdf"  className="mt-5"  severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
                <Button label="export Csv" icon="pi pi-file-excel" className="mt-5" onClick={exportCSV} />
            </div>
        </div>
    );

    const SearchAll = (dates1, dates2,nationality, visaType , dates3 ) => {
       
        const visaService = new eVisaService();
        let startDate = FormatDate(dates1);
        let endDate = FormatDate(dates2);
        let arrivalDate = FormatDate(dates3);
        let VisaType=visaFee1?.visaTypeId;
        let Nationality =selectedNationality?.id ? selectedNationality?.id :'';
        setLoading(true);
        visaService
            .searchVisaReport(startDate === "1970-01-01" ? "" : startDate, endDate === "1970-01-01" ? "" : endDate
        , Nationality, arrivalDate === "1970-01-01" ? "" : arrivalDate, VisaType, "VisaReport-View")
            .then((res) => {
                setLoading(false);
                setcompanyTypes([res.data.payload]);
            })
            .catch((err) => {
                setLoading(false);
                setcompanyTypes(null);
            });
    };
 
    return (
        <>
            <Toast ref={toast} />
            {/* {loading ? <SnipperModal /> : <> {null}</>} */}

            <div className="grid crud-demo">
                <h4>
                    <Message severity="info" text="Start Date, End Date, Arrival Date, Nationality, or VisaType are all valid search terms." />
                </h4>

                <div className="col-12">
                    <DataTable
                        ref={dt}
                        value={companyTypes}
                       
                        //onSelectionChange={(e) => setselectedcompanies(e.value)}
                        dataKey="id"
                        paginator
                        rows={5}
                        loading={loading}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} All Visa Requests"
                        scrollable
                        globalFilter={globalFilter}
                        emptyMessage="No Visa Requests found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column field="approved" header="Approved" body={approvedBodyTemplate} sortable   alignFrozen="left" style={{ minWidth: "14rem" }}></Column>
                        <Column field="denied" header="Denied" body={deniedBodyTemplate} sortable   style={{ minWidth: "14rem" }}></Column>
                        <Column field="incompelete" header="Incompelete" body={incompeleteBodyTemplate}  sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="pending" header="Pending" body={pendingBodyTemplate}  sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="requsted" header="Requsted" body={requstedBodyTemplate}  sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="validated" header="Validated" body={validatedBodyTemplate}   sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="dateRange" header="Date Interval" body={dateRangeBodyTemplate}   sortable style={{ minWidth: "14rem" }}></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
};
export default VisaReport;
