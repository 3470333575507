import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { jsPDF } from "jspdf";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import eVisaService from "../../service/eVisaService/eVisaService";
import AccountService from "../../service/account/AcountService";
import { useAuth } from "../../utilities/authContext";
import { Message } from "primereact/message";

const VisaApprovalReport = () => {
    let emptyVisa = {
        total:0,
        dateRange:'',
        officerName:''
    };

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = prevMonth === 11 ? year - 1 : year;
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;

    let minDate = new Date();
    minDate.setMonth(prevMonth);
    minDate.setFullYear(prevYear);
    let maxDate = new Date();
    maxDate.setMonth(nextMonth);
    maxDate.setFullYear(nextYear);
    const [companyTypes, setcompanyTypes] = useState();
    const [users, setUsers] = useState([]);
    const userService = new AccountService();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [dates1, setDates1] = useState(null);
    const [dates2, setDates2] = useState(null);
    const [userName, setuserName] = useState("");
    const [visaFee1, setvisaFee1] = useState(emptyVisa);
    const toast = useRef(null);
    const dt = useRef(null);
    const roles = useAuth();

    let [loading, setLoading] = useState(false);
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const fromStartDate = formatDate(yesterday);
    const toStartDate = formatDate(today);
    
 
    const visaService = new eVisaService();
    useEffect(() => {
        setLoading(true);
        userService
        .getUsers("Users-View")
        .then((res) => {
            setUsers(res.data);
        })
        .catch((e) => {
            toast.current.show({ severity: "error", summary: "Error Message", detail: "Couldn't fetch Users", life: 4000 });
        })
        .finally(() => {
            setLoading(false);
        });
        visaService.getAllReport(fromStartDate === "1970-01-01" ? "" : fromStartDate, toStartDate === "1970-01-01" ? "" : toStartDate, "GetApprovalHistory-View")
            .then((res) => {
                 ;
                setLoading(false);
                setcompanyTypes(res?.data?.payload?.approvalHistory);
            })
            .catch((err) => {
                setLoading(false);
                setcompanyTypes(null);
            });
        
    }, []);
    const cols = [
        { field: 'EmployeeName', header: 'Employee Name' },
        { field: 'TotalVisaApproval', header: 'Total Visa Approval' },
        { field: 'dateRange', header: 'Date Range' },
    ];
    const exportCSV = () => {
        dt.current.exportCSV();
    };
    
    const openDetail = (rowData) => {
          
        localStorage.setItem("selectedusername", rowData?.officerName);
    };
    const editVisa = (visaType) => {
        
        // getVisaById(visaType?.id)
        // setvisaTypesById({ ...visaType });
        //setvisaTypeUpdateDialog(true);
    };

   
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles?.privileges.includes("GetApprovalHistoryDetail-View") === true ? (
                    <Link
                        to={{ pathname: "/visa-requests/ApprovalHistoryDetail", state: { selected: rowData } }}
                        onClick={() => {
                            openDetail(rowData);
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button label="Detail" style={{ backgroundColor: "#0D4D99" }} onClick={() => localStorage.setItem("DetailClaim", "GetApprovalHistoryDetail-View")} />
                    </Link>
                ) : (
                    <></>
                )}
            </div>
        );
    };
    const FormatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };
    const header = (
        <div className="flex ">
            <div className="inline-flex flex-wrap flex-gap">
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="fromStartDate" className="mb-2 mt-2">
                            Start Date
                        </label>
                        <Calendar
                            id="fromStartDate"
                            value={dates1}
                            onChange={(e) => {
                                setDates1(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            maxDate={dates2}
                        />
                    </span>
                </span>
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="toStartDate" className="mb-2 mt-2">
                            End Date
                        </label>
                        <Calendar
                            id="toStartDate"
                            value={dates2}
                            onChange={(e) => {
                                setDates2(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            minDate={dates1}
                        />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2">
                        <label htmlFor="userName" className="mb-2 mt-2">
                           User Name
                        </label>
                         <Dropdown value={userName} onChange={(e) => setuserName(e.target.value)} options={users} optionLabel="username" placeholder="Select a Username" filter />
                    </span>
                </span>
                <span>                  
                </span>
                <Button label="Search" icon="pi pi-search" className="mt-5" style={{ minWidth: 200, height: 42, backgroundColor: "#0D4D99" }} onClick={(e) => SearchAll(dates1, dates2, userName)} />
                <Button label="export Csv" icon="pi pi-file-excel" className="mt-5" onClick={exportCSV} />
            </div>
        </div>
    );
    const SearchAll = (dates1, dates2,userName ) => {
   
        let fromStartDate  = FormatDate(dates1);
        let toStartDate  = FormatDate(dates2);
        let user = userName ? userName?.username : ""
        
        setLoading(true);
        visaService.searchUserLogReport(fromStartDate === "1970-01-01" ? "" : fromStartDate, toStartDate === "1970-01-01" ? "" : toStartDate, user, "GetApprovalHistoryDetail-View")
            .then((res) => {
                 ;
                setLoading(false);
                 
                setcompanyTypes(res?.data?.payload?.approvalHistory);
            })
            .catch((err) => {
                setLoading(false);
                setcompanyTypes(null);
            });
    };
    return (
        <>
            <Toast ref={toast} />
            <div className="grid crud-demo">
                <h4>
                    <Message severity="info" text="Start Date, End Date, and Username is mandatory search terms." />
                </h4>
                <div className="col-12">
                    <DataTable
                        ref={dt}
                        value={companyTypes}
                        dataKey="id"
                        paginator
                        rows={5}
                        loading={loading}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} All Visa Requests"
                        scrollable
                        globalFilter={globalFilter}
                        emptyMessage="No Visa Requests found."
                        header={header}
                        responsiveLayout="scroll">  
                        <Column field="officerName" header="Employee Name"  sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="total" header="Total Visa Approval"sortable style={{ minWidth: "14rem" }}></Column>
                        {roles?.privileges.includes("GetApprovalHistoryDetail-View") === true ? 
                        <Column header="Details" body={actionBodyTemplate} frozen alignFrozen="right" style={{ minWidth: "14rem" }} ></Column> : <></>}
                    </DataTable>
                </div>u
            </div>
        </>
    );
};
export default VisaApprovalReport;
