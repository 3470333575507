import React, { useState, useEffect, useRef } from "react";

import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import SnipperModal from "../../modal/SnipperModal";
import MasterDataService from "../../../service/master-data/MasterDataService";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useAuth } from "../../../utilities/authContext";

const AttachementType = () => {
    let emptyAttachementType = {
        id: null,
        name: "",
        description: "",
        recordStatus: 0,
        isProfilePhoto: true,
        isImage:true

    };
    const attachmentbody = {
        name: "",
        description: "",
        isProfilePhoto: true,
        isImage:true
    };
    const activateDeactivateBody = {
        status: 0,
    };

    let [loading, setLoading] = useState(false);

    const [attachementTypes, setAttachementTypes] = useState(null);

    const [attachementDialog, setAttachementDialog] = useState(false);
    const [attachementUpdateDialog, setAttachementUpdateDialogDialog] = useState(false);
    const [deleteAttachementDialog, setDeleteAttachementDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);

    const [attachementType, setAttachementType] = useState(emptyAttachementType);
    const [isProfilePhoto, setisProfilePhoto] = useState(true);
    const [isImage, setisImage] = useState(true);
    const [selectedAttachements, setSelectedAttachements] = useState(null);

    const [performingAction, setPerformingAction] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'description': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        const masterDataService = new MasterDataService();
        setLoading(true)
        masterDataService.getAttachementType("AttachementType-View")
            .then((res) => {
                setAttachementTypes(res.data);
            })
            .catch(() => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching attachement types.", life: 3000 });
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        const _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value)
    }

    const openNew = () => {
        setAttachementType(emptyAttachementType);
        setSubmitted(false);
        setAttachementDialog(true);
    };
    const refreshVisaFee = () => {
        const masterDataService = new MasterDataService();
        masterDataService
            .getAttachementType("AttachementType-View")
            .then((res) => {
                setAttachementTypes(res.data);
            })
            .catch((error) => { });
    };
    const hideAttachementDialog = () => {
        setSubmitted(false);
        setLoading(false)
        setAttachementDialog(false);
    };

    const hideDialog = () => {
        setLoading(false);
        setSubmitted(false);
         
        setPerformingAction(false)
        setAttachementUpdateDialogDialog(false);
    };

    const hideDeleteAttachementDialog = () => {
        setDeleteAttachementDialog(false);
    };
    const hideActivateDeactivateAttachementDialog = () => {
        setActivateDeactivateDialog(false);
    };

    const SubmitAttachement = () => {
        setPerformingAction(true);
        setSubmitted(true);

        if (attachementType.name.trim()) {
            let _attachementTypes = [...attachementTypes];
            let _attachementType = { ...attachementType };
            attachmentbody.name = _attachementType.name;
            attachmentbody.description = _attachementType.description;

            attachmentbody.isProfilePhoto = isProfilePhoto;
            attachmentbody.isImage = isImage;
            const masterDataService = new MasterDataService();

            masterDataService
                .createAttachementType(attachmentbody, "AttachementType-Add")
                .then(() => {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Attachment added successfully.", life: 3000 });
                    refresh();
                })
                .catch(() => {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Attachment already registered ", life: 3000 });
                }).finally(() => {
                    setAttachementTypes(_attachementTypes);
                    setAttachementDialog(false);
                    setAttachementType(emptyAttachementType);
                    setPerformingAction(false);
                });
        }
    };

    const UpdateAttachementType = () => {
        setPerformingAction(true)

        let _attachementTypes = [...attachementTypes];
        let _attachementType = { ...attachementType };

        if (attachementType.id) {
            const index = findIndexById(attachementType.id);
            _attachementTypes[index] = _attachementType;
            attachmentbody.name = _attachementType.name;
            attachmentbody.description = _attachementType.description;

            attachmentbody.isProfilePhoto = isProfilePhoto;
            attachmentbody.isImage = isImage;

            const masterDataService = new MasterDataService();
            masterDataService
                .updateAttachementType(attachmentbody, _attachementType.id, "AttachementType-Edit")
                .then(() => {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Attachment Updated", life: 3000 });
                    refreshVisaFee();
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
                }).finally(() => {
                    setAttachementTypes(_attachementTypes);
                    setAttachementUpdateDialogDialog(false);
                    setAttachementType(emptyAttachementType);
                    setPerformingAction(false);
                });
        }
    };

    const editAttachement = (attachementType) => {
        setAttachementType({ ...attachementType });
        setAttachementUpdateDialogDialog(true);
        setisProfilePhoto(attachementType?.isProfilePhoto);
        setisImage(attachementType?.isImage);

    };

    const confirmDeleteAttachement = (attachementType) => {
        setAttachementType(attachementType);
        setDeleteAttachementDialog(true);
    };

    const confirmActivateDeactivateAttachement = (attachementType) => {
        setAttachementType(attachementType);
        setActivateDeactivateDialog(true);
    };

    const deleteAttachement = () => {
        setPerformingAction(true)
        let _attachementTypes = attachementTypes.filter((val) => val.id !== attachementType.id);

        const masterDataService = new MasterDataService();
        masterDataService.deleteAttachementType(attachementType.id, "AttachementType-Delete")
            .then((res) => {
                setAttachementTypes(_attachementTypes);
                setDeleteAttachementDialog(false);
                setAttachementType(emptyAttachementType);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Attachement Deleted", life: 3000 });
            }).catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Error while deleting the attachment type", life: 3000 });
            })
            .finally(() => {
                setPerformingAction(false)
            });
    };
    const activateDeactivateAttachement = () => {
        setPerformingAction(true)
        setSubmitted(true);

        let _attachementTypes = [...attachementTypes];
        let _attachementType = { ...attachementType };
        if (attachementType.id) {
            const index = findIndexById(attachementType.id);
            _attachementTypes[index] = _attachementType;
            const masterDataService = new MasterDataService();

            if (attachementType.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            } else activateDeactivateBody.status = 1;
            masterDataService
                .activateDeactivateAttachementType(attachementType.id, activateDeactivateBody, "AttachementType-ChangeStatus")
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Attachement Activated" : "Attachement Deactivated", life: 3000 });
                    refresh();
                })
                .catch(() => {
                    toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Error while changing status", life: 3000 });
                }).finally(() => {
                    setAttachementTypes(_attachementTypes);
                    setActivateDeactivateDialog(false);
                    setAttachementType(emptyAttachementType);
                    setPerformingAction(false);
                });
        }
    };
    const refresh = () => {
        const masterDataService = new MasterDataService();
        masterDataService
            .getAttachementType("AttachementType-View")
            .then((res) => {
                setAttachementTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < attachementTypes.length; i++) {
            if (attachementTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _attachementType = { ...attachementType };
        _attachementType[`${name}`] = val;
        setAttachementType(_attachementType);
    };
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("AttachementType-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    };
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search attachment type" />
            </span>
        )
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Attachement-Type</span>
                {rowData.name}
            </>
        );
    };
    const profilePhotoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">isProfilePhoto</span>
                {rowData.isProfilePhoto ? "true" : "false"}
                {/* {console.log("data", rowData.isProfilePhoto)} */}
            </>
        );
    };
    const isImageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">isImage</span>
                {rowData.isImage ? "true" : "false"}
                {/* {console.log("data", rowData.isProfilePhoto)} */}
            </>
        );
    };
    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("AttachementType-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editAttachement(rowData)} /> : <></>}
                {roles.privileges.includes("AttachementType-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteAttachement(rowData)} /> : <></>}
                {roles.privileges.includes("AttachementType-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmActivateDeactivateAttachement(rowData)} /> : <></>}
            </div>
        );
    };

    const status = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={status} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Attachment Type</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search attachment type" />
            </span>
        </div>
    );
    const updateAttachementDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdateAttachementType} />}
        </>
    );

    const attachementDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideAttachementDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitAttachement} />}
        </>
    );

    const deleteAttachementDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeleteAttachementDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteAttachement} />}
        </>
    );

    const activateAttachementDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivateAttachementDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateAttachement} />}
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable
                        ref={dt}
                        value={attachementTypes}
                        selection={selectedAttachements}
                        onSelectionChange={(e) => setSelectedAttachements(e.value)}
                        dataKey="id"
                        paginator
                        filters={filters}
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        scrollable
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Attachements"
                        globalFilter={globalFilterValue}
                        emptyMessage="No Attachments found."
                        // header={header}
                        responsiveLayout="scroll"
                    >
                        <Column field="name" header="Name" sortable body={nameBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                        <Column field="description" header="Description" sortable body={descriptionBodyTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>
                        <Column field="isProfilePhoto" header="Is Profile Photo" sortable body={profilePhotoBodyTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>
                        <Column field="isImage" header="Is Image" sortable body={isImageBodyTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>

                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable filter filterElement={statusFilterTemplate} style={{ minWidth: "5rem" }} headerStyle={{ minWidth: "5rem" }}></Column>

                        {(roles.privileges.includes("AttachementType-Edit") === true ||
                            roles.privileges.includes("AttachementType-Delete") === true ||
                            roles.privileges.includes("AttachementType-ChangeStatus") === true) ? <Column header="Actions" body={actionBodyTemplate}></Column> : <></>}
                    </DataTable>

                    <Dialog visible={attachementDialog} style={{ width: "30vw", height: "45vh" }} header="Create Attachment Type" modal className="p-fluid" footer={attachementDialogFooter} onHide={hideAttachementDialog}>
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={attachementType.name} onChange={(e) => onInputChange(e, "name")} required autoFocus className={classNames({ "p-invalid": submitted && !attachementType.name })} />
                            {submitted && !attachementType.name && <small className="p-error">Attachment type name is required.</small>}
                        </div>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={isProfilePhoto} onChange={e => setisProfilePhoto(e.checked)} />
                            <label htmlFor="binary">Is Profile Photo</label>
                        </div>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={isImage} onChange={e => setisImage(e.checked)} />
                            <label htmlFor="binary">Is Image </label>
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={attachementType.description} onChange={(e) => onInputChange(e, "description")} required rows={5} cols={20} className={classNames({ "p-invalid": submitted && !attachementType.description })} />
                            {submitted && !attachementType.description && <small className="p-error text-red">Attachment type description is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={attachementUpdateDialog} style={{ width: "30vw", height: "45vh" }} header="Update Attachment Type" modal className="p-fluid" footer={updateAttachementDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={attachementType.name} onChange={(e) => onInputChange(e, "name")} required autoFocus className={classNames({ "p-invalid": submitted && !attachementType.name })} />
                            {submitted && !attachementType.name && <small className="p-invalid">Attachment type name is required.</small>}
                        </div>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={isProfilePhoto} onChange={e => setisProfilePhoto(e.checked)} />
                            <label htmlFor="binary">Is Profile Photo</label>
                        </div>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={isImage} onChange={e => setisImage(e.checked)} />
                            <label htmlFor="binary">Is Image Photo</label>
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={attachementType.description} onChange={(e) => onInputChange(e, "description")} required rows={5} cols={20} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteAttachementDialog} header="Confirm" modal footer={deleteAttachementDialogFooter} onHide={hideDeleteAttachementDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {attachementType && (
                                <span>
                                    Are you sure you want to delete <b>{attachementType.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} header="Confirm" modal footer={activateAttachementDialogFooter} onHide={hideActivateDeactivateAttachementDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {attachementType && (
                                <span>
                                    Are you sure you want to <b>{attachementType.recordStatus === 1 ? "Activate" : "Deactivate"}</b> <b>{attachementType.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default AttachementType;
