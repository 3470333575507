import React from 'react';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import { useAuth } from '../utilities/authContext';

export const Access = () => {
	const auth = useAuth();

	const history = useHistory();

	const goDashboard = () => {
		if (auth?.username) {
			// history.goBack();
			history.push('/')
		} else {
			history.push('/');
		}
	}


	return <div className="exception-body access-body">
		<div className="exception-container">
			<img src="assets/layout/images/apollo_logo.png" alt="evisa logo" />
			<h1>{auth?.username !== undefined && auth?.username !== null ? "Denied" : "Session Expired"}</h1>
			<p>{auth?.username !== undefined && auth?.username !== null ? "You do not have the necessary privileges." : ""}</p>

			<Button label={auth?.username !== undefined && auth?.username !== null ? "Go Back" : "Back to Login"} icon="pi pi-arrow-left" onClick={goDashboard} />
		</div>
	</div>
}