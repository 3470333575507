import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import CompanyCategoryService from '../../../service/master-data/companyCategoryService';
import SnipperModal from "../../modal/SnipperModal";
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useAuth } from '../../../utilities/authContext';

const CompanyCategoryPage = () => {
    let emptyAttachementType = {
        id: 0,
        category: '',
        description: '',

    };
    const atachmentbody = {
        id: 0,
        category: '',
        description: '',
        status: 1,
    };
    const activateDeactivateBody = {
        status: 0,
        companyCategoryId: 0
    };
    let [loading, setLoading] = useState(false);

    const [attachementTypes, setAttachementTypes] = useState(null);

    const [attachementUpdateDialog, setAttachementUpdateDialogDialog] = useState(false);
    const [attachementDialog, setAttachementDialog] = useState(false);
    const [deleteAttachementDialog, setDeleteAttachementDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);

    const [performingAction, setPerformingAction] = useState(false);
    const [attachementType, setAttachementType] = useState(emptyAttachementType);
    const [selectedAttachements, setSelectedAttachements] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {

        const masterDataService = new CompanyCategoryService();
        setLoading(true);
        masterDataService.getAll("CompanyCategory-View")
            .then(res => {
                setLoading(false);
                setAttachementTypes(res.data)
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching company categories.", life: 3000 });
            }).finally(() => {
                setLoading(false);
            })
    }, []);
    const openNew = () => {
        setAttachementType(emptyAttachementType);
        setSubmitted(false);
        setAttachementDialog(true);
    }


    const hideAttachementDialog = () => {
        setSubmitted(false);
        setAttachementDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setAttachementUpdateDialogDialog(false);
    }

    const hideDeleteAttachementDialog = () => {
        setDeleteAttachementDialog(false);
    }
    const hideActivateDeactivateAttachementDialog = () => {
        setActivateDeactivateDialog(false);
    }

    const SubmitAttachement = () => {
        setSubmitted(true);
        setPerformingAction(true);
        // console.log(attachementType)

        if (attachementType.category) {
            // let _attachementTypes = [...attachementTypes];
            let _attachementType = { ...attachementType };
            // if (attachementType.id) {
            //     // const index = findIndexById(attachementType.id);
            //     // _attachementTypes[index] = _attachementType;
            //     // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Category Updated', life: 3000 });
            // }
            // else {
            const masterDataService = new CompanyCategoryService();
            masterDataService.create(_attachementType, "CompanyCategory-Add")
                .then(() => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Category Created', life: 3000 });
                    refresh();
                })
                .catch(() => {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Company Category already Registered ", life: 3000 });
                }).finally(() => {
                    setPerformingAction(false);
                    // setAttachementTypes(_attachementTypes);
                    setAttachementDialog(false);
                    setAttachementType(emptyAttachementType);
                })
            // }
        }
    }
    const UpdateAttachementType = () => {
        setSubmitted(true);
        setPerformingAction(true);

        let _attachementTypes = [...attachementTypes];
        let _attachementType = { ...attachementType };

        if (attachementType.id) {
            const index = findIndexById(attachementType.id);
            _attachementTypes[index] = _attachementType;

            const masterDataService = new CompanyCategoryService();
            atachmentbody.id = _attachementType.id;
            atachmentbody.description = _attachementType.description;
            atachmentbody.category = _attachementType.category;

            masterDataService.edit(atachmentbody, "CompanyCategory-Edit")
                .then(() => {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Company Category Updated", life: 3000 });
                    refresh()
                })
                .catch(() => {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Error while company category ", life: 3000 });
                }).finally(() => {
                    setPerformingAction(false);
                    setAttachementType(_attachementTypes);
                    setAttachementUpdateDialogDialog(false);
                    setAttachementType(emptyAttachementType);
                });
        }
    };
    const editAttachement = (attachementType) => {
        setAttachementType({ ...attachementType });
        setAttachementUpdateDialogDialog(true);
    }

    const confirmDeleteAttachement = (attachementType) => {
        setAttachementType(attachementType);
        setDeleteAttachementDialog(true);
    }

    const confirmActivateDeactivateAttachement = (attachementType) => {
        setAttachementType(attachementType);
        setActivateDeactivateDialog(true);
    }

    const activateDeactivateAttachement = () => {
        setSubmitted(true);
        setPerformingAction(true);

        let _attachementTypes = [...attachementTypes];
        let _attachementType = { ...attachementType };
        // console.log(_attachementType)
        if (_attachementType.id) {
            const masterDataService = new CompanyCategoryService();
            activateDeactivateBody.companyCategoryId = _attachementType.id;
            activateDeactivateBody.statusAction = _attachementType.recordStatus;

            masterDataService
                .activateDeactivate(activateDeactivateBody, "CompanyCategory-ChangeStatus")
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Company Category  Activated" : "Company Category  Deactivated", life: 3000 });
                    refresh();
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
                }).finally(() => {
                    setPerformingAction(false);
                    setAttachementTypes(_attachementTypes);
                    setActivateDeactivateDialog(false);
                    setAttachementType(emptyAttachementType);
                });
        }
    };
    const deleteAttachement = () => {
        setPerformingAction(true)
        let _attachementTypes = attachementTypes.filter(val => val.id !== attachementType.id);

        const masterDataService = new CompanyCategoryService();
        masterDataService.delete(attachementType.id, "CompanyCategory-Delete")
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company CategoryDeleted', life: 3000 });
            }).catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setAttachementTypes(_attachementTypes);
                setDeleteAttachementDialog(false);
                setAttachementType(emptyAttachementType);
            })

    }

    const refresh = () => {
        const masterDataService = new CompanyCategoryService();
        masterDataService.getAll("CompanyCategory-View")
            .then((res) => {
                setAttachementTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < attachementTypes.length; i++) {
            if (attachementTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _attachementType = { ...attachementType };
        _attachementType[`${name}`] = val;
        setAttachementType(_attachementType);
        // console.log("dddddd " + _attachementType.category)

    }
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("CompanyCategory-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Company category</span>
                {rowData.category}
            </>
        );
    }
    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("CompanyCategory-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editAttachement(rowData)} /> : <></>}
                {roles.privileges.includes("CompanyCategory-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteAttachement(rowData)} /> : <></>}
                {roles.privileges.includes("CompanyCategory-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmActivateDeactivateAttachement(rowData)} /> : <></>}
            </div>
        );
    }

    const statuses = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }

    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Company Category</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const updateAttachementDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdateAttachementType} />}
        </>
    );

    const attachementDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideAttachementDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitAttachement} />}
        </>
    );

    const deleteAttachementDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeleteAttachementDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteAttachement} />}
        </>
    );

    const activateAttachementDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivateAttachementDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateAttachement} />}
        </>
    );
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable ref={dt} filters={filters} value={attachementTypes} selection={selectedAttachements} onSelectionChange={(e) => setSelectedAttachements(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Categories" // header={header}
                        globalFilter={globalFilter} emptyMessage="No Categories found." scrollable responsiveLayout="scroll">

                        <Column field="category" header="Name" sortable body={nameBodyTemplate} style={{ minWidth: "8rem" }} headerStyle={{ minWidth: "8rem" }}></Column>
                        <Column field="description" header="Description" sortable body={descriptionBodyTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable style={{ minWidth: "5rem" }} filter filterElement={statusFilterTemplate} headerStyle={{ minWidth: "5rem" }}></Column>

                        {(roles.privileges.includes("CompanyCategory-Edit") === true ||
                            roles.privileges.includes("CompanyCategory-Delete") === true ||
                            roles.privileges.includes("CompanyCategory-ChangeStatus") === true) ? <Column header="Actions" body={actionBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column> : <></>}
                    </DataTable>
                    <Dialog visible={attachementDialog} style={{ width: "30vw", height: "45vh" }} header="Create Company Category"
                        modal className="p-fluid" footer={attachementDialogFooter} onHide={hideAttachementDialog}>

                        <div className="field">
                            <label htmlFor="category">Name</label>
                            <InputText id="category" value={attachementType.category} onChange={(e) => onInputChange(e, 'category')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !attachementType.category })} />
                            {submitted && !attachementType.category && <small className="p-error">Company Category name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={attachementType.description} onChange={(e) => onInputChange(e, 'description')} required
                                rows={5} cols={20} className={classNames({ 'p-invalid': submitted && !attachementType.description })} />
                            {submitted && !attachementType.description && <small className="p-error text-red">Company Category description is required.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={attachementUpdateDialog} style={{ width: "30vw", height: "45vh" }} header="Update Company Category"
                        modal className="p-fluid" footer={updateAttachementDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="category">Name</label>
                            <InputText id="category" value={attachementType.category} onChange={(e) => onInputChange(e, 'category')} required autoFocus className={classNames({ 'p-invalid': submitted && !attachementType.category })} />
                            {submitted && !attachementType.category && <small className="p-invalid">Company Category name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={attachementType.description} onChange={(e) => onInputChange(e, 'description')} required rows={5} cols={20} />
                        </div>
                    </Dialog>
                    <Dialog visible={deleteAttachementDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAttachementDialogFooter} onHide={hideDeleteAttachementDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {attachementType && <span>Are you sure you want to delete <b>{attachementType.category}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={activateDeactivateDialog} style={{ width: '450px' }} header="Confirm" modal footer={activateAttachementDialogFooter} onHide={hideActivateDeactivateAttachementDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {attachementType && <span>Are you sure you want to <b>{attachementType.recordStatus === 1 ? "Activate" : "Deactivate"}</b> <b>{attachementType.category}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default CompanyCategoryPage;