import React, { useState, useEffect, useRef } from "react";

import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import SnipperModal from "../../modal/SnipperModal";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import RequiredAttachmentService from "../../../service/master-data/requiredAttachment";
import MasterDataService from "../../../service/master-data/MasterDataService";
import CompanyCategoryService from "../../../service/master-data/companyCategoryService";
import { useAuth } from "../../../utilities/authContext";

const CompanyCategoryAttachment = () => {
    let emptyrequiredAttachement = {
        id: 0,
        recordStatus: 0,
        companyCategoryId: 0,
        description: "",
        attachementTypeId: 0,
        attachementType: [],
        companyCategory: []
    };
    const requestBody = {

        id: 0,
        companyCategoryId: 0,
        description: "",
        attachementTypeId: 0

    };
    const activateDeactivateBody = {
        status: 0,
    };

    let [loading, setLoading] = useState(false);

    const [visaFees, setvisaFees] = useState(null);
    const [attachmentTypes, setAttachmentTypes] = useState(null);
    const [companyCategories, setCompanyCategories] = useState(null);

    const [selectedAttachmentType, SetSelectedAttachmentType] = useState({ id: 0, name: "" });
    const [selectedCompanyCategory, SetSelectedCompanyCategory] = useState({ id: 0, name: "" });

    const [accommodationUpdateDialog, setAccommodationUpdateDialogDialog] = useState(false);
    const [visaFeeDialog, setvisaFeeDialog] = useState(false);
    const [deletevisaFeeDialog, setDeletevisaFeeDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);
    const [visaFee, setvisaFee] = useState(emptyrequiredAttachement);
    const [selectedAccommodations, setSelectedAccommodations] = useState(null);

    const [submitted, setSubmitted] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'companyCategory.category': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'attachementType.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'description': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });
    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        
        const masterDataService = new RequiredAttachmentService();
        setLoading(true)
        masterDataService
            .getAll("CompanyAttachement-View")
            .then((res) => {
                setLoading(false)
                setvisaFees(res.data);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching company attachments.", life: 3000 });
            })
            .finally(() => {
                setLoading(false);
            });
        const commonService = new MasterDataService();
        commonService
            .getAttachementType("CompanyAttachement-View")
            .then((res) => {
                setAttachmentTypes(res.data);
            })
        const companyCategoryService = new CompanyCategoryService();
        companyCategoryService.getAll("CompanyAttachement-View")
            .then((res) => {
                setCompanyCategories(res.data);
            })
    }, []);
    const openNew = () => {
        setvisaFee(emptyrequiredAttachement);
        setSubmitted(false);
        setvisaFeeDialog(true);
    };

    const hidevisaFeeDialog = () => {
        setSubmitted(false);
        setvisaFeeDialog(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setAccommodationUpdateDialogDialog(false);
    };

    const hideDeletevisaFeeDialog = () => {
        setDeletevisaFeeDialog(false);
    };
    const hideActivateDeactivatevisaFeeDialog = () => {
        setActivateDeactivateDialog(false);
    };

    function refreshVisaFee() {
        window.location.reload(false);
      }
    const SubmitVisaFee = () => {
        setSubmitted(true);

        // let _visaFees = [...visaFees];
        let _vsiaFee = { ...visaFee };
        setLoading(true)
        const masterDataService = new RequiredAttachmentService();
        masterDataService.create(_vsiaFee, "CompanyAttachement-Add")
            .then((res) => {
                setLoading(false)
                toast.current.show({ severity: "success", summary: "Successful", detail: "Company  Attachement Created", life: 3000 });
                refreshVisaFee()
            })
            .catch((err) => {
                setLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Company already Attachement Created", life: 3000 });
                refreshVisaFee()
            });

        // setvisaFees(_visaFees);
        setvisaFeeDialog(false);
        setvisaFee(emptyrequiredAttachement);
    };
    const UpdatevisaFee = () => {
        setSubmitted(true);

        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };
        if (visaFee.id) {
            const index = findIndexById(visaFee.id);

            _visaFees[index] = _visaFee;
            const masterDataService = new RequiredAttachmentService();

            requestBody.id = _visaFee.id;
            requestBody.description = _visaFee.description;
            requestBody.attachementTypeId = _visaFee.attachementTypeId;
            requestBody.companyCategoryId = _visaFee.companyCategoryId;

            setLoading(true)
            masterDataService.edit(requestBody, "CompanyAttachement-Edit")
                .then((res) => {
                    setLoading(false)
                    refreshVisaFee()
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Company Attachment Updated", life: 3000 });
                    // reset();
                    // history.push('/roles')
                })
                .catch((err) => {
                    setLoading(false)
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Company Attachment  already Registered ", life: 3000 });
                    refreshVisaFee();
                });
            setvisaFees(_visaFees);
            setAccommodationUpdateDialogDialog(false);
            setvisaFee(emptyrequiredAttachement);
        }
    };
    const editAccommodation = (visaFee) => {
        setvisaFee({ ...visaFee });
        setAccommodationUpdateDialogDialog(true);
    };

    const confirmDeleteVisaFee = (visaFee) => {
        setvisaFee(visaFee);
        setDeletevisaFeeDialog(true);
    };

     const deleteVisaFee = () => {
        let _visaFees = visaFees.filter((val) => val.id !== visaFee.id);

        const masterDataService = new RequiredAttachmentService();
        setLoading(true)

        masterDataService.delete(visaFee.id, "CompanyAttachement-Delete").then((res) => {
            setLoading(false)
            setvisaFees(_visaFees);
            setDeletevisaFeeDialog(false);
            setvisaFee(emptyrequiredAttachement);
            toast.current.show({ severity: "success", summary: "Successful", detail: "Attachment Deleted", life: 3000 });
        }).catch((err) => {
            setLoading(false)
            toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
            refreshVisaFee();
        });
    };
  
    const activateDeactivateVisaFee = () => {
        setSubmitted(true);

        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };
        if (visaFee.id) {
            const index = findIndexById(visaFee.id);

            _visaFees[index] = _visaFee;
            const masterDataService = new RequiredAttachmentService();
            activateDeactivateBody.id = visaFee.id
            if (visaFee.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            } else activateDeactivateBody.status = 1;
            setLoading(true)
            masterDataService
                .activateDeactivate(activateDeactivateBody, "CompanyAttachement-ChangeStatus")
                .then((res) => {
                    setLoading(false)
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Attachment Activated" : "Attachment Deactivated", life: 3000 });
                    refreshVisaFee()
                })
                .catch((err) => {
                    setLoading(false)
                    toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
                    refreshVisaFee();
                });
        }
        setvisaFees(_visaFees);
        setActivateDeactivateDialog(false);
        setvisaFee(emptyrequiredAttachement);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < visaFees.length; i++) {
            if (visaFees[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };
   
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = val;
        setvisaFee(_visaFee);
    };
    const onAttachmentTypeChange = (e, name) => {
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = e.value;
        setvisaFee(_visaFee);
        let selectedValue = attachmentTypes.find((c) => c.id === e.value);
        SetSelectedAttachmentType(selectedValue);

    };
    const onCompanyCategoryChange = (e, name) => {
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = e.value;
        setvisaFee(_visaFee);
        let selectedValue = companyCategories.find((c) => c.id === e.value);
        SetSelectedCompanyCategory(selectedValue);

    };
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("CompanyAttachement-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    };
    const companyCategoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Company Category</span>
                {rowData.companyCategory.category}
            </>
        );
    };
    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    };
    const attachementTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Attachement Type</span>
                {rowData?.attachementType?.name}
            </>
        );
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("CompanyAttachement-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editAccommodation(rowData)} /> : <></>}
                {roles.privileges.includes("CompanyAttachement-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteVisaFee(rowData)} /> : <></>}
                {/* <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmactivateDeactivateVisaFee(rowData)} /> */}
            </div>
        );
    };

    const statuses = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Company Attachements</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const updatevisaFeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdatevisaFee} />
        </>
    );

    const visaFeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hidevisaFeeDialog} />
            <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitVisaFee} />
        </>
    );

    const deletevisaFeeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeletevisaFeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteVisaFee} />
        </>
    );

    const activateDeactivatevisaFeeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivatevisaFeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateVisaFee} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable
                        ref={dt}
                        value={visaFees}
                        selection={selectedAccommodations}
                        onSelectionChange={(e) => setSelectedAccommodations(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        filters={filters}
                        scrollable
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Accommodations"
                        globalFilter={globalFilter}
                        emptyMessage="No Company Attachment found."
                        // header={header}
                        responsiveLayout="scroll"
                    >

                        <Column field="companyCategory.category" header="Company Category" sortable body={companyCategoryBodyTemplate} style={{ minWidth: "8rem" }} headerStyle={{ minWidth: "8rem" }}></Column>
                        <Column field="description" header="Description" sortable body={descriptionBodyTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>
                        <Column field="attachementType.name" header="Attachement Type" sortable body={attachementTypeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable style={{ minWidth: "5rem" }} filter filterElement={statusFilterTemplate} headerStyle={{ minWidth: "5rem" }}></Column>

                        {(roles.privileges.includes("CompanyAttachement-Edit") === true ||
                            roles.privileges.includes("CompanyAttachement-Delete") === true) ? <Column header="Actions" body={actionBodyTemplate}></Column> : <></>}
                    </DataTable>

                    <Dialog visible={visaFeeDialog} style={{ width: "30vw", height: "50vh" }} header="Create Required Attachment " modal className="p-fluid" footer={visaFeeDialogFooter} onHide={hidevisaFeeDialog}>

                        <div className="field">
                            <label htmlFor="type">Attachment Type</label>
                            <Dropdown optionValue="id" id="type" optionLabel="name" value={selectedAttachmentType.id} optionName={visaFee.attachementTypeId} options={attachmentTypes} onChange={(e) => onAttachmentTypeChange(e, "attachementTypeId")} placeholder="Select Attachement Type" />
                        </div>
                        <div className="field">
                            <label htmlFor="companyCategory">Company Category</label>
                            <Dropdown optionValue="id" id="companyCategory" optionLabel="category" value={selectedCompanyCategory.id} optionName={visaFee.companyCategoryId} options={companyCategories} onChange={(e) => onCompanyCategoryChange(e, "companyCategoryId")} placeholder="Select Company Category" />
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={visaFee.description} rows={5} cols={20} onChange={(e) => onInputChange(e, "description")} required className={classNames({ "p-invalid": submitted && !visaFee.description })} />
                            {submitted && !visaFee.description && <small className="p-error">Description is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={accommodationUpdateDialog} style={{ width: "30vw", height: "50vh" }} header="Update  Required Attachment" modal className="p-fluid" footer={updatevisaFeeDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="type">Attachment Type</label>
                            <Dropdown optionValue="id" optionLabel="name" value={visaFee.attachementTypeId} optionName={visaFee.attachementTypeId} options={attachmentTypes} onChange={(e) => onAttachmentTypeChange(e, "attachementTypeId")} placeholder="Select Attachement Type" />
                        </div>
                        <div className="field">
                            <label htmlFor="companyCategory">Company Category</label>
                            <Dropdown optionValue="id" optionLabel="category" value={visaFee.companyCategoryId} optionName={visaFee.companyCategoryId} options={companyCategories} onChange={(e) => onCompanyCategoryChange(e, "companyCategoryId")} placeholder="Select Company Category" />
                        </div>
                        <div className="field">
                            <label htmlFor="name">Description</label>
                            <InputTextarea id="description" rows={5} cols={20} value={visaFee.description} onChange={(e) => onInputChange(e, "description")} required className={classNames({ "p-invalid": submitted && !visaFee.description })} />
                            {submitted && !visaFee.description && <small className="p-error">Description is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletevisaFeeDialog} style={{ width: "450px" }} header="Confirm" modal footer={deletevisaFeeDialogFooter} onHide={hideDeletevisaFeeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {visaFee && (
                                <span>
                                    Are you sure you want to delete attachement <b>{visaFee.description}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: "450px" }} header="Confirm" modal footer={activateDeactivatevisaFeeDialogFooter} onHide={hideActivateDeactivatevisaFeeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {visaFee && (
                                <span>
                                    Are you sure you want to activate attachement <b>{visaFee.description}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default CompanyCategoryAttachment;
