import React, { useState, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import VisaService from '../../../service/VisaValidity/VisaService';
import SnipperModal from "../../modal/SnipperModal";
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useAuth } from '../../../utilities/authContext';

const VisaValadity = () => {
    let emptyVisaValadityType = {
        id: null,
        days: null,
        name: '',
        image: null,
        description: '',
        category: null,
        isMultipleEntry:true

    };
    const visavalidityBody = {
        name: '',
        days: 0,
        isMultipleEntry:true
    };
    const activateDeactivateBody = {
        status: 0,
    };

    let [loading, setLoading] = useState(false);
    const [visaValadityTypes, setvisaValidityTypes] = useState(null);
    const [countryUpdateDialog, setValidityUpdateDialogDialog] = useState(false);
    const [countryDialog, setvisavalidityDialog] = useState(false);
    const [deleteCountryDialog, setDeleteValidityDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);
    const [isMultipleEntry, setisMultipleEntry] = useState(true);
    const [performingAction, setPerformingAction] = useState(false);
    const [visavaliditytype, setvisavaliditytype] = useState(emptyVisaValadityType);
    const [selectedvisavalidities, setselectedvisavalidities] = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });
    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        setLoading(true);
        const visaService = new VisaService();
        visaService.getVisaValidity("VisaValidity-View")
            .then(res => {
                setvisaValidityTypes(res.data)
            }).catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching visa validities.", life: 3000 });
            }).finally(() => {
                setLoading(false);
            })
    }, []);
    const openNew = () => {
        setvisavaliditytype(emptyVisaValadityType);
        setSubmitted(false);
        setvisavalidityDialog(true);
    }


    const hideCountryDialog = () => {
        setSubmitted(false);
        setvisavalidityDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setValidityUpdateDialogDialog(false);
    }

    const hideDeleteCountryDialog = () => {
        setDeleteValidityDialog(false);
    }
    const hideActivateDeactivateCountryDialog = () => {
        setActivateDeactivateDialog(false);
    }

    const SubmitVisaValidity = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _validitytypes = [...visaValadityTypes];
        let _validitytype = { ...visavaliditytype };

        visavalidityBody.name = _validitytype.name;
        visavalidityBody.days = _validitytype.days;
        visavalidityBody.isMultipleEntry = isMultipleEntry;
        const visaService = new VisaService();

        visaService.createVisaValidity(visavalidityBody, "VisaValidity-Add")
            .then((res) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Visa Validity Created', life: 3000 });
                refresh();
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Visa Validity already Registered ", life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setvisaValidityTypes(_validitytypes);
                setvisavalidityDialog(false);
                setvisavaliditytype(emptyVisaValadityType);
            })
    }
    const UpdateVisaValidity = () => {
        setPerformingAction(true);
        setSubmitted(true);

        if (visavaliditytype.name.trim()) {
            let _validitytypes = [...visaValadityTypes];
            let _validitytype = { ...visavaliditytype };
            if (visavaliditytype.id) {
                const index = findIndexById(visavaliditytype.id);

                _validitytypes[index] = _validitytype;
                const visaService = new VisaService();

                visavalidityBody.name = _validitytype.name;
                visavalidityBody.days = _validitytype.days;
                visavalidityBody.isMultipleEntry=isMultipleEntry;
                visaService.editVisaValidity(visavalidityBody, _validitytype.id, "VisaValidity-Edit")
                    .then((res) => {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Visa Validity Updated', life: 3000 });
                        refresh();

                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Visa Validity already Registered ", life: 3000 });
                    }).finally(() => {
                        setPerformingAction(false);
                        setvisaValidityTypes(_validitytypes);
                        setValidityUpdateDialogDialog(false);
                        setvisavaliditytype(emptyVisaValadityType);
                    })
            }
        }
    }
    const editCountry = (visavaliditytype) => {
        setvisavaliditytype({ ...visavaliditytype });
        setValidityUpdateDialogDialog(true);
        setisMultipleEntry(visavaliditytype?.isMultipleEntry);
        
    }

    const confirmDeleteCountry = (visavaliditytype) => {
        setvisavaliditytype(visavaliditytype);
        setDeleteValidityDialog(true);
    }

    const confirmActivateDeactivateCountry = (visavaliditytype) => {
        setvisavaliditytype(visavaliditytype);
        setActivateDeactivateDialog(true);
    }

    const deleteCounrty = () => {
        setPerformingAction(true);
        let _validitytypes = visaValadityTypes.filter(val => val.id !== visavaliditytype.id);

        const visaService = new VisaService();
        visaService.deleteVisaValidity(visavaliditytype.id, "VisaValidity-Delete")
            .then(res => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Visa Validity Deleted', life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setvisaValidityTypes(_validitytypes);
                setDeleteValidityDialog(false);
                setvisavaliditytype(emptyVisaValadityType);
            });

    }
    const activateDeactivateCountryname = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _validitytypes = [...visaValadityTypes];
        let _validitytype = { ...visavaliditytype };
        if (visavaliditytype.id) {
            const index = findIndexById(visavaliditytype.id);

            _validitytypes[index] = _validitytype;
            const visaService = new VisaService();

            if (visavaliditytype.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            }
            else
                activateDeactivateBody.status = 1;

            visaService.activateDeactivateVisaValidity(visavaliditytype.id, activateDeactivateBody, "VisaValidity-ChangeStatus")
                .then((res) => {
                    toast.current.show({
                        severity: "success", summary: "Successful",
                        detail: res.data.recordStatus === 2 ? "Visa Validity Activated" : "Visa Validity  Deactivated", life: 3000
                    });
                    refresh();
                })
                .catch((err) => {
                }).finally(() => {
                    setPerformingAction(false);
                    setvisaValidityTypes(_validitytypes);
                    setActivateDeactivateDialog(false);
                    setvisavaliditytype(emptyVisaValadityType);
                });
        }
    }
    const refresh = () => {
        const visaService = new VisaService();
        visaService.getVisaValidity("VisaValidity-View")
            .then((res) => {
                setvisaValidityTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < visaValadityTypes.length; i++) {
            if (visaValadityTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _validitytype = { ...visavaliditytype };
        _validitytype[`${name}`] = val;
        setvisavaliditytype(_validitytype);
    }
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("VisaValidity-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Country Name</span>
                {rowData.name}
            </>
        );
    }
    const multipleEntryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">is Multiple entry</span>

                {rowData?.isMultipleEntry ? "true" : "false"}

            </>
        );
    }
    const daysBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Country Code</span>
                {rowData.days}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("VisaValidity-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editCountry(rowData)} /> : <></>}
                {roles.privileges.includes("VisaValidity-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteCountry(rowData)} /> : <></>}
                {roles.privileges.includes("VisaValidity-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmActivateDeactivateCountry(rowData)} /> : <></>}
            </div>
        );
    }


    const status = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={status} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }

    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Visa Validity</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const updateCountryDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdateVisaValidity} />}
        </>
    );

    const counrtyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideCountryDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitVisaValidity} />}
        </>
    );

    const deleteCountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeleteCountryDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteCounrty} />}
        </>
    );

    const activateDeactivatecountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivateCountryDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateCountryname} />}
        </>
    );
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput} ></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable ref={dt} filters={filters} value={visaValadityTypes} selection={selectedvisavalidities} onSelectionChange={(e) => setselectedvisavalidities(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Visa Validities" // header={header}
                        globalFilter={globalFilter} scrollable emptyMessage="No Visa Validities found." responsiveLayout="scroll">

                        <Column field="name" header="Name" sortable body={nameBodyTemplate}
                            style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                            
                        <Column field="days" header="No.Staying Days" sortable body={daysBodyTemplate}
                            style={{ minWidth: "10rem" }} headerStyle={{ minWidth: "10rem" }}></Column>
                            
                        <Column field="name" header="Is MultipleEntry" sortable body={multipleEntryBodyTemplate}
                            style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                            
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable filter filterElement={statusFilterTemplate} style={{ minWidth: "5rem" }} headerStyle={{ minWidth: "5rem" }}></Column>

                        {(roles.privileges.includes("VisaValidity-Edit") === true ||
                            roles.privileges.includes("VisaValidity-Delete") === true ||
                            roles.privileges.includes("VisaValidity-ChangeStatus") === true) ? <Column header="Actions" body={actionBodyTemplate}></Column> : <></>}
                    </DataTable>
                    <Dialog visible={countryDialog} style={{ width: '450px' }} header="Create Visa Validity"
                        modal className="p-fluid" footer={counrtyDialogFooter} onHide={hideCountryDialog}>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={visavaliditytype.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !visavaliditytype.name })} />
                            {submitted && !visavaliditytype.name && <small className="p-error"> Visa Valadity name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="days">No.Staying Days</label>
                            <InputText id="days" value={visavaliditytype.days} onChange={(e) => onInputChange(e, 'days')} required
                                keyfilter="int" className={classNames({ 'p-invalid': submitted && !visavaliditytype.days })} />
                            {submitted && !visavaliditytype.days && <small className="p-error text-red">No.Staying Days is required.</small>}
                        </div>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={isMultipleEntry} onChange={e => setisMultipleEntry(e.checked)} />
                            <label htmlFor="binary">Is Multiple Entry</label>
                        </div>
                    </Dialog>

                    <Dialog visible={countryUpdateDialog} style={{ width: '450px' }} header="Update Visa Validity"
                        modal className="p-fluid" footer={updateCountryDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="name">Visa Validity Name</label>
                            <InputText id="name" value={visavaliditytype.name} onChange={(e) => onInputChange(e, 'name')}
                                required autoFocus className={classNames({ 'p-invalid': submitted && !visavaliditytype.name })} />
                            {submitted && !visavaliditytype.name && <small className="p-invalid"> Visa Validity Name  is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="days">No. of Staying Days</label>
                            <InputText id="days" value={visavaliditytype.days} onChange={(e) => onInputChange(e, 'days')}
                                required keyfilter="int" />
                        </div>
                        <div className="field-checkbox">
                            <Checkbox inputId="binary" checked={isMultipleEntry} onChange={e => setisMultipleEntry(e.checked)} />
                            <label htmlFor="binary">Is Multiple Entry</label>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCountryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCountryDialogFooter} onHide={hideDeleteCountryDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {visavaliditytype && <span>Are you sure you want to delete <b>{visavaliditytype.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: '450px' }} header="Confirm" modal footer={activateDeactivatecountryDialogFooter} onHide={hideActivateDeactivateCountryDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {visavaliditytype && <span>
                                Are you sure you want to <b>{visavaliditytype.recordStatus === 1 ? "Activate" : "Deactivate"}</b> <b>{visavaliditytype.name}</b>?
                            </span>}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

export default VisaValadity;