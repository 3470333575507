import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import MasterDataService from '../../../service/master-data/MasterDataService';
import SnipperModal from "../../modal/SnipperModal";
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useAuth } from '../../../utilities/authContext';

const AccommodationType = () => {
    let emptyAccommodationType = {
        id: null,
        name: '',
        description: '',
        recordStatus: 0,
    };
    const accommodationBody = {
        name: "",
        description: "",
    };
    const activateDeactivateBody = {
        status: 0,
    };


    let [loading, setLoading] = useState(false);
    const [accommodationTypes, setAccommodationTypes] = useState(null);
    const [accommodationUpdateDialog, setAccommodationUpdateDialogDialog] = useState(false);
    const [accommodationDialog, setAccommodationDialog] = useState(false);

    const [deleteAccommodationDialog, setDeleteAccommodationDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);

    const [performingAction, setPerformingAction] = useState(false);
    const [accommodationType, setAccommodationType] = useState(emptyAccommodationType);
    const [selectedAccommodations, setSelectedAccommodations] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });
    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        setLoading(true)
        const masterDataService = new MasterDataService();
        masterDataService.getAccommodationType("AccommodationType-View")
            .then(res => {
                setLoading(false)
                setAccommodationTypes(res.data)
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching accommodation types.", life: 3000 });
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    const openNew = () => {
        setAccommodationType(emptyAccommodationType);
        setSubmitted(false);
        setAccommodationDialog(true);
    }


    const hideAccommodationDialog = () => {
        setSubmitted(false);
        setAccommodationDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setAccommodationUpdateDialogDialog(false);
    }

    const hideDeleteAccommodationDialog = () => {
        setDeleteAccommodationDialog(false);
    }
    const hideActivateDeactivateAccommodationDialog = () => {
        setActivateDeactivateDialog(false);
    }

    const SubmitAccommodation = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _accommodationTypes = [...accommodationTypes];
        let _accommodationType = { ...accommodationType };

        accommodationBody.name = _accommodationType.name;
        accommodationBody.description = _accommodationType.description;
        const masterDataService = new MasterDataService();

        masterDataService.createAccommodationType(accommodationBody, "AccommodationType-Add")
            .then((res) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Accommodation Created', life: 3000 });
                refresh();
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Accommodation Type already Registered ", life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setAccommodationTypes(_accommodationTypes);
                setAccommodationDialog(false);
                setAccommodationType(emptyAccommodationType);
            })
    }
    const UpdateAccommodationType = () => {
        setPerformingAction(true);
        setSubmitted(true);

        if (accommodationType.name.trim()) {
            let _accommodationTypes = [...accommodationTypes];
            let _accommodationType = { ...accommodationType };
            if (accommodationType.id) {
                const index = findIndexById(accommodationType.id);
                _accommodationTypes[index] = _accommodationType;
                const masterDataService = new MasterDataService();
                accommodationBody.name = _accommodationType.name;
                accommodationBody.description = _accommodationType.description;
                masterDataService.updateAccommodationType(accommodationBody, _accommodationType.id, "AccomodationType-Edit")
                    .then((res) => {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Accommodation Updated', life: 3000 });
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
                    }).finally(() => {
                        setPerformingAction(false);
                        setAccommodationTypes(_accommodationTypes);
                        setAccommodationUpdateDialogDialog(false);
                        setAccommodationType(emptyAccommodationType);
                    })
            }
        }
    }
    const editAccommodation = (accommodationType) => {
        setAccommodationType({ ...accommodationType });
        setAccommodationUpdateDialogDialog(true);
    }

    const confirmDeleteAccommodation = (accommodationType) => {
        setAccommodationType(accommodationType);
        setDeleteAccommodationDialog(true);
    }

    const confirmActivateDeactivateAccommodation = (accommodationType) => {
        setAccommodationType(accommodationType);
        setActivateDeactivateDialog(true);
    }

    const deleteAccommodation = () => {
        setPerformingAction(true);
        let _accommodationTypes = accommodationTypes.filter(val => val.id !== accommodationType.id);

        const masterDataService = new MasterDataService();
        masterDataService.deleteAccommodationType(accommodationType.id, "AccommodationType-Delete").then(res => {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Accommodation Deleted', life: 3000 });
        }).finally(() => {
            setPerformingAction(false);
            setAccommodationTypes(_accommodationTypes);
            setDeleteAccommodationDialog(false);
            setAccommodationType(emptyAccommodationType);
        });

    }
    const activateDeactivateAccommodation = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _accommodationTypes = [...accommodationTypes];
        let _accommodationType = { ...accommodationType };
        if (accommodationType.id) {
            const index = findIndexById(accommodationType.id);

            _accommodationTypes[index] = _accommodationType;
            const masterDataService = new MasterDataService();

            if (accommodationType.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            }
            else
                activateDeactivateBody.status = 1;

            masterDataService.activateDeactivateAccommodationType(accommodationType.id, activateDeactivateBody, "", "AccommodationType-ChangeStatus")
                .then((res) => {

                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Accommodation Activated" : "Accommodation Deactivated", life: 3000 });
                    refresh();
                })
                .catch((err) => {

                }).finally(() => {
                    setPerformingAction(false);
                    setAccommodationTypes(_accommodationTypes);
                    setActivateDeactivateDialog(false);
                    setAccommodationType(emptyAccommodationType);
                });

        }
    }
    const refresh = () => {
        const masterDataService = new MasterDataService();
        masterDataService
            .getAccommodationType("AccommodationType-View")
            .then((res) => {
                setAccommodationTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < accommodationTypes.length; i++) {
            if (accommodationTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _accommodationType = { ...accommodationType };
        _accommodationType[`${name}`] = val;
        setAccommodationType(_accommodationType);
    }
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("AccommodationType-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Accommodation-Type</span>
                {rowData.name}
            </>
        );
    }
    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("AccomodationType-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editAccommodation(rowData)} /> : <></>}
                {roles.privileges.includes("AccommodationType-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteAccommodation(rowData)} /> : <></>}
                {roles.privileges.includes("AccommodationType-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmActivateDeactivateAccommodation(rowData)} /> : <></>}
            </div>
        );
    }

    const status = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={status} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }

    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Accommodation Types</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const updateAccommodationDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdateAccommodationType} />}
        </>
    );

    const accommodationDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideAccommodationDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitAccommodation} />}
        </>
    );

    const deleteAccommodationDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeleteAccommodationDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteAccommodation} />}
        </>
    );

    const activateDeactivateAccommodationDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivateAccommodationDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateAccommodation} />}
        </>
    );



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable ref={dt} filters={filters} value={accommodationTypes} selection={selectedAccommodations} onSelectionChange={(e) => setSelectedAccommodations(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Accommodations" // header={header}
                        globalFilter={globalFilter} emptyMessage="No Accommodations found." scrollable responsiveLayout="scroll">

                        <Column field="name" header="Name" sortable body={nameBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                        <Column field="description" header="Description" sortable body={descriptionBodyTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable filter filterElement={statusFilterTemplate} style={{ minWidth: "5rem" }} headerStyle={{ minWidth: "5rem" }}></Column>

                        {(roles.privileges.includes("AccomodationType-Edit") === true ||
                            roles.privileges.includes("AccommodationType-Delete") === true ||
                            roles.privileges.includes("AccommodationType-ChangeStatus") === true) ? <Column header="Actions" body={actionBodyTemplate}></Column> : <></>}
                    </DataTable>


                    <Dialog visible={accommodationDialog} style={{ width: '450px' }} header="Create Accommodation Type"
                        modal className="p-fluid" footer={accommodationDialogFooter} onHide={hideAccommodationDialog}>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={accommodationType.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !accommodationType.name })} />
                            {submitted && !accommodationType.name && <small className="p-error">Accommodation type name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={accommodationType.description} onChange={(e) => onInputChange(e, 'description')} required
                                rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !accommodationType.description })} />
                            {submitted && !accommodationType.description && <small className="p-error text-red">Accommodation type description is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={accommodationUpdateDialog} style={{ width: '450px' }} header="Update Accommodation Type"
                        modal className="p-fluid" footer={updateAccommodationDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={accommodationType.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !accommodationType.name })} />
                            {submitted && !accommodationType.name && <small className="p-invalid">Accommodation type name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={accommodationType.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteAccommodationDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAccommodationDialogFooter} onHide={hideDeleteAccommodationDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {accommodationType && <span>Are you sure you want to delete <b>{accommodationType.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: '450px' }} header="Confirm" modal footer={activateDeactivateAccommodationDialogFooter} onHide={hideActivateDeactivateAccommodationDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {accommodationType && <span>
                                Are you sure you want to <b>{accommodationType.recordStatus === 1 ? "Activate" : "Deactivate"}</b> <b>{accommodationType.name}</b>?
                            </span>}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

export default AccommodationType;