import React from 'react';

const AppFooter = () => {

    

    return (
        <div className="layout-footer">
            <span className="footer-text-left">&copy; 2017 - {new Date().getUTCFullYear()}   Immigration and Citizenship Service , Federal Democratic Republic of Ethiopia.</span>
            <span className="footer-text-right">e-<b>VISA</b> Administration</span>
        </div>
    )

}

export default AppFooter;