import React from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';

const AppBreadcrumb = () => {

    const location = useLocation();

    const paths = location.pathname.split('/');
    const arr = [];

    const home = { icon: 'pi pi-home', url: '/dashboard' }

    const concatURL = (arr) => {
        let str = "/";
        for (const value of arr) {
            const current = value?.label.charAt(0).toLowerCase() + value?.label.slice(1)

            str += current + '/'
        }
        return str.slice(0, str.length - 1)
    }

    const concatLabel = (arr) => {
        let str = "";
        for (const value of arr) {
            str += value.charAt(0).toUpperCase() + value.slice(1) + " "
        }

        return str.slice(0, str.length - 1)
    }

    paths.map((value) => {
        if (value === '') {
            // console.log('/')
        } else {
            const url = concatURL(arr) + '/' + value
            const label = concatLabel(value.split('-'))

            arr.push({ label })
        }
    })

    return (
        <div className="card">
            <BreadCrumb model={arr} home={home} />

            {/* <ul>
                <li><button type="button" className="p-link" onClick={() => history.push('/dashboard')}><i className="pi pi-home"></i></button></li>
                {
                    location.pathname === '/' ? <li>/</li> : paths.map((path, index) => <li key={index}>{path === '' ? '/' : path}</li>)
                }
            </ul> */}

            {/* <div className="layout-breadcrumb-options">
                <button type="button" className="p-link" title="Backup">
                    <i className="pi pi-cloud-upload"></i>
                </button>
                <button type="button" className="p-link" title="Bookmark">
                    <i className="pi pi-bookmark"></i>
                </button>
                <button type="button" className="p-link" title="Logout">
                    <i className="pi pi-power-off"></i>
                </button>
            </div> */}
        </div>
    );

}

export default withRouter(AppBreadcrumb);
