import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode, FilterOperator } from "primereact/api";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import AccountService from "../../../service/account/AcountService";
import { Checkbox } from "primereact/checkbox";
import { useAuth } from "../../../utilities/authContext";

export default function User() {
    const emptyUserModel = {
        id: null,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        username: "",
        password: "",
        confirmPassword: "",
        isSuperAdmin: false,
        isAccountLocked: false,
        clientId: 3,
        roles: null,
    };

    const emptyResetPasswordModel = {
        username: "",
        password: "",
        confirmPassword: "",
    };

    const fields = [
        { name: "First Name", code: "firstName", type: "text", filter: "alpha", size: 6, visibility: "all" },
        { name: "Last Name", code: "lastName", type: "text", filter: "alpha", size: 6, visibility: "all" },
        { name: "Username", code: "username", type: "text", filter: "alphanum", size: 12, visibility: "new" },
        { name: "Email", code: "email", size: 12, visibility: "all" },
        { name: "Password", code: "password", type: "password", size: 6, visibility: "new" },
        { name: "Confirm Password", code: "confirmPassword", type: "confirmPassword", size: 6, visibility: "new" },
        { name: "Phone Number", code: "phoneNumber", type: "phone", size: 12, visibility: "all" },
        { name: "Roles", code: "roles", type: "select", size: 12 },
        { name: "Is Super Admin", code: "isSuperAdmin", type: "checkbox", visibility: "all" },
    ];

    const newUserDialogSize = "75vh";
    const updateUserDialogSize = "55vh";

    const [user, setUser] = useState(emptyUserModel);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);

    const [selectedUsername, setSelectedUsername] = useState("");

    const [userDialog, setUserDialog] = useState(false);
    const [newUserDialog, setNewUserDialog] = useState(false);
    const [resetDialong, setResetDialog] = useState(false);
    const [changeStatusDialog, setChangeStatusDialog] = useState(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);

    const [loading, setLoading] = useState(true);
    const [refreashTable, setRefreashTable] = useState(false);
    const [performingAction, setPerformingAction] = useState(false);

    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        lastName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        phoneNumber: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        username: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        isAccountLocked: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const {
        control,
        watch,
        setValue,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: { ...emptyUserModel },
    });

    const {
        control: resetControl,
        watch: watchReset,
        formState: { errors: resetErrors },
        handleSubmit: handleSubmitReset,
    } = useForm({
        defaultValues: { ...emptyResetPasswordModel },
    });

    const priv = useAuth();

    const phoneNumberWatcher = watch("phoneNumber");
    const passwordWatcher = watch("password");
    useEffect(() => {
        if (phoneNumberWatcher === undefined) {
            setValue("phoneNumber", "");
        }
    });

    const resetPasswordWatcher = watchReset("password");

    const handleEmailValidation = (email) => {
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    };

    const handlePasswordValidation = (password) => {
        return /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./.test(password);
    };

    const handlePasswordConfirm = (password) => {
        return password === passwordWatcher;
    };

    const handleResetPasswordConfirm = (password) => {
        return password === resetPasswordWatcher;
    };

    const toast = useRef(null);

    const userService = new AccountService();

    useEffect(() => {
        userService
            .getUsers("Users-View")
            .then((res) => {
                setUsers(res.data);
                setRefreashTable(false);
            })
            .catch((e) => {
                toast.current.show({ severity: "error", summary: "Error Message", detail: "Couldn't fetch Users", life: 4000 });
            })
            .finally(() => {
                setLoading(false);
            });

        userService
            .getroles("Users-View")
            .then((res) => {
                const activeRoles = [];
                res.data.map((value) => {
                    if (value.recordStatus === 2) {
                        activeRoles.push(value);
                    }
                });
                setRoles(activeRoles);
            })
            .catch((e) => {});
    }, [refreashTable]);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        const _filters = { ...filters };
        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const fetchSingleUser = (id) => {
        let roles = [];
        userService
            .getSingleUser(id, "Users-View")
            .then((res) => {
                res.data.identityUserRoles?.map((value) => {
                    if (value.role.recordStatus === 2) {
                        roles.push(value.role);
                    }
                });
            })
            .finally(() => {
                setValue("roles", roles);
            });
    };

    const showNewUserDialog = () => {
        setUser(emptyUserModel);
        reset(emptyUserModel);

        setNewUserDialog(true);
        setUserDialog(true);
    };

    const showResetDialong = () => {
        setResetDialog(true);
    };

    const showEditUserDialog = (_user) => {
        setUser(_user);
        reset(_user);

        fetchSingleUser(_user.id);

        setNewUserDialog(false);
        setUserDialog(true);
    };

    const showChangeStatusDialog = (_user) => {
        setUser(_user);
        setChangeStatusDialog(true);
    };

    const showConfirmDeleteDialog = (_user) => {
        setUser(_user);
        setDeleteUserDialog(true);
    };

    const hideUserDialog = () => {
        setPerformingAction(false);
        setUserDialog(false);
    };

    const hideResetDialog = () => {
        setPerformingAction(false);
        setResetDialog(false);
        setSelectedUsername();
    };

    const hideUserDeleteDialog = () => {
        setDeleteUserDialog(false);
    };

    const resetUserPassword = (data) => {
        let _user = data;
        _user["username"] = _user?.username?.username;
        let userData = { username: "", Newpassword: "" };
        userData["username"] = _user["username"];
        userData["Newpassword"] = _user["confirmPassword"];

        // console.log("user", userData);
        userService
            .resetAccount(userData, "Users-View")
            .then((res) => {
                setResetDialog(false);
                toast.current.show({ severity: "success", summary: "Success", detail: "Account Reset Successfully!", life: 4000 });
            })
            .catch((e) => {
                toast.current.show({ severity: "error", summary: "Error Message", detail: "Couldn't fetch Users", life: 4000 });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const addUser = (data) => {
        let _user = data;

        const newRoles = [];
        _user.roles.map((value) => {
            newRoles.push(value.id);
        });

        _user.firstName = _user.firstName.charAt(0).toUpperCase() + _user.firstName.slice(1);
        _user.lastName = _user.lastName.charAt(0).toUpperCase() + _user.lastName.slice(1);

        _user.clientId = 3;
        _user.roles = newRoles;

        setPerformingAction(true);

        userService
            .createUser(_user, "Users-Add")
            .then((res) => {
                setRefreashTable(true);
                toast.current.show({
                    severity: "success",
                    summary: "Success Message",
                    detail: "Successfully added a new user",
                    life: 4000,
                });
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Error Message", detail: `${err.response.data.errors[0]}`, life: 4000 });
            })
            .finally(() => {
                setPerformingAction(false);
            });

        setNewUserDialog(false);
        setUserDialog(false);
    };

    const editUser = (data) => {
        let _user = data;

        const newRoles = [];

        _user.roles.map((value) => {
            newRoles.push(value.id);
        });

        _user.clientId = 3;
        _user.roles = newRoles;

        _user.firstName = _user.firstName.charAt(0).toUpperCase() + _user.firstName.slice(1);
        _user.lastName = _user.lastName.charAt(0).toUpperCase() + _user.lastName.slice(1);

        setPerformingAction(true);

        userService
            .updateUser(_user, "Users-Edit")
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Success Message", detail: "User updated successfully", life: 4000 });
                setRefreashTable(true);
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Error Message", detail: "Error updating user", life: 4000 });
            })
            .finally(() => {
                setPerformingAction(false);
            });

        setUserDialog(false);
        setUser(emptyUserModel);
    };

    const deleteUser = () => {
        setPerformingAction(true);
debugger;
        if (user.id) {
            userService
                .deleteUser(user.id, "Users-Delete")
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Success Message", detail: "User Successfully Deleted", life: 4000 });
                    setRefreashTable(true);
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Error Message", detail: "Error deleting user", life: 4000 });
                })
                .finally(() => {
                    setPerformingAction(false);
                });
        }

        setDeleteUserDialog(false);
        setUser(emptyUserModel);
    };

    const changeStatus = () => {
        setPerformingAction(true);

        const successMessage = user.isAccountLocked === false ? "User Successfully Deactivated." : "User Successfully Activated.";
        const errorMessage = user.isAccountLocked === false ? "Error while deactivating user." : "Error while activating user.";
        const status = user.isAccountLocked === true ? 1 : 2;
        const id = user.id;

        if (status) {
            userService
                .activateDeactivateUser({ statusAction: status, userId: id }, "Users-ChangeStatus")
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Success Message", detail: successMessage, life: 4000 });
                    setRefreashTable(true);
                })
                .catch(() => {
                    toast.current.show({ severity: "error", summary: "Error Message", detail: errorMessage, life: 4000 });
                })
                .finally(() => {
                    setChangeStatusDialog(false);
                    setUser(emptyUserModel);
                    setPerformingAction(false);
                });
        }
    };

    const recordStatusBody = (rowData) => {
        const status = rowData.isAccountLocked;

        if (!status) {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }}>Active</span>;
        } else {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }}>Inactive</span>;
        }
    };

    const addNewUserBtn = () => {
        let template;
        template =
            priv?.privileges.includes("Users-Add") === true ? (
                <div className="md:flex justify-content-between align-items-center">
                    <div className="my-2">
                        <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={showNewUserDialog} />
                        <Button label="Reset Password" icon="pi pi-replay" className="p-button-warning mr-2" onClick={showResetDialong} />
                    </div>
                </div>
            ) : (
                <></>
            );
        return template;
    };

    const searchInput = () => {
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search User" />
            </span>
        );
    };

    const statuses = [
        { name: "Active", value: "false" },
        { name: "Inactive", value: "true" },
    ];

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    };

    const headerRender = () => {
        return (
            <div className="md:flex justify-content-between align-items-center">
                <h5 className="m-0">Manage Users</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search User" />
                </span>
            </div>
        );
    };

    const passwordHeader = <h6>Pick a strong password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {priv.privileges.includes("Users-Edit") === true ? <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning pl-1 mr-2" onClick={() => showEditUserDialog(rowData)} /> : <></>}
                {priv.privileges.includes("Users-Delete") === true ? <Button icon="pi pi-trash" className="p-button-rounded p-button-danger pl-1 mr-2" onClick={() => showConfirmDeleteDialog(rowData)} /> : <></>}
                {priv.privileges.includes("Users-ChangeStatus") === true ? (
                    rowData.isAccountLocked === false ? (
                        <Button icon="pi pi-lock" className="p-button-rounded p-button-info pl-1" onClick={() => showChangeStatusDialog(rowData)} />
                    ) : (
                        <Button icon="pi pi-lock-open" className="p-button-rounded p-button-info pl-1" onClick={() => showChangeStatusDialog(rowData)} />
                    )
                ) : (
                    <></>
                )}
            </div>
        );
    };

    const userDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideUserDialog} />
            {performingAction ? <Button label="Submit" icon="pi pi-spin pi-spinner"></Button> : <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={newUserDialog ? handleSubmit(addUser) : handleSubmit(editUser)} />}
        </>
    );

    const resetDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideResetDialog} />
            {performingAction ? <Button label="Reset" icon="pi pi-spin pi-spinner"></Button> : <Button label="Reset" icon="pi pi-check" className="p-button-raised" onClick={handleSubmitReset(resetUserPassword)} />}
        </>
    );

    const changeStatusRoleDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={() => setChangeStatusDialog(false)} />
            {performingAction ? <Button label="Submit" icon="pi pi-spin pi-spinner"></Button> : <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={changeStatus} />}
        </>
    );

    const deleteUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideUserDeleteDialog} />
            {performingAction ? <Button label="Submit" icon="pi pi-spin pi-spinner"></Button> : <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteUser} />}
        </>
    );

    return (
        <div className="card">
            <Toast ref={toast} />
            <Toolbar className="mb-4" left={addNewUserBtn} right={searchInput} />
            <DataTable
                value={users}
                paginator
                // header={headerRender}
                rows={10}
                rowsPerPageOptions={[5, 10, 15]}
                dataKey="id"
                rowHover
                filters={filters}
                filterDisplay="menu"
                loading={loading}
                responsiveLayout="scroll"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                emptyMessage="No User found start by creating one."
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords} Users"
            >
                <Column field="firstName" header="First Name" sortable style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                <Column field="lastName" header="Last Name" sortable style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                <Column field="email" header="Email" sortable style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                <Column field="username" header="Username" sortable style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                <Column field="phoneNumber" header="Phone Number" sortable style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                <Column field="isAccountLocked" header="Status" body={recordStatusBody} filter filterElement={statusFilterTemplate} sortable style={{ minWidth: "10rem" }} headerStyle={{ minWidth: "10rem" }}></Column>
                <Column body={actionBodyTemplate} header="Action" frozen alignFrozen="right" style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
            </DataTable>

            <Dialog visible={deleteUserDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideUserDeleteDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {user && (
                        <span>
                            Are you sure you want to delete{" "}
                            <b>
                                {user.firstName} {user.lastName}
                            </b>
                            ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={changeStatusDialog} style={{ width: "450px" }} header="Confirm" modal footer={changeStatusRoleDialogFooter} onHide={() => setChangeStatusDialog(false)}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {user && (
                        <span>
                            Are you sure you want to{" "}
                            <b>
                                {user.isAccountLocked === false ? "Deactivate" : "Activate"} {user.firstName} {user.lastName}
                            </b>
                            ?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={userDialog} style={{ width: "60vw", height: newUserDialog ? newUserDialogSize : updateUserDialogSize }} header={newUserDialog ? "New User" : "Update User"} modal className="p-fluid" footer={userDialogFooter} onHide={hideUserDialog}>
                <div className="p-fluid formgrid grid">
                    {fields.map((value) => {
                        if (value.type === "select") {
                            return (
                                <div className="field col-12" key={value.code}>
                                    <label htmlFor="roles">Roles *</label>
                                    <Controller name="roles" control={control} rules={{ required: true }} render={({ field: { onChange, value } }) => <MultiSelect value={value} options={roles} onChange={onChange} optionLabel="name" placeholder="Select Roles" display="chip" />} />
                                    {errors.roles?.type === "required" && <small className="p-error">Select atleast One Role.</small>}
                                </div>
                            );
                        } else if (value.type === "text" && (value.visibility === "all" || newUserDialog)) {
                            return (
                                <div className={`field col-${value.size}`} key={value.code}>
                                    <label htmlFor={value.code}>{value.name} *</label>
                                    <Controller name={value.code} control={control} rules={{ required: true }} render={({ field }) => <InputText keyfilter={value?.filter} id={value.code} placeholder={value.name} value={field.value} onChange={field.onChange} required {...field} />} />
                                    {errors[value.code]?.type === "required" && <small className="p-error">{value.name} is required.</small>}
                                </div>
                            );
                        } else if (value.type === "phone") {
                            return (
                                <div className="field col-12" key={value.code}>
                                    <label htmlFor="phoneNumber">Phone Number *</label>
                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        rules={{ required: true, minLength: 13 }}
                                        render={({ field }) => <PhoneInput {...field} id="phoneNumber" placeholder="Enter phone number" limitMaxLength={true} value={field.value} defaultCountry="ET" international countryCallingCodeEditable={false} onChange={field.onChange} />}
                                    />
                                    {errors.phoneNumber?.type === "required" && <small className="p-error">Phone Number is required.</small>}
                                    {errors.phoneNumber?.type === "minLength" && <small className="p-error">Phone Number should be 10 digits.</small>}
                                </div>
                            );
                        } else if (value.type === "password" && newUserDialog) {
                            return (
                                <div className="field col-6" key={value.code}>
                                    <label htmlFor="password">{value.name} *</label>
                                    <Controller
                                        name={value.code}
                                        control={control}
                                        rules={{ required: true, minLength: 8, validate: handlePasswordValidation }}
                                        render={({ field }) => <Password value={field.value} autoComplete="new-password" placeholder="Password" onChange={field.onChange} header={passwordHeader} footer={passwordFooter} toggleMask />}
                                    />
                                    {errors.password?.type === "required" && <small className="p-error">Password is required.</small>}
                                    {errors.password?.type === "minLength" && <small className="p-error">Password should be Minimum length 8.</small>}
                                    {errors.password?.type === "validate" && <small className="p-error">Week passwords are not allowed.</small>}
                                </div>
                            );
                        } else if (value.type === "confirmPassword" && newUserDialog) {
                            return (
                                <div className="field col-6" key={value.code}>
                                    <label htmlFor="password">{value.name} *</label>
                                    <Controller
                                        name={value.code}
                                        control={control}
                                        rules={{ required: true, validate: handlePasswordConfirm }}
                                        render={({ field }) => <Password value={field.value} autoComplete="new-password" placeholder="Password" onChange={field.onChange} header={passwordHeader} footer={passwordFooter} toggleMask />}
                                    />
                                    {errors.confirmPassword?.type === "required" && <small className="p-error">Confirm Password is required.</small>}
                                    {errors.confirmPassword?.type === "validate" && <small className="p-error">The password didn't match.</small>}
                                </div>
                            );
                        } else if (value.code === "email") {
                            return (
                                <div className={`field col-12`} key={value.code}>
                                    <label htmlFor={value.code}>{value.name} *</label>
                                    <Controller name={value.code} control={control} rules={{ required: true, validate: handleEmailValidation }} render={({ field }) => <InputText {...field} placeholder="someone@example.com" value={field.value} onChange={field.onChange} required />} />
                                    {errors[value.code]?.type === "required" && <small className="p-error">Email is required.</small>}
                                    {errors[value.code]?.type === "validate" && <small className="p-error">Email is not valid.</small>}
                                </div>
                            );
                        } else if (value.type === "checkbox") {
                            return (
                                <div className="field col-12" key={value.code}>
                                    <div className="field-checkbox">
                                        <Controller name={value.code} control={control} rules={{}} render={({ field }) => <Checkbox inputId="binary" checked={field.value} onChange={field.onChange} />} />
                                        <label htmlFor={value.code}>{value.name}</label>
                                    </div>
                                </div>
                            );
                        }
                        return <div key={value.code}></div>;
                    })}
                </div>
            </Dialog>

            <Dialog visible={resetDialong} style={{ width: "40vw", height: "50vh" }} header="Reset User Password" modal className="p-fluid" footer={resetDialogFooter} onHide={hideResetDialog}>
                <div>
                    <div className={`field col-12`} key="username">
                        <label htmlFor="username">Username *</label>
                        <Controller
                            name="username"
                            control={resetControl}
                            rules={{ required: true }}
                            // render={({ field }) => <InputText id="username" placeholder="username" value={field.value} onChange={field.onChange} required  {...field} />}
                            render={({ field }) => <Dropdown value={field.value} onChange={field.onChange} options={users} optionLabel="username" placeholder="Select a Username" filter />}
                        />
                        {resetErrors.username?.type === "required" && <small className="p-error">Username is required.</small>}
                    </div>
                    <div className="field col-12">
                        <label htmlFor="password">Password *</label>
                        <Controller
                            name="password"
                            control={resetControl}
                            rules={{ required: true, minLength: 8, validate: handlePasswordValidation }}
                            render={({ field }) => <Password value={field.value} autoComplete="new-password" placeholder="Password" onChange={field.onChange} header={passwordHeader} footer={passwordFooter} toggleMask />}
                        />
                        {resetErrors.password?.type === "required" && <small className="p-error">Password is required.</small>}
                        {resetErrors.password?.type === "minLength" && <small className="p-error">Password should be Minimum length 8.</small>}
                        {resetErrors.password?.type === "validate" && <small className="p-error">Week passwords are not allowed.</small>}
                    </div>
                    <div className="field col-12">
                        <label htmlFor="password">Confirm Password *</label>
                        <Controller
                            name="confirmPassword"
                            control={resetControl}
                            rules={{ required: true, validate: handleResetPasswordConfirm }}
                            render={({ field }) => <Password value={field.value} autoComplete="new-password" placeholder="Password" onChange={field.onChange} header={passwordHeader} footer={passwordFooter} toggleMask />}
                        />
                        {resetErrors.confirmPassword?.type === "required" && <small className="p-error">Confirm Password is required.</small>}
                        {resetErrors.confirmPassword?.type === "validate" && <small className="p-error">The password didn't match.</small>}
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
