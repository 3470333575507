import React, { useEffect } from 'react';
import { Route, withRouter, useLocation, Redirect } from 'react-router-dom';
import App from "./App";
import { Login } from "./components/user-management/login/Login";
import { Error } from "./pages/Error";
import { NotFound } from "./pages/NotFound";
import { Access } from "./pages/Access";
import { toggleAuth, useAuth } from './utilities/authContext';
import { ResetPassword } from './components/user-management/reset-password/ResetPassword';

const AppWrapper = (props) => {
	let location = useLocation();

	const auth = useAuth();

	useEffect(() => {
		window.scrollTo(0, 0)
		toggleAuth(auth)
	}, [location, auth]);

	switch (props.location.pathname) {
		case "/":
			if (auth?.username && props.location.pathname === "/") {
				return <Redirect to="/dashboard" />
			}
			if (auth?.username !== undefined && auth?.username !== null && auth?.username !== "") {
				return <Redirect to={props.location.pathname} />
			} else {
				return <Route path="/" component={Login} />
			}
		case "/error":
			return <Route path="/error" component={Error} />
		case "/notfound":
			return <Route path="/notfound" component={NotFound} />
		case "/access":
			return <Route path="/access" component={Access} />
		case "/reset-password":
			return <Route path="/reset-password" component={ResetPassword} />
		default:
			if (auth?.username) {
				return <App />; // need revisit
			}
			return <Route path="/" component={Login} />
	}

}

export default withRouter(AppWrapper);