import { useState } from "react";
import { Chart } from 'primereact/chart';

export const DoughnutChartDemo = () => {
    const [chartData] = useState({
        labels: ['Visa Request', 'Visa Extension'],
        datasets: [
            {
                data: [100, 50],
                backgroundColor: [
                    "#FCDD09",
                    "#0D4D99"
                ],
                hoverBackgroundColor: [
                    "#FCDD09",
                    "#0D4D99"
                ]
            }]
    });

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    return (
        <div className="card flex justify-content-center">
            <Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: 'relative', width: '40%' }} />
        </div>
    )
}