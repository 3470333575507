import React, { useState, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SnipperModal from "../../modal/SnipperModal";
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import MasterDataService from '../../../service/master-data/MasterDataService';
import { useAuth } from '../../../utilities/authContext';


const Nationality = () => {
    let emptyRegionsType = {
        id: 0,
        name: '',
        recordStatus: 0,
    };
    const regionBody = {
        id: 0,
        name: '',

    };
    const activateDeactivateBody = {
        status: 0,
    };

    let [loading, setLoading] = useState(false);
    const [regionTypes, setregionTypes] = useState(null);

    const [regionUpdateDialog, setRegionUpdateDialogDialog] = useState(false);
    const [regionDialog, setregionDialog] = useState(false);
    const [deleteCountryDialog, setDeleteregionDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);
    const [regontype, setregontype] = useState(emptyRegionsType);

    const [selectednationalites, setselectednationalites] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [performingAction, setPerformingAction] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        const masterDataService = new MasterDataService();
        setLoading(true)
        masterDataService.getregion("Region-View")
            .then(res => {
                setLoading(false)
                setregionTypes(res.data)
            }).catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching regions.", life: 3000 });
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    const openNew = () => {
        setregontype(emptyRegionsType);
        setSubmitted(false);
        setregionDialog(true);
    }
    const hideNationalityDialog = () => {
        setSubmitted(false);
        setregionDialog(false);
    }
    const hideDialog = () => {
        setSubmitted(false);
        setRegionUpdateDialogDialog(false);
    }
    const hideDeleteRegionDialog = () => {
        setDeleteregionDialog(false);
    }
    const hideActivateDeactivateRegionDialog = () => {
        setActivateDeactivateDialog(false);
    }
    const SubmitRegion = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _regiontypes = [...regionTypes];
        let _regiontype = { ...regontype };
        regionBody.name = _regiontype.name;
        const masterDataService = new MasterDataService();

        masterDataService.createRegion(regionBody, "", "Region-Add")
            .then((res) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Region Created', life: 3000 });
                refresh();
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Region already Registered ", life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setregionTypes(_regiontypes);
                setregionDialog(false);
                setregontype(emptyRegionsType);
            });
    }
    const UpdateRegion = () => {
        setPerformingAction(true);
        setSubmitted(true);

        if (regontype.name.trim()) {
            let _regiontypes = [...regionTypes];
            let _regiontype = { ...regontype };
            if (regontype.id) {
                const index = findIndexById(regontype.id);
                _regiontypes[index] = _regiontype;
                const masterDataService = new MasterDataService();
                regionBody.name = _regiontype.name;

                masterDataService.editRegion(regionBody, _regiontype.id, "", "Region-Edit")
                    .then((res) => {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Region Updated', life: 3000 });
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
                    }).finally(() => {
                        setPerformingAction(false);
                        setregionTypes(_regiontypes);
                        setRegionUpdateDialogDialog(false);
                        setregontype(emptyRegionsType);
                    })
            }
        }
    }
    const editCountry = (regontype) => {
        setregontype({ ...regontype });
        setRegionUpdateDialogDialog(true);
    }
    const confirmDeleteNationality = (regontype) => {
        setregontype(regontype);
        setDeleteregionDialog(true);
    }
    const confirmActivateDeactivateNationality = (regontype) => {
        setregontype(regontype);
        setActivateDeactivateDialog(true);
    }
    const deleteRegion = () => {
        setPerformingAction(true);
        let _regiontypes = regionTypes.filter(val => val.id !== regontype.id);
        const masterDataService = new MasterDataService();
        masterDataService.deleteRegion(regontype.id, "Region-Delete")
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Region Deleted', life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setregionTypes(_regiontypes);
                setDeleteregionDialog(false);
                setregontype(emptyRegionsType);
            });
    }
    const activateDeactivateRegionname = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _regiontypes = [...regionTypes];
        let _regiontype = { ...regontype };
        if (regontype.id) {
            const index = findIndexById(regontype.id);

            _regiontypes[index] = _regiontype;
            const masterDataService = new MasterDataService();

            if (regontype.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            }

            else
                activateDeactivateBody.status = 1; masterDataService.activateDeactivateRegion(regontype.id, activateDeactivateBody, "Region-ChangeStatus")
                    .then((res) => {
                        toast.current.show({
                            severity: "success", summary: "Successful",
                            detail: res.data.recordStatus === 2 ? "Region Activated" : "Region Deactivated", life: 3000
                        });
                        refresh();
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while changing status', life: 3000 });
                    }).finally(() => {
                        setPerformingAction(false);
                        setregionTypes(_regiontypes);
                        setActivateDeactivateDialog(false);
                        setregontype(emptyRegionsType);
                    });
        }

    }


    const refresh = () => {
        const masterDataService = new MasterDataService();
        masterDataService
            .getregion("Region-View")
            .then((res) => {
                setregionTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < regionTypes.length; i++) {
            if (regionTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _regiontype = { ...regontype };
        _regiontype[`${name}`] = val;
        setregontype(_regiontype);
    }
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("Region-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Region Name</span>
                {rowData.name}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("Region-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editCountry(rowData)} /> : <></>}
                {roles.privileges.includes("Region-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteNationality(rowData)} /> : <></>}
                {roles.privileges.includes("Region-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmActivateDeactivateNationality(rowData)} /> : <></>}
            </div>
        );
    }
    const status = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={status} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Region</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const updateCountryDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdateRegion} />}
        </>
    );
    const counrtyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideNationalityDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitRegion} />}
        </>
    );
    const deleteCountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeleteRegionDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteRegion} />}
        </>
    );
    const activateDeactivatecountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivateRegionDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateRegionname} />}
        </>
    );
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable ref={dt} filters={filters} value={regionTypes} selection={selectednationalites} onSelectionChange={(e) => setselectednationalites(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Regions" // header={header}
                        globalFilter={globalFilter} emptyMessage="No Region found." scrollable responsiveLayout="scroll">

                        <Column field="name" header="Region Name" sortable body={nameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable filter filterElement={statusFilterTemplate} style={{ width: "10rem" }} headerStyle={{ width: "10rem" }}></Column>

                        {(roles.privileges.includes("Region-Edit") === true ||
                            roles.privileges.includes("Region-Delete") === true ||
                            roles.privileges.includes("Region-ChangeStatus") === true) ? <Column header="Actions" body={actionBodyTemplate}></Column> : <></>}
                    </DataTable>
                    <Dialog visible={regionDialog} style={{ width: '450px' }} header="Create Region"
                        modal className="p-fluid" footer={counrtyDialogFooter} onHide={hideNationalityDialog}>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={regontype.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !regontype.name })} />
                            {submitted && !regontype.name && <small className="p-error"> Region name is required.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={regionUpdateDialog} style={{ width: '450px' }} header="Update Region"
                        modal className="p-fluid" footer={updateCountryDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Region Name</label>
                            <InputText id="name" value={regontype.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !regontype.name })} />
                            {submitted && !regontype.name && <small className="p-invalid"> Region name is required.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteCountryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCountryDialogFooter} onHide={hideDeleteRegionDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {regontype && <span>Are you sure you want to delete <b>{regontype.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: '450px' }} header="Confirm" modal footer={activateDeactivatecountryDialogFooter} onHide={hideActivateDeactivateRegionDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {regontype &&
                                <span>
                                    Are you sure you want to <b>{regontype.recordStatus === 1 ? "Activate" : "Deactivate"}</b> <b>{regontype.name}</b>?
                                </span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
export default Nationality;