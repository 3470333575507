import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom'
import AppWrapper from './AppWrapper';
import { AuthProvider } from './utilities/authContext';
import ReactIdleTimer from './utilities/ReactIdleTimer';
//import * as serviceWorker from './serviceWorker';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

ReactDOM.render(
	<Router>
		<AuthProvider>
			<AppWrapper></AppWrapper>
		</AuthProvider>
	</Router>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
