import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import MasterDataService from "../../../service/master-data/MasterDataService";
import SnipperModal from "../../modal/SnipperModal";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useAuth } from '../../../utilities/authContext';
const CardType = () => {
    let emptycardType = {
        id: null,
        faqTitle: '',
        faqDescription: '',
    };
    const cardtypeBody = {
        faqTitle: '',
        faqDescription: '',
    };
    let [loading, setLoading] = useState(false);
    const [cardTypes, setcardTypes] = useState(null);

    const [cardUpdateDialog, setCardTypeUpdateDialog] = useState(false);
    const [cardtypeDialog, setcardTypeDialog] = useState(false);
    const [deleteCardDialog, setDeleteCardDialog] = useState(false);
    const [performingAction, setPerformingAction] = useState(false);
    const [cardstype, setcardstype] = useState(emptycardType);
    const [selectedCardTypes, setselectedvisaCards] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'faqTitle': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
       
       
    });


    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        const visaService = new MasterDataService();
        setLoading(true)
        visaService.getAllFaq("FAQ-View")
            .then(res => {
                setcardTypes(res.data)
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Error while fetching FAQs", life: 4000 });
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        const _filters = { ...filters };
        _filters['global'].value = value;
        console.log("filter", _filters['global'].value);
        setFilters(_filters);
        setGlobalFilterValue(value)
    }
    const openNew = () => {
        setcardstype(emptycardType);
        setSubmitted(false);
        setcardTypeDialog(true);
    }

    const hideCardDialog = () => {
        setSubmitted(false);
        setcardTypeDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCardTypeUpdateDialog(false);
    }

    const hideDeleteCardtypeDialog = () => {
        setDeleteCardDialog(false);
    }
    const createFaq = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _cardtypes = [...cardTypes];
        let _cardtype = { ...cardstype };

        cardtypeBody.faqTitle = _cardtype.faqTitle;
        cardtypeBody.faqDescription = _cardtype.faqDescription;
        const visaService = new MasterDataService();

        visaService.createFaq(cardtypeBody, "FAQ-View")
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'FAQ Created', life: 3000 });
                refresh();
            })
            .catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "FAQ already Registered ", life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setcardTypes(_cardtypes);
                setcardTypeDialog(false);
                setcardstype(emptycardType);
            })
    }

    const updateFaq = () => {
        setPerformingAction(true);
        setSubmitted(true);

        if (cardstype.faqTitle.trim()) {
            let _cardtypes = [...cardTypes];
            let _cardtype = { ...cardstype };
            if (cardstype.id) {
                const index = findIndexById(cardstype.id);

                _cardtypes[index] = _cardtype;
                const visaService = new MasterDataService();
                cardtypeBody.faqTitle = _cardtype.faqTitle;
                cardtypeBody.faqDescription = _cardtype.faqDescription;

                visaService.updateFaq(cardtypeBody, _cardtype.id, "FAQ-View")
                    .then(() => {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'FAQ Updated Successfully', life: 3000 });
                    })
                    .catch(() => {
                        toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Error While Updating FAQ", life: 3000 });
                    }).finally(() => {
                        setPerformingAction(false);
                        setcardTypes(_cardtypes);
                        setCardTypeUpdateDialog(false);
                        setcardstype(emptycardType);
                    })
            }
            else {

            }
        }
    }
    const editFaq = (cardstype) => {
        setcardstype({ ...cardstype });
        setCardTypeUpdateDialog(true);
    }

    const confirmDeleteCountry = (cardstype) => {
        setcardstype(cardstype);
        setDeleteCardDialog(true);
    }
    const deleteFaq = () => {
        setPerformingAction(true)
        let _cardtypes = cardTypes.filter(val => val.id !== cardstype.id);

        const visaService = new MasterDataService();
        visaService.deleteFaq(cardstype.id, "FAQ-View")
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'FAQ Deleted Successfully!', life: 3000 });
            }).catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Error while deleting FAQ", life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setcardTypes(_cardtypes);
                setDeleteCardDialog(false);
                setcardstype(emptycardType);
            });

    }
    const refresh = () => {
        const visaService = new MasterDataService();
        visaService.getAllFaq("FAQ-View")
            .then((res) => {
                setcardTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < cardTypes.length; i++) {
            if (cardTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _cardtype = { ...cardstype };
        _cardtype[`${name}`] = val;
        setcardstype(_cardtype);
    }
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("CardType-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                <b>{rowData.faqTitle}</b>
            </>
        );
    }
    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description </span>
                {rowData.faqDescription}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editFaq(rowData)} />

                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteCountry(rowData)} />

            </div>
        );
    }

  

  

   
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue}  onChange={onGlobalFilterChange} placeholder="Search FAQs..." />
            </span>
        )
    }

   const updateCardDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={updateFaq} disabled={cardstype.faqDescription && cardstype.faqTitle ? false : true} />}
        </>
    );

    const counrtyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideCardDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={createFaq} disabled={cardstype.faqDescription && cardstype.faqTitle ? false : true} />}
        </>
    );

    const deleteCardtypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={() => setDeleteCardDialog(false)} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteFaq} />}
        </>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput} ></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}

                    <DataTable 
                      ref={dt} 
                      value={cardTypes} 
                      selection={selectedCardTypes} 
                      onSelectionChange={(e) => setselectedvisaCards(e.value)}
                      dataKey="id" 
                      paginator 
                      filters={filters} 
                      rows={10} 
                      rowsPerPageOptions={[5, 10, 25]} 
                      filterDisplay="menu"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} FAQ"
                      globalFilter={globalFilterValue} 
                      emptyMessage="No FAQs found." 
                      scrollable 
                      responsiveLayout="scroll">
                       

                        <Column field="faqTitle" header="Title"  body={nameBodyTemplate}
                            style={{ minWidth: "40rem" }} headerStyle={{ minWidth: "40rem" }} ></Column>
                        <Column field="description" header="Description"  body={descriptionBodyTemplate}
                            style={{ minWidth: "60rem" }} headerStyle={{ minWidth: "60rem" }}></Column>
                       
                        <Column header="Actions" body={actionBodyTemplate} style={{ minWidth: "8rem" }} headerStyle={{ minWidth: "8rem" }} ></Column>
                    </DataTable>
                    <Dialog visible={cardtypeDialog} style={{ width: "30vw", height: "45vh" }} header="Create FAQ"
                        modal className="p-fluid" footer={counrtyDialogFooter} onHide={hideCardDialog}>

                        <div className="field">
                            <label htmlFor="name">FAQ Title</label>
                            <InputText id="name" value={cardstype.faqTitle} onChange={(e) => onInputChange(e, 'faqTitle')} autoFocus
                            />

                        </div>
                        <div className="field">
                            <label htmlFor="description">FAQ description</label>
                            <InputTextarea id="description" value={cardstype.faqDescription} onChange={(e) => onInputChange(e, 'faqDescription')} required
                                rows={5} cols={20} />

                        </div>
                    </Dialog>

                    <Dialog visible={cardUpdateDialog} style={{ width: "30vw", height: "45vh" }} header="Update FAQ"
                        modal className="p-fluid" footer={updateCardDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="name">FAQ Title</label>
                            <InputText id="name" value={cardstype.faqTitle} onChange={(e) => onInputChange(e, 'faqTitle')}
                                autoFocus />

                        </div>
                        <div className="field">
                            <label htmlFor="dadescriptionys">FAQ description</label>
                            <InputTextarea id="description" value={cardstype.faqDescription} onChange={(e) => onInputChange(e, 'faqDescription')}
                                rows={5} cols={20} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCardDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCardtypeDialogFooter} onHide={hideDeleteCardtypeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {cardstype && <span>Are you sure you want to <b>Delete</b>?</span>}
                        </div>
                    </Dialog>



                </div>
            </div>
        </div>
    );
}

export default CardType;