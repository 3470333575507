import HttpService from "../shared/HttpService";

export default class extensionService {
    getAll = (claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/FilterByStatus?visaStatus=0";
        return HttpService.getService(relativePath, "", claim);
    };
    getNewExtension = (claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/FilterByStatus?visaStatus=6";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getvisaAttachments = (id) => {
        let relativePath = "visaservice/api/v1/VisaRequestsBackOffice/GetVisaExtensionReport?requestId=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };
    getRecieptAttachments = (id) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/GetVisaExtensionReceipt?requestId=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };
    getApprovedExtension = (claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/FilterByStatus?visaStatus=3";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getRepliedExtension = (claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/FilterByStatus?visaStatus=7";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getIncompleteExtension = (claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/FilterByStatus?visaStatus=2";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getDeniedExtension = (claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/FilterByStatus?visaStatus=4";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getUnpaidExtension = (claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/FilterByStatus?visaStatus=5";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };

    getDetail = (id) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/GetById?id=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };

    approveVisaExtension = (Id, approval, payload) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/Approve?requestId=" + Id + "&remark=" + approval;
        // return HttpService.updateExtensionStatus(Id, approval, relativePath, "", "", payload, "VisaExtension-Approve");
        return HttpService.putService(Id, relativePath, "", "", "", "VisaExtension-Approve");
    };
    denyVisaExtension = (Id, approval, payload) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/Deny?requestId=" + Id + "&remark=" + approval;
        return HttpService.putService(Id, relativePath, "", "", "", "VisaExtension-Deny");
        // return HttpService.updateExtensionStatus(Id, approval, relativePath, "", "", payload, "VisaExtension-Deny");
    };
    incompleteVisaExtension = (Id, approval, reason, payload) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/InCompelete?requestId=" + Id + "&remark=" + approval + "&visaInCompleteReason=" + reason;
        return HttpService.putService(Id, relativePath, "", "", "", "VisaApplication-InComplete");
        // return HttpService.updateExtensionStatusIncomplete(Id, approval, reason, relativePath, "", "", payload, "VisaApplication-InComplete");
    };
    searchVisaBySEDate = (startDate, endDate, status, claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/Search?VisaStatus=" + status + "&StartDate=" + startDate + "&EndDate=" + endDate;
        return HttpService.getService(relativePath, "", claim);
    };
    searchAllVisaBySEDate = (startDate, endDate, claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/Search?" + "&StartDate=" + startDate + "&EndDate=" + endDate;
        return HttpService.getService(relativePath, "", claim);
    };
    searchVisa = (startDate, endDate, passport, traveller, reference, email, orderCode, status, claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/Search?VisaStatus=" + status + "&StartDate=" + startDate + "&EndDate=" + endDate + "&PassportNumber=" + passport + "&TravelerName=" + traveller + "&ReferenceNumber=" + reference + "&Email=" + email + "&OrderCode=" + orderCode;

        return HttpService.getService(relativePath, "", claim);
    };
    // searchUnpaidVisa = (value, path, status, clientToken, idToken) => {
    //     let relativePath = "visaservice/api/v1/VisaExtensionRequest/Search?VisaStatus=" + `${status}` + "&" + `${path}=` + value;
    //     return HttpService.getService(relativePath, "", "");
    // };
    searchAllVisa = (value, path, claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/Search?" + `${path}=` + value;
        return HttpService.getService(relativePath, "", claim);
    };
    searchHistory = (id) => {
        let relativePath = "visaservice/api/v1/VisaExtensionRequest/GetVisaExtensionStatusHistory?requestId=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };
}
