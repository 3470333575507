import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Controller, useForm } from "react-hook-form";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { useAuth } from "../../../utilities/authContext";
import HttpService from "../../../service/shared/HttpService";

const ChangePassword = () => {
    const toast = useRef(null);
    const auth = useAuth();
    const [performingActions, setPerformingActions] = useState(false);

    const forgotPasswordObj = {
        username: auth?.username, // change this with the username 
        oldPassword: "",
        newPassword: "",
        repeatnewpassword: "",
    };

    const [errorTheSame, setErrorTheSame] = useState();

    const { control, getValues, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: { ...forgotPasswordObj }
    })

    const validateConfirmPassword = () => {
        return getValues("newPassword") === getValues("repeatnewpassword")
    }

    const handlePasswordValidation = password => {
        return /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./.test(
            password
        )
    }

    const submitChangePassword = (data) => {
        const forgotPasswordObj = data

        if (forgotPasswordObj.oldPassword === forgotPasswordObj.newPassword) {
            setErrorTheSame("Old password and New cannot be the same")
            return
        } else {
            setErrorTheSame("");
        }

        setPerformingActions(true);

        HttpService.postService({
            username: forgotPasswordObj.username,
            oldPassword: forgotPasswordObj.oldPassword,
            newPassword: forgotPasswordObj.newPassword
        }, '/identity-server/api/v1/Password/ChangePassword', "", "", "", "Users-ChangePassword")
            .then((resp) => {
                reset();
                toast.current.show({ severity: 'success', summary: 'Successfull', detail: 'Successfully changed password.', life: 4000 });
            }).catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Old password didn't match", life: 3000 });
            }).finally(() => {
                setPerformingActions(false);
            });

    }

    const passwordHeader = <h6>Pick a strong password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div>
            <Toast ref={toast} />
            <div className="grid">
                <div className="col-3"></div>
                <div className="col-12 lg:col-4 card p-5">
                    <div className="grid justify-center" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
                        <h2 className="col-12">Change password</h2>

                        <div className="p-fluid">
                            <div className="field col-12">
                                <label htmlFor="oldPassword">Old Password</label>
                                <Controller
                                    name="oldPassword"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) =>
                                        <Password value={field.value} id="oldPassword" placeholder="********" onChange={field.onChange} header={passwordHeader} footer={passwordFooter} toggleMask />
                                    }
                                />
                                {errors.oldPassword?.type === 'required' && <small className="p-error">Old password is required.</small>}
                            </div>
                            <div className="field col-12">
                                <label htmlFor="newPassword">New Password</label>
                                <Controller
                                    name="newPassword"
                                    control={control}
                                    rules={{ required: true, minLength: 8, validate: handlePasswordValidation }}
                                    render={({ field }) =>
                                        <Password value={field.value} id="newPassword" placeholder="********" onChange={field.onChange} header={passwordHeader} footer={passwordFooter} toggleMask />
                                    }
                                />
                                {errors.newPassword?.type === 'required' && <small className="p-error">New password is required.</small>}
                                {errors.newPassword?.type === 'validate' && <small className="p-error">Weak password is not allowed.</small>}
                                {errors.newPassword?.type === 'minLength' && <small className="p-error">Password should be minimum of length 8.</small>}
                                {errorTheSame && <small className="p-error">{errorTheSame}</small>}
                            </div>
                            <div className="field col-12">
                                <label htmlFor="repeatnewpassword">Confirm Password</label>
                                <Controller
                                    name="repeatnewpassword"
                                    control={control}
                                    rules={{ required: true, validate: validateConfirmPassword }}
                                    render={({ field }) =>
                                        <Password value={field.value} id="repeatnewpassword" placeholder="********" onChange={field.onChange} header={passwordHeader} footer={passwordFooter} toggleMask />
                                    }
                                />
                                {errors.repeatnewpassword?.type === 'required' && <small className="p-error">Confirm password is required.</small>}
                                {errors.repeatnewpassword?.type === 'validate' && <small className="p-error">Password didn't match.</small>}
                            </div>
                        </div>

                        <div className="col-12 text-center">
                            {performingActions ? <Button label="Change Password" icon="pi pi-spin pi-spinner" disabled /> :
                                <Button label="Change Password" onClick={handleSubmit(submitChangePassword)} icon="pi pi-check" />}
                        </div>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
        </div>
    );
};

export default ChangePassword;
