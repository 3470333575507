import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Moment from "moment";
import VisaFeeService from "../../../service/master-data/visaFeeService";
import MasterDataService from "../../../service/master-data/MasterDataService";
import VisaService from '../../../service/VisaValidity/VisaService';
import SnipperModal from "../../modal/SnipperModal";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useAuth } from "../../../utilities/authContext";


const VisaFee = () => {
    let emptyvisaFee = {
        id: 0,
        visaTypeId: 0,
        visaType: [],
        servicePrice: 0,
        visaTypeValidityId: 0,
        visaValidity: [],
        visaApplicationFee: 0,
        recordStatus: 0,
        serviceFee: 0,
        effectiveDateFrom: 0,
        effectiveDateTo: 0,
        visaTypeValidities: [],
        visamode: 0

        //visaTypeValidityId:0

    };
    const entryType =
    {
        1: 'SinglEntry',
        2: 'MultipleEntry',
    }
    const activateDeactivateBody = {
        status: 0,
    };

    const feebody = {
        id: 0,
        effectiveDateTo: '',

    };
    let [loading, setLoading] = useState(false);
    const [visaFees, setvisaFees] = useState(null);
    const [visatypes, setvisaVisaTypes] = useState(null);
    const [nationalities, setNationalities] = useState(null);
    const [visaValidity, setvisaValidities] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedVisatype, SetSelectedVisatype] = useState({ id: 0, name: "" });
    const [selectedvisavalidity, setselectedvisavalidity] = useState({ id: 0, name: "" })
    const [selectedNationality, SetSelectedNationality] = useState({ id: 0, name: "" });
    const [accommodationUpdateDialog, setAccommodationUpdateDialogDialog] = useState(false);
    const [visaFeeDialog, setvisaFeeDialog] = useState(false);
    const [deletevisaFeeDialog, setDeletevisaFeeDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);
    const [visaFee, setvisaFee] = useState(emptyvisaFee);
    const [selectedAccommodations, setSelectedAccommodations] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'visaTypeValidities?.visaType?.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'visaTypeValidities?.visaType?.suffix': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'visaTypeValidities?.visaValidity?.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'visaApplicationFee': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'serviceFee': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const toast = useRef(null);
    const dt = useRef(null)

    const roles = useAuth()

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [date, setDate] = useState(new Date());


    useEffect(() => {
        const visaFeeService = new VisaFeeService();
        setLoading(true)
        visaFeeService
            .getAll("VisaFee-View")
            .then((res) => {
                setLoading(false)
                setvisaFees(res.data.payload);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching visa fees.", life: 3000 });
            })
            .finally(() => {
                setLoading(false);
            });

        const visaService = new VisaService();
        visaService
            .getVisaType("VisaFee-View")
            .then((res) => {
                setvisaVisaTypes(res.data);
            })


        visaService.GetAllVisaTypeValidities("VisaFee-View")
            .then((res) => {
                setvisaValidities(res.data);
            })

    }, []);

    const refreashVisaFee = () => {
        const visaFeeService = new VisaFeeService();
        setLoading(true)
        visaFeeService
            .getAll("VisaFee-View")
            .then((res) => {
                setLoading(false)
                setvisaFees(res.data.payload);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching visa fees.", life: 3000 });
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const openNew = () => {
        setvisaFee(emptyvisaFee);
        setSubmitted(false);
        setvisaFeeDialog(true);
    };

    const hidevisaFeeDialog = () => {
        setSubmitted(false);
        setvisaFeeDialog(false);
    };
    const hideDialog = () => {
        setSubmitted(false);
        setAccommodationUpdateDialogDialog(false);
    };

    const hideDeletevisaFeeDialog = () => {
        setDeletevisaFeeDialog(false);
    };
    const hideActivateDeactivatevisaFeeDialog = () => {
        setActivateDeactivateDialog(false);
    };

    const SubmitVisaFee = () => {
        setSubmitted(true);

        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };

        _visaFee.effectiveDateFrom = String(_visaFee?.effectiveDateFrom).split("T")[0];
        _visaFee.effectiveDateTo = String(_visaFee?.effectiveDateTo).split("T")[0];

        const visaFeeService = new VisaFeeService();
        setLoading(true)
        visaFeeService.create(_visaFee, "VisaFee-Add")
            .then((res) => {
                setLoading(false)
                toast.current.show({ severity: "success", summary: "Successful", detail: "Visa Fee Created", life: 3000 });
                refreashVisaFee()
            })
            .catch((err) => {
                setLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Visa Fee already Registered ", life: 3000 });
                refreashVisaFee()
            }).finally(() => {
                setvisaFee(emptyvisaFee);
                setStartDate(null)
                setEndDate(null)
                setselectedvisavalidity({id:0,name:""})
            });
        setvisaFees(_visaFees);
        setvisaFeeDialog(false);
        setvisaFee(emptyvisaFee);
    }
    const UpdatevisaFee = () => {
        setSubmitted(true);
        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };
        _visaFee.effectiveDateFrom = String(_visaFee?.effectiveDateFrom).split("T")[0];
        _visaFee.effectiveDateTo = String(_visaFee?.effectiveDateTo).split("T")[0];
        if (visaFee.id) {
            const index = findIndexById(visaFee.id);

            _visaFees[index] = _visaFee;
            // console.log("_visaFee", _visaFee)

            const visaFeeService = new VisaFeeService();
            setLoading(true);

            visaFeeService.edit(_visaFee, "VisaFee-Edit")
                .then((res) => {

                    setLoading(false);
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Visa Fee Effective date Updated", life: 3000 });
                    refreshVisaFee();
                    // reset();
                    // history.push('/roles')
                })
                .catch((err) => {
                    setLoading(false);
                    toast.current.show({ severity: "error", summary: "Error Occured", detail: "Effective to  must be greater than Effective from ", life: 3000 });
                });
        } else {
            toast.current.show({ severity: "error", summary: "Error Occured", detail: "Id can not be null", life: 3000 });
        }

        setvisaFees(_visaFees);
        setAccommodationUpdateDialogDialog(false);
        setvisaFee(emptyvisaFee);
    };
    const editAccommodation = (visaFee) => {
        // console.log(visaFee.effectiveDateTo)
        setvisaFee({ ...visaFee });
        setEndDate(visaFee.effectiveDateTo)
        setAccommodationUpdateDialogDialog(true);
    };

    const confirmDeleteVisaFee = (visaFee) => {
        setvisaFee(visaFee);
        setDeletevisaFeeDialog(true);
    };

    const confirmactivateDeactivateVisaFee = (visaFee) => {
        setvisaFee(visaFee);
        setActivateDeactivateDialog(true);
    };

    const deleteVisaFee = () => {
        let _visaFees = visaFees.filter((val) => val.id !== visaFee.id);

        const visaFeeService = new VisaFeeService();
        setLoading(true)
        visaFeeService.delete(visaFee.id, "VisaFee-Delete").then((res) => {
            setLoading(false)
            setvisaFees(_visaFees);
            setDeletevisaFeeDialog(false);
            setvisaFee(emptyvisaFee);
            toast.current.show({ severity: "success", summary: "Successful", detail: "Visa fees Deleted", life: 3000 });
        }).catch((err) => {
            setLoading(false)
            toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
            ;
        });
    };
    const refreshVisaFee = () => {
        const visaFeeService = new VisaFeeService();
        visaFeeService
            .getAll("VisaFee-View")
            .then((res) => {
                setvisaFees(res.data.payload);
            })
            .catch((error) => { });
    };
    const activateDeactivateVisaFee = () => {
        setSubmitted(true);

        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };
        if (visaFee.id) {
            const index = findIndexById(visaFee.id);

            _visaFees[index] = _visaFee;
            const visaFeeService = new VisaFeeService();
            activateDeactivateBody.id = visaFee.id;
            if (visaFee.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            } else activateDeactivateBody.status = 1;
            setLoading(true)
            visaFeeService
                .activateDeactivate(activateDeactivateBody, "VisaFee-ChangeStatus")
                .then((res) => {
                    setLoading(false)
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Visa Fee Activated" : "Visa Fee Deactivated", life: 3000 });
                    refreshVisaFee();
                })
                .catch((err) => {
                    setLoading(false)
                    toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
                    ;
                });
        }
        setvisaFees(_visaFees);
        setActivateDeactivateDialog(false);
        setvisaFee(emptyvisaFee);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < visaFees.length; i++) {
            if (visaFees[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };
    const onInputChange = (value, name) => {
        const val = value;
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = val;
        setvisaFee(_visaFee);
    };
    const onVisaValididtyChange = (e, name) => {
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = e.value;
        setvisaFee(_visaFee);
        let selectedVisaType = visaValidity.find((c) => c.id === e.value);
        setselectedvisavalidity(selectedVisaType);

    };
    var newDate = new Date();
    var today = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());

    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("VisaFee-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    };
    const datefromBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {rowData?.effectiveDateFrom.split("T")[0]}
            </>
        );
    };
    const dateToBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {rowData?.effectiveDateTo.split("T")[0]}
            </>
        );
    };
    const serviceFeeamouBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {rowData?.serviceFee}
            </>
        );
    };

    const visaApplicationFeeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Visa Application Fee</span>

                {rowData?.visaApplicationFee}
            </>
        );
    };

    const visaValidityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">End Date</span>
                {rowData.visaTypeValidities?.visaValidity?.name}
            </>
        );
    };

    const suffixBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Visa  Type</span>
                {rowData.visaTypeValidities?.visaType?.suffix}
            </>
        );
    };
    const visaTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Visa  Type</span>
                {rowData.visaTypeValidities?.visaType?.name}
            </>
        );
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("VisaFee-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editAccommodation(rowData)} /> : <></>}
                {roles.privileges.includes("VisaFee-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteVisaFee(rowData)} /> : <></>}
                {roles.privileges.includes("VisaFee-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmactivateDeactivateVisaFee(rowData)} /> : <></>}
            </div>
        );
    };
    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }


    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }
    const updatevisaFeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdatevisaFee} />
        </>
    );

    const visaFeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hidevisaFeeDialog} />
            <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitVisaFee} />
        </>
    );

    const deletevisaFeeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeletevisaFeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteVisaFee} />
        </>
    );

    const activateDeactivatevisaFeeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivatevisaFeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateVisaFee} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable
                        ref={dt}
                        value={visaFees}
                        selection={selectedAccommodations}
                        onSelectionChange={(e) => setSelectedAccommodations(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        filters={filters}
                        scrollable
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} visa fees"
                        globalFilter={globalFilter}
                        emptyMessage="No visa fees found."
                        responsiveLayout="scroll"
                    >
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="visaTypeValidities.visaType.name" header="Visa Type" sortable body={visaTypeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="visaTypeValidities.visaType.suffix" header="Visa Code" sortable body={suffixBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="visaTypeValidities.visaValidity.name" header="Visa Validity" sortable body={visaValidityBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="visaApplicationFee" header="Application Fee" sortable body={visaApplicationFeeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="serviceFee" header="Service Fee" sortable body={serviceFeeamouBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="effectiveDateFrom" header="Effective Date From" sortable body={datefromBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="effectiveDateTo" header="Effective Date To" sortable body={dateToBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>

                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>

                        {(roles.privileges.includes("VisaFee-Edit") === true ||
                            roles.privileges.includes("VisaFee-Delete") === true ||
                            roles.privileges.includes("VisaFee-ChangeStatus") === true) ? <Column body={actionBodyTemplate} header="Action" style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column> : <></>}
                    </DataTable>

                    <Dialog visible={visaFeeDialog} style={{ width: "450px" }} header="Create Visa Fee" modal className="p-fluid" footer={visaFeeDialogFooter} onHide={hidevisaFeeDialog}>
                        <div className="field">
                            <label htmlFor="name">Application Fee in USD</label>
                            <InputNumber id="visaApplicationFee" value={visaFee.visaApplicationFee} onValueChange={(e) => onInputChange(e.value, "visaApplicationFee")} required autoFocus className={classNames({ "p-invalid": submitted && !visaFee.feeAmount })} />
                        </div>
                        <div className="field">
                            <label htmlFor="name">Service Price in USD</label>
                            <InputNumber id="servicePrice" value={visaFee.servicePrice} onValueChange={(e) => onInputChange(e.value, "servicePrice")} required className={classNames({ "p-invalid": submitted && !visaFee.servicePrice })} />
                            {submitted && !visaFee.servicePrice && <small className="p-error">No of Service Price is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Effective StartDate</label>
                            <Calendar

                                value={startDate}
                                minDate={today}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    setStartDate(e.value);
                                    onInputChange(Moment(e.value).format(), "effectiveDateFrom");
                                }}
                                showIcon
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="name">Effective EndDate</label>
                            <Calendar
                                value={endDate}
                                minDate={startDate}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    setEndDate(e.value);
                                    onInputChange(Moment(e.value).format(), "effectiveDateTo");
                                }}
                                showIcon
                            />
                        </div>
                        <div className="field">
                            <Dropdown optionValue="id" optionLabel="name"
                                value={selectedvisavalidity.id} optionName={visaFee.visaTypeValidityId}
                                options={visaValidity} onChange={(e) => onVisaValididtyChange(e, "visaTypeValidityId")}
                                placeholder="Select Visa Validity" />
                        </div>

                    </Dialog>
                    <Dialog visible={accommodationUpdateDialog} style={{ width: "450px" }} header="Update  Fee Date" modal className="p-fluid" footer={updatevisaFeeDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Effective EndDate</label>
                            <Calendar
                                value={new Date(visaFee.effectiveDateTo)}
                                minDate={new Date(visaFee.effectiveDateFrom)}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    setEndDate(e.value);
                                    onInputChange(Moment(e.value).format(), "effectiveDateTo");
                                }}
                                showIcon
                            />
                        </div>
                    </Dialog>
                    <Dialog visible={deletevisaFeeDialog} style={{ width: "450px" }} header="Confirm" modal footer={deletevisaFeeDialogFooter} onHide={hideDeletevisaFeeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {visaFee && (
                                <span>
                                    Are you sure you want to delete visa fee <b>{visaFee.feeAmount}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: "450px" }} header="Confirm" modal footer={activateDeactivatevisaFeeDialogFooter} onHide={hideActivateDeactivatevisaFeeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {visaFee && (
                                <span>
                                    {/* Are you sure you want to activate Fee amount <b>{visaFee.feeAmount}</b>? */}
                                    Are you sure you want to <b>{visaFee?.recordStatus === 1 ? "Activate" : "Deactivate"}</b> <b>Visa Fee</b>?

                                </span>



                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default VisaFee;
