import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ScrollPanel } from "primereact/scrollpanel";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useHistory, useLocation } from "react-router-dom";
import VisaService from "../../service/eVisaService/eVisaService";
import "./VisaDetail.css";
import SnipperModal from "../modal/SnipperModal";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { Document } from "react-pdf";
import { Page } from "@react-pdf/renderer";
import byteToFile from "byte-to-file";
import { useAuth } from "../../utilities/authContext";
import MasterDataService from "../../service/master-data/MasterDataService";
import CompanyApplicationService from "../../service/Company/CompanyApplicationService";

const VisaDetail = () => {
    const [displayResponsive, setDisplayResponsive] = useState(false);
    const [remark, setRemark] = useState("");
    const [approvalStatusValue, setApprovalStatusValue] = useState(0);
    const [detailData, setDetailData] = useState([]);
    const [historyDialog, setHistoryDialog] = useState(false);
    const [AirportHisDialog, setAirportHisDialog] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [AirportHisData, setAirportHisData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const toast = useRef(null);
    const [performingAction, setPerformingAction] = useState(false);
    const [performingActionreciept, setperformingActionreciept] = useState(false);
    const [incompleteReasons, setIncompleteReasons] = useState();
    const roles = useAuth();

    if (location.state !== null && location.state !== undefined) {
        localStorage.removeItem("selectedVisaId");
        //var visid= localStorage.setItem("selectedVisaId", location.state.selected.id);
        // localStorage.setItem("selectedcompanyReferenceNumber", location.state.selected.companyReferenceNumber);
    }
    useEffect(() => {
        const visaService = new VisaService();
        visaService
            .getDetail(localStorage.selectedVisaId)
            .then((res) => {
                setDetailData(res.data);
                setAirportHisData(res.data?.entryStatusHistories);
            })
            .catch((error) => {});
    }, []);
    const companyDeatil = (ref) => {
        localStorage.setItem("companyRef", ref);
    };
    const ApprovalHistory = () => {
        setPerformingAction(true);
        const visaService = new VisaService();
        visaService
            .searchHistory(localStorage.selectedVisaId)
            .then((res) => {
                setHistoryData(res.data);
                setPerformingAction(false);
            })
            .catch((error) => {})
            .finally(() => {
                setPerformingAction(false);
            });
    };

    const fetchIncompleteReasons = () => {
        const masterdataservice = new MasterDataService();
        debugger;
        masterdataservice.getIncompeleteReasons(1, 2, "VisaApplication-InComplete").then((res) => {
            setIncompleteReasons(res.data);
            setIsLoading(false);
        });
    };
    const refreshVisa = () => {
        setIsLoading(true);
        const visaService = new VisaService();
        visaService
            .getDetail(localStorage.selectedVisaId)
            .then((res) => {
                setDetailData(res.data);
                setAirportHisData(res.data?.entryStatusHistories);
            })
            .catch((error) => {})
            .finally(() => {
                setIsLoading(false);
            });
    };
    function downloadRecieptPDF() {
        setperformingActionreciept(true);
        const visaService = new VisaService();
        visaService
            .getRecieptAttachments(localStorage.selectedVisaId)
            .then((res) => {
                setperformingActionreciept(false);
                const linkSource = "data:application/pdf;base64," + res.data.payload?.visaDocument;
                const downloadLink = document.createElement("a");
                const fileName = "Visa-Reciept_" + detailData.passportNumber + "_" + detailData.referenceNumber + ".pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            })
            .catch((error) => {})
            .finally(() => {
                setperformingActionreciept(false);
            });
    }
    function downloadPDF() {
        setPerformingAction(true);
        const visaService = new VisaService();
        visaService
            .getvisaAttachments(localStorage.selectedVisaId)
            .then((res) => {
                setPerformingAction(false);
                const linkSource = "data:application/pdf;base64," + res.data.payload?.visaDocument;
                const downloadLink = document.createElement("a");
                const fileName = "Visa_" + detailData.passportNumber + "_" + detailData.referenceNumber + ".pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            })
            .catch((error) => {})
            .finally(() => {
                setPerformingAction(false);
            });
    }
    const formatString = (string) => {
        const arr = string?.split(" ");

        for (var i = 0; i < arr?.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const formattedString = arr?.join(" ");
        return formattedString;
    };

    const formatDate = (date) => {
        return new Date(date).toDateString();
    };

    const dialogFuncMap = {
        displayResponsive: setDisplayResponsive,
    };
    const onClick = (name, value) => {
        setApprovalStatusValue(value);
        dialogFuncMap[`${name}`](true);
        fetchIncompleteReasons();
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const showHistory = () => {
        setHistoryDialog(true);
        ApprovalHistory();
    };
    const showAirportHistory = () => {
        setAirportHisDialog(true);
    };
    const hideHistoryDialog = () => {
        setHistoryDialog(false);
    };
    const hideAirportHistoryDialog = () => {
        setAirportHisDialog(false);
    };
    const inCompleteApplication = (body) => {
        const visaService = new VisaService();
        const activateDeactivateBody = {
            approvalStatus: approvalStatusValue,
            remark: remark,
            id: Number.parseInt(localStorage.selectedCompanyId),
        };
        setIsLoading(true);

        visaService
            .incompeleteVisaApplication(body.id, "clienttoken", "accesstoken", body)
            .then((res) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "success", summary: "This visa Marked As Incomplete Successfully", detail: res.message, life: 3000 });
                refreshVisa();
            })
            .catch((err) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };
    const validateApplication = (body) => {
        const visaService = new VisaService();
        visaService
            .ValidateVisaApplication(body.id, "clienttoken", "accesstoken", body)
            .then((res) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "success", summary: "This visa is Request Validated Successfully and Sent For Approval", detail: res.message, life: 3000 });
                refreshVisa();
            })
            .catch((err) => {
                setRemark("");
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };
    const approveApplication = (body) => {
        const visaService = new VisaService();
        visaService
            .approveVisaApplication(body.id, "clienttoken", "accesstoken", body)
            .then((res) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "success", summary: "This visa is Request Approved Successfully", detail: res.message, life: 3000 });
                refreshVisa();
            })
            .catch((err) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };
    const denyApplication = (body) => {
        const visaService = new VisaService();
        visaService
            .denyVisaApplication(body.id, "clienttoken", "accesstoken", body)
            .then((res) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "success", summary: "This visa is Request Denied Successfully", detail: res.message, life: 3000 });
                refreshVisa();
            })
            .catch((err) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };
    const undoApproval = (body) => {
        const visaService = new VisaService();
        console.log("undo id", body.id);
        console.log("remark", body.remark);
        visaService
            .undoApproval(body, "clienttoken", "accesstoken", body.remark)
            .then((res) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "success", summary: "This visa is Undid Successfully", detail: res.message, life: 3000 });
                refreshVisa();
            })
            .catch((err) => {
                setIsLoading(false);
                setRemark("");
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };

    const sendVisaManually = (body) => {
        const visaService = new VisaService();
        visaService
            .sendVisaApplication(body.id, "", "ApprovedVisaApplication-SendVisa")
            .then((res) => {
                setIsLoading(false);
                toast.current.show({ severity: "success", summary: "Visa Document sent Successfully", detail: "Visa Document sent Successfully for reference number " + detailData?.referenceNumber, life: 5000 });
            })
            .catch((err) => {
                setIsLoading(false);
                //setRemark("");
                toast.current.show({ severity: "error", summary: "Error Occured", detail: "Error while sending Visa Document for reference number " + detailData?.referenceNumber, life: 5000 });
            });
    };

    const changeApplication = () => {
        dialogFuncMap[`displayResponsive`](false);
        const activateDeactivateBody = {
            remark: remark,
            id: Number.parseInt(localStorage.selectedVisaId),
        };

        setIsLoading(true);

        switch (approvalStatusValue) {
            case 0:
                validateApplication(activateDeactivateBody);
                break;
            case 1:
                validateApplication(activateDeactivateBody);
                break;
            case 2:
                inCompleteApplication(activateDeactivateBody);

                break;
            case 3:
                approveApplication(activateDeactivateBody);

                break;
            case 4:
                denyApplication(activateDeactivateBody);
                break;
            case 5:
                sendVisaManually(activateDeactivateBody);
                break;
            case 6:
                undoApproval(activateDeactivateBody);
                break;
            default:
        }
    };
    const renderFooter = (name) => {
        return (
            <div>
                <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={() => {
                        onHide(name);
                        setRemark("");
                        setSelectedIncompleteReasons([]);
                    }}
                    className="p-button-raised"
                />
                <Button
                    label={approvalStatusValue === 2 ? "Incomplete" : approvalStatusValue === 3 ? "Approve" : approvalStatusValue === 1 ? "Validate" : approvalStatusValue === 6 ? "Undo" : "Deny"}
                    icon={approvalStatusValue === 1 ? "pi pi-pencil" : approvalStatusValue === 3 ? "pi pi-pencil" : approvalStatusValue === 2 ? "pi pi-check-circle" : approvalStatusValue === 6 ? "pi pi-undo" : "pi pi-ban"}
                    onClick={() => changeApplication()}
                    disabled={approvalStatusValue === 3 || approvalStatusValue === 1 ? false : remark == "" ? true : false}
                />
            </div>
        );
    };
    const renderSendVisaFooter = (name) => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-raised" />
                <Button label="Send" icon="pi pi-check-circle" onClick={() => changeApplication()} />
            </div>
        );
    };

    const [selectedIncompleteReasons, setSelectedIncompleteReasons] = useState([]);

    const onIncompleteReasonChange = (e) => {
        let _selectedIncompleteReasons = [...selectedIncompleteReasons];
        if (e.checked) _selectedIncompleteReasons.push(e.value);
        else _selectedIncompleteReasons = _selectedIncompleteReasons.filter((incompleteReason) => incompleteReason.id !== e.value.id);
        setSelectedIncompleteReasons(_selectedIncompleteReasons);
        let remark = "";
        remark += _selectedIncompleteReasons?.map((reason) => reason?.text);
        setRemark(remark);
    };
    return (
        <div>
            {detailData?.visaStatus === 3 && approvalStatusValue !== 6 ? (
                <Dialog header="Send e-Visa" visible={displayResponsive} onHide={() => onHide("displayResponsive")} breakpoints={{ "960px": "75vw" }} style={{ width: "40vw" }} footer={renderSendVisaFooter}>
                    <h5>Are you sure you want to send e-Visa to the applicant?</h5>
                </Dialog>
            ) : detailData?.visaStatus === 3 && approvalStatusValue === 6 ? (
                <Dialog
                    header="Update Status Of Visa Application"
                    visible={displayResponsive}
                    onHide={() => {
                        onHide("displayResponsive");
                        setRemark("");
                        setSelectedIncompleteReasons([]);
                    }}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "40vw", minHeight: "10vh" }}
                    footer={renderFooter("displayResponsive")}
                >
                    <>
                        <h5>Remark</h5>
                        <InputTextarea value={remark} onChange={(e) => setRemark(e.target.value)} rows={8} cols={70} autoResize />
                    </>
                </Dialog>
            ) : detailData?.visaStatus !== 3 ? (
                <Dialog
                    header="Update Status Of Visa Application"
                    visible={displayResponsive}
                    onHide={() => {
                        onHide("displayResponsive");
                        setRemark("");
                        setSelectedIncompleteReasons([]);
                    }}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "40vw", minHeight: "10vh" }}
                    footer={renderFooter("displayResponsive")}
                >
                    <>
                        {approvalStatusValue !== 1 && approvalStatusValue !== 3 && approvalStatusValue !== 5 && approvalStatusValue !== 6 ? (
                            <div className="scrollpanel-demo">
                                <ScrollPanel style={{ width: "100%", height: "120px" }} className="custombar1">
                                    {incompleteReasons?.map((incompleteReason) => {
                                        return (
                                            <div key={incompleteReason["id"]} className="flex align-items-center">
                                                <Checkbox inputId={incompleteReason["id"]} name="Incomplete Reason" value={incompleteReason} onChange={onIncompleteReasonChange} checked={selectedIncompleteReasons?.some((item) => item.id === incompleteReason.id)} style={{ marginBottom: "0.5rem" }} />
                                                <label htmlFor={incompleteReason["id"]} className="ml-2" style={{ marginBottom: "0.5rem" }}>
                                                    {incompleteReason["text"]}
                                                </label>
                                            </div>
                                        );
                                    })}{" "}
                                </ScrollPanel>
                            </div>
                        ) : null}
                        <h5>Remark</h5>
                        <InputTextarea value={remark} onChange={(e) => setRemark(e.target.value)} rows={8} cols={70} autoResize />
                    </>
                </Dialog>
            ) : null}
            <Dialog visible={historyDialog} style={{ width: "600px", minHeight: "300px" }} header="Approval History " modal className="p-fluid" onHide={hideHistoryDialog}>
                {historyData?.map((data) => (
                    <div className="card" key={data?.id}>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">Status</div>
                                    <div className="text-cyan-500 font-bold mb-3">{data?.status}</div>
                                    <div className="company-name">Remark</div>
                                    <div className="text-cyan-500 font-bold ">{data?.remark}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    {data?.approvalStatus === 1 ? (
                                        <div className="company-name">Validated Date</div>
                                    ) : data?.approvalStatus === 2 ? (
                                        <div className="company-name">InCompleted Date</div>
                                    ) : data?.approvalStatus === 3 ? (
                                        <div className="company-name">Approved Date</div>
                                    ) : data?.approvalStatus === 4 ? (
                                        <div className="company-name">Denied Date</div>
                                    ) : null}
                                    <div className="text-cyan-500 font-bold mb-3">{data.registeredDate?.split("T").join(" | ").split(".")[0]} GMT </div>
                                    <div className="company-name">Issued by</div>
                                    <div className="text-cyan-500 font-bold ">{data?.registeredBy}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Dialog>
            <Dialog visible={AirportHisDialog} style={{ width: "600px", minHeight: "300px" }} header="Airport Verification History " modal className="p-fluid" onHide={hideAirportHistoryDialog}>
                {AirportHisData?.length === 0 || AirportHisData?.length === undefined ? (
                    <div className="field col">
                        <div className="invoice-company text-center">
                            <div className="company-name text-center">
                                <h4>No Data Found!</h4>
                            </div>
                        </div>
                    </div>
                ) : (
                    AirportHisData?.map((data) => (
                        <div className="card" key={data?.id}>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">Entry Status</div>
                                        <div className="text-cyan-500 font-bold mb-3">{data?.entryStatusDescription}</div>
                                        <div className="company-name">Registered By</div>
                                        <div className="text-cyan-500 font-bold ">{data?.registeredBy}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">Registered Date</div>
                                        <div className="text-cyan-500 font-bold ">{data.registeredDate?.split("T").join(" | ").split(".")[0]} GMT </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </Dialog>
            <div className="grid">
                {isLoading ? <SnipperModal /> : <> {null}</>}
                <Toast ref={toast} />

                <div className="col-12">
                    <div className="card">
                        <h4>
                            <b>Traveller Profile</b>
                        </h4>
                        <div className="formgrid grid">
                            {detailData?.attachments?.map((attach) =>
                                attach.isProfilePhoto ? (
                                    <div className="field lg:col-2 md:col-4 sm:col-12" key={attach?.attachmentPath}>
                                        <Image className="pp" src={attach?.attachmentPath} width="100%" alt="Profile Photo" preview />
                                    </div>
                                ) : null
                            )}

                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Name</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">
                                        {formatString(detailData?.firstName)} {formatString(detailData?.surName)}
                                    </div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Gender</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatString(detailData?.gender)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Email</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.email}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Address City</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.cityAddress}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Birth Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.birthDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Citizenship</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.citizenshipDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Country of Birth</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.birthCountryDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Street Adress</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.streetAddress}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Place of Birth</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.birthPlace}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Occupation</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.occupation}</div>

                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Country Adress</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.countryAdressDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Phone Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.phoneNumber}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h4>
                            <b>Request Details</b>
                        </h4>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Request Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.startDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Arrival Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.arrivalDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Visa Type</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaTypeValidity?.name}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Airlines </p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.airlines}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Reference Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.referenceNumber}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Payment Status</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.paymentStatusDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Entry Status</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.entryStatusDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Request Status</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.visaStatusDescription}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Last Departure Country</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.departureCountryDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Last Departure City</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.departureCity}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Flight Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.flightNumber}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Visa Fee</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaFee} USD</div>
                                    {detailData?.visaTypeValidity?.isBussiness === true ? (
                                        <div>
                                            {/* {detailData?.companyReferenceNumber?} */}
                                            <div className="company-name">
                                                <p className="text-700 text-lg p-0">Company Reference Number</p>
                                            </div>

                                            {roles?.privileges.includes("BusinessVisa-CompanyDetail") === true ? (
                                                <Link
                                                    to={{ pathname: "/company-application/DetailByReference" }}
                                                    onClick={() => {
                                                        companyDeatil(detailData?.companyReferenceNumber);
                                                    }}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <Button label={detailData?.companyReferenceNumber} style={{ backgroundColor: "#0d4d99" }} onClick={() => localStorage.setItem("DetailClaim", "ApprovedCompany-Detail")} />
                                                </Link>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    ) : null}
                                    {/* <Button icon={rowData.recordStatus && rowData.recordStatus === 1  } label="Approve"  className="p-button-rounded p-button-danger mt-2" onClick={() => confirmActivateDeactivateCountry(rowData)} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h4>
                            <b>Passport Details</b>
                        </h4>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Passport Type</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.passportTypeDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Passport Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.passportNumber}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Country of Issue</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.issuingCountryDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Issuing Authority</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.issuingAuthority}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Passport Issue Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.passportIssueDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Passport Expiration Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{formatDate(detailData?.passportExpireDate)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h4>
                            <b>Address in Ethiopia</b>
                        </h4>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Accomodation Type</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.accommodationTypeDescription}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Hotel Accomodation Name</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.accommodationName}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Street Address</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.accommodationAdress}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">City</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.accommodationCity}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Phone Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.accommodationPhonenumber}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {detailData?.paymentStatus === 2 ? (
                <div className="card">
                    <h4>
                        <b>e-Visa Detail</b>
                    </h4>
                    <div className="formgrid grid">
                        <div className="field col">
                            <div className="invoice-company">
                                <div className="company-name">
                                    <p className="text-700 text-lg p-0">e-Visa Number</p>
                                </div>
                                <div className="text-cyan-500 font-bold ">{detailData?.visaStatus === 3 ? detailData?.visaNumber : <> No Data ! !</>}</div>
                            </div>
                        </div>
                        <div className="field col">
                            <div className="invoice-company">
                                <div className="company-name">
                                    <p className="text-700 text-lg p-0">Issue Date</p>
                                </div>
                                <div className="text-cyan-500 font-bold ">{detailData?.visaStatus === 3 ? formatDate(detailData?.visaIssueDate) : <> No Data !</>}</div>
                            </div>
                        </div>
                        <div className="field col">
                            <div className="invoice-company">
                                <div className="company-name">
                                    <p className="text-700 text-lg p-0">Valid From</p>
                                </div>
                                <div className="text-cyan-500 font-bold ">{detailData?.visaStatus === 3 ? formatDate(detailData?.arrivalDate) : <> No Data !</>}</div>
                            </div>
                        </div>
                        <div className="field col">
                            <div className="invoice-company">
                                <div className="company-name">
                                    <p className="text-700 text-lg p-0">Valid Until</p>
                                </div>
                                <div className="text-cyan-500 font-bold ">{detailData?.visaStatus === 3 ? formatDate(detailData?.visaExpiryDate) : <> No Data !</>}</div>
                            </div>
                        </div>
                        {detailData?.visaStatus === 3 ? (
                            <div className="field col">
                                <div className="invoice-company">
                                    <div>
                                        <div className="text-cyan-500 font-bold ">
                                            {performingAction ? (
                                                <Button label="Downloading" icon="pi pi-spin pi-spinner"></Button>
                                            ) : (
                                                <Button
                                                    icon="pi pi-download"
                                                    label="Download Visa"
                                                    style={{ backgroundColor: "#0d4d99" }}
                                                    onClick={() => {
                                                        downloadPDF();
                                                    }}
                                                ></Button>
                                            )}
                                        </div>
                                    </div>
                                    {/* ) : null} */}
                                </div>
                            </div>
                        ) : null}

                        <div className="field col">
                            <div className="invoice-company">
                                <div>
                                    <div className="text-cyan-500 font-bold ">
                                        {performingActionreciept ? (
                                            <Button label="Downloading" icon="pi pi-spin pi-spinner"></Button>
                                        ) : (
                                            <Button
                                                icon="pi pi-download"
                                                style={{ backgroundColor: "#0d4d99" }}
                                                label="Download Reciept"
                                                onClick={() => {
                                                    downloadRecieptPDF();
                                                }}
                                            ></Button>
                                        )}
                                    </div>
                                </div>
                                {/* ) : null} */}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="card">
                <div className="formgrid grid">
                    <div className="field col-7">
                        <div className="scrollpanel-demo">
                            <h4>
                                <b>Visa Documents</b>
                            </h4>
                            <ScrollPanel style={{ height: "300px" }} className="custombar1">
                                {detailData?.attachments?.map((attach) =>
                                    attach?.isProfilePhoto ? null : (
                                        <div className="col-12" key={attach?.attachmentPath}>
                                            <div className="mb-4">
                                                {attach.fileType === ".pdf" ? (
                                                    <div className="card">
                                                        <h4>{attach?.attachmentType}</h4>
                                                        <a href={attach.attachmentPath} download="passport">
                                                            <Button className="bg-pink-700" icon="pi pi-file-pdf  " iconPos="left" tooltip={attach.attachmentType}></Button>
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="card">
                                                            <h4>{attach?.attachmentType}</h4>
                                                            <Image src={attach?.attachmentPath} alt="attachment" width={250} preview />{" "}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                                {detailData?.visaStatus === 3 ? <div>{/* <h4>Attachements </h4> */}</div> : null}
                            </ScrollPanel>
                        </div>
                    </div>

                    <div className="field col-3">
                        {detailData?.visaStatus === 0 && detailData?.paymentStatus === 2 && (roles?.privileges.includes("VisaApplication-InComplete") === true || roles?.privileges.includes("NewVisaApplication-Validate") === true || roles?.privileges.includes("VisaApplication-Deny") === true) ? (
                            <div className="invoice-company">
                                <h4>
                                    <b> Visa Request Actions</b>
                                </h4>
                                {roles?.privileges.includes("NewVisaApplication-Validate") === true ? (
                                    <Button label="Validate" className="p p-button-success" icon="pi pi-check" style={{ display: "inline-block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 1)}></Button>
                                ) : (
                                    <></>
                                )}
                                {roles?.privileges.includes("VisaApplication-InComplete") === true ? (
                                    <Button label="Incomplete" className="p p-button-warning" icon="pi pi-pencil" style={{ display: "inline-block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 2)}></Button>
                                ) : (
                                    <></>
                                )}
                                {roles?.privileges.includes("VisaApplication-Deny") === true ? (
                                    <Button label="Deny" className="p p-button-danger" icon="pi pi-ban" style={{ display: "inline-block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 4)}></Button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : detailData?.visaStatus === 1 &&
                          (roles?.privileges.includes("VisaApplication-InComplete") === true || roles?.privileges.includes("VisaApplication-Approve") === true || roles?.privileges.includes("VisaApplication-Deny") === true || roles?.privileges.includes("VisaApproval-Undo") === true) ? (
                            <div className="invoice-company">
                                <h3>
                                    <b icon="pi pi-check" className="p p-button-success">
                                        Visa Request Actions
                                    </b>
                                </h3>
                                <div className="flex flex-gap  flex-wrap">
                                    {roles?.privileges.includes("VisaApproval-Undo") === true ? (
                                        <Button label="Undo Approval" className="p p-button-success flex-auto flex align-items-center justify-content-center" icon="pi pi-undo" style={{ display: "inline-block", minWidth: 180 }} onClick={() => onClick("displayResponsive", 6)}></Button>
                                    ) : (
                                        <></>
                                    )}
                                    {roles?.privileges.includes("VisaApplication-Approve") === true ? (
                                        <Button label="Approve" className="p p-button-success flex-auto flex align-items-center justify-content-center" icon="pi pi-check-circle" style={{ display: "inline-block", minWidth: 130 }} onClick={() => onClick("displayResponsive", 3)}></Button>
                                    ) : (
                                        <></>
                                    )}
                                    {roles?.privileges.includes("VisaApplication-InComplete") === true ? (
                                        <Button label="Incomplete" className="p p-button-warning flex-auto flex align-items-center justify-content-center" icon="pi pi-pencil" style={{ display: "inline-block", minWidth: 150 }} onClick={() => onClick("displayResponsive", 2)}></Button>
                                    ) : (
                                        <></>
                                    )}
                                    {roles?.privileges.includes("VisaApplication-Deny") === true ? (
                                        <Button label="Deny" className="p p-button-danger flex-auto flex align-items-center justify-content-center" icon="pi pi-ban" style={{ display: "inline-block", minWidth: 130 }} onClick={() => onClick("displayResponsive", 4)}></Button>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <div className="flex flex-gap mt-5"></div>
                            </div>
                        ) : (detailData?.visaStatus === 2 && roles?.privileges.includes("VisaApproval-Undo") === true) || (detailData?.visaStatus === 4 && roles?.privileges.includes("VisaApproval-Undo") === true) ? (
                            <div className="invoice-company flex flex-gap ">
                                <div className="mt-5 pt-2">
                                    {roles?.privileges.includes("VisaApproval-Undo") === true ? (
                                        <Button label="Undo Approval" className="p p-button-success" icon="pi pi-undo" style={{ display: "inline-block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 6)}></Button>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        ) : detailData?.visaStatus === 3 && roles?.privileges.includes("ApprovedVisaApplication-SendVisa") === true && roles?.privileges.includes("VisaApproval-Undo") === true ? (
                            <div className="invoice-company flex flex-gap ">
                                <div className="mt-5 pt-2">
                                    {roles?.privileges.includes("VisaApproval-Undo") === true ? (
                                        <Button label="Undo Approval" className="p p-button-success" icon="pi pi-undo" style={{ display: "inline-block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 6)}></Button>
                                    ) : (
                                        <></>
                                    )}
                                </div>

                                <div>
                                    <h5>
                                        <b icon="pi pi-check" className="p p-button-success">
                                            {" "}
                                            Send Visa Manually
                                        </b>
                                    </h5>
                                    <Button label="Send e-Visa" style={{ backgroundColor: "#0d4d99" }} icon="pi pi-send" onClick={() => onClick("displayResponsive", 5)}></Button>
                                </div>
                            </div>
                        ) : detailData?.visaStatus === 5 && (roles?.privileges.includes("VisaApplication-MarkComplete") === true || roles?.privileges.includes("VisaApplication-Deny") === true || roles?.privileges.includes("VisaApproval-Undo") === true) ? (
                            <div className="invoice-company">
                                <h3>
                                    <b icon="pi pi-check" className="p p-button-success">
                                        {" "}
                                        Visa Request Approvals
                                    </b>
                                </h3>

                                {roles?.privileges.includes("VisaApproval-Undo") === true ? (
                                    <Button label="Undo" className="p p-button-success" icon="pi pi-undo" style={{ display: "inline-block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 6)}></Button>
                                ) : (
                                    <></>
                                )}
                                {roles?.privileges.includes("VisaApplication-MarkComplete") === true ? (
                                    <Button label="Mark As Complete" style={{ backgroundColor: "#0d4d99", display: "inline-block", marginBottom: "20px", marginLeft: "6px" }} icon="pi pi-check" onClick={() => onClick("displayResponsive", 1)}></Button>
                                ) : (
                                    <></>
                                )}
                                {roles?.privileges.includes("VisaApplication-InComplete") === true ? (
                                    <Button label="Incomplete" className="p p-button-warning" icon="pi pi-pencil" style={{ display: "inline-block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 2)}></Button>
                                ) : (
                                    <></>
                                )}
                                {roles?.privileges.includes("VisaApplication-Deny") === true ? (
                                    <Button label="Deny" className="p p-button-danger" icon="pi pi-ban" style={{ display: "inline-block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 4)}></Button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-start">
                    {detailData?.visaStatus === 0 ? (
                        <></>
                    ) : (
                        <div className="field  mt-5">
                            <Button label="View Approval History" style={{ backgroundColor: "#0d4d99" }} onClick={showHistory}></Button>
                        </div>
                    )}
                    {detailData?.visaStatus === 0 ? (
                        <></>
                    ) : (
                        <div className="field ml-2 mt-5">
                            <Button label="View Airport Verification" style={{ backgroundColor: "#0d4d99" }} onClick={showAirportHistory}></Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(VisaDetail, comparisonFn);
