import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import axiosInstance from "./../../../utilities/axios";
import SnipperModal from "../../modal/SnipperModal";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useEffect } from "react";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";

export const ResetPassword = () => {
    const [resetPassWordObj, setResetPasswordObj] = useState({
        username: "",
        password: "",
        newpassword: "",
    });
    const history = useHistory();

    let [loading, setLoading] = useState(false);

    const toast = useRef(null);

    const [buttonDisabled, setButtonDisabled] = useState(true);

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    const activationToken = window.location.hash?.split("?")[1]?.split("=")[1];

    const handleSubmit = () => {
        if (resetPassWordObj.newpassword !== resetPassWordObj.password) {
            toast.current.show({ severity: "error", summary: "Error", detail: "The confirmation password should match the new password", life: 4000 });
            return;
        }
        setLoading(true);
        axiosInstance
            .post(
                "/identity-server/api/v1/Password/ForgotPassword",
                {
                    userName: resetPassWordObj.username,
                    token: activationToken,
                    password: resetPassWordObj.password,
                },
                {
                    headers: {
                        accesstoken: localStorage.getItem("accessToken"),
                    },
                }
            )
            .then(async (resp) => {
                toast.current.show({ severity: "success", summary: "Successfull", detail: "Successfully resetted password", life: 4000 });
                setLoading(false);

                await delay(5000);
                history.push("/");
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error while resetting password", life: 4000 });
                setLoading(false);
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setResetPasswordObj((prevState) => ({
            ...prevState,

            [name]: value,
        }));
    };

    const handlePasswordValidation = (password) => {
        return /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})./.test(password);
    };

    useEffect(() => {
        if (resetPassWordObj.password !== "" && resetPassWordObj.username !== "" && resetPassWordObj.newpassword !== "") {
            if (!handlePasswordValidation(resetPassWordObj.password)) {
                setButtonDisabled(true);
            } else {
                setButtonDisabled(false);
            }
        }
        if (resetPassWordObj.password === "" || resetPassWordObj.username === "" || resetPassWordObj.newpassword === "") {
            setButtonDisabled(true);
        }
    }, [resetPassWordObj]);

    const passwordHeader = <h6>Pick a strong password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="login-body">
            <Toast ref={toast} />
            <div className="body-container">
                <div className="grid">
                    <div className="sm:col-12 md:col-4"></div>
                    <div className="sm:col-12 md:col-4 p-0">
                        <div className="login-wrapper mt-12">
                            <div className="login-container card">
                                {loading ? <SnipperModal /> : <> {null}</>}
                                <div className="grid p-fluid justify-center">
                                    <div className="xs:col-12 col-12 ">
                                        <img src="assets/layout/images/logo.png" alt="logo" />
                                        <>
                                            <div className="login-header mt-6 p-2">
                                                <span className="title">Reset Your Password</span>
                                            </div>
                                            <p className="text-left pl-2" style={{ fontSize: "1.5rem" }}>
                                                <strong>Note</strong>: <small>Use strong as well as easy to remember passwords. </small>
                                            </p>
                                            <div className="col-12 login-input">
                                                <InputText type="text" name="username" onInput={(e) => handleChange(e)} placeholder="UserName" />
                                            </div>
                                            <div className="col-12 login-input">
                                                <Password type="password" name="password" onInput={(e) => handleChange(e)} placeholder="New Password" header={passwordHeader} footer={passwordFooter} toggleMask />
                                            </div>

                                            <div className="col-12 login-input">
                                                <Password type="password" name="newpassword" onInput={(e) => handleChange(e)} placeholder="Confirm new password" toggleMask />
                                            </div>
                                            <div className="col-12">
                                                <Button label="Reset Password" disabled={buttonDisabled} onClick={() => handleSubmit()} />
                                            </div>
                                            <div className="col-12 text-left">
                                                <button type="text" className="p-link" onClick={() => history.push("/")}>
                                                    Back to Sign In
                                                </button>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sm:col-12 md:col-4"></div>
                </div>
            </div>
        </div>
    );
};
