import HttpService from "../shared/HttpService";

export default class CompanyApplicationService {
    getAll = (claim) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/GetAll";
        return HttpService.getService(relativePath, "", claim);
    };

    getDetail = (id) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/GetById?id=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };
    getDetailByReference = (referenceCode) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/GetByReferenceCode?referenceCode=" + referenceCode;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };
    getAttachments = (id) => {
        let relativePath = "companyservice/api/v1/CompanyAttachments/GetAll?companyId=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };
    // activateDeactivateCompany = (companyActivateBody, clientToken, idToken, payload) => {
    //     let relativePath = "companyservice/api/v1/CompanyApplication/UpdateStatus";
    //     return HttpService.activateDeactivateService(companyActivateBody, relativePath, "", "", payload);
    // };

    // Review needed here
    UpdateCompanyApplication = (companyActivateBody) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/UpdateStatus";
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.updateStatus(companyActivateBody, relativePath, "", "", "", claim);
    };

    filterNewCompanies = (claim) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/FilterApplications?approvalStatus=1";
        return HttpService.getbyApprovalStatus(relativePath, "", "", claim);
    };
    filterApprovedCompanies = (claim) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/FilterApplications?approvalStatus=2";
        return HttpService.getbyApprovalStatus(relativePath, "", "", claim);
    };
    filterIncompleteCompanies = (claim) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/FilterApplications?approvalStatus=3";
        return HttpService.getbyApprovalStatus(relativePath, "", "", claim);
    };
    filterRepliedCompanies = (claim) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/FilterApplications?approvalStatus=5";
        return HttpService.getbyApprovalStatus(relativePath, "", "", claim);
    };
    filterDeniedCompanies = (claim) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/FilterApplications?approvalStatus=4";
        return HttpService.getbyApprovalStatus(relativePath, "", "", claim);
    };
    searchVisaBySEDate = (startDate, endDate, claim) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/SearchApplications?" + "requestDateFrom=" + startDate + "&requestDateTo=" + endDate;
        return HttpService.getService(relativePath, "", claim);
    };
    searchVisa = (startDate, endDate, reference, companyName, email, status, claim) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/SearchApplications?status=" + status + "&requestDateFrom=" + startDate + "&requestDateTo=" + endDate + "&companyName=" + companyName + "&email=" + email + "&referenceNumber=" + reference;
        return HttpService.getService(relativePath, "", claim);
    };

    searchHistory = (id) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/GetCompanyApplicationStatusHistory?companyApplicationId=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };
}
