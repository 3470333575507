import React, { useState, useEffect, useRef } from "react";

import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import SnipperModal from "../../modal/SnipperModal";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputNumber } from 'primereact/inputnumber';
import RequiredAttachmentService from "../../../service/master-data/requiredAttachment";
import MasterDataService from "../../../service/master-data/MasterDataService";
import CompanyCategoryService from "../../../service/master-data/companyCategoryService";
import { useAuth } from "../../../utilities/authContext";
import DiscountService from "../../../service/master-data/DiscountService";

const DiscountedNationality = () => {
    let emptyrequiredAttachement = {
        id: 0,
        recordStatus: 0,
        nationalityId: 0,
        nationality: "",
        overStayFeePerDay: 0

    };
    const requestBody = {

     
        nationalityId: 0,
        overStayFeePerDay: 0

    };
    const activateDeactivateBody = {
        id:0,
        status: 0
    };

    let [loading, setLoading] = useState(false);

    const [visaFees, setvisaFees] = useState(null);
    const [nationalityTypes, setnationalityTypes] = useState(null);
    const [companyCategories, setCompanyCategories] = useState(null);

    const [selectednationalityType, setSelectednationalityType] = useState({ id: 0, name: "" });
    const [selectedCompanyCategory, SetSelectedCompanyCategory] = useState({ id: 0, name: "" });

    const [accommodationUpdateDialog, setAccommodationUpdateDialogDialog] = useState(false);
    const [visaFeeDialog, setvisaFeeDialog] = useState(false);
    const [deletevisaFeeDialog, setDeletevisaFeeDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);
    const [visaFee, setvisaFee] = useState(emptyrequiredAttachement);
    const [selectedAccommodations, setSelectedAccommodations] = useState(null);

    const [submitted, setSubmitted] = useState(false);

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'nationality': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
         
    });
    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        const discountService = new DiscountService();
        setLoading(true);
        discountService.getDisCounted("DiscountedNationality-View")
            .then((res) => {
                setLoading(false)
                setvisaFees(res?.data?.payload);
                console.log("dis",res.data.payload)
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching Discounted Nationality.", life: 3000 });
            })
            .finally(() => {
                setLoading(false);
            });
        const commonService = new MasterDataService();
        commonService
            .getnationality("DiscountedNationality-View")
            .then((res) => {
                setnationalityTypes(res.data);
               
            })
           

    }, []);
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        const _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value)
    }

    const openNew = () => {
    
        setvisaFee(emptyrequiredAttachement);
        setSubmitted(false);
        setvisaFeeDialog(true);
    };

    const hidevisaFeeDialog = () => {
        setSubmitted(false);
        setvisaFeeDialog(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setAccommodationUpdateDialogDialog(false);
    };

    const hideDeletevisaFeeDialog = () => {
        setDeletevisaFeeDialog(false);
    };
    const hideActivateDeactivatevisaFeeDialog = () => {
        setActivateDeactivateDialog(false);
    };

    const SubmitVisaFee = () => {
        setSubmitted(true);
        // let _visaFees = [...visaFees];
        let _vsiaFee = { ...visaFee };
        setLoading(true)

        const discountService = new DiscountService();
        discountService.createDisCounted(_vsiaFee, "", "DiscountedNationality-Add")
            .then((res) => {
                setLoading(false)
                toast.current.show({ severity: "success", summary: "Successful", detail: "Discounted  Penality fee Created", life: 3000 });
                refreshVisaFee()
            })
            .catch((err) => {
                setLoading(false)
                toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Discounted  Penality fee  already  Created", life: 3000 });
            });

        // setvisaFees(_visaFees);
        setvisaFeeDialog(false);
        setvisaFee(emptyrequiredAttachement);
    };
    const UpdatevisaFee = () => {
        setSubmitted(true);

        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };
        if (visaFee.id) {
            const index = findIndexById(visaFee.id);

            _visaFees[index] = _visaFee;
            const discountService = new DiscountService();


            
            requestBody.id=_visaFee?.id;
            requestBody.nationalityId = _visaFee?.nationalityId;
            requestBody.overStayFeePerDay = _visaFee?.overStayFeePerDay;


            setLoading(true)
            discountService.updateDisCounted(requestBody, "DiscountedNationality-Edit")
                .then((res) => {
                    setLoading(false)
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Discounted Nationality Updated", life: 3000 });
                    refreshVisaFee()
                    // reset();
                    // history.push('/roles')
                })
                .catch((err) => {
                    setLoading(false)
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Discounted Nationality  already Registered ", life: 3000 });
                });


            setvisaFees(_visaFees);
            setAccommodationUpdateDialogDialog(false);
            setvisaFee(emptyrequiredAttachement);
        }
    };
    const editAccommodation = (visaFee) => {
        setvisaFee({ ...visaFee });
        setAccommodationUpdateDialogDialog(true);
    };

    const confirmDeleteVisaFee = (visaFee) => {
        setvisaFee(visaFee);
        setDeletevisaFeeDialog(true);
    };

    const confirmactivateDeactivateVisaFee = (visaFee) => {
        setvisaFee(visaFee);
        setActivateDeactivateDialog(true);
    };

    const deleteVisaFee = () => {
        let _visaFees = visaFees.filter((val) => val.id !== visaFee.id);

        const discountService = new DiscountService();
        setLoading(true)

        discountService.deleteDisCounted(visaFee.id, "DiscountedNationality-Delete").then((res) => {
            setLoading(false)
            setvisaFees(_visaFees);
            setDeletevisaFeeDialog(false);
            setvisaFee(emptyrequiredAttachement);
            toast.current.show({ severity: "success", summary: "Successful", detail: "Discounted penality Deleted", life: 3000 });
        }).catch((err) => {
            setLoading(false)
            toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });
            ;
        });
    };
    const refreshVisaFee = () => {
        const discountService = new DiscountService();
        setLoading(true);
        discountService.getDisCounted("DiscountedNationality-View")
            .then((res) => {
                setLoading(false)
                setvisaFees(res?.data?.payload);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching Discounted Nationality.", life: 3000 });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const activateDeactivateVisaFee = () => {
        setSubmitted(true);
       
        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };
        if (visaFee.id) {
            const index = findIndexById(visaFee.id);

            _visaFees[index] = _visaFee;
            const discountService = new DiscountService();
            activateDeactivateBody.id = visaFee.id
            if (visaFee.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            } else activateDeactivateBody.status = 1;
            setLoading(true)
            discountService
                .activateDeactivateDisCounted(activateDeactivateBody, "DiscountedNationality-ChangeStatus")
                .then((res) => {
                    setLoading(false)
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Attachment Activated" : "Attachment Deactivated", life: 3000 });
                    refreshVisaFee()
                })
                .catch((err) => {
                    setLoading(false)
                    toast.current.show({ severity: 'error', summary: 'Error Occured', detail: err.message, life: 3000 });

                });
        }
        setvisaFees(_visaFees);
        setActivateDeactivateDialog(false);
        setvisaFee(emptyrequiredAttachement);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < visaFees.length; i++) {
            if (visaFees[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const onInputChange = (value, name) => {
        const val = value;
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = val;
        setvisaFee(_visaFee);
    };
    const onAttachmentTypeChange = (e, name) => {
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = e.value;
        setvisaFee(_visaFee);
        let selectedValue = nationalityTypes.find((c) => c.id === e.value);
        setSelectednationalityType(selectedValue);

    };

    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("DiscountedNationality-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    };
    const companyCategoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Company Category</span>
                {rowData?.nationality}
            </>
        );
    };
    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData?.overStayFeePerDay}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("DiscountedNationality-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editAccommodation(rowData)} /> : <></>}
                {roles.privileges.includes("DiscountedNationality-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteVisaFee(rowData)} /> : <></>}
                {roles.privileges.includes("DiscountedNationality-ChangeStatus")===true?
                 <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmactivateDeactivateVisaFee(rowData)} /> : <></>
                }
               
            </div>
        );
    };

    const statuses = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
            </span>
        )
    }

   

    const updatevisaFeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            <Button label="Update" icon="pi pi-check" className="p-button-raised" disabled = {visaFee.nationalityId && visaFee.overStayFeePerDay?false:true} onClick={UpdatevisaFee} />
        </>
    );

    const visaFeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hidevisaFeeDialog} />
            <Button label="Submit" icon="pi pi-check" className="p-button-raised" disabled = {visaFee.nationalityId && visaFee.overStayFeePerDay?false:true}onClick={SubmitVisaFee} />
        </>
    );

    const deletevisaFeeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeletevisaFeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteVisaFee} />
        </>
    );

    const activateDeactivatevisaFeeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivatevisaFeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateVisaFee} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable
                        ref={dt}
                        value={visaFees}
                        selection={selectedAccommodations}
                        onSelectionChange={(e) => setSelectedAccommodations(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        filters={filters}
                        scrollable
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Accommodations"
                        globalFilter={globalFilterValue}
                        emptyMessage="No Discounted Nationality found."
                        // header={header}
                        responsiveLayout="scroll" >
                        <Column field="nationality" header="Discounted Nationality" sortable body={companyCategoryBodyTemplate} style={{ minWidth: "8rem" }} headerStyle={{ minWidth: "8rem" }}></Column>
                        <Column field="overStayFeePerDay" header="Penality fee" sortable body={descriptionBodyTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable style={{ minWidth: "5rem" }} filter filterElement={statusFilterTemplate} headerStyle={{ minWidth: "5rem" }}></Column>

                        {(roles.privileges.includes("DiscountedNationality-Edit") === true ||
                            roles.privileges.includes("DiscountedNationality-Delete") === true) ? <Column header="Actions" body={actionBodyTemplate}></Column> : <></>}
                    </DataTable>

                    <Dialog visible={visaFeeDialog} style={{ width: "30vw", height: "35vh" }} header="Create Discounted Nationality" modal className="p-fluid" footer={visaFeeDialogFooter} onHide={hidevisaFeeDialog}>

                        <div className="field">
                            <label htmlFor="type">Nationality</label>
                            <Dropdown optionValue="id" id="type" optionLabel="name" value={selectednationalityType.id} optionName={visaFee.nationalityId} options={nationalityTypes} onChange={(e) => onAttachmentTypeChange(e, "nationalityId")} placeholder="Select Nationality" />
                        </div>

                        <div className="field">
                            <label htmlFor="overStayFeePerDay">One Day Penality</label>
                            <InputNumber id="overStayFeePerDay" value={visaFee.overStayFeePerDay} onChange={(e) => onInputChange(e.value, "overStayFeePerDay")} />
                        </div>
                    </Dialog>

                    <Dialog  visible={accommodationUpdateDialog} style={{ width: "30vw", height: "35vh" }} header="Update  Discounted Nationality" modal className="p-fluid" footer={updatevisaFeeDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="type">Nationality</label>
                           
                            
                            <Dropdown optionValue="id" optionLabel="name" value={visaFee.nationalityId.toString()} optionName={visaFee?.nationalityId} options={nationalityTypes} onChange={(e) => onAttachmentTypeChange(e, "nationalityId")} placeholder="Select Nationality" />
                        </div>
                        <div className="field">
                            <label htmlFor="name"> Penality Fee</label>
                            <InputNumber id="overStayFeePerDay"  value={visaFee.overStayFeePerDay} onChange={(e) => onInputChange(e.value, "overStayFeePerDay")}  />
                        
                        </div>
                    </Dialog>

                    <Dialog visible={deletevisaFeeDialog} style={{ width: "450px" }} header="Confirm" modal footer={deletevisaFeeDialogFooter} onHide={hideDeletevisaFeeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {visaFee && (
                                <span>
                                    Are you sure you want to delete Discounted Nationality <b>{visaFee.description}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: "450px" }} header="Confirm" modal footer={activateDeactivatevisaFeeDialogFooter} onHide={hideActivateDeactivatevisaFeeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {visaFee && (
                                <span>
                                    Are you sure you want to activate Discounted Nationality <b>{visaFee.description}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default DiscountedNationality;
