import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import MasterDataService from "../../service/master-data/MasterDataService";
import eVisaService from "../../service/eVisaService/eVisaService";
import CompanyApplicationService from "../../service/Company/CompanyApplicationService";
import { useForm } from "react-hook-form";
import SnipperModal from "../modal/SnipperModal";
import { useAuth } from "../../utilities/authContext";
import { Message } from "primereact/message";
import Moment from "moment";
import { Accordion, AccordionTab } from "primereact/accordion";
import { FilterMatchMode, FilterOperator } from "primereact/api";
//import { data } from 'cypress/types/jquery';

const VisaApplication = () => {
    let emptyVisa = {
        id: 0,
        firstName: "",
        email: "",
        visaType: 0,
        companyReferenceNumber: "",
        visaStatus: 0,
        visaStatusDescription: "",
        orderCode: "",
        surName: "",
        gender: "",
        birthDate: "",
        birthCountry: 0,
        birthCountryDescription: null,
        phoneNumber: "",
        occupation: "",
        streetAddress: "",
        cityAddress: "",
        countryAdress: 0,
        passportType: 0,
        passportNumber: "",
        passportIssueDate: "",
        passportExpireDate: "",
        issuingCountry: 0,
        issuingAuthority: 0,
        citizenship: 0,
        arrivalDate: "",
        visaValidity: 0,
        departureCountry: 0,
        city: " ",
        airlines: "",
        flightNumber: "",
        accommodationType: 0,
        accommodationName: "",
        accommodationCity: "",
        departureCity: "",
        accommodationPhonenumber: "",
        accommodationAdress: "",
        referenceNumber: " ",
        recordStatus: 2,
        status: "",
        paymentStatus: 0,
        startDate: "",
        visaIssueDate: "",

        visaExpiryDate: "",
        attachments: [],
        attachmentTypeId: 0,
        VisaTypeValidityId: 0,
        visaTypeValidity: [],
    };
    const activateDeactivateBody = {
        status: 0,
        id: 0,
    };
    const visaStatus = {
        0: "New",
        1: "VALIDATED",
        2: "Incompelete",
        3: "Approved",
        4: "Denied",
    };
    const applicationStatus = {
        approvalStatus: 0,
        approvalRemark: "",
        id: 0,
    };
    const filtevisa = {
        referenceNumber: "",
        passportNumber: "",
        Status: "",
        EndDate: "",
        StartDate: "",
        TravelerName: "",
        Email: "",
        OrderCode: "",
    };
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = prevMonth === 11 ? year - 1 : year;
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;

    let minDate = new Date();
    minDate.setMonth(prevMonth);
    minDate.setFullYear(prevYear);

    let maxDate = new Date();
    maxDate.setMonth(nextMonth);
    maxDate.setFullYear(nextYear);

    const [companyTypes, setcompanyTypes] = useState(null);
    const [selectedaccommodation, setselectedaccommodation] = useState({ id: 0, name: "" });
    const [visaTypes, setvisaTypes] = useState(null);
    const [countries, setcountries] = useState(emptyVisa);
    const [nationalities, setnationalities] = useState(emptyVisa);
    const [passportType, setpassportType] = useState(emptyVisa);
    const [accommodations, setAccommodations] = useState(null);
    const [passportTypes, setpassportTypes] = useState(null);
    const [visaTypeupdateDialog, setvisaTypeUpdateDialog] = useState(null);
    const [departurecountries, setdeparturecountries] = useState(emptyVisa);
    const [visaType, setvisaType] = useState(emptyVisa);
    const [visaTypesById, setvisaTypesById] = useState(null);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);
    const [companyType, setcompanyType] = useState(emptyVisa);
    const [selectedcompanies, setselectedcompanies] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [accommodation, setaccommodation] = useState(emptyVisa);
    const [selectedpassportType, setselectedpassportType] = useState({ id: 0, name: "" });
    const [selectednationality, setselectednationality] = useState({ id: 0, name: "" });
    const [selectedcountry, setselectedcountry] = useState({ id: 0, name: "" });
    const [selecteddeparturecountry, setselecteddeparturecountry] = useState({ id: 0, name: "" });

    const [dates1, setDates1] = useState(null);
    const [dates2, setDates2] = useState(null);
    const [passport, setPassport] = useState("");
    const [reference, setReference] = useState("");
    const [traveller, setTraveller] = useState("");
    const [email, setEmail] = useState("");
    const [OrderCode, setOrderCode] = useState("");
    const [arrivalDate, setArrivalDate] = useState(null);

    const toast = useRef(null);
    let [loading, setLoading] = useState(false);
    const dt = useRef(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "visaTypeValidity.name": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const roles = useAuth();

    useEffect(() => {
        const visaService = new eVisaService();
        const masterDataService = new MasterDataService();
        setLoading(true);

        visaService
            .getWaitingRequests("WaitingVisaApplication-View")
            .then((res) => {
                setLoading(false);
                setcompanyTypes(res.data);
                // console.log("data", res.data);
            })
            .catch((error) => {
                if (error?.response.status !== 404) {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching waiting visa requests.", life: 3000 });
                }
            })
            .finally(() => {
                setLoading(false);
            });
        masterDataService
            .getBulkMasterData("WaitingVisaApplication-View")
            .then((res) => {
                setLoading(false);
                setnationalities(res.data?.nationalities);
                setAccommodations(res.data?.accommodationTypes);
                setpassportTypes(res.data?.passportTypes);
                setcountries(res.data?.countries);
            })
            .catch((error) => {});
    }, []);
    const hideActivateDeactivateCountryDialog = () => {
        setActivateDeactivateDialog(false);
    };
    const Gender = [
        { name: "Male", value: "male" },
        { name: "Female", value: "female" },
    ];
    const FormatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < companyTypes.length; i++) {
            if (companyTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };
    const UpdateVisa = () => {
        debugger;
        setSubmitted(true);
        if (visaTypesById.firstName.trim()) {
            let _visaTypes = [...companyTypes];
            let _visaType = { ...visaTypesById };

            if (visaTypesById.id) {
                const index = findIndexById(visaTypesById.id);
                _visaTypes[index] = _visaType;

                setLoading(true);
                let visaBody = new FormData();
                visaBody.append("Id", _visaType?.id);
                visaBody.append("FirstName", _visaType?.firstName);
                visaBody.append("SurName", _visaType?.surName);
                visaBody.append("AccommodationType", _visaType?.accommodationType);
                visaBody.append("AccomodationName", _visaType?.accommodationName);
                visaBody.append("AccomodationAdress", _visaType?.accommodationAdress);
                visaBody.append("AccomodationPhonenumber", _visaType?.accommodationPhonenumber);
                visaBody.append("AccomodationCity", _visaType?.accommodationCity);
                visaBody.append("Email", _visaType?.email);
                visaBody.append("FlightNumber", _visaType?.flightNumber);
                visaBody.append("BirthCountry", _visaType?.birthCountry);
                visaBody.append("BirthPlace", _visaType?.birthPlace);

                visaBody.append("StreetAddress", _visaType?.streetAddress);
                visaBody.append("Gender", _visaType?.gender);
                visaBody.append("BirthDate", Moment(_visaType?.birthDate).format("L"));
                visaBody.append("PhoneNumber", _visaType?.phoneNumber);
                visaBody.append("Occupation", _visaType?.occupation);
                visaBody.append("CityAddress", _visaType?.cityAddress);
                visaBody.append("CountryAdress", _visaType?.countryAdress);
                visaBody.append("PassportType", _visaType?.passportType);
                visaBody.append("PassportNumber", _visaType?.passportNumber);
                visaBody.append("PassportExpireDate", Moment(_visaType?.passportExpireDate).format("L"));
                visaBody.append("PassportIssueDate", Moment(_visaType?.passportIssueDate).format("L"));

                visaBody.append("IssuingCountry", _visaType?.issuingCountry);
                visaBody.append("IssuingAuthority", _visaType?.issuingAuthority);
                visaBody.append("Citizenship", _visaType?.citizenship);

                visaBody.append("DepartureCountry", _visaType?.departureCountry);
                visaBody.append("DepartureCity", _visaType?.departureCity);
                visaBody.append("Airlines", _visaType?.airlines);

                visaBody.append("ArrivalDate", _visaType?.arrivalDate);
                visaBody.append("VisaValidity", _visaType?.visaTypeValidity?.visaValidityId);
                visaBody.append("VisaTypeValidityId", _visaType?.visaTypeValidity?.id);

                const visaService = new eVisaService();

                visaService
                    .updateVisa(visaBody, _visaType?.id, "", "WaitingVisaApplication-Edit")
                    .then((res) => {
                        setvisaTypeUpdateDialog(false);
                        setLoading(false);
                        toast.current.show({ severity: "success", summary: "Successful", detail: "Visa Request Updated", life: 3000 });
                    })
                    .catch((err) => {
                        setLoading(false);

                        toast.current.show({ severity: "error", summary: "Error Occured", detail: "Error while updating this request \n" + err.response.data.errors[0], life: 3000 });
                    });
                // setLoading(false)
            } else {
            }
            setLoading(false);
            setvisaTypes(_visaTypes);
            // setvisaTypeUpdateDialog(false);
            setvisaType(emptyVisa);
        }
    };
    const hideDialog = () => {
        setSubmitted(false);
        setvisaTypeUpdateDialog(false);
    };
    const editVisa = (visaType) => {
        // getVisaById(visaType?.id)
        // setvisaTypesById({ ...visaType });
        setvisaTypeUpdateDialog(true);

        const visaService = new eVisaService();
        visaService
            .getDetailById(visaType?.id, "NewVisaApplication-View")
            .then((res) => {
                setLoading(false);
                let data = res.data;
                data["citizenship"] = String(data["citizenship"]);
                setvisaTypesById(data);
            })
            .catch((error) => {});

        let selectedbirthCountry = countries.find((c) => c?.id === visaTypesById?.birthCountry);
        let selectedPassportType = passportTypes.find((c) => c?.id === visaTypesById?.passportType);
        let selectedValue = accommodations.find((c) => c?.id === visaTypesById?.accommodationType);
        let selectedGender = Gender.find((c) => c?.value === visaTypesById?.gender);
        let selectednationality = nationalities.find((c) => parseInt(c?.id) === visaTypesById?.citizenship);
        let selecteddepartureCountry = nationalities.find((c) => c?.id === visaTypesById?.departureCountry);
        // let selectedissuingCountry = countries.find((c) => c?.id === visaTypesById?.issuingCountry);

        let _visaType = { ...visaType };

        _visaType["gender"] = selectedGender;
        _visaType["citizenship"] = selectednationality;
        _visaType["birthCountry"] = selectedbirthCountry;
        _visaType["passportType"] = selectedPassportType;
        _visaType["departureCountry"] = selecteddepartureCountry;

        setvisaTypesById(_visaType);
        setselectedaccommodation(selectedValue);
        setselectednationality(selectednationality);
        setselectedcountry(selectedbirthCountry);
        setselectedpassportType(selectedPassportType);
        setselecteddeparturecountry(selecteddepartureCountry);
    };
    const onBirthCountryChange = (e, name) => {
        let _visatypeId = { ...visaTypesById };
        _visatypeId[name] = e.value;
        setvisaTypesById(_visatypeId);
    };
    const changeApplicationStatus = () => {
        setSubmitted(true);

        let _companytypes = [...companyTypes];
        let _companytype = { ...companyType };
        if (companyType.id) {
            const index = findIndexById(companyType.id);
            _companytypes[index] = _companytype;
            const visaService = new CompanyApplicationService();
            activateDeactivateBody.id = _companytype.id;
            activateDeactivateBody.approvalStatus = _companytype.approvalStatus;
            activateDeactivateBody.approvalRemark = _companytype.approvalRemark;
            if (companyType.approvalStatus === 0) {
                activateDeactivateBody.approvalStatus = 2;
            }
            if (companyType.approvalStatus === 0) {
                activateDeactivateBody.approvalStatus = 2;
            } else activateDeactivateBody.approvalStatus = 0;
            visaService
                .UpdateCompanyApplication(activateDeactivateBody, "clienttoken", "accesstoken", "")
                .then((res) => {
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: res.data.approvalStatus === 2 ? "Company Approved" : "Company Approved",
                        life: 3000,
                    });
                })
                .catch((err) => {
                    // console.log(err);
                });
        }
        setcompanyTypes(_companytypes);
        setActivateDeactivateDialog(false);
        setcompanyType(emptyVisa);
    };
    // const findIndexById = (id) => {
    //     let index = -1;
    //     for (let i = 0; i < companyTypes.length; i++) {
    //         if (companyTypes[i].id === id) {
    //             index = i;
    //             break;
    //         }
    //     }
    //     return index;
    // };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _visaType = { ...visaTypesById };
        _visaType[`${name}`] = val;
        setvisaTypesById(_visaType);
    };
    const passportNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Passport Number</span>
                {rowData.passportNumber}
            </>
        );
    };

    const arrivalDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Arrival Date</span>
                {rowData.arrivalDate.split("T")[0]}
            </>
        );
    };
    const requestDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Arrival Date</span>
                {rowData.startDate.split("T")[0]}
            </>
        );
    };

    const visaTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Visa Type</span>
                {rowData?.visaTypeValidity?.name}
            </>
        );
    };
    const companyReferenceNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ReferenceNumber</span>
                {rowData.referenceNumber}
            </>
        );
    };
    const OrderCodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ReferenceNumber</span>
                {rowData.orderCode}
            </>
        );
    };

    const openDetail = (rowData) => {
        localStorage.setItem("selectedVisaId", rowData?.id);
    };
    const birthCountryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nationality</span>
                {rowData.citizenshipDescription}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex">
                {/* <Button icon={rowData.recordStatus && rowData.recordStatus === 1  } label="Approve"  className="p-button-rounded p-button-danger mt-2" onClick={() => confirmActivateDeactivateCountry(rowData)} /> */}
                {roles?.privileges.includes("WaitingVisaApplication-Detail") === true ? (
                    <Link
                        to={{ pathname: "/visa-requests/visa-detail", state: { selected: rowData } }}
                        onClick={() => {
                            openDetail(rowData);
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button label="Detail" style={{ backgroundColor: "#0D4D99" }} onClick={() => localStorage.setItem("DetailClaim", "WaitingVisaApplication-Detail")} />
                    </Link>
                ) : (
                    <></>
                )}
                {roles?.privileges.includes("WaitingVisaApplication-Edit") === true ? <Button label="Edit" style={{ backgroundColor: "#0D4D99" }} className="ml-2" onClick={() => editVisa(rowData)} /> : <></>}
            </div>
        );
    };
    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Request Status </span>
                {visaStatus[rowData.visaStatus]}
            </>
        );
    };
    const travellrNameTemplate = (rowData) => {
        return <>{rowData?.travelerName}</>;
    };
    const updateVisaDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdateVisa} />
        </>
    );
    const status = ["New", "Validated", "Incompelete", "Approved", "Denied"];
    const header = (
        <div className="flex ">
            {/* <h5 className="m-0">New Visa Application</h5> */}
            <div className="inline-flex flex-wrap flex-gap">
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="startDate" className="mb-2 mt-2">
                            Start Date
                        </label>
                        <Calendar
                            id="startDate"
                            value={dates1}
                            onChange={(e) => {
                                setDates1(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            maxDate={dates2}
                        />
                    </span>
                </span>
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            value={dates2}
                            onChange={(e) => {
                                setDates2(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            minDate={dates1}
                        />
                    </span>
                </span>
                <span style={{ minWidth: 300 }} className="">
                    <span className="flex flex-column gap-2 ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Traveller Name
                        </label>
                        <InputText value={traveller} onChange={(e) => setTraveller(e.target.value)} />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2 ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Passport Number
                        </label>
                        <InputText value={passport} onChange={(e) => setPassport(e.target.value)} />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Reference Number
                        </label>
                        <InputText value={reference} onChange={(e) => setReference(e.target.value)} />
                    </span>
                </span>

                <span style={{ minWidth: 300 }} className="">
                    <span className="flex flex-column gap-2">
                        <label className="mb-2 mt-2">Email</label>
                        <InputText value={email} onChange={(e) => setEmail(e.target.value)} />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2">
                        <label className="mb-2 mt-2">Order Code</label>
                        <InputText value={OrderCode} onChange={(e) => setOrderCode(e.target.value)} />
                    </span>
                </span>
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="arrivalDate" className="mb-2 mt-2 ">
                            Arrival Date
                        </label>
                        <Calendar
                            id="arrivalDate"
                            value={arrivalDate}
                            onChange={(e) => {
                                setArrivalDate(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                        />
                    </span>
                </span>

                <Button label="Search" icon="pi pi-search" className="mt-5  " style={{ minWidth: 200, height: 42, backgroundColor: "#0D4D99" }} onClick={(e) => SearchAll(dates1, dates2, passport, traveller, reference, email, OrderCode, arrivalDate)} />
            </div>
        </div>
    );
    const SearchAll = (dates1, dates2, passport, traveller, reference, email, orderCode, dates3) => {
        const visaService = new eVisaService();
        let startDate = FormatDate(dates1);
        let endDate = FormatDate(dates2);
        let arrivalDate = FormatDate(dates3);

        setLoading(true);

        visaService
            .searchVisa(startDate === "1970-01-01" ? "" : startDate, endDate === "1970-01-01" ? "" : endDate, passport, traveller, reference, email, orderCode, arrivalDate === "1970-01-01" ? "" : arrivalDate, "1", "WaitingVisaApplication-View")
            .then((res) => {
                setLoading(false);
                setcompanyTypes(res.data);
            })
            .catch((err) => {
                setLoading(false);
                setcompanyTypes(null);
            });
    };
    const activateDeactivatecountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideActivateDeactivateCountryDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={changeApplicationStatus} />
        </>
    );
    return (
        <div className="grid crud-demo">
            <h4>
                <Message severity="info" text="Passport Number, Reference Number, Traveler Name, Email, or Request Date are all valid search terms." />
            </h4>
            <div className="col-12">
                <Toast ref={toast} />

                {/* {loading ? <SnipperModal /> : <> {null}</>} */}
                <DataTable
                    ref={dt}
                    value={companyTypes}
                    selection={selectedcompanies}
                    onSelectionChange={(e) => setselectedcompanies(e.value)}
                    dataKey="id"
                    paginator
                    rows={5}
                    loading={loading}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Waiting Visa Requests"
                    scrollable
                    globalFilter={globalFilter}
                    emptyMessage="No Visa Requests found."
                    header={header}
                    responsiveLayout="scroll"
                >
                    <Column field="travelerName" header="Traveller Name" body={travellrNameTemplate} sortable frozen filter filterPlaceholder="Search by name" style={{ minWidth: "14rem" }}></Column>
                    <Column field="passportNumber" header="Passport Number" body={passportNumberBodyTemplate} frozen sortable filter filterPlaceholder="Search by Passport Number" style={{ minWidth: "14rem" }}></Column>
                    {/* <Column field="email" header="Email Adress" body={emailBodyTemplate} sortable filter filterPlaceholder="Search by Email Adresss" style={{ minWidth: '30rem' }} headerStyle={{ minWidth: '30rem' }}></Column> */}
                    <Column field="startDate" header="Request Date" body={requestDateBodyTemplate} sortable filter filterPlaceholder="Search by request date " style={{ minWidth: "14rem" }}></Column>
                    <Column field="arrivalDate" header="Arrival Date" body={arrivalDateBodyTemplate} sortable filter filterPlaceholder="Search by arrival date " style={{ minWidth: "14rem" }}></Column>

                    <Column field="citizenshipDescription" header="Nationality" body={birthCountryBodyTemplate} sortable filter filterPlaceholder="Search by Nationality" style={{ minWidth: "14rem" }}></Column>
                    <Column field="visaTypeValidity.name" header="Visa Type" body={visaTypeBodyTemplate} sortable filter filterPlaceholder="Search by visa type " style={{ minWidth: "14rem" }}></Column>
                    <Column field="referenceNumber" header="Reference Number" body={companyReferenceNumberBodyTemplate} sortable filter filterPlaceholder="Search by Refernce Number" style={{ minWidth: "14rem" }}></Column>
                    <Column field="orderCode" header="Order Code" body={OrderCodeBodyTemplate} sortable filter filterPlaceholder="Search by order code" style={{ minWidth: "14rem" }}></Column>
                    <Column field="visaStatusDescription" header="Status" body={statusBodyTemplate} style={{ minWidth: "14rem" }}></Column>

                    {roles?.privileges.includes("WaitingVisaApplication-Detail") === true ? <Column header="Actions" body={actionBodyTemplate} frozen alignFrozen="right"></Column> : <></>}
                </DataTable>
                <Dialog visible={visaTypeupdateDialog} style={{ width: "1000px" }} header="Update  Validated Visa Request" modal className="p-fluid" footer={updateVisaDialogFooter} onHide={hideDialog}>
                    <Accordion activeIndex={0}>
                        <AccordionTab header="Personal Information">
                            <div className="p-fluid formgrid grid">
                                <div className="field  col-4">
                                    <label htmlFor="firstName">
                                        <b>First Name</b>
                                    </label>
                                    <InputText id="firstName" value={visaTypesById?.firstName} onChange={(e) => onInputChange(e, "firstName")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.firstName })} />
                                    {submitted && !visaTypesById?.firstName && <small className="p-invalid"> First Name name is required.</small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="surName">
                                        <b>Last Name</b>
                                    </label>
                                    <InputText id="surName" value={visaTypesById?.surName} onChange={(e) => onInputChange(e, "surName")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.surName })} />
                                    {submitted && !visaTypesById?.surName && <small className="p-invalid"> Last Name is required.</small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="Gender">
                                        <b>Gender</b>
                                    </label>
                                    <Dropdown optionLabel="name" value={visaTypesById?.gender} options={Gender} onChange={(e) => onInputChange(e, "gender")} placeholder="Select Gender" />
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="occupation">
                                        <b>Occupation</b>
                                    </label>
                                    <InputText id="occupation" value={visaTypesById?.occupation} onChange={(e) => onInputChange(e, "occupation")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.occupation })} />
                                    {submitted && !visaTypesById?.occupation && <small className="p-invalid">Occupation is required.</small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="birthPlace">
                                        <b>Place of Birth</b>
                                    </label>
                                    <InputText id="birthPlace" value={visaTypesById?.birthPlace} onChange={(e) => onInputChange(e, "birthPlace")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.birthPlace })} />
                                    {submitted && !visaTypesById?.birthPlace && <small className="p-invalid">Birth Place is required.</small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="name">
                                        <b>Nationality</b>
                                    </label>
                                    <Dropdown optionValue="id" optionLabel="name" value={visaTypesById?.citizenship} optionName={nationalities?.id} options={nationalities} onChange={(e) => onBirthCountryChange(e, "citizenship")} placeholder="Select nationality " />
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="name">
                                        <b>Birth Country</b>
                                    </label>
                                    <Dropdown optionValue="id" optionLabel="name" value={visaTypesById?.birthCountry} optionName={countries?.id} options={countries} onChange={(e) => onBirthCountryChange(e, "birthCountry")} placeholder="Select birth  Country " />
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="email">
                                        <b>Birth Date </b>
                                    </label>
                                    <Calendar
                                        value={new Date(visaTypesById?.birthDate)}
                                        //minDate={new Date(visaType.effectiveDateFrom)}
                                        dateFormat="dd/mm/yy"
                                        onChange={(e) => {
                                            onInputChange(e, "birthDate");
                                        }}
                                        showIcon
                                    />
                                </div>
                            </div>
                        </AccordionTab>

                        <AccordionTab header="Passport Information">
                            <div className="p-fluid formgrid grid">
                                <div className="field  col-4">
                                    <label htmlFor="email">
                                        <b>Passport Number</b>
                                    </label>
                                    <InputText id="email" value={visaTypesById?.passportNumber} onChange={(e) => onInputChange(e, "passportNumber")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.passportNumber })} />
                                    {submitted && !visaTypesById?.passportNumber && <small className="p-invalid">Passport Number is required.</small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="name">
                                        <b>Passport Type </b>
                                    </label>
                                    <Dropdown optionValue="id" optionLabel="name" value={visaTypesById?.passportType} optionName={passportType?.id} options={passportTypes} onChange={(e) => onBirthCountryChange(e, "passportType")} placeholder="Select Passport Type " />
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="issuingAuthority">
                                        <b>Issuing Authority</b>
                                    </label>
                                    <InputText id="issuingAuthority" value={visaTypesById?.issuingAuthority} onChange={(e) => onInputChange(e, "issuingAuthority")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.issuingAuthority })} />
                                    {submitted && !visaTypesById?.issuingAuthority && <small className="p-invalid">Issuing Authority Number is required.</small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="name">
                                        <b>Issuing Country</b>
                                    </label>
                                    <Dropdown optionValue="id" optionLabel="name" value={visaTypesById?.issuingCountry} optionName={countries?.id} options={countries} onChange={(e) => onBirthCountryChange(e, "issuingCountry")} placeholder="Select Departure Country   " />
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="email">
                                        <b>Passport Issue Date</b>
                                    </label>
                                    <Calendar
                                        value={new Date(visaTypesById?.passportIssueDate)}
                                        //minDate={new Date(visaType.effectiveDateFrom)}
                                        dateFormat="dd/mm/yy"
                                        onChange={(e) => {
                                            onBirthCountryChange(e, "passportIssueDate");
                                        }}
                                        showIcon
                                    />
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="email">
                                        <b>Passport Expire Date</b>
                                    </label>
                                    <Calendar
                                        value={new Date(visaTypesById?.passportExpireDate)}
                                        //minDate={new Date(visaType.effectiveDateFrom)}
                                        dateFormat="dd/mm/yy"
                                        onChange={(e) => {
                                            onBirthCountryChange(e, "passportExpireDate");
                                        }}
                                        showIcon
                                    />
                                </div>
                            </div>
                        </AccordionTab>

                        <AccordionTab header="Address Information">
                            <div className="p-fluid formgrid grid">
                                <div className="field  col-4">
                                    <label htmlFor="email">
                                        <b>Email</b>
                                    </label>
                                    <InputText id="email" value={visaTypesById?.email} onChange={(e) => onInputChange(e, "email")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.email })} />
                                    {submitted && !visaTypesById?.email && <small className="p-invalid">Email is required.</small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="phoneNumber">
                                        <b>Phone Number</b>
                                    </label>
                                    <InputText id="phoneNumber" value={visaTypesById?.phoneNumber} onChange={(e) => onInputChange(e, "phoneNumber")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.phoneNumber })} />
                                    {submitted && !visaTypesById?.phoneNumber && <small className="p-invalid">phoneNumber is required.</small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="streetAddress">
                                        <b>Street Address</b>
                                    </label>
                                    <InputText id="streetAddress" value={visaTypesById?.streetAddress} onChange={(e) => onInputChange(e, "streetAddress")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.streetAddress })} />
                                    {submitted && !visaTypesById?.streetAddress && <small className="p-invalid">Street Address is required.</small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="cityAddress">
                                        <b>City Address</b>
                                    </label>
                                    <InputText id="cityAddress" value={visaTypesById?.cityAddress} onChange={(e) => onInputChange(e, "cityAddress")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.cityAddress })} />
                                    {submitted && !visaTypesById?.cityAddress && <small className="p-invalid">City Address is required.</small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="name">
                                        <b>Adresss Country</b>
                                    </label>
                                    <Dropdown optionValue="id" optionLabel="name" value={visaTypesById?.countryAdress} optionName={countries?.id} options={countries} onChange={(e) => onBirthCountryChange(e, "countryAdress")} placeholder="Select Address Country " />
                                </div>
                            </div>
                        </AccordionTab>

                        <AccordionTab header="Accommodation Information">
                            <div className="p-fluid formgrid grid">
                                <div className="field  col-6">
                                    <label htmlFor="accommodationName">
                                        <b>Accomodation Name</b>
                                    </label>
                                    <InputText id="accommodationName" value={visaTypesById?.accommodationName} onChange={(e) => onInputChange(e, "accommodationName")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.accommodationName })} />
                                    {submitted && !visaTypesById?.accommodationName && <small className="p-invalid"> Accomodation Name is required.</small>}
                                </div>
                                <div className="field  col-6">
                                    <label htmlFor="accommodationAdress">
                                        <b>Accomodation Adress</b>
                                    </label>
                                    <InputText id="accommodationAdress" value={visaTypesById?.accommodationAdress} onChange={(e) => onInputChange(e, "accommodationAdress")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.accommodationAdress })} />
                                    {submitted && !visaTypesById?.acccommodationAdress && <small className="p-invalid">Accomodation Adress is required.</small>}
                                </div>
                                <div className="field  col-6">
                                    <label htmlFor="name">
                                        <b>Accommodation Type </b>
                                    </label>
                                    <Dropdown optionValue="id" optionLabel="name" value={visaTypesById?.accommodationType} optionName={accommodation?.accomadationId} options={accommodations} onChange={(e) => onBirthCountryChange(e, "accommodationType")} placeholder="Select Accommodation Type " />
                                </div>
                                <div className="field  col-6">
                                    <label htmlFor="accommodationPhonenumber">
                                        <b>Accomodation Phone number</b>
                                    </label>
                                    <InputText id="accommodationPhonenumber" value={visaTypesById?.accommodationPhonenumber} onChange={(e) => onInputChange(e, "accommodationPhonenumber")} autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.accommodationPhonenumber })} />
                                    {submitted && !visaTypesById?.accommodationPhonenumber && <small className="p-invalid">Accomodation Phone number is required.</small>}
                                </div>
                                <div className="field  col-6">
                                    <label htmlFor="accommodationCity">
                                        <b>Accomodation City</b>
                                    </label>
                                    <InputText id="accommodationCity" value={visaTypesById?.accommodationCity} onChange={(e) => onInputChange(e, "accommodationCity")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.accommodationCity })} />
                                    {submitted && !visaTypesById?.accommodationCity && <small className="p-invalid">Accommodation City is required.</small>}
                                </div>
                                <div className="field  col-6">
                                    <label htmlFor="streetAddress">
                                        <b>Street Address</b>
                                    </label>
                                    <InputText id="streetAddress" value={visaTypesById?.streetAddress} onChange={(e) => onInputChange(e, "streetAddress")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.streetAddress })} />
                                    {submitted && !visaTypesById?.streetAddress && <small className="p-invalid">Street Address is required.</small>}
                                </div>
                            </div>
                        </AccordionTab>

                        <AccordionTab header="Flight Information">
                            <div className="p-fluid formgrid grid">
                                <div className="field  col-4">
                                    <label htmlFor="flightNumber">
                                        <b>Flight Number</b>
                                    </label>
                                    <InputText id="flightNumber" value={visaTypesById?.flightNumber} onChange={(e) => onInputChange(e, "flightNumber")} />
                                    {submitted && !visaTypesById?.flightNumber && <small className="p-invalid"> </small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="airlines">
                                        <b>Airlines</b>
                                    </label>
                                    <InputText id="airlines" value={visaTypesById?.airlines} onChange={(e) => onInputChange(e, "airlines")} />
                                    {submitted && !visaTypesById?.airlines && <small className="p-invalid"> </small>}
                                </div>
                                <div className="field  col-4">
                                    <label htmlFor="departureCity">
                                        <b>Departure City</b>
                                    </label>
                                    <InputText id="departureCity" value={visaTypesById?.departureCity} onChange={(e) => onInputChange(e, "departureCity")} required autoFocus className={classNames({ "p-invalid": submitted && !visaTypesById?.departureCity })} />
                                    {submitted && !visaTypesById?.departureCity && <small className="p-invalid"> Departure City is required.</small>}
                                </div>

                                <div className="field  col-4">
                                    <label htmlFor="name">
                                        <b>Departure Country</b>
                                    </label>
                                    <Dropdown optionValue="id" optionLabel="name" value={visaTypesById?.departureCountry} optionName={departurecountries?.id} options={countries} onChange={(e) => onBirthCountryChange(e, "departureCountry")} placeholder="Select Departure Country   " />
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </Dialog>
                <Dialog visible={activateDeactivateDialog} style={{ width: "450px" }} header="Confirm" modal footer={activateDeactivatecountryDialogFooter} onHide={hideActivateDeactivateCountryDialog}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                        {companyType && (
                            <span>
                                Are you sure you want to Approve <b>{companyType.name}</b>?
                            </span>
                        )}
                    </div>
                </Dialog>
            </div>
        </div>
    );
};
export default VisaApplication;
