import HttpService from "../shared/HttpService";

export default class eVisaService {
    getAll = (claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/FilterByStatus?visaStatus=0";
        return HttpService.getService(relativePath, "", claim);
    };
    getReport = (userName,claim) => {
           
        let relativePath = "visaservice/api/v1/Report/GetApprovalHistoryDetail?userName=" + userName;
        return HttpService.getService(relativePath, "", claim);
    };
    
    getNewRequests = (claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/FilterByStatus?visaStatus=6";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getAccommodationType = (claim) => {
        let relativePath = "masterdataservice/api/v1/AccommodationType/GetAll";
        return HttpService.getService(relativePath, "", claim);
    };

    // not used
    getVisaType = (clientToken, idToken, payload) => {
        let relativePath = "visaservice/api/v1/VisaType/GetAll";
        var tokenCollection = JSON.parse(localStorage.getItem("tokenCollection"));
        return HttpService.getService(relativePath, "", "", payload);
    };

    getWaitingRequests = (claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/FilterByStatus?visaStatus=1";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getApprovedRequests = (claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/FilterByStatus?visaStatus=3";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getDeniedRequests = (claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/FilterByStatus?visaStatus=4";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getIncompelteRequests = (claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/FilterByStatus?visaStatus=2";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getRepliedRequests = (claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/FilterByStatus?visaStatus=7";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };
    getUnpaidVisaRequests = (claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/FilterByStatus?visaStatus=5";
        return HttpService.getbyvisaStatus(relativePath, "", "", claim);
    };

    getDetail = (id) => {
        let relativePath = "visaservice/api/v1/VisaRequest/GetById?id=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };

    getDetailById = (id, claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/GetById?id=" + id;
        //const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };
    incompeleteVisaApplication = (id, clientToken, idToken, payload) => {
        let relativePath = "visaservice/api/v1/VisaRequestApproval/Incompelete?id=" + id + "&remark=" + payload.remark;
        return HttpService.updateStatus(payload, relativePath, "", "", payload, "VisaApplication-InComplete");
    };

    ValidateVisaApplication = (id, clientToken, idToken, payload) => {
        let relativePath = "visaservice/api/v1/VisaRequestApproval/Compelete?id=" + id + "&remark=" + payload.remark;
        return HttpService.updateStatus(payload, relativePath, "", "", payload, "NewVisaApplication-Validate");
    };

    sendVisaApplication = (id, payload, claim) => {
        let relativePath = "visaservice/api/v1/VisaRequestsBackOffice/SendVisa?requestId=" + id;
        return HttpService.SendVisa(payload, relativePath, claim);
    };
    approveVisaApplication = (id, clientToken, idToken, payload) => {
        let relativePath = "visaservice/api/v1/VisaRequestApproval/Approve?id=" + id + "&remark=" + payload.remark;
        return HttpService.updateStatus(payload, relativePath, "", "", payload, "VisaApplication-Approve");
    };
    denyVisaApplication = (id, clientToken, idToken, payload) => {
        let relativePath = "visaservice/api/v1/VisaRequestApproval/Deny?id=" + id + "&remark=" + payload.remark;
        return HttpService.updateStatus(payload, relativePath, "", "", payload, "VisaApplication-Deny");
    };
    IncompeleteApplication = (visaStatusBody, clientToken, idToken, payload) => {
        let relativePath = "visaservice/api/v1/VisaRequestApproval/Incompelete?id=" + visaStatusBody.id + "&remark=" + visaStatusBody.remark;
        return HttpService.updateStatus(visaStatusBody, relativePath, "", "", payload, "VisaApplication-InComplete");
    };
    CompeleteApplication = (visaStatusBody, clientToken, idToken, payload) => {
        let relativePath = "visaservice/api/v1/VisaRequestApproval/Compelete?id=" + visaStatusBody.id + "&remark=" + visaStatusBody.remark;
        return HttpService.updateStatus(visaStatusBody, relativePath, "", "", payload, "VisaApplication-MarkComplete");
    };
    DenyApplication = (visaStatusBody, clientToken, idToken, payload) => {
        let relativePath = "visaservice/api/v1/VisaRequestApproval/Incompelete?id=" + visaStatusBody.id + "&remark=" + visaStatusBody.remark;
        return HttpService.updateStatus(visaStatusBody, relativePath, "", "", payload, "VisaApplication-Deny");
    };
    ApproveApplication = (visaStatusBody, clientToken, idToken, payload) => {
        let relativePath = "visaservice/api/v1/VisaRequestApproval/Approve?id=" + visaStatusBody.id + "&remark=" + visaStatusBody.remark;
        return HttpService.updateStatus(visaStatusBody, relativePath, "", "", payload, "VisaApplication-Approve");
    };
    undoApproval = (visaStatusBody, clientToken, idToken, payload) => {
        let relativePath = "visaservice/api/v1/VisaRequestApproval/UndoApproval?id=" + visaStatusBody.id + "&remark=" + visaStatusBody.remark;
        return HttpService.updateStatus(visaStatusBody, relativePath, "", "", payload, "VisaApproval-Undo");
    };

    // not used
    // filterNewCompanies = (clientToken, idToken, payload, approvalStatus) => {
    //     let relativePath = "companyservice/api/v1/CompanyApplication/FilterApplications?approvalStatus=1";
    //     var tokenCollection = JSON.parse(localStorage.getItem("tokenCollection"));
    //     return HttpService.getbyApprovalStatus(relativePath, "", "", payload, approvalStatus);
    // };
    // filterApprovedCompanies = (clientToken, idToken, payload, approvalStatus) => {
    //     let relativePath = "companyservice/api/v1/CompanyApplication/FilterApplications?approvalStatus=2";
    //     var tokenCollection = JSON.parse(localStorage.getItem("tokenCollection"));
    //     return HttpService.getbyApprovalStatus(relativePath, "", "", payload, approvalStatus);
    // };
    // filterIncompleteCompanies = (clientToken, idToken, payload, approvalStatus) => {
    //     let relativePath = "companyservice/api/v1/CompanyApplication/FilterApplications?approvalStatus=3";
    //     var tokenCollection = JSON.parse(localStorage.getItem("tokenCollection"));
    //     return HttpService.getbyApprovalStatus(relativePath, "", "", payload, approvalStatus);
    // };
    // filterDeniedCompanies = (clientToken, idToken, payload, approvalStatus) => {
    //     let relativePath = "companyservice/api/v1/CompanyApplication/FilterApplications?approvalStatus=4";
    //     var tokenCollection = JSON.parse(localStorage.getItem("tokenCollection"));
    //     return HttpService.getbyApprovalStatus(relativePath, "", "", payload, approvalStatus);
    // };

    // unused function
    filterVisaApplication = (clientToken, idToken, payload, filtevisa) => {
        let relativePath = "companyservice/api/v1/CompanyApplication/FilterApplications?filtevisa";
        return HttpService.getbyApprovalStatus(filtevisa, relativePath, "", "", payload);
    };

    getvisaAttachments = (id) => {
        let relativePath = "visaservice/api/v1/VisaRequestsBackOffice/GetVisaReport?requestId=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };
    getRecieptAttachments = (id) => {
        let relativePath = "visaservice/api/v1/VisaRequestsBackOffice/GetVisaReceipt?requestId=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };

    updateVisa = (requestData, id, payload, claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/Update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", payload, claim);
    };
    searchVisaBySEDate = (startDate, endDate, status, claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/Search?Status=" + status + "&StartDate=" + startDate + "&EndDate=" + endDate;
        return HttpService.getService(relativePath, "", claim);
    };
    searchAllVisaBySEDate = (startDate, endDate, claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/Search?" + "&StartDate=" + startDate + "&EndDate=" + endDate;
        return HttpService.getService(relativePath, "", claim);
    };
    searchVisa = (startDate, endDate, passport, traveller, reference, email, orderCode, arrivalDate, status, claim) => {
        let relativePath =
            "visaservice/api/v1/VisaRequest/Search?Status=" +
            `${status}` +
            "&StartDate=" +
            startDate +
            "&EndDate=" +
            endDate +
            "&passportNumber=" +
            passport +
            "&TravelerName=" +
            traveller +
            "&referenceNumber=" +
            reference +
            "&Email=" +
            email +
            "&OrderCode=" +
            orderCode +
            "&arrivalDate=" +
            arrivalDate;
        return HttpService.getService(relativePath, "", claim);
    };
    searchVisaReport = (startDate, endDate,nationality, arrivalDate, visaType, claim) => {
        let relativePath =
            "visaservice/api/v1/Report/GeneralVisaReport?VisaType=" +
            `${visaType}` +
            "&From=" +
            startDate +
            "&To=" +
            endDate +
            "&Nationality=" +
            nationality +
            "&ArrivalDate=" +
            arrivalDate;
        return HttpService.getService(relativePath, "", claim);
    };
    searchApprovalUserLogReport = (startDate, endDate,username, claim) => {
    
        let relativePath =
            "visaservice/api/v1/Report/ApprovalUserLogReport?userName=" +
            `${username}` +
            "&From=" +
            startDate +
            "&To=" +
            endDate ;
        return HttpService.getService(relativePath, "", claim);
    };
    searchUserLogReport = (startDate, endDate,username, claim) => {
      
        let relativePath =
            "visaservice/api/v1/Report/GetApprovalHistory?userName=" +
            `${username}` +
            "&fromStartDate=" +
            startDate +
            "&toStartDate=" +
            endDate ;
        return HttpService.getService(relativePath, "", claim);
    };
   getAllReport= (startDate, endDate, claim) => {
          
            let relativePath =
                "visaservice/api/v1/Report/GetApprovalHistory?fromStartDate=" +
                `${startDate}` +
                "&toStartDate=" +
                endDate ;
            return HttpService.getService(relativePath, "", claim);
        };
    searchAllVisa = (startDate, endDate, passport, traveller, reference, email, status, claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/Search?Status=" + `${status}` + "&StartDate=" + startDate + "&EndDate=" + endDate + "&passportNumber=" + passport + "&TravelerName=" + traveller + "&referenceNumber=" + reference + "&Email=" + email;
        return HttpService.getService(relativePath, "", claim);
    };
    searchHistory = (id) => {
        let relativePath = "visaservice/api/v1/VisaRequestsBackOffice/GetVisaStatusHistory?requestId=" + id;
        const claim = localStorage.getItem("DetailClaim");
        return HttpService.getService(relativePath, "", claim);
    };
    getUnsentMailVisaRequests = (status,claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/GetUsentEmail?visaStatus="+status;
        return HttpService.getService(relativePath, "", claim);
    };
    sendVisaRequestEMails = (status,id,claim) => {
        let relativePath = "visaservice/api/v1/ManualEmailSender/SendEmailManually?requestId="+`${id}`+"&visaStatus="+`${status}`;
        return HttpService.postService("",relativePath, "","","", claim);
    };
    sendVisaRequestEMailsByStatus = (status,claim) => {
        let relativePath = "visaservice/api/v1/ManualEmailSender/SendEmailManually?&visaStatus="+`${status}`;
       return HttpService.postService("",relativePath, "","","", claim);
    };
    getUnsenteMailcounts = () => {
        let relativePath = "visaservice/api/v1/ManualEmailSender/CountEmail";
        return HttpService.getService(relativePath, "", "ManualEmailSender-CountEmail");
    };
    searchVisaUnsentEmails = (visaStatus,startDate, endDate, passport, traveller, reference, email, orderCode, arrivalDate, claim) => {
        let relativePath =
            "visaservice/api/v1/VisaRequest/SearchEmail?visaStatus=" +
            `${visaStatus}` +
            "&StartDate=" +
            startDate +
            "&EndDate=" +
            endDate +
            "&passportNumber=" +
            passport +
            "&TravelerName=" +
            traveller +
            "&referenceNumber=" +
            reference +
            "&Email=" +
            email +
            "&OrderCode=" +
            orderCode +
            "&arrivalDate=" +
            arrivalDate;
        return HttpService.getService(relativePath, "", claim);
    };
}
