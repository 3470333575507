import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import CompanyApplicationService from "../../service/Company/CompanyApplicationService";
import "./detail.css";

import SnipperModal from "../modal/SnipperModal";
import { Toast } from "primereact/toast";
import { useAuth } from "../../utilities/authContext";
import { Checkbox } from "primereact/checkbox";
import MasterDataService from "../../service/master-data/MasterDataService";

const Invoice = () => {
    const [displayResponsive, setDisplayResponsive] = useState(false);
    const [remark, setRemark] = useState("");
    const [approvalStatusValue, setApprovalStatusValue] = useState(0);
    const [detailData, setDetailData] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [attachements, setAttachments] = useState([]);
    const [historyDialog, setHistoryDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [incompleteReasons, setIncompleteReasons] = useState();

    const location = useLocation();
    const toast = useRef(null);

    const roles = useAuth();
    const history = useHistory();

    if (location.state !== null && location.state !== undefined) {
        // localStorage.removeItem("selectedCompanyId");
        localStorage.removeItem("selectedCompanyProfileId");
        // localStorage.setItem("selectedCompanyId", location.state.selected.id);
        // localStorage.setItem("selectedCompanyProfileId", location.state.selected.companyProfileId);
    }
    const approvalStatus = {
        0: "Default",
        1: "Requested",
        2: "Approved",
        3: "Incomplete",
        4: "Deny",
    };
    useEffect(() => {
        const companyApplicationService = new CompanyApplicationService();
        companyApplicationService
            .getDetailByReference(localStorage.getItem("companyRef"))
            .then((res) => {
                setDetailData(res.data);
            })

            .catch((error) => {});

    }, []);

    const refreshData = () => {
        setIsLoading(true);
        const applicationService = new CompanyApplicationService();

        applicationService
            .getDetail(localStorage.getItem("companyRef"))
            .then((res) => {
                setDetailData(res.data);
            })
            .catch((error) => {})
            .finally(() => {
                setIsLoading(false);
            });
    };

    const dialogFuncMap = {
        displayResponsive: setDisplayResponsive,
    };

    const formatDate = (date) => {
        return new Date(date).toDateString();
    };

    const onClick = (name, value) => {
        setApprovalStatusValue(value);
        dialogFuncMap[`${name}`](true);

        if (value === 2) {
            localStorage.setItem("DetailClaim", "CompanyApplication-Approve");
        } else if (value === 3) {
            localStorage.setItem("DetailClaim", "BusinessVisa-CompanyDetail");
        } else if (value === 4) {
            localStorage.setItem("DetailClaim", "CompanyApplication-Deny");
        }
    };

    const hideHistoryDialog = () => {
        setHistoryDialog(false);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const changeApplicationStatus = () => {
        dialogFuncMap[`displayResponsive`](false);
        const applicationService = new CompanyApplicationService();
        const activateDeactivateBody = {
            approvalStatus: approvalStatusValue,
            approvalRemark: remark,
            id: Number.parseInt(localStorage.getItem("companyRef")),
        };

        setIsLoading(true);
        applicationService
            .UpdateCompanyApplication(activateDeactivateBody)
            .then((res) => {
                setIsLoading(false);
                toast.current.show({ severity: "success", summary: "Successful", detail: res.message, life: 3000 });
                refreshData();
            })
            .catch((err) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };
    const showHistory = () => {
        setIsLoading(true);
        const applicationService = new CompanyApplicationService();
        applicationService
            .searchHistory(detailData?.id)
            .then((res) => {
                setHistoryData(res.data);
                setIsLoading(false);
                // console.log("historyData:", res.data);
            })
            .catch((error) => {
                setIsLoading(false);
            });
        setHistoryDialog(true);
    };

    const renderFooter = (name) => {
        return (
            <div>
                <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={() => {
                        onHide(name);
                        setRemark("");
                        setSelectedIncompleteReasons([]);
                    }}
                    className="p-button-raised"
                />
                <Button
                    label={approvalStatusValue === 3 ? "Incomplete" : approvalStatusValue === 2 ? "Approve" : "Deny"}
                    icon={approvalStatusValue === 3 ? "pi pi-pencil" : approvalStatusValue === 2 ? "pi pi-check-circle" : "pi pi-ban"}
                    onClick={() => changeApplicationStatus()}
                    disabled={approvalStatusValue === 2 ? false : remark == "" ? true : false}
                />
            </div>
        );
    };

    const [selectedIncompleteReasons, setSelectedIncompleteReasons] = useState([]);
    const onIncompleteReasonChange = (e) => {
        let _selectedIncompleteReasons = [...selectedIncompleteReasons];
        if (e.checked) _selectedIncompleteReasons.push(e.value);
        else _selectedIncompleteReasons = _selectedIncompleteReasons.filter((incompleteReason) => incompleteReason.id !== e.value.id);

        setSelectedIncompleteReasons(_selectedIncompleteReasons);
        let remark = "";
        remark += _selectedIncompleteReasons?.map((reason) => reason?.text);
        setRemark(remark);
    };

    return (
        <div>
            <Dialog
                header="Update Status of Company Application"
                visible={displayResponsive}
                onHide={() => {
                    onHide("displayResponsive");
                    setRemark("");
                    setSelectedIncompleteReasons([]);
                }}
                breakpoints={{ "960px": "75vw" }}
                style={{ width: "40vw" }}
                footer={renderFooter("displayResponsive")}
            >
                {approvalStatusValue !== 1 && approvalStatusValue !== 2 ? (
                    <div className="scrollpanel-demo">
                        <ScrollPanel style={{ width: "100%", height: "120px" }} className="custombar1">
                            {incompleteReasons?.map((incompleteReason) => {
                                return (
                                    <div key={incompleteReason["id"]} className="flex align-items-center">
                                        <Checkbox inputId={incompleteReason["id"]} name="Incomplete Reason" value={incompleteReason} onChange={onIncompleteReasonChange} checked={selectedIncompleteReasons?.some((item) => item.id === incompleteReason.id)} style={{ marginBottom: "0.5rem" }} />
                                        <label htmlFor={incompleteReason["id"]} className="ml-2" style={{ marginBottom: "0.5rem" }}>
                                            {incompleteReason["text"]}
                                        </label>
                                    </div>
                                );
                            })}
                        </ScrollPanel>
                    </div>
                ) : null}
                <h5>Remark</h5>
                <InputTextarea value={remark} onChange={(e) => setRemark(e.target.value)} rows={6} cols={70} autoResize />
            </Dialog>
            <Dialog visible={historyDialog} style={{ width: "600px", minHeight: "300px" }} header="Approval History " modal className="p-fluid" onHide={hideHistoryDialog}>
                {historyData?.map((data) => (
                    <div className="card" key={data?.remark}>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">Status</div>
                                    <div className="text-cyan-500 font-bold mb-3">{data?.status}</div>
                                    <div className="company-name">Remark</div>
                                    <div className="text-cyan-500 font-bold ">{data?.remark}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    {data?.approvalStatus === 2 ? (
                                        <div className="company-name">Approved Date</div>
                                    ) : data?.approvalStatus === 3 ? (
                                        <div className="company-name">InCompleted Date</div>
                                    ) : data.approvalStatus === 4 ? (
                                        <div className="company-name">Denied Date</div>
                                    ) : data.approvalStatus === 1 || data.approvalStatus === 0 ? (
                                        <div className="company-name">Registered Date</div>
                                    ) : null}
                                    <div className="text-cyan-500 font-bold mb-3">{data.registeredDate?.split("T").join(" | ").split(".")[0]} GMT</div>
                                    <div className="company-name">Issued by</div>
                                    <div className="text-cyan-500 font-bold ">{data.registeredBy}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Dialog>

            <div className="grid">
                {isLoading ? <SnipperModal /> : <> {null}</>}
                <Toast ref={toast} />

                <div className="col-12">
                    <div className="card">
                        <h4 className="font-bold">Company Profile</h4>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Company</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData.companyProfile?.name}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Company Category</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData.companyProfile?.companyCategory?.category}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Region</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData.companyProfile?.region}</div>
                                    <div className="company-name">City</div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData.companyProfile?.city}</div>
                                    <div className="company-name">SubCity</div>
                                    <div className="text-cyan-500 font-bold ">{detailData.companyProfile?.subcity}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Zone</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData.companyProfile?.zone}</div>

                                    <div className="text-700 text-lg p-0">Kebele</div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData.companyProfile?.kebele}</div>
                                    <div className="company-name">Woreda</div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData.companyProfile?.woreda}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Phone Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData.companyProfile?.telephone}</div>
                                    <div className="company-name">Email</div>
                                    <div className="text-cyan-500 font-bold ">{detailData.companyProfile?.companyManagerEmailAddress}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h4 className="font-bold">Request Details</h4>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Reference Code</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.companyReferenceCode}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Registration Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{formatDate(detailData?.companyProfile?.startDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Verification Status</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{approvalStatus[detailData?.approvalStatus]}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Business License Issued Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{formatDate(detailData?.companyProfile?.businessLicenseIssuedDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Business License Expiration Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.companyProfile?.businessLicenseExpiryDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Tax Identification Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.companyProfile?.taxPayerIdentificationNumber}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">TIN Certificate Issue Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{formatDate(detailData?.companyProfile?.tinCertificateIssuedDate)}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">TIN Certificate Expiration Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{formatDate(detailData?.companyProfile?.tinCertificateExpiryDate)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h4 className="font-bold">Company Representative</h4>
                        <div className="formgrid grid">
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">First Name</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.userDetail?.firstName}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Last Name</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.userDetail?.lastName}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Email</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.userDetail?.email}</div>
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Phone Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.companyProfile?.telephone}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company">
                                    <div className="company-name">
                                        <p className="text-700 text-lg p-0">Passport Expiry Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{formatDate(detailData?.companyProfile?.passportExpiryDate)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="formgrid grid">
                            <div className="field col-7">
                                <div className="scrollpanel-demo">
                                    <h4 className="font-bold">Company Documents</h4>
                                    <ScrollPanel style={{ height: "300px" }} className="custombar1">
                                        {detailData?.companyProfile?.companyAttachmentsDTO?.map((attach) => (
                                            <div className="col-12" key={attach?.attachmentType}>
                                                <div>
                                                    {attach.fileType === ".pdf" ? (
                                                        <div className="card mb-3">
                                                            <h4>{attach?.attachmentTypename}</h4>
                                                            <a href={attach.attachmentpath} download="passport">
                                                                <Button className="bg-pink-700" icon="pi pi-file-pdf  " iconPos="left" tooltip={attach.attachmentTypename}>
                                                                    {}
                                                                </Button>
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        <div className="card mb-3">
                                                            <h4>{attach?.attachmentTypename}</h4>
                                                            <Image src={attach.attachmentpath} alt="galleria" width={250} preview />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </ScrollPanel>
                                </div>
                            </div>
                            {/* <div className="field col-2"></div> */}
                            <div className="field col-3">
                                {detailData?.approvalStatus === 1 && (roles?.privileges.includes("CompanyApplication-Incomplete") === true || roles?.privileges.includes("CompanyApplication-Approve") === true || roles?.privileges.includes("CompanyApplication-Deny") === true) ? (
                                    <div className="invoice-company">
                                        <h4 className="font-bold">Company Application Actions</h4>
                                        <div className="flex">
                                            {roles?.privileges.includes("CompanyApplication-Approve") === true ? (
                                                <Button label="Approve" className="p p-button-success" icon="pi pi-check-circle" style={{ display: "block", marginBottom: "20px", marginLeft: "8px" }} onClick={() => onClick("displayResponsive", 2)}></Button>
                                            ) : (
                                                <></>
                                            )}
                                            {roles?.privileges.includes("CompanyApplication-Incomplete") === true ? (
                                                <Button label="Incomplete" className="p p-button-warning" icon="pi pi-pencil" style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 3)}></Button>
                                            ) : (
                                                <></>
                                            )}
                                            {roles?.privileges.includes("CompanyApplication-Deny") === true ? (
                                                <Button label="Deny" className="p p-button-danger" icon="pi pi-ban" style={{ display: "block", marginBottom: "20px", marginLeft: "10px" }} onClick={() => onClick("displayResponsive", 4)}></Button>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                ) : detailData?.approvalStatus === 3 && (roles?.privileges.includes("CompanyApplication-Approve") === true || roles?.privileges.includes("CompanyApplication-Deny") === true) ? (
                                    <div className="invoice-company">
                                        <h4 className="font-bold">Company Application Actions</h4>
                                        <div className="flex">
                                            {roles?.privileges.includes("CompanyApplication-Approve") === true ? (
                                                <Button label="Approve" className="p p-button-success" icon="pi pi-check-circle" style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 2)}></Button>
                                            ) : (
                                                <></>
                                            )}
                                            {roles?.privileges.includes("CompanyApplication-Deny") === true ? (
                                                <Button label="Deny" className="p p-button-danger" icon="pi pi-ban" style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 4)}></Button>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* {detailData?.approvalStatus === 1 ? <></>

                            : (
                                <div className="card">
                                    <h4 className="font-bold">Approval Status</h4>
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <div className="invoice-company">
                                                <div className="company-name">Approval Status</div>
                                                <div className="text-cyan-500 font-bold mb-3">{historyData?.status}</div>
                                                <div className="company-name">Remark</div>
                                                <div className="text-cyan-500 font-bold ">{historyData?.remark}</div>
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <div className="invoice-company">
                                                {historyData?.approvalStatus === 2 ?
                                                    (<div className="company-name">Approved Date</div>)
                                                    : historyData?.approvalStatus === 3 ?
                                                        (<div className="company-name">InCompleted Date</div>)
                                                        : historyData.approvalStatus === 4 ?
                                                            (<div className="company-name">Denied Date</div>)
                                                            : null
                                                }
                                                <div className="text-cyan-500 font-bold mb-3">{historyData.registeredDate?.split("T")[0]}</div>
                                                <div className="company-name">Issued by</div>
                                                <div className="text-cyan-500 font-bold ">{historyData.registeredBy}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )} */}
                    </div>
                </div>
            </div>
            {detailData?.approvalStatus === 1 ? (
                <></>
            ) : (
                <div className="field col-3 mt-5">
                    <Button label="View Approval History" style={{ backgroundColor: "#0d4d99" }} onClick={showHistory}></Button>
                </div>
            )}
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Invoice, comparisonFn);
