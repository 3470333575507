import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import eVisaService from "../../service/eVisaService/eVisaService";
import AccountService from "../../service/account/AcountService";
import MasterDataService from "../../service/master-data/MasterDataService";
import { useAuth } from "../../utilities/authContext";
import { Message } from "primereact/message";

const ApprovalUserLogReport = () => {
    let emptyVisa = {
        approved:0,
        dateRange:'',
        denied:0,
        incompelete:0,
        validated:0,
        entryAllowed: 0,
        entryDenied: 0,
        departed:0,
        user:''
    };

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month === 0 ? 11 : month - 1;
    let prevYear = prevMonth === 11 ? year - 1 : year;
    let nextMonth = month === 11 ? 0 : month + 1;
    let nextYear = nextMonth === 0 ? year + 1 : year;

    let minDate = new Date();
    minDate.setMonth(prevMonth);
    minDate.setFullYear(prevYear);

    let maxDate = new Date();
    maxDate.setMonth(nextMonth);
    maxDate.setFullYear(nextYear);

    const [companyTypes, setcompanyTypes] = useState(null);

    const [users, setUsers] = useState([]);
    const userService = new AccountService();

    const [visaFee1, setvisaFee1] = useState(emptyVisa);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedNationality, setselectedNationality] = useState({ id: 0, name: "" });
    const [dates1, setDates1] = useState(null);
    const [dates2, setDates2] = useState(null);
    const [userName, setuserName] = useState("");
    const [VisaType, setVisaType] = useState("");
    const [arrivalDate, setArrivalDate] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);
    const roles = useAuth();

    let [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        userService
        .getUsers("Users-View")
        .then((res) => {
            setUsers(res.data);
        })
        .catch((e) => {
            toast.current.show({ severity: "error", summary: "Error Message", detail: "Couldn't fetch Users", life: 4000 });
        })
        .finally(() => {
            setLoading(false);
        });
        
    }, []);
    const cols = [
        { field: 'user', header: 'User Name' },
        { field: 'validated', header: 'Validated' },
        { field: 'approved', header: 'Approved' },
        { field: 'denied', header: 'Denied' },
        { field: 'incompelete', header: 'Incompelete' },
        { field: 'entryAllowed', header: 'Entry Allowed' },
        { field: 'entryDenied', header: 'Entry Denied' },
        { field: 'departed', header: 'Departed' },
        { field: 'dateRange', header: 'Date Date Interval' },
       
    ];
   

    const exportCSV = () => {
        dt.current.exportCSV();
    };
    
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
   
    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable(exportColumns, companyTypes);
                doc.save('EvisaReport.pdf');
            });
        });
    };

    const approvedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">approved</span>
                {rowData?.approved}
            </>
        );
    };
    const deniedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">denied</span>
                {rowData?.denied}
            </>
        );
    };
    const incompeleteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">incompelete</span>
                {rowData?.incompelete}
            </>
        );
    };
    
    const validatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">validated</span>
                {rowData?.validated}
            </>
        );
    };
    const entryAllowedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">entryAllowed</span>
                {rowData?.entryAllowed}
            </>
        );
    };

    const entryDeniedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">entryDenied</span>
                {rowData?.entryDenied}
            </>
        );
    };
    const departedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">departed</span>
                {rowData?.departed}
            </>
        );
    };

    const userBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">user Name</span>
                {rowData?.user}
            </>
        );
    };

    
    const dateRangeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">dateRange Date</span>
                {rowData.dateRange?.split("T")[0]}
            </>
        );
    };
    
  
    const FormatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };
    const header = (
        <div className="flex ">
            <div className="inline-flex flex-wrap flex-gap">
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="startDate" className="mb-2 mt-2">
                            Start Date
                        </label>
                        <Calendar
                            id="startDate"
                            value={dates1}
                            onChange={(e) => {
                                setDates1(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            maxDate={dates2}
                        />
                    </span>
                </span>
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            value={dates2}
                            onChange={(e) => {
                                setDates2(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            minDate={dates1}
                        />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                           User Name
                        </label>
                         <Dropdown value={userName} onChange={(e) => setuserName(e.target.value)} options={users} optionLabel="username" placeholder="Select a Username" filter />
                    </span>
                </span>
                <span>                  
                </span>
                <Button label="Search" icon="pi pi-search" className="mt-5" style={{ minWidth: 200, height: 42, backgroundColor: "#0D4D99" }} onClick={(e) => SearchAll(dates1, dates2, userName)} />
                {/* <Button label="export pdf"  type="button" icon="pi pi-file-pdf"  className="mt-5"  severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" /> */}
                <Button label="export Csv" icon="pi pi-file-excel" className="mt-5" onClick={exportCSV} />
            </div>
        </div>
    );

    const SearchAll = (dates1, dates2,userName ) => {
        const visaService = new eVisaService();
        let startDate = FormatDate(dates1);
        let endDate = FormatDate(dates2);
        setLoading(true);
        visaService.searchApprovalUserLogReport(startDate === "1970-01-01" ? "" : startDate, endDate === "1970-01-01" ? "" : endDate, userName.username, "ApprovalUserLogReport-View")
            .then((res) => {
                setLoading(false);
                setcompanyTypes([res.data.payload]);
            })
            .catch((err) => {
                setLoading(false);
                setcompanyTypes(null);
            });
    };
    return (
        <>
            <Toast ref={toast} />
            {/* {loading ? <SnipperModal /> : <> {null}</>} */}

            <div className="grid crud-demo">
                <h4>
                    <Message severity="info" text="Start Date, End Date, and Username is mandatory search terms." />
                </h4>

                <div className="col-12">
                    <DataTable
                        ref={dt}
                        value={companyTypes}
                        //onSelectionChange={(e) => setselectedcompanies(e.value)}
                        dataKey="id"
                        paginator
                        rows={5}
                        loading={loading}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} All Visa Requests"
                        scrollable
                        globalFilter={globalFilter}
                        emptyMessage="No Visa Requests found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column field="validated" header="Validated" body={validatedBodyTemplate}   sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="approved" header="Approved" body={approvedBodyTemplate} sortable   alignFrozen="left" style={{ minWidth: "14rem" }}></Column>
                        <Column field="incompelete" header="Incompelete" body={incompeleteBodyTemplate}  sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="denied" header="Denied" body={deniedBodyTemplate} sortable   style={{ minWidth: "14rem" }}></Column>
                        <Column field="entryAllowed" header="Entry Allowed" body={entryAllowedBodyTemplate}   sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="entryDenied" header="Entry Denied" body={entryDeniedBodyTemplate}   sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="departed" header="Departed" body={departedBodyTemplate}   sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="user" header="User Name" body={userBodyTemplate}   sortable style={{ minWidth: "14rem" }}></Column>
                        <Column field="dateRange" header="Date Interval" body={dateRangeBodyTemplate}   sortable style={{ minWidth: "14rem" }}></Column>

                    </DataTable>
                </div>
            </div>
        </>
    );
};
export default ApprovalUserLogReport;
