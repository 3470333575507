import axiosInstance from "../../utilities/axios";

class HttpService {
    static postService = (requestData, relativePath, clientToken, idToken, queryString = "", clientClaim) => {
        return axiosInstance.post(relativePath + queryString, requestData,
            {
                headers: {
                    clientClaim: clientClaim,
                    idToken: localStorage.getItem("idToken"),
                    accessToken: localStorage.getItem('accessToken')
                }
            }
        );
    };

    static putService = (requestData, relativePath, clientToken, idToken, queryString = "", clientClaim) => {
        return axiosInstance.put(relativePath + queryString, requestData,
            {
                headers: {
                    clientClaim: clientClaim,
                    idToken: localStorage.getItem("idToken"),
                    accessToken: localStorage.getItem('accessToken')
                }
            });
    };

    static activateDeactivateService = (activateDeactivateBody, relativePath, clientToken, idToken, queryString = "", clientClaim) => {
        return axiosInstance.put(relativePath, activateDeactivateBody, {
            headers: {
                clientClaim: clientClaim,
                idToken: localStorage.getItem("idToken"),
                accessToken: localStorage.getItem('accessToken')
            }
        });
    };

    static updateStatus = (activateDeactivateBody, relativePath, clientToken, idToken, queryString = "", clientClaim) => {
        return axiosInstance.put(relativePath, activateDeactivateBody, {
            headers: {
                clientClaim: clientClaim,
                idToken: localStorage.getItem("idToken"),
                accessToken: localStorage.getItem('accessToken')
            }
        });
    };

    static SendVisa = (activateDeactivateBody, relativePath, clientClaim) => {
        return axiosInstance.post(relativePath, activateDeactivateBody, {
            headers: {
                clientClaim: clientClaim,
                idToken: localStorage.getItem("idToken"),
                accessToken: localStorage.getItem('accessToken')
            }
        });
    };

    static getService = (relativePath, queryString = "", clientClaim) => {
        return axiosInstance.get(relativePath, {
            params: queryString, headers: {
                clientClaim: clientClaim,
                idToken: localStorage.getItem("idToken"),
                    accessToken: localStorage.getItem('accessToken')
            }
        });
    };
    static getbyvisaStatus = (relativePath, queryString = "", approvalStatus, clientClaim) => {
        return axiosInstance.get(
            relativePath,
            {
                params: queryString, headers: {
                    clientClaim: clientClaim,
                    idToken: localStorage.getItem("idToken"),
                    accessToken: localStorage.getItem('accessToken')
                }
            }
        );
    };

    static patchService = (requestBody, relativePath, clientClaim) => {
        return axiosInstance.patch(relativePath, requestBody, {
            headers: {
                clientClaim: clientClaim,
                idToken: localStorage.getItem("idToken"),
                accessToken: localStorage.getItem('accessToken')
            }
        });
    };

    static getbyApprovalStatus = (relativePath, queryString = "", approvalStatus, clientClaim) => {
        return axiosInstance.get(
            relativePath,
            {
                params: queryString, headers: {
                    clientClaim: clientClaim,
                    idToken: localStorage.getItem("idToken"),
                    accessToken: localStorage.getItem('accessToken')
                }
            }
        );
    };

    static updateExtensionStatus = (Id, approval, relativePath, clientToken, idToken, queryString = "", clientClaim) => {
        return axiosInstance.put(relativePath, Id, approval, {
            headers: {
                clientClaim: clientClaim,
                idToken: localStorage.getItem("idToken"),
                accessToken: localStorage.getItem('accessToken')
            }
        });
    };
    static updateExtensionStatusIncomplete = (Id, approval, reason, relativePath, clientToken, idToken, queryString = "", clientClaim) => {
        return axiosInstance.put(relativePath, Id, approval, reason, {
            headers: {
                clientClaim: clientClaim,
                idToken: localStorage.getItem("idToken"),
                accessToken: localStorage.getItem('accessToken')
            }
        });
    };

    static deleteService = (
        relativePath,
        queryString = "",
        clientClaim
    ) => {
        return axiosInstance.delete(
            relativePath,
            {
                params: queryString, headers: {
                    clientClaim: clientClaim,
                    idToken: localStorage.getItem("idToken"),
                    accessToken: localStorage.getItem('accessToken')
                }
            }
        );
    };
}

export default HttpService;
