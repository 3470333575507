import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';

import SnipperModal from "../../modal/SnipperModal";
import axiosInstance, { refreashSystemToken } from "./../../../utilities/axios";
import { toggleAuth, useAuth } from "../../../utilities/authContext";

export const Login = () => {
    const [forgotPassword, setForgotPassword] = useState(false);

    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null || accessToken === "") {
        refreashSystemToken();
    }
    let [loading, setLoading] = useState(false);

    const [loginObj, setLoginObj] = useState({
        userName: "",
        password: "",
    });
    const [email, setEmail] = useState("");
    const [username, setuserName] = useState("");

    const history = useHistory();

    const toast = useRef(null);

    const auth = useAuth()

    const showError = () => {
        toast.current?.show({ severity: 'error', summary: 'Error Message', detail: 'Invalid Username or Password', life: 3000 });
    }

    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        if (loginObj.password !== "" && loginObj.userName !== "") {
            setButtonDisabled(false);
        }
        if (loginObj.password === "" || loginObj.userName === "") {
            setButtonDisabled(true);
        }
    }, [loginObj])

    const handleLoginSubmit = () => {
        if (loginObj.password !== "" && loginObj.userName !== "") {
            setLoading(true);
            axiosInstance
                .post("identity-server/api/v1/User/Login", {
                    username: loginObj.userName,
                    password: loginObj.password,
                }, {
                    headers: {
                        accessToken: localStorage.getItem('accessToken')
                    }
                })
                .then((resp) => {
                    const idToken = resp.data.idToken;
                    const refreashToken = resp.data?.refreshToken;
                    const fullname = resp.data.firstName + " " + resp.data.lastName;
                    const username = resp.data.username
                    const roles = []
                    resp.data?.roles.map((value) => {
                        value?.claims?.map((ro) => {
                            const claim = ro?.claim
                            roles.push(claim);
                        })
                    })

                    const tokenCollection = {
                        idToken: idToken,
                        clientToken: accessToken,
                    };

                    localStorage.setItem("fullname", fullname);
                    localStorage.setItem("username", username)
                    localStorage.setItem("userRefreashToken", refreashToken);
                    localStorage.setItem("roles", roles.join(","))
                    localStorage.setItem("idToken", idToken)
                    // localStorage.setItem("tokenCollection", JSON.stringify(tokenCollection));

                    toggleAuth(auth)

                    history.push("/dashboard");
                    setLoading(false);
                })
                .catch((err) => {
                    // console.log("Login Error: ", err);
                    showError()
                    setLoading(false);
                });
        }
    };

    const handleForgotPassword = () => {
        setForgotPassword(true);
    };

    const handleRecoverPassword = () => {
        debugger;
        if (username !== null || username !== "") {
            setLoading(true);
            debugger;
            axiosInstance
                .get(`/identity-server/api/v1/Password/ForgotPassword?userName=${username}`, {
                    headers: { accessToken: localStorage.getItem("accessToken") }
                })
                .then((resp) => {
                    toast.current.show({ severity: 'success', summary: 'Success Message', detail: "Recovery link sent to " + email, life: 4000 })
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: "Error while sending recovery link", life: 4000 })
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setLoginObj((prevState) => ({
            ...prevState,

            [name]: value,
        }));
    };

    return (
        <div className="login-body">
            <Toast ref={toast} />
            <div className="body-container">
                <div className="grid">
                    <div className="sm:col-12 md:col-4"></div>
                    <div className="sm:col-12 md:col-4 p-0">
                        <div className="login-wrapper mt-12">
                            <div className="login-container card">
                                {loading ? <SnipperModal /> : <> {null}</>}
                                <div className="grid p-fluid justify-center">
                                    <div className="xs:col-12 col-12 ">
                                        <img src="assets/layout/images/logo.png" alt="logo" />
                                        {!forgotPassword ? (
                                            <>
                                                <p className="text-left pl-2 mt-8" style={{ fontSize: "1.5rem" }}>
                                                    <strong>Note</strong>: <small>Only a person who has an account is allowed to sign in! </small>
                                                </p>
                                                <div className="col-12 login-input pl-2">
                                                    <InputText name="userName" onInput={(e) => handleChange(e)} required placeholder="Username" />
                                                </div>

                                                <div className="col-12 login-input pl-2">
                                                    <InputText name="password" type="password" required onInput={(e) => handleChange(e)} placeholder="********" />
                                                </div>
                                                <div className="col-12">
                                                    <Button label="Sign In" disabled={buttonDisabled} onClick={() => handleLoginSubmit()} />
                                                </div>
                                                <div className="col-12 text-left">
                                                    <button type="text" className="p-link" onClick={() => handleForgotPassword()}>
                                                        Forgot Password?
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-left pl-2 mt-8" style={{ fontSize: "1.5rem" }}>
                                                    <strong>Note</strong>: <small>If you forgot your account, please enter the email the account created with and click recover my password button.</small>
                                                </p>
                                                <div className="col-12 login-input">
                                                    <InputText name="username" onInput={(e) => setuserName(e.target.value)} required placeholder="userName" />
                                                </div>
                                                <div className="col-12">
                                                    <Button label="Recover My Password" onClick={() => handleRecoverPassword()} />
                                                </div>
                                                <div className="col-12 text-left">
                                                    <button type="text" className="p-link" onClick={() => setForgotPassword(false)}>
                                                        Back to Sign In
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sm:col-12 md:col-4"></div>
                </div>
            </div>
        </div>
    );
};
