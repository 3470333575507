
import React, { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox"
import { InputTextarea } from "primereact/inputtextarea";
import { FilterMatchMode, FilterOperator } from "primereact/api";

import VisaService from '../../../service/VisaValidity/VisaService';
import AccountService from "../../../service/account/AcountService";
import SnipperModal from "../../modal/SnipperModal";
import { useAuth } from "../../../utilities/authContext";
import VisaFeeService from "../../../service/master-data/visaFeeService";


export default function VisaType() {
    const emptyvisatypeModel = {
        id: 0,
        suffix: '',
        name: '',
        description: '',
        recordStatus: 0,
        visaValidities: [],
        validities: '',
        isBusiness: true,
        needExtension:true

    }
    const visatypeBody = {
        id: 0,
        name: '',
        suffix: '',
        description: '',
        visaValidities: [],
        isBusiness: true,
        needExtension:true
    };
    const activateDeactivateBody = {
        status: 0,

    };
    const status = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const [visatype, setvisatype] = useState(emptyvisatypeModel)
    const [visatypes, setvisatypes] = useState([]);
    const [validities, setvalidities] = useState([]);
    const [selectedvisatypes, setSelectedvisatypes] = useState([]);
    const [isBusiness, setIsBusiness] = useState(true);
    const [needExtension, setneedExtension] = useState(true);
    

    const [visatypeDialog, setvisatypeDialog] = useState(false);
    const [newvisatypeDialog, setNewvisatypeDialog] = useState(false);
    const [changeStatusDialog, setChangeStatusDialog] = useState(false);
    const [deletevisatypeDialog, setDeletevisatypeDialog] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingForUpdate, setLoadingForUpdate] = useState(true);
    const [selectAll, setSelectAll] = useState(false)
    const [performingAction, setPerformingAction] = useState(false);

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    // const [submitted, setSubmitted] = useState(false);
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'description': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const { control, formState: { errors }, handleSubmit, reset } = useForm({
        defaultValues: { ...emptyvisatypeModel }
    })

    const roles = useAuth()

    const toast = useRef(null);
    const visaService = new VisaService();

    useEffect(() => {
        visaService.getVisaType("VisaTypes-View")
            .then((res) => {

                setvisatypes(res.data)

            }).catch((error) => {
            }).finally(() => {
                setLoading(false);
            });

        visaService.getVisaValidity("VisaTypes-View")
            .then((res) => {
                setvalidities(res.data);
            }).catch((e) => {
                setvalidities([])
            })
    }, [loading])

    const getvisatypeForUpdating = (id) => {
        visaService.getSingleVisatype(id, "VisaTypes-View")
            .then((res) => {
                const visatypes = res.data?.visaValidities.map((value) => {
                    return value.id
                })
                setSelectedvisatypes(visatypes)
            }).finally(() => {
                setLoadingForUpdate(false);
            })
    }

    const onvisatypeChange = (e) => {
        let _validities = [...selectedvisatypes];

        if (e.checked)
            _validities.push(e.value);
        else
            _validities.splice(_validities.indexOf(e.value), 1);

        // console.log(_validities)

        setSelectedvisatypes(_validities);
    }

    const checkAllSelection = () => {
        if (validities.length === selectedvisatypes.length) {
            setSelectAll(true)
        } else {
            setSelectAll(false);
        }
    }

    useEffect(() => {
        checkAllSelection()
    }, [selectedvisatypes])
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < visatypes.length; i++) {
            if (visatypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };
    const onSelectAllChange = (e) => {
        if (e.checked) {
            let _validities = []
            for (let i = 0; i < validities.length; i++) {
                _validities.push(validities[i].id)
            }

            setSelectedvisatypes(_validities)
        }
        else
            setSelectedvisatypes([]);

        setSelectAll(e.checked)
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        const _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value)
    }

    const showNewvisatypeDialog = () => {
        setvisatype(emptyvisatypeModel)
        reset(emptyvisatypeModel)
        setLoadingForUpdate(false)
        setNewvisatypeDialog(true)
        setvisatypeDialog(true)
    }
    const showEditvisatypeDialog = (_visatype) => {
        setvisatype(_visatype)
        reset(_visatype)
        getvisatypeForUpdating(_visatype.id)
        setIsBusiness(_visatype?.isBusiness)
        setneedExtension(_visatype?.needExtension)
        setNewvisatypeDialog(false)
        setvisatypeDialog(true)

        // console.log(_visatype)
    }

    const showConfirmDeleteDialog = (_visatype) => {
        setvisatype(_visatype)
        setDeletevisatypeDialog(true);
    }

    const showChangeStatusDialog = (_visatype) => {
        setvisatype(_visatype);
        setChangeStatusDialog(true);
    }

    const hidevisatypeDialog = () => {
        setSelectedvisatypes([]);
        setPerformingAction(false)
        setvisatypeDialog(false);
        setLoadingForUpdate(true);
    }

    const hidevisatypeDeleteDialog = () => {
        setDeletevisatypeDialog(false);
    }

    const addvisatype = (data) => {

        let _visatype = { ...data };
        visatypeBody.name = _visatype.name;
        visatypeBody.isBusiness = isBusiness;
        visatypeBody.needExtension = needExtension;
        visatypeBody.suffix = _visatype.suffix;
        visatypeBody.description = _visatype.description;
        visatypeBody.visaValidities = selectedvisatypes;

        setPerformingAction(true)

        visaService.createVisaType(visatypeBody, "VisaTypes-Add")
            .then((res) => {
                setLoading(true)
                toast.current.show({
                    severity: "success",
                    summary: "Success Message",
                    detail: "Successfully added a new visa type",
                    life: 4000
                })
            }).catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: `${err.response.data.errors[0]}`, life: 4000 });
            }).finally(() => {
                setSelectedvisatypes([]);
                setvisatypeDialog(false);
                setvisatype(emptyvisatypeModel);
                // setvisatypes(data)
                setPerformingAction(false);
            })
    }
    const editvisatype = (data) => {
        // console.log(selectedvisatypes)
        let _visatype = data
        const index = data.id
        visatypeBody.id = visatype.id;
        visatypeBody.name = _visatype.name;
        visatypeBody.suffix = _visatype.suffix;
        visatypeBody.isBusiness = isBusiness;
        visatypeBody.needExtension = needExtension;
        visatypeBody.description = _visatype.description;
        visatypeBody.visaValidities = selectedvisatypes;

        setPerformingAction(true)

        visaService.updateVisaType(visatypeBody, "VisaTypes-Edit")
            .then((res) => {

                toast.current.show({ severity: 'success', summary: 'Success Message', detail: "Visa type updated successfully", life: 4000 });
                setLoading(true)
            }).catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Error updating visatype", life: 4000 });
            }).finally(() => {
                setSelectedvisatypes([]);
                setvisatypeDialog(false);
                setvisatype(emptyvisatypeModel)
                setPerformingAction(false);
            })
    }

    const deletevisatype = () => {
        setPerformingAction(true);

        if (visatype.id) {
            visaService.deleteVisaType(
                visatype.id, "VisaTypes-Delete"
            ).then((res) => {
                toast.current.show({ severity: 'success', summary: 'Success Message', detail: "Visa type Successfully Deleted", life: 4000 });
                setLoading(true)
            }).catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Error deleting visatype", life: 4000 });
            }).finally(() => {
                setDeletevisatypeDialog(false);
                setvisatype(emptyvisatypeModel)
                setPerformingAction(false);
            })
        }
    }
    const refreshVisaType = () => {

        visaService.getVisaType("VisaTypes-View")
            .then((res) => {
                setvisatypes(res.data);
            })
            .catch((error) => { });
    };
    const changeStatus = () => {
        //setPerformingAction(true)

        let _visatypes = [...visatypes];
        let _visatype = { ...visatype };
        if (visatype.id) {
            const index = findIndexById(visatype.id);

            _visatypes[index] = _visatype;

            activateDeactivateBody.id = visatype.id;
            if (visatype.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            } else activateDeactivateBody.status = 1;


            visaService.activateDeactivateVisatype(activateDeactivateBody, "VisaTypes-ChangeStatus")
                .then((res) => {

                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Visa type Activated" : "Visa  type Deactivated", life: 3000 });
                    refreshVisaType();
                })
                .catch((err) => {
                    setLoading(false)
                    toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Error While Updating Visa type status", life: 3000 });

                });
        }
        setvisatypes(_visatypes);
        setChangeStatusDialog(false);
        setvisatype(emptyvisatypeModel);
    };

    const addNewvisatypeBtn = () => {
        let template;
        template = roles?.privileges.includes("VisaTypes-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={showNewvisatypeDialog} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }

    const searchInput = () => {
        return (
            <span className='p-input-icon-left'>
                <i className='pi pi-search' />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search ..." />
            </span>
        )
    }
    const BussinessStatusBody = (rowData) => {
        const status = rowData.isBusiness

        if (status === 0) {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Yes</span>

        } else {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >No</span>

        }
    }
    const headerRender = () => {
        return (
            <div className='md:flex justify-content-between align-items-center'>
                <h5 className='m-0'>Manage visatypes</h5>
                <span className='p-input-icon-left'>
                    <i className='pi pi-search' />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="visatype" />
                </span>
            </div>
        )
    }



    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={status} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("VisaTypes-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning pl-1 mr-2" onClick={() => showEditvisatypeDialog(rowData)} /> : <></>}
                {roles.privileges.includes("VisaTypes-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger pl-1 mr-2" onClick={() => showConfirmDeleteDialog(rowData)} /> : <></>}
                {roles.privileges.includes("VisaTypes-ChangeStatus") === true ?
                    (rowData.recordStatus !== 1 ? <Button icon="pi pi-lock" className="p-button-rounded p-button-info pl-1" onClick={() => showChangeStatusDialog(rowData)} /> :

                        <Button icon="pi pi-lock-open" className="p-button-rounded p-button-info pl-1" onClick={() => showChangeStatusDialog(rowData)} />) : <></>}
            </div>
        );
    };

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Visa Name</span>
                {rowData.name}

            </>
        );
    }
    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description </span>
                {rowData.description}
            </>
        );
    }

    const suffixBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Visa Code</span>
                {rowData.suffix}
            </>
        );
    }
    const bussinesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">isBusiness</span>

                {rowData.isBusiness ? "true" : "false"}

            </>
        );
    }
    const extandableBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">is extendable</span>

                {rowData.needExtension ? "true" : "false"}

            </>
        );
    }
    // const visaValiditiesBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Visa Validities</span>
    //             {rowData.visaValidities}
    //         </>
    //     );
    // }
    const visatypeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hidevisatypeDialog} />
            {performingAction ? <Button label="Submit" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={newvisatypeDialog ? handleSubmit(addvisatype) : handleSubmit(editvisatype)} />}
        </>
    );

    const deletevisatypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hidevisatypeDeleteDialog} />
            {performingAction ? <Button label="Submit" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deletevisatype} />}
        </>
    );

    const changeStatusvisatypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={() => setChangeStatusDialog(false)} />
            {performingAction ? <Button label="Submit" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={changeStatus} />}
        </>
    );
    return (
        <div className="card">
            {loading ? <SnipperModal /> : <> {null}</>}
            <Toast ref={toast} />
            <Toolbar className="mb-4" left={addNewvisatypeBtn} right={searchInput} />
            <DataTable
                value={visatypes}
                paginator
                // header={headerRender}
                rows={5}
                rowsPerPageOptions={[5, 10, 15]}
                dataKey="id"
                rowHover
                filters={filters}
                filterDisplay="menu"
                loading={loading}
                responsiveLayout="scroll"
                scrollable
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                emptyMessage="No Visa Type found."
                currentPageReportTemplate="Showing {first} - {last} of {totalRecords} visatypes">
                <Column field="name" header="Visa Type Name" sortable body={nameBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                <Column field="suffix" header="Visa Type Suffx" sortable body={suffixBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                <Column field="description" header="Descriptions" sortable body={descriptionBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column>
                <Column field="recordStatus" header="Status" body={recordStatusBody} sortable filter filterElement={statusFilterTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>
                <Column field="isBusiness" header="Is Business" body={bussinesBodyTemplate} sortable filter filterElement={statusFilterTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>
                <Column field="needExtension" header="Is Extendable" body={extandableBodyTemplate} sortable filter filterElement={statusFilterTemplate} style={{ minWidth: "20rem" }} headerStyle={{ minWidth: "20rem" }}></Column>

                {/* <Column field="visaValidities" header="Visa Validities" sortable  body={visaValiditiesBodyTemplate}  style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column> */}

                {(roles.privileges.includes("VisaTypes-Edit") === true ||
                    roles.privileges.includes("VisaTypes-Delete") === true ||
                    roles.privileges.includes("VisaTypes-ChangeStatus") === true) ? <Column body={actionBodyTemplate} header="Action" style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column> : <></>}
            </DataTable>

            <Dialog visible={deletevisatypeDialog} style={{ width: "450px" }} header="Confirm" modal footer={deletevisatypeDialogFooter} onHide={hidevisatypeDeleteDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {visatype && (
                        <span>
                            Are you sure you want to delete <b>{visatype.name}</b> visatype?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={changeStatusDialog} style={{ width: "450px" }} header="Confirm" modal footer={changeStatusvisatypeDialogFooter} onHide={() => setChangeStatusDialog(false)}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {visatype && (
                        <span>
                            Are you sure you want to <b>{visatype.recordStatus === 1 ? "Activate" : "Deactivate"} {visatype.name}</b> visatype?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={visatypeDialog} style={{ width: "70vw", height: "80vh" }} header={newvisatypeDialog ? "New Visa Type" : "Update Visa Type"} modal className="p-fluid" footer={visatypeDialogFooter} onHide={hidevisatypeDialog}>
                <div className="field">
                    <label htmlFor="name">Name *</label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <InputText id="name" value={field.value} onChange={field.onChange} required autoFocus  {...field} />}
                    />
                    {errors.name?.type === 'required' && <small className="p-error">visatype name is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="suffix">Suffix *</label>
                    <Controller
                        name="suffix"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <InputText {...field} rows={5} cols={30} id="suffix" value={field.value} onChange={field.onChange} required />}
                    />
                    {errors.suffix?.type === 'required' && <small className="p-error">visatype suffix is required.</small>}
                </div>
                <div className="field-checkbox">
                    <Checkbox inputId="binary" checked={isBusiness} onChange={e => setIsBusiness(e.checked)} />
                    <label htmlFor="binary">Is Businness</label>
                </div>
                <div className="field-checkbox">
                    <Checkbox inputId="binary" checked={needExtension} onChange={e => setneedExtension(e.checked)} />
                    <label htmlFor="binary"> Is Extendable</label>
                </div>
                <div className="field">
                    <label htmlFor="Description">Description *</label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <InputTextarea {...field} rows={5} cols={30} id="description" value={field.value} onChange={field.onChange} required />}
                    />
                    {errors.description?.type === 'required' && <small className="p-error">visatype description is required.</small>}
                </div>

                <div className="field col-12">
                    <label htmlFor="Validities">
                        <Checkbox inputId="binary" className="ml-3" checked={selectAll} onChange={onSelectAllChange} />
                        <label htmlFor="binary" className="p-checkbox-label ml-1" >Select All Visa Validities</label>
                    </label>
                    <div className="p-fluid formgrid grid">
                        {loadingForUpdate ? <i className="field pi pi-spin pi-spinner ml-5 mt-5" style={{ 'fontSize': '2em' }}></i> :
                            validities.map((value) => {
                                return (
                                    <div className="field col-4 field-checkbox" key={value.id}>
                                        <Checkbox inputId={value.id} value={value.id} onChange={onvisatypeChange} checked={selectedvisatypes.indexOf(value.id) !== -1}></Checkbox>
                                        <label htmlFor={value.id} className="p-checkbox-label">{value.name}</label>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </Dialog>
        </div>
    )
}