import HttpService from "../shared/HttpService";

export default class CardTypeService {

  getCard = (claim) => {
    let relativePath = "masterdataservice/api/v1/CardType/GetAll";
    return HttpService.getService(relativePath, "", claim)
  }
  createCard = (requestData, payload, claim) => {
    let relativePath = "masterdataservice/api/v1/CardType/Create";
    return HttpService.postService(requestData, relativePath, "", "", payload, claim)
  }
  updateCard = (requestData, id, payload, claim) => {
    let relativePath = "masterdataservice/api/v1/CardType/Update?id=" + id;
    return HttpService.putService(requestData, relativePath, "", "", payload, claim)
  }
  activateDeactivateCard = (id, countryActivateBody, payload, claim) => {
    let relativePath = "masterdataservice/api/v1/CardType/UpdateStatus?id=" + id;
    return HttpService.activateDeactivateService(countryActivateBody, relativePath, "", "", payload, claim)
  }
  deleteCard = (id, claim) => {
    let relativePath = "masterdataservice/api/v1/CardType/Delete?id=" + id;
    return HttpService.deleteService(relativePath, "", claim)
  }
}
