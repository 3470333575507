import HttpService from "../shared/HttpService";

export default class VerificationService {
    getApplicantDetail = (visaNumber, claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/GetByVisaNumber?visaNumber=" + visaNumber;
        return HttpService.getService(relativePath, "", claim);
    };
    getApplicantReference = (referenceNumber, claim) => {
        let relativePath = "visaservice/api/v1/VisaRequest/GetByReferenceNumber?referenceNumber=" + referenceNumber;
        return HttpService.getService(relativePath, "", claim);
    };
    departApplicant = (requestData, claim) => {
        let relativePath = "visaservice/api/ApplicantEntryStatus/Depart";
        return HttpService.putService(requestData, relativePath, "", "", "", claim);
    };
    allowApplicant = (requestData, claim) => {
        let relativePath = "visaservice/api/ApplicantEntryStatus/Allow";
        return HttpService.putService(requestData, relativePath, "", "", "", claim);
    };
    denyApplicant = (requestData, claim) => {
        let relativePath = "visaservice/api/ApplicantEntryStatus/Deny";
        return HttpService.putService(requestData, relativePath, "", "", "", claim);
    };
}
