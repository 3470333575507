import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import extensionService from "../../service/extensionService/extensionService";
import SnipperModal from "../modal/SnipperModal";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useAuth } from "../../utilities/authContext";
import { Message } from "primereact/message";
const IncompleteExtensionApplication = () => {
    let emptyVisa = {
        travellrName: "",
        passportNumber: "",
        arrivalDate: new Date(),
        referenceNumber: "",
        email: "",
        startDate: "",
        noofExtendedDays: 0,
        visaType: "",
        birthCountryDescription: "",
        name: "",
        visaStatus: "",
        firstName: "",
        surName: "",
        visaTypeValidity: [],
    };
    const visaStatus = {
        0: "New",
        1: "Validated",
        2: "Incompelete",
        3: "Approved",
        4: "Denied",
    };
    let [loading, setLoading] = useState(false);
    const [extensionApplications, setextensionApplications] = useState(null);
    const [selectedextensionvisa, setselectedextensionvisa] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [dates1, setDates1] = useState(null);
    const [dates2, setDates2] = useState(null);
    const [passport, setPassport] = useState("");
    const [reference, setReference] = useState("");
    const [traveller, setTraveller] = useState("");
    const [email, setEmail] = useState("");
    const [OrderCode, setOrderCode] = useState("");
    const [searchOptions, setSearchOptions] = useState(null);
    const [searchValue, setSearchValue] = useState();
    const toast = useRef(null);
    const dt = useRef(null);
    const roles = useAuth();

    useEffect(() => {
        const extensionServices = new extensionService();
        setLoading(true);

        extensionServices
            .getRepliedExtension("RepliedExtension-View")
            .then((res) => {
                setextensionApplications(res.data);
            })
            .catch((error) => {
                if (error?.response.status !== 404) {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching incomplete visa extensions.", life: 3000 });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < extensionApplications.length; i++) {
            if (extensionApplications[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };
    const Options = [
        { name: "Passport Number", value: 0 },
        { name: "Reference Number", value: 1 },
        { name: "Traveller Name", value: 2 },
        { name: "Email", value: 3 },
    ];
    const RequestTypes = {
        0: "passportNumber",
        1: "referenceNumber",
        2: "TravelerName",
        3: "Email",
    };
    const FormatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };
    const passportNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Passport Number</span>
                {rowData.visaRequest.passportNumber}
            </>
        );
    };
    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.visaRequest.email}
            </>
        );
    };
    const requestDateTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Arrival Date</span>
                {rowData.startDate.split("T")[0]}
            </>
        );
    };
    const OrderCodeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ReferenceNumber</span>
                {rowData?.orderCode}
            </>
        );
    };
    const birthCountryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Birth Country</span>
                {rowData.visaRequest?.citizenshipDescription}
            </>
        );
    };

    const visaTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Visa Type</span>
                {rowData?.visaRequest?.visaTypeValidity?.name}
            </>
        );
    };
    const referenceNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reference Number</span>
                {rowData?.referenceNumber}
            </>
        );
    };

    const openDetail = (rowData) => {
        localStorage.setItem("selectedTravellerId", rowData?.id);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles?.privileges.includes("IncompleteVisaExtension-Detail") === true ? (
                    <Link
                        to={{ pathname: "/visa-extension/Extension-Detail", state: { selected: rowData } }}
                        onClick={() => {
                            openDetail(rowData);
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button label="Detail" style={{ backgroundColor: "#0d4d99" }} onClick={() => localStorage.setItem("DetailClaim", "IncompleteVisaExtension-Detail")} />
                    </Link>
                ) : (
                    <></>
                )}
            </div>
        );
    };
    const extensionstatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Request Status </span>
                {rowData.visaStatusDescription.toUpperCase()}
            </>
        );
    };

    const travellrNameTemplate = (rowData) => {
        return <>{rowData?.visaRequest?.travelerName}</>;
    };
    const noofExtendedDaysTemplate = (rowData) => {
        return <>{rowData.noofExtendedDays}</>;
    };

    const header = (
        <div className="flex ">
            {/* <h5 className="m-0">New Visa Application</h5> */}
            <div className="inline-flex flex-wrap flex-gap">
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="startDate" className="mb-2 mt-2">
                            Start Date
                        </label>
                        <Calendar
                            id="startDate"
                            value={dates1}
                            onChange={(e) => {
                                setDates1(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            maxDate={dates2}
                        />
                    </span>
                </span>
                <span>
                    <span className="flex flex-column gap-2  ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            value={dates2}
                            onChange={(e) => {
                                setDates2(e.value);
                            }}
                            readOnlyInput
                            showButtonBar
                            showIcon
                            style={{ maxWidth: 200 }}
                            minDate={dates1}
                        />
                    </span>
                </span>
                <span style={{ minWidth: 300 }} className="">
                    <span className="flex flex-column gap-2 ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Traveller Name
                        </label>
                        <InputText value={traveller} onChange={(e) => setTraveller(e.target.value)} />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2 ">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Passport Number
                        </label>
                        <InputText value={passport} onChange={(e) => setPassport(e.target.value)} />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2">
                        <label htmlFor="EndDate" className="mb-2 mt-2">
                            Reference Number
                        </label>
                        <InputText value={reference} onChange={(e) => setReference(e.target.value)} />
                    </span>
                </span>

                <span style={{ minWidth: 300 }} className="">
                    <span className="flex flex-column gap-2">
                        <label className="mb-2 mt-2">Email</label>
                        <InputText value={email} onChange={(e) => setEmail(e.target.value)} />
                    </span>
                </span>
                <span style={{ maxWidth: 200 }} className="">
                    <span className="flex flex-column gap-2">
                        <label className="mb-2 mt-2">Order Code</label>
                        <InputText value={OrderCode} onChange={(e) => setOrderCode(e.target.value)} />
                    </span>
                </span>

                <Button label="Search" icon="pi pi-search" className="mt-5  " style={{ minWidth: 200, height: 42, backgroundColor: "#0D4D99" }} onClick={(e) => SearchAll(dates1, dates2, passport, traveller, reference, email, OrderCode)} />
            </div>
        </div>
    );

    const SearchAll = (dates1, dates2, passport, traveller, reference, email, orderCode) => {
        const visaService = new extensionService();
        let startDate = FormatDate(dates1);
        let endDate = FormatDate(dates2);

        setLoading(true);

        visaService
            .searchVisa(startDate === "1970-01-01" ? "" : startDate, endDate === "1970-01-01" ? "" : endDate, passport, traveller, reference, email, orderCode, "5", "InCompleteVisaExtension-View")
            .then((res) => {
                setLoading(false);
                setextensionApplications(res.data);
            })
            .catch((err) => {
                setLoading(false);
                setextensionApplications(null);
            });
    };
    return (
        <div className="grid crud-demo">
            <h4>
                <Message severity="info" text="Passport Number, Reference Number, Traveler Name, Email, or Request Date are all valid search terms." />
            </h4>

            <div className="col-12">
                <Toast ref={toast} />
                {/* {loading ? <SnipperModal /> : <> {null}</>} */}
                <DataTable
                    ref={dt}
                    value={extensionApplications}
                    selection={selectedextensionvisa}
                    onSelectionChange={(e) => setselectedextensionvisa(e.value)}
                    dataKey="id"
                    paginator
                    rows={5}
                    loading={loading}
                    rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Replied eVisa Extensions"
                    globalFilter={globalFilter}
                    emptyMessage="No Replied Extension found."
                    header={header}
                    scrollable
                    responsiveLayout="scroll"
                >
                    <Column field="visaRequest.travelerName" header="Traveller Name" body={travellrNameTemplate} filter filterPlaceholder="Search by name" sortable frozen style={{ minWidth: "14rem" }}></Column>
                    <Column field="visaRequest.citizenshipDescription" header="Nationality" body={birthCountryBodyTemplate} filter filterPlaceholder="Search by nationality" sortable style={{ minWidth: "14rem" }}></Column>
                    <Column field="visaRequest.passportNumber" header="Passport Number" body={passportNumberBodyTemplate} sortable filter filterPlaceholder="Search by passport number" style={{ minWidth: "14rem" }}></Column>
                    <Column field="referenceNumber" header="Reference Number" setbody={referenceNumberBodyTemplate} sortable filter filterPlaceholder="Search by reference number " frozen style={{ minWidth: "14rem" }}></Column>

                    <Column field="startDate" header="Request Date" body={requestDateTemplate} sortable filter filterPlaceholder="Search by request date" style={{ minWidth: "14rem" }}></Column>
                    <Column field="visaStatusDescription" header="Extension Status" body={extensionstatusBodyTemplate} style={{ minWidth: "14rem" }}></Column>
                    <Column field="noofExtendedDays" header="Extended Days" body={noofExtendedDaysTemplate} sortable filter filterPlaceholder="Search by extended days" style={{ minWidth: "14rem" }}></Column>
                    <Column field="visaRequest.visaTypeValidity.name" header="Visa Type" body={visaTypeBodyTemplate} filter filterPlaceholder="Search by visa type" sortable style={{ minWidth: "14rem" }}></Column>
                    <Column field="orderCode" header="Order Code" body={OrderCodeBodyTemplate} sortable filter filterPlaceholder="Search by order code" style={{ minWidth: "14rem" }}></Column>
                    {roles?.privileges.includes("IncompleteVisaExtension-Detail") === true ? <Column header="Actions" body={actionBodyTemplate} frozen alignFrozen="right"></Column> : <></>}
                </DataTable>
            </div>
        </div>
    );
};
export default IncompleteExtensionApplication;
