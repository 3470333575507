import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import MasterDataService from '../../../service/master-data/MasterDataService';
import { useForm } from 'react-hook-form';
import SnipperModal from "../../modal/SnipperModal";
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useAuth } from '../../../utilities/authContext';

const Nationality = () => {
    let emptyNationalitiesType = {
        id: 0,
        name: '',
        recordStatus: 0,
    };
    const nationaltyBody = {
        id: 0,
        name: '',

    };
    const activateDeactivateBody = {
        status: 0,
    };

    let [loading, setLoading] = useState(false);
    const [nationaltyTypes, setnationaltyTypes] = useState(null);

    const [nationalityUpdateDialog, setNationlaityUpdateDialogDialog] = useState(false);
    const [nationalityDialog, setnationalityDialog] = useState(false);
    const [deleteCountryDialog, setDeleteNationalityDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);

    const [performingAction, setPerformingAction] = useState(false);
    const [nationalitytype, setnationalitytype] = useState(emptyNationalitiesType);
    const [selectednationalites, setselectednationalites] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {

        const masterDataService = new MasterDataService();
        setLoading(true)
        masterDataService.getnationality("Nationality-View")
            .then(res => {
                setLoading(false)
                setnationaltyTypes(res.data)
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching nationlities.", life: 3000 });
            }).finally(() => {
                setLoading(false);
            });
    }, []);
    const openNew = () => {
        setnationalitytype(emptyNationalitiesType);
        setSubmitted(false);
        setnationalityDialog(true);
    }
    const hideNationalityDialog = () => {
        setSubmitted(false);
        setnationalityDialog(false);
    }
    const hideDialog = () => {
        setSubmitted(false);
        setNationlaityUpdateDialogDialog(false);
    }
    const hideDeleteCountryDialog = () => {
        setDeleteNationalityDialog(false);
    }
    const hideActivateDeactivateCountryDialog = () => {
        setActivateDeactivateDialog(false);
    }
    const SubmitNationality = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _nationalitytypes = [...nationaltyTypes];
        let _nationalitytype = { ...nationalitytype };
        nationaltyBody.name = _nationalitytype.name;
        const masterDataService = new MasterDataService();

        masterDataService.createNationality(nationaltyBody, "", "Nationality-Add")
            .then((res) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nationality Created', life: 3000 });
                refresh();
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Nationality already Registered ", life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setnationaltyTypes(_nationalitytypes);
                setnationalityDialog(false);
                setnationalitytype(emptyNationalitiesType);
            })
    }
    const UpdateNationality = () => {
        setPerformingAction(true);
        setSubmitted(true);

        if (nationalitytype.name.trim()) {
            let _nationalitytypes = [...nationaltyTypes];
            let _nationalitytype = { ...nationalitytype };
            if (nationalitytype.id) {
                const index = findIndexById(nationalitytype.id);
                _nationalitytypes[index] = _nationalitytype;
                const masterDataService = new MasterDataService();

                nationaltyBody.name = _nationalitytype.name;
                masterDataService.updateNationalities(nationaltyBody, _nationalitytype.id, "", "Nationality-Edit")
                    .then((res) => {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nationality Updated', life: 3000 });
                    })
                    .catch((err) => {
                        toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Error updating nationlities", life: 3000 });
                    }).finally(() => {
                        setPerformingAction(false);
                        setnationaltyTypes(_nationalitytypes);
                        setNationlaityUpdateDialogDialog(false);
                        setnationalitytype(emptyNationalitiesType);
                    })
            }
        }
    }
    const editCountry = (nationalitytype) => {
        setnationalitytype({ ...nationalitytype });
        setNationlaityUpdateDialogDialog(true);
    }
    const confirmDeleteNationality = (nationalitytype) => {
        setnationalitytype(nationalitytype);
        setDeleteNationalityDialog(true);
    }
    const confirmActivateDeactivateNationality = (nationalitytype) => {
        setnationalitytype(nationalitytype);
        setActivateDeactivateDialog(true);
    }
    const deleteCounrty = () => {
        setPerformingAction(true);

        let _nationalitytypes = nationaltyTypes.filter(val => val.id !== nationalitytype.id);
        const masterDataService = new MasterDataService();
        masterDataService.deleteNationality(nationalitytype.id, "Nationality-Delete")
            .then(res => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Nationality Deleted', life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setnationaltyTypes(_nationalitytypes);
                setDeleteNationalityDialog(false);
                setnationalitytype(emptyNationalitiesType);
            });
    }
    const activateDeactivateNationlaityname = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _nationalitytypes = [...nationaltyTypes];
        let _nationalitytype = { ...nationalitytype };
        if (nationalitytype.id) {
            const index = findIndexById(nationalitytype.id);

            _nationalitytypes[index] = _nationalitytype;
            const masterDataService = new MasterDataService();

            if (nationalitytype.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            }
            else
                activateDeactivateBody.status = 1;

            masterDataService.activateDeactivateNationality(nationalitytype.id, activateDeactivateBody, "", "Nationality-ChangeStatus")
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Nationality Activated" : "Nationality Deactivated", life: 3000 });
                    refresh();
                }).catch((err) => {
                }).finally(() => {
                    setPerformingAction(false);
                    setnationaltyTypes(_nationalitytypes);
                    setActivateDeactivateDialog(false);
                    setnationalitytype(emptyNationalitiesType);
                });
        }

    }
    const refresh = () => {
        const masterDataService = new MasterDataService();
        masterDataService.getnationality("Nationality-View")
            .then((res) => {
                setnationaltyTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < nationaltyTypes.length; i++) {
            if (nationaltyTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _nationalitytype = { ...nationalitytype };
        _nationalitytype[`${name}`] = val;
        setnationalitytype(_nationalitytype);
    }
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("Nationality-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nationlaity Name</span>
                {rowData.name}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("Nationality-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editCountry(rowData)} /> : <></>}
                {roles.privileges.includes("Nationality-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteNationality(rowData)} /> : <></>}
                {roles.privileges.includes("Nationality-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmActivateDeactivateNationality(rowData)} /> : <></>}
            </div>
        );
    }
    const status = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={status} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Nationality</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const updateCountryDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdateNationality} />}
        </>
    );
    const counrtyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideNationalityDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitNationality} />}
        </>
    );
    const deleteCountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeleteCountryDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteCounrty} />}
        </>
    );
    const activateDeactivatecountryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivateCountryDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateNationlaityname} />}
        </>
    );
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}
                    <DataTable ref={dt} filters={filters} value={nationaltyTypes} selection={selectednationalites} onSelectionChange={(e) => setselectednationalites(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Countries" // header={header}
                        globalFilter={globalFilter} emptyMessage="No Countries found." scrollable responsiveLayout="scroll">

                        <Column field="name" header="Nationality Name" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable filter filterElement={statusFilterTemplate} style={{ width: "12rem" }} headerStyle={{ width: "12rem" }}></Column>

                        {(roles.privileges.includes("Nationality-Edit") === true ||
                            roles.privileges.includes("Nationality-Delete") === true ||
                            roles.privileges.includes("Nationality-ChangeStatus") === true) ? <Column header="Actions" body={actionBodyTemplate}></Column> : <></>}
                    </DataTable>
                    <Dialog visible={nationalityDialog} style={{ width: '450px' }} header="Create  Nationality name"
                        modal className="p-fluid" footer={counrtyDialogFooter} onHide={hideNationalityDialog}>

                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={nationalitytype.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !nationalitytype.name })} />
                            {submitted && !nationalitytype.name && <small className="p-error"> Nationality name is required.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={nationalityUpdateDialog} style={{ width: '450px' }} header="Update  Nationality"
                        modal className="p-fluid" footer={updateCountryDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Nationality Name</label>
                            <InputText id="name" value={nationalitytype.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !nationalitytype.name })} />
                            {submitted && !nationalitytype.name && <small className="p-invalid"> Nationality name is required.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteCountryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCountryDialogFooter} onHide={hideDeleteCountryDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {nationalitytype && <span>Are you sure you want to delete <b>{nationalitytype.name}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={activateDeactivateDialog} style={{ width: '450px' }} header="Confirm" modal footer={activateDeactivatecountryDialogFooter} onHide={hideActivateDeactivateCountryDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {nationalitytype &&
                                <span>
                                    Are you sure you want to <b>{nationalitytype.recordStatus === 1 ? "Activate" : "Deactivate"}</b> <b>{nationalitytype.name}</b>?
                                </span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
export default Nationality;