import React, { useRef, useState, useEffect } from "react";
import { classNames } from "primereact/utils";
import { useHistory, useLocation } from "react-router-dom";
import AppTopbar from "./common/AppTopbar";
import AppBreadcrumb from "./common/AppBreadcrumb";
import AppFooter from "./common/AppFooter";
import AppMenu from "./common/AppMenu";
import AppConfig from "./common/AppConfig";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css"; //icons
import "./App.scss";
import Router from "./route/router";
import { useIdleTimer } from "react-idle-timer";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Cookies from "universal-cookie";
import { toggleAuth, useAuth } from "./utilities/authContext";
import { refreshToken } from "./utilities/axios";
import eVisaService  from "./service/eVisaService/eVisaService";
export const WarnningComponent = () => {
    return (
        <>
            <h1>Session Expired!</h1>
        </>
    );
};
const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);

    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [activeTopbarNotificationItem, setActiveTopbarNotificationItem] = useState(null);
    const [menuActive, setMenuActive] = useState(false);
    const [themeColor, setThemeColor] = useState("blue");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(false);
    const [scheme, setScheme] = useState("light");
    const copyTooltipRef = useRef();
    const history = useHistory();
    const cookies = new Cookies();
    let sessionExpiredDialog = false;

    const auth = useAuth();

    const timeout = 600000; // 10 minutes of inactivity session out
    const [remaining, setRemaining] = useState(timeout);
    const [elapsed, setElapsed] = useState(0);
    const [lastActive, setLastActive] = useState(+new Date());
    const [isIdle, setIsIdle] = useState(false);

    const handleOnActive = () => setIsIdle(true);
    const handleOnIdle = () => setIsIdle(true);

    const { getRemainingTime, getLastActiveTime, getElapsedTime } = useIdleTimer({ timeout, onActive: handleOnActive, onIdle: handleOnIdle });

    useEffect(() => {
        setRemaining(getRemainingTime());
        setLastActive(getLastActiveTime());
        setElapsed(getElapsedTime());

        setInterval(() => {
            setRemaining(getRemainingTime());
            setLastActive(getLastActiveTime());
            setElapsed(getElapsedTime());
        }, 36000);
    }, []);

    const logoutUser = () => {
        cookies.remove("refreshToken");
        localStorage.removeItem("userRefreshToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("idToken");
        localStorage.removeItem("tokenCollection");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("fullname");
        localStorage.removeItem("username");
        localStorage.removeItem("roles");
        localStorage.removeItem("selectedCompanyId");
        localStorage.removeItem("selectedCompanyProfileId");
        localStorage.removeItem("selectedVisaId");
        localStorage.removeItem("selectedTravellerId");
        localStorage.removeItem("DetailClaim");
        localStorage.removeItem("userRefreashToken");
    };

    if (isIdle.toString() === "true") {
        sessionExpiredDialog = true;
        logoutUser();
    }

    const sessionExpiredDialogFooter = (
        <div className="flex align-items-center justify-content-center">
            <Button
                label="Ok"
                icon="pi pi-check"
                onClick={() => {
                    logoutUser();
                    history.push("/");
                    auth?.togglePrivileges();
                }}
            />
        </div>
    );
    const getnotification=()=>{
        const visaService = new eVisaService();
        visaService
            .getUnsentMailVisaRequests("GetUsentEmail-view")
            .then((res) => {
                console.log("data", res.data);
            })
            
          
    }
    let menuClick;
    let topbarItemClick;
    let topbarNotificationItemClick;
    useEffect(() => {
        // SystemToken();
        toggleAuth(auth);
    }, [auth]);

    const priveleges = auth?.privileges;
    // const priveleges = localStorage.getItem("roles")?.split(",")

    const menu = [];
    // if (priveleges?.includes("Dashboard-View")) {
    menu.push({
        items: [
            {
                label: "Dashboard",
                priv: "Dashboard-View",
                isShow: true,
                hasSubMenu: false,
                icon: "pi pi-fw pi-home",
                to: "/dashboard",
            },
        ],
    });
    // }

    let accountManagement = [
        { label: "Users", icon: "pi pi-fw pi-user", to: "/account-management/users", priv: "Users-View", isShow: priveleges?.includes("Users-View") ? true : false },
        { label: "Company Users", icon: "pi pi-fw pi-users", to: "/account-management/company-users", priv: "CompanyUsers-View", isShow: priveleges?.includes("CompanyUsers-View") ? true : false },
        { label: "Roles", icon: "pi pi-fw pi-chart-bar", to: "/account-management/roles", priv: "Roles-View", isShow: priveleges?.includes("Roles-View") ? true : false },
    ];

    if (accountManagement.filter((v) => v.isShow === true).length > 0) {
        menu.push({
            items: [
                {
                    label: "Account management",
                    // priv: "AccountManagement-View",
                    icon: "pi pi-fw pi-align-left",
                    isShow: accountManagement.filter((v) => v.isShow === true).length > 0,
                    hasSubMenu: true,
                    items: [
                        { label: "Users", icon: "pi pi-fw pi-user", to: "/account-management/users", priv: "Users-View", isShow: priveleges?.includes("Users-View") ? true : false },
                        { label: "Company Users", icon: "pi pi-fw pi-users", to: "/account-management/company-users", priv: "CompanyUsers-View", isShow: priveleges?.includes("CompanyUsers-View") ? true : false },
                        { label: "Roles", icon: "pi pi-fw pi-chart-bar", to: "/account-management/roles", priv: "Roles-View", isShow: priveleges?.includes("Roles-View") ? true : false },
                    ],
                },
            ],
        });
    }

    let masterdata = [
        { label: "Attachement Type", icon: "pi pi-file-pdf", to: "/masterdata/attachement-type", priv: "AttachementType-View", isShow: priveleges?.includes("AttachementType-View") ? true : false },
        { label: "Visa Attachement", icon: "pi pi-file-pdf", to: "/masterdata/Visa-Attachment", priv: "VisaAttachment-View", isShow: priveleges?.includes("VisaAttachment-View") ? true : false },
        { label: "Passport Type", icon: "pi pi-fw pi-folder-open", to: "/masterdata/passport-type", priv: "PassportType-View", isShow: priveleges?.includes("PassportType-View") ? true : false },
        { label: "Company Category", icon: "pi pi-building", to: "/masterdata/company-categories", priv: "CompanyCategory-View", isShow: priveleges?.includes("CompanyCategory-View") ? true : false },
        { label: "Visa Fee", icon: "pi pi-dollar", to: "/masterdata/visa-fees", priv: "VisaFee-View", isShow: priveleges?.includes("VisaFee-View") ? true : false },
        { label: "Visa Extension Fee", icon: "pi pi-dollar", to: "/masterdata/visa-Extension-fees", priv: "VisaExtensionFee-View", isShow: priveleges?.includes("VisaExtensionFee-View") ? true : false },
        { label: "Card Type", icon: "pi pi-credit-card", to: "/masterdata/card-type", priv: "CardType-View", isShow: priveleges?.includes("CardType-View") ? true : false },
        { label: "Company Attachement", icon: "pi pi-file-pdf", to: "/masterdata/company-attachments", priv: "CompanyAttachement-View", isShow: priveleges?.includes("CompanyAttachement-View") ? true : false },
        { label: "Region", icon: "pi pi-fw pi-map-marker", to: "/masterdata/Region", priv: "Region-View", isShow: priveleges?.includes("Region-View") ? true : false },
        { label: "Nationality", icon: "pi pi-flag", to: "/masterdata/Nationality", priv: "Nationality-View", isShow: priveleges?.includes("Nationality-View") ? true : false },
        { label: "Country", icon: "pi pi-flag-fill", to: "/masterdata/Country", priv: "Country-View", isShow: priveleges?.includes("Country-View") ? true : false },
        { label: "Visa Types", icon: "pi pi-tags", to: "/masterdata/Visa-Type", priv: "VisaTypes-View", isShow: priveleges?.includes("VisaTypes-View") ? true : false },
        { label: "Visa Validity", icon: "pi pi-check-circle", to: "/masterdata/Visa-Validity", priv: "VisaValidity-View", isShow: priveleges?.includes("VisaValidity-View") ? true : false },
        { label: "Accommodation Type", icon: "pi pi-home", to: "/masterdata/accommodation-type", priv: "AccommodationType-View", isShow: priveleges?.includes("AccommodationType-View") ? true : false },
        { label: "Discounted Nationality", icon: "pi pi-fw pi-home", to: "/masterdata/Discounted-Nationality", priv: "DiscountedNationality-View", isShow: priveleges?.includes("DiscountedNationality-View") ? true : false },
        { label: "Notification Messages", icon: "pi pi-fw pi-flag", to: "/masterdata/NotificationMessages", priv: "MarkAsIncomplete-View", isShow: priveleges?.includes("MarkAsIncomplete-View") ? true : false },

        { label: "FAQ", icon: "pi pi-comments", to: "/masterdata/FAQ", priv: "FAQ-View", isShow: priveleges?.includes("FAQ-View") ? true : false },
    ];

    if (masterdata.filter((v) => v.isShow === true).length > 0) {
        menu.push({
            items: [
                {
                    label: "Master Data",
                    // priv: "Masterdata-View",
                    icon: "pi pi-fw pi-database",
                    isShow: masterdata.filter((v) => v.isShow === true).length > 0,
                    hasSubMenu: true,
                    items: [
                        { label: "Attachement Type", icon: "pi pi-file-pdf", to: "/masterdata/attachement-type", priv: "AttachementType-View", isShow: priveleges?.includes("AttachementType-View") ? true : false },
                        { label: "Visa Attachement", icon: "pi pi-file-pdf", to: "/masterdata/Visa-Attachment", priv: "VisaAttachment-View", isShow: priveleges?.includes("VisaAttachment-View") ? true : false },
                        { label: "Passport Type", icon: "pi pi-fw pi-folder-open", to: "/masterdata/passport-type", priv: "PassportType-View", isShow: priveleges?.includes("PassportType-View") ? true : false },
                        { label: "Company Category", icon: "pi pi-building", to: "/masterdata/company-categories", priv: "CompanyCategory-View", isShow: priveleges?.includes("CompanyCategory-View") ? true : false },
                        { label: "Visa Fee", icon: "pi pi-dollar", to: "/masterdata/visa-fees", priv: "VisaFee-View", isShow: priveleges?.includes("VisaFee-View") ? true : false },
                        { label: "Visa Extension Fee", icon: "pi pi-dollar", to: "/masterdata/visa-Extension-fees", priv: "VisaExtensionFee-View", isShow: priveleges?.includes("VisaExtensionFee-View") ? true : false },
                        { label: "Card Type", icon: "pi pi-credit-card", to: "/masterdata/card-type", priv: "CardType-View", isShow: priveleges?.includes("CardType-View") ? true : false },
                        { label: "Company Attachement", icon: "pi pi-file-pdf", to: "/masterdata/company-attachments", priv: "CompanyAttachement-View", isShow: priveleges?.includes("CompanyAttachement-View") ? true : false },
                        { label: "Region", icon: "pi pi-fw pi-map-marker", to: "/masterdata/Region", priv: "Region-View", isShow: priveleges?.includes("Region-View") ? true : false },
                        { label: "Nationality", icon: "pi pi-flag", to: "/masterdata/Nationality", priv: "Nationality-View", isShow: priveleges?.includes("Nationality-View") ? true : false },
                        { label: "Country", icon: "pi pi-flag-fill", to: "/masterdata/Country", priv: "Country-View", isShow: priveleges?.includes("Country-View") ? true : false },
                        { label: "Visa Types", icon: "pi pi-tags", to: "/masterdata/Visa-Type", priv: "VisaTypes-View", isShow: priveleges?.includes("VisaTypes-View") ? true : false },
                        { label: "Visa Validity", icon: "pi pi-check-circle", to: "/masterdata/Visa-Validity", priv: "VisaValidity-View", isShow: priveleges?.includes("VisaValidity-View") ? true : false },
                        { label: "Accommodation Type", icon: "pi pi-fw pi-home", to: "/masterdata/accommodation-type", priv: "AccommodationType-View", isShow: priveleges?.includes("AccommodationType-View") ? true : false },
                        { label: "Discounted Countries", icon: "pi pi-fw pi-flag", to: "/masterdata/Discounted-Nationality", priv: "DiscountedNationality-View", isShow: priveleges?.includes("DiscountedNationality-View") ? true : false },
                        { label: "Notification Messages", icon: "pi pi-fw pi-flag", to: "/masterdata/NotificationMessages", priv: "MarkAsIncomplete-View", isShow: priveleges?.includes("MarkAsIncomplete-View") ? true : false },
                        { label: "FAQ", icon: "pi pi-comments", to: "/masterdata/FAQ", priv: "FAQ-View", isShow: priveleges?.includes("FAQ-View") ? true : false },
                    ],
                },
            ],
        });
    }

    let companyApplication = [
        { label: "New Companies", icon: "pi pi-fw pi-question-circle", to: "/company-application/new-applications", priv: "NewCompany-View", isShow: priveleges?.includes("NewCompany-View") ? true : false },
        { label: "Incomplete Companies", icon: "pi pi-fw pi-filter-fill", to: "/company-application/incomplete-applications", priv: "InCompleteCompany-View", isShow: priveleges?.includes("InCompleteCompany-View") ? true : false },
        { label: "Replied Companies", icon: "pi pi-fw pi-reply", to: "/company-application/replied-applications", priv: "RepliedCompany-View", isShow: priveleges?.includes("RepliedCompany-View") ? true : false },
        { label: "Approved Companies", icon: "pi pi-fw pi-check", to: "/company-application/approved-applications", priv: "ApprovedCompany-View", isShow: priveleges?.includes("ApprovedCompany-View") ? true : false },
        { label: "Denied Companies", icon: "pi pi-fw pi-ban", to: "/company-application/denied-applications", priv: "DeniedCompany-View", isShow: priveleges?.includes("DeniedCompany-View") ? true : false },
        { label: "All Companies", icon: "pi pi-fw pi-list", to: "/company-application/all-applications", priv: "AllCompany-View", isShow: priveleges?.includes("AllCompany-View") ? true : false },
    ];

    if (companyApplication.filter((v) => v.isShow === true).length > 0) {
        menu.push({
            items: [
                {
                    label: "Company Application",
                    // priv: "CompanyApplication-View",
                    icon: "pi pi-fw pi-home",
                    isShow: companyApplication.filter((v) => v.isShow === true).length > 0,
                    hasSubMenu: true,
                    items: [
                        { label: "New Companies", icon: "pi pi-fw pi-question-circle", to: "/company-application/new-applications", priv: "NewCompany-View", isShow: priveleges?.includes("NewCompany-View") ? true : false },
                        { label: "Incomplete Companies", icon: "pi pi-fw pi-filter-fill", to: "/company-application/incomplete-applications", priv: "InCompleteCompany-View", isShow: priveleges?.includes("InCompleteCompany-View") ? true : false },
                        { label: "Approved Companies", icon: "pi pi-fw pi-check", to: "/company-application/approved-applications", priv: "ApprovedCompany-View", isShow: priveleges?.includes("ApprovedCompany-View") ? true : false },
                        { label: "Replied Companies", icon: "pi pi-fw pi-reply", to: "/company-application/replied-applications", priv: "RepliedCompany-View", isShow: priveleges?.includes("RepliedCompany-View") ? true : false },
                        { label: "Denied Companies", icon: "pi pi-fw pi-ban", to: "/company-application/denied-applications", priv: "DeniedCompany-View", isShow: priveleges?.includes("DeniedCompany-View") ? true : false },
                        { label: "All Companies", icon: "pi pi-fw pi-list", to: "/company-application/all-applications", priv: "AllCompany-View", isShow: priveleges?.includes("AllCompany-View") ? true : false },
                    ],
                },
            ],
        });
    }

    let visaRequest = [
        { label: "New Requests", icon: "pi pi-fw  pi-question-circle", to: "/visa-requests/new-requests", priv: "NewVisaApplication-View", isShow: priveleges?.includes("NewVisaApplication-View") ? true : false },
        { label: "Waiting Requests", icon: "pi pi-fw  pi-spinner", to: "/visa-requests/Waiting-requests", priv: "WaitingVisaApplication-View", isShow: priveleges?.includes("WaitingVisaApplication-View") ? true : false },
        { label: "Incomplete Requests", icon: "pi pi-fw pi-filter", to: "/visa-requests/Incomplete-requests", priv: "InCompleteVisaApplication-View", isShow: priveleges?.includes("InCompleteVisaApplication-View") ? true : false },
        { label: "Approved Requests", icon: "pi pi-fw pi-check", to: "/visa-requests/Approved-requests", priv: "ApprovedVisaApplication-View", isShow: priveleges?.includes("ApprovedVisaApplication-View") ? true : false },
        { label: "Denied Requests", icon: "pi pi-fw pi-ban", to: "/visa-requests/Denied-requests", priv: "DeniedVisaApplication-View", isShow: priveleges?.includes("DeniedVisaApplication-View") ? true : false },
        { label: "Replied Requests", icon: "pi pi-fw pi-reply", to: "/visa-requests/Replied-requests", priv: "RepliedVisaApplication-View", isShow: priveleges?.includes("RepliedVisaApplication-View") ? true : false },
        { label: "Unpaid Visa", icon: "pi pi-fw  pi pi-dollar", to: "/unpaid-visa/unpaidVisa", priv: "UnpaidVisa-View", isShow: priveleges?.includes("UnpaidVisa-View") ? true : false },
        { label: "All Requests", icon: "pi pi-fw pi-list", to: "/visa-requests/All-requests", priv: "AllVisaApplication-View", isShow: priveleges?.includes("AllVisaApplication-View") ? true : false },

    ];
    

    if (visaRequest.filter((v) => v.isShow === true).length > 0) {
        menu.push({
            items: [
                {
                    label: "Visa Requests",
                    // priv: "VisaRequests-View",
                    icon: "pi pi-fw pi-check-square",
                    isShow: visaRequest.filter((v) => v.isShow === true).length > 0,
                    hasSubMenu: true,
                    items: [
                        { label: "New Requests", icon: "pi pi-fw  pi-question-circle", to: "/visa-requests/new-requests", priv: "NewVisaApplication-View", isShow: priveleges?.includes("NewVisaApplication-View") ? true : false },
                        { label: "Waiting Requests", icon: "pi pi-fw  pi-spinner", to: "/visa-requests/Waiting-requests", priv: "WaitingVisaApplication-View", isShow: priveleges?.includes("WaitingVisaApplication-View") ? true : false },
                        { label: "Incomplete Requests", icon: "pi pi-fw pi-filter", to: "/visa-requests/Incomplete-requests", priv: "InCompleteVisaApplication-View", isShow: priveleges?.includes("InCompleteVisaApplication-View") ? true : false },
                        { label: "Approved Requests", icon: "pi pi-fw pi-check", to: "/visa-requests/Approved-requests", priv: "ApprovedVisaApplication-View", isShow: priveleges?.includes("ApprovedVisaApplication-View") ? true : false },
                        { label: "Denied Requests", icon: "pi pi-fw pi-ban", to: "/visa-requests/Denied-requests", priv: "DeniedVisaApplication-View", isShow: priveleges?.includes("DeniedVisaApplication-View") ? true : false },
                        { label: "Replied Requests", icon: "pi pi-fw pi-reply", to: "/visa-requests/Replied-requests", priv: "RepliedVisaApplication-View", isShow: priveleges?.includes("RepliedVisaApplication-View") ? true : false },
                        { label: "Unpaid Requests", icon: "pi pi-fw  pi pi-dollar", to: "/unpaid-visa/unpaidVisa", priv: "UnpaidVisa-View", isShow: priveleges?.includes("UnpaidVisa-View") ? true : false },
                        { label: "All Requests", icon: "pi pi-fw pi-list", to: "/visa-requests/All-requests", priv: "AllVisaApplication-View", isShow: priveleges?.includes("AllVisaApplication-View") ? true : false },

                    ],
                },
            ],
        });
    }

    let visaExtension = [
        { label: "New Extensions", icon: "pi pi-fw  pi-question-circle", to: "/visa-extension/New-Extensions", priv: "NewVisaExtension-View", isShow: priveleges?.includes("NewVisaExtension-View") ? true : false },
        { label: "Incomplete Extensions", icon: "pi pi-fw pi-filter", to: "/visa-extension/Incomplete-Extensions", priv: "InCompleteVisaExtension-View", isShow: priveleges?.includes("InCompleteVisaExtension-View") ? true : false },
        { label: "Approved Extensions", icon: "pi pi-fw pi-check", to: "/visa-extension/Approved-Extensions", priv: "ApprovedVisaExtension-View", isShow: priveleges?.includes("ApprovedVisaExtension-View") ? true : false },
        { label: "Replied Extensions", icon: "pi pi-fw pi-reply", to: "/visa-extension/Replied-Extensions", priv: "RepliedExtension-View", isShow: priveleges?.includes("RepliedExtension-View") ? true : false },
        { label: "Denied Extensions", icon: "pi pi-fw pi-ban", to: "/visa-extension/Denied-Extensions", priv: "DeniedVisaExtension-View", isShow: priveleges?.includes("DeniedVisaExtension-View") ? true : false },
        { label: "Unpaid Extensions", icon: "pi pi-fw  pi pi-dollar", to: "/unpaid-visa/unpaidVisaExtension", priv: "UnpaidVisaExtension-View", isShow: priveleges?.includes("UnpaidVisaExtension-View") ? true : false },
        { label: "All Extensions", icon: "pi pi-fw pi-list", to: "/visa-extension/All-Extensions", priv: "AllVisaExtension-View", isShow: priveleges?.includes("AllVisaExtension-View") ? true : false },
    ];
    let VisaReport = [
        { label: "General Visa Report", icon: "pi pi-paperclip", to: "/Report/eVisaReport", priv: "VisaReport-View", isShow: true },
        { label: "Visa Report",  icon: "pi pi-paperclip", to: "/Report/ApprovalUserLogReport", isShow: priveleges?.includes("VisaReport-View") ? true : false },
        { label: "Visa Approval Report",  icon: "pi pi-paperclip", to: "/Report/VisaApprovalReport", isShow: priveleges?.includes("VisaReport-View") ? true : false },

    ];
    if (visaExtension.filter((v) => v.isShow === true).length > 0) {
        menu.push({
            items: [
                {
                    label: "Visa Extensions",
                    // priv: "VisaExtensions-View",
                    icon: "pi pi-fw pi-check-square",
                    isShow: visaExtension.filter((v) => v.isShow === true).length > 0,
                    hasSubMenu: true,
                    items: [
                        { label: "New Extensions", icon: "pi pi-fw  pi-question-circle", to: "/visa-extension/New-Extensions", priv: "NewVisaExtension-View", isShow: priveleges?.includes("NewVisaExtension-View") ? true : false },
                        { label: "Incomplete Extensions", icon: "pi pi-fw pi-filter", to: "/visa-extension/Incomplete-Extensions", priv: "InCompleteVisaExtension-View", isShow: priveleges?.includes("InCompleteVisaExtension-View") ? true : false },
                        { label: "Approved Extensions", icon: "pi pi-fw pi-check", to: "/visa-extension/Approved-Extensions", priv: "ApprovedVisaExtension-View", isShow: priveleges?.includes("ApprovedVisaExtension-View") ? true : false },
                        { label: "Replied Extensions", icon: "pi pi-fw pi-reply", to: "/visa-extension/Replied-Extensions", priv: "RepliedExtension-View", isShow: priveleges?.includes("RepliedExtension-View") ? true : false },
                        { label: "Denied Extensions", icon: "pi pi-fw pi-ban", to: "/visa-extension/Denied-Extensions", priv: "DeniedVisaExtension-View", isShow: priveleges?.includes("DeniedVisaExtension-View") ? true : false },
                        { label: "Unpaid Extensions", icon: "pi pi-fw  pi pi-dollar", to: "/unpaid-visa/unpaidVisaExtension", priv: "UnpaidVisaExtension-View", isShow: priveleges?.includes("UnpaidVisaExtension-View") ? true : false },
                        { label: "All Extensions", icon: "pi pi-fw pi-list", to: "/visa-extension/All-Extensions", priv: "AllVisaExtension-View", isShow: priveleges?.includes("AllVisaExtension-View") ? true : false },
                    ],
                },
            ],
        });
    }
  
    if (priveleges?.includes("Visa-Verification")) {
        menu.push({
            items: [
                {
                    label: "Visa Verification",
                    priv: "Visa-Verification",
                    icon: "pi pi-fw pi-check-square",
                    isShow: true,
                    hasSubMenu: true,
                    items: [{ label: "Verify e-Visa", icon: "pi pi-bars", to: "/visa-Verfication/Verification-Detail", priv: "Visa-Verification", isShow: true }],
                },
            ],
        });
    }
    let emailNotification = [
        { label: "All Notifications", icon: "pi pi-fw pi-comments", to: "/EmailNotification/All-Notification", priv: "AllNotification-View", isShow: priveleges?.includes("AllNotification-View") ? true : false },
    ];
    if (emailNotification.filter((v) => v.isShow === true).length > 0) {
        menu.push({
            items: [
                {
                    label: "Email Notifications",
                    // priv: "VisaRequests-View",
                    icon: "pi pi-fw pi-telegram",
                    isShow: emailNotification.filter((v) => v.isShow === true).length > 0,
                    hasSubMenu: true,
                    items: [
                        { label: "All Notifications", icon: "pi pi-fw pi-comments", to: "/EmailNotification/All-Notification", priv: "AllNotification-View", isShow: priveleges?.includes("AllNotification-View") ? true : false },
                    ],
                },
            ],
        });
    }
    if (VisaReport.filter((v) => v.isShow === true).length > 0) {
        menu.push({
            items: [
                {
                    label: "e-Visa Report",
                    // priv: "VisaExtensions-View",
                    icon: "pi pi-chart-bar",
                    isShow: VisaReport.filter((v) => v.isShow === true).length > 0,
                    hasSubMenu: true,
                    items: [
                        { label: "General Visa Report", icon: "pi pi-fw  pi-paperclip", to: "/Report/VisaReport",  priv: "VisaReport-View", isShow: priveleges?.includes("VisaReport-View") ? true : false },
                        //{ label: "User Log Report", icon: "pi pi-fw pi-user", to: "/Report/ApprovalUserLogReport", priv: "ApprovalUserLogReport-View", isShow: priveleges?.includes("VisaReport-View") ? true : false },
                        { label: "User Approval Report", icon: "pi pi-fw pi-paperclip", to: "/Report/VisaApprovalReport", priv: "ApprovalUserLogReport-View", isShow: priveleges?.includes("VisaReport-View") ? true : false },                   
                    ],
                },
            ],
        });
    }
    const location = useLocation();
    console.log("location", location);
    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (location.pathname.split("/")[1] === "masterdata" && location.pathname.split("/").length === 2) {
            history.push("/masterdata/attachement-type");
        }
        if (location.pathname.split("/")[1] === "account-management" && location.pathname.split("/").length === 2) {
            history.push("/account-management/users");
        }
        if (location.pathname.split("/")[1] === "company-application" && location.pathname.split("/").length === 2) {
            history.push("/company-application/new-applications");
        }
        if (location.pathname.split("/")[1] === "visa-requests" && location.pathname.split("/").length === 2) {
            history.push("/visa-requests/new-requests");
        }
        if (location.pathname.split("/")[1] === "visa-extension" && location.pathname.split("/").length === 2) {
            history.push("/visa-extension/New-Extensions");
        }
    });

    useEffect(() => {
        toggleAuth(auth);
    }, [auth]);

    useEffect(() => {
        const roles = localStorage?.getItem("roles")?.split(",");
        const fullname = localStorage?.getItem("fullname");
        const username = localStorage?.getItem("username");
        const idToken = localStorage?.getItem("idToken");

        auth.togglePrivileges(roles);
        auth.toggleFullname(fullname);
        auth.toggleUsername(username);
        auth.toggleIdToken(idToken);
    }, []);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuClick = (event) => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (layoutMode === "overlay" && !isMobile()) {
            setOverlayMenuActive((prevState) => !prevState);
        } else {
            if (isDesktop()) setStaticMenuDesktopInactive((prevState) => !prevState);
            else setStaticMenuMobileActive((prevState) => !prevState);
        }
        event.preventDefault();
    };

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };
    const onTopbarNotificationMenuButtonClick = (event) => {
        topbarNotificationItemClick = true;
        setTopbarNotificationMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };
    
    const onTopbarItemClick = (event) => {
        topbarItemClick = true;

        if (activeTopbarItem === event.item) setActiveTopbarItem(null);
        else setActiveTopbarItem(event.item);

        event.originalEvent.preventDefault();
    };
    const onTopbarNotificationItemClick = (event) => {
        topbarNotificationItemClick = true;

        if (activeTopbarNotificationItem === event.item) setActiveTopbarNotificationItem(null);
        else setActiveTopbarNotificationItem(event.item);

        event.originalEvent.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = (event) => {
        setMenuActive((prevState) => !prevState);
    };

    const onDocumentClick = (event) => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }

            hideOverlayMenu();
        }

        topbarItemClick = false;
        menuClick = false;
    };

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (layoutMode === "static") return !staticMenuDesktopInactive;
            else if (layoutMode === "overlay") return overlayMenuActive;
            else return true;
        } else {
            return true;
        }
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const isMobile = () => {
        return window.innerWidth < 1025;
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isHorizontal = () => {
        return layoutMode === "horizontal";
    };

    const isSlim = () => {
        return layoutMode === "slim";
    };

    const changeMenuMode = (event) => {
        setLayoutMode(event.menuMode);
        setStaticMenuDesktopInactive(false);
        setOverlayMenuActive(false);
    };

    const onSchemeChange = (color) => {
        setScheme(color);
        const themeLink = document.getElementById("theme-css");
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf("/") + 1, href.lastIndexOf("."));
        const themeTokens = themeFile.split("-");
        const themeName = themeTokens[1];
        changeTheme(themeName + "-" + color);
        changeLogo(color);
    };

    const changeTheme = (theme) => {
        setThemeColor(theme.split("-")[0]);
        changeStyleSheetUrl("layout-css", theme, "layout");
        changeStyleSheetUrl("theme-css", theme, "theme");
    };

    const onThemeChange = (theme) => {
        setThemeColor(theme);
        changeTheme(theme + "-" + scheme);
    };

    const changeStyleSheetUrl = (id, value, prefix) => {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute("href").split("/");
        urlTokens[urlTokens.length - 1] = prefix + "-" + value + ".css";
        let newURL = urlTokens.join("/");

        replaceLink(element, newURL);
    };

    const changeLogo = (scheme) => {
        const invoiceLogoLink = document.getElementById("invoice-logo");
        const logoUrl = `assets/layout/images/logo-${scheme === "light" ? "dark" : "white"}.png`;

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute("href", href);
        } else {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute("href", href);
            cloneLinkElement.setAttribute("id", id + "-clone");

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove();
                cloneLinkElement.setAttribute("id", id);
            });
        }
    };

    const layoutClassName = classNames("layout-wrapper", {
        "layout-horizontal": layoutMode === "horizontal",
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-slim": layoutMode === "slim",
        "layout-static-inactive": staticMenuDesktopInactive && layoutMode !== "slim",
        "layout-mobile-active": staticMenuMobileActive,
        "layout-overlay-active": overlayMenuActive,
        "p-input-filled": inputStyle === "filled",
    });

    const menuContainerClassName = classNames("layout-menu-container", { "layout-menu-container-inactive": !isMenuVisible() });

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar 
            topbarMenuActive={topbarMenuActive} 
            topbarNotificationMenuActive={topbarNotificationMenuActive}
             activeTopbarItem={activeTopbarItem}
              onMenuButtonClick={onMenuButtonClick}
               onTopbarMenuButtonClick={onTopbarMenuButtonClick} 
               onTopbarNotificationMenuButtonClick={onTopbarNotificationMenuButtonClick} 
               onTopbarItemClick={onTopbarItemClick} 
            />
            <div className={menuContainerClassName} onClick={onMenuClick}>
                <div className="layout-menu-content">
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode} active={menuActive} />
                    <div className="layout-menu-footer">
                        {/* <div className="layout-menu-footer-title">TASKS</div> */}

                        {/* <div className="layout-menu-footer-content">
                            <ProgressBar value={50} showValue={false}></ProgressBar>
                            Today
                            <ProgressBar value={80} showValue={false}></ProgressBar>
                            Overall
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="layout-content">
                <AppBreadcrumb />

                <Router />
                <AppFooter />

                {staticMenuMobileActive && <div className="layout-mask"></div>}
            </div>

            {/* <AppConfig themeColor={themeColor} onThemeChange={onThemeChange} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} changeMenuMode={changeMenuMode} ripple={ripple} onRippleChange={onRippleChange} scheme={scheme} onSchemeChange={onSchemeChange} /> */}

            <Dialog visible={sessionExpiredDialog} style={{ width: "450px" }} modal footer={sessionExpiredDialogFooter}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    <h2>Your Session is Expired!</h2>
                </div>
            </Dialog>
        </div>
    );
};

export default App;
