import HttpService from "../shared/HttpService";


export default class VisaFeeService {

    getAll = (claim) => {
        let relativePath = "visaservice/api/v1/VisaFees/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    getAllExtensionFee = (claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionFees/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    delete = (id, claim) => {
        let relativePath = "visaservice/api/v1/VisaFees/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }
    deleteByactivateDeactivate = (requestData, claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionFees/UpdateStatus";
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    
    create = (requestData, claim) => {
        let relativePath = "visaservice/api/v1/VisaFees/Create";
        return HttpService.postService(requestData, relativePath, "", "", "", claim)
    }
    createExtensionFee = (requestData, claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionFees/Create";
        return HttpService.postService(requestData, relativePath, "", "", "", claim)
    }
    edit = (requestData, claim) => {
        let relativePath = "visaservice/api/v1/VisaFees/Update";
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    activateDeactivate = (requestData, claim) => {
        let relativePath = "visaservice/api/v1/VisaFees/UpdateStatus";
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    activateDeactivateExtension = (requestData, claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionFees/UpdateStatus";
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    deleteExtensionFee = (id, claim) => {
        let relativePath = "visaservice/api/v1/VisaFees/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }
    updateExtensionFee = (requestData, claim) => {
        let relativePath = "visaservice/api/v1/VisaExtensionFees/Update";
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }

}
