import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { Message } from 'primereact/message';
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { useLocation } from "react-router-dom";
import VisaService from "../../service/eVisaService/eVisaService";
import "./VisaDetail.css";
import SnipperModal from "../modal/SnipperModal";
import { Toast } from "primereact/toast";
import { Document } from "react-pdf";
import { Page } from "@react-pdf/renderer";
import byteToFile from "byte-to-file";
import { InputText } from "primereact/inputtext";
import VerificationService from "../../service/VerificationService/VerificationService";

const VisaDetail = () => {
    const [displayResponsive, setDisplayResponsive] = useState(false);
    const [searchOptions, setSearchOptions] = useState(null);
    const [approvalStatusValue, setApprovalStatusValue] = useState(0);
    const [detailData, setDetailData] = useState();
    const [attachements, setAttachments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const location = useLocation();
    const toast = useRef(null);

    if (location.state !== null && location.state !== undefined) {

        localStorage.removeItem("selectedVisaId");
        localStorage.setItem("selectedVisaId", location.state.selected.id);
    }
    const approvalStatus = {
        0: "DEFAULT",
        1: "ENTRYALLOWED",
        2: "DENIED",
        3: "DEPARTED",
        4: "EXPIRED BEFORE ENTRY"
    };
    const Options = [
        { name: "Visa Number", value: 0 },
        { name: "Reference Number", value: 1 },

    ];

    const body = {
        id: detailData?.id
    }

    useEffect((value) => {



    }, []);

    const formatString = (string) => {
        const arr = string?.split(" ");

        for (var i = 0; i < arr?.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const formattedString = arr?.join(" ");
        return formattedString;
    };

    const formatDate = (date) => {
        return new Date(date).toDateString();
    };

    const dialogFuncMap = {
        displayResponsive: setDisplayResponsive,
    };

    const onClick = (name, value) => {
        setApprovalStatusValue(value);
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const departApplicants = () => {
        const visaService = new VerificationService()
        const activateDeactivateBody = {

            //approvalStatus: approvalStatusValue,
            id: body,

        };
        setIsLoading(true);
        // console.log(activateDeactivateBody);
        visaService.departApplicant(body, "Visa-Verification")
            .then((res) => {
                setIsLoading(false);
                toast.current.show({ severity: "success", summary: "This Appplicant Departed From Ethiopia", detail: res.message, life: 3000 });
            })
            .catch((err) => {
                setIsLoading(false);
                // ("");
                toast.current.show({ severity: "error", summary: "Error Message", detail: "Error Occured while Departing entry,please check entry date and entry status", life: 3000 });
            });
    };
    const allowApplicants = () => {
        const visaService = new VerificationService()

        visaService.allowApplicant(body, "Visa-Verification")
            .then((res) => {
                setIsLoading(false);

                toast.current.show({ severity: "success", summary: "Applicant Allowed to Enter Ethiopia", detail: res.message, life: 3000 });
            })
            .catch((err) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error Message", detail: "Error Occured while allowing entry,please check entry date and entry status", life: 3000 });
            });
    };
    const denyApplication = () => {
        const visaService = new VerificationService()
        visaService
            .denyApplicant(body, "Visa-Verification")
            .then((res) => {
                setIsLoading(false);
                toast.current.show({ severity: "success", summary: "This  Request Denied  to enter Ethiopia", detail: res.message, life: 3000 });
            })
            .catch((err) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error Message", detail: "Error Occured while denying entry,please check entry date and entry status", life: 3000 });
            });
    };
    const changeApplication = () => {
        dialogFuncMap[`displayResponsive`](false);
        const activateDeactivateBody = {
            // approvalStatus: approvalStatusValue,
            //   :   ,
            id: Number.parseInt(localStorage.selectedVisaId),
        };

        setIsLoading(true);
        // console.log(activateDeactivateBody);
        switch (approvalStatusValue) {
            case 0:
                allowApplicants(activateDeactivateBody);
                break;
            case 1:
                departApplicants(activateDeactivateBody);
                break;
            case 2:
                denyApplication(activateDeactivateBody);
                break;
            default:
                console.log("Unkonwn Status");
        }
    };
    const renderFooter = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-raised" />
                <Button label="Yes" icon="pi pi-check" onClick={() => changeApplication()} className="p-button-raised" />
            </div>
        );
    };
    const SearchAll = (value) => {
        const visaService = new VerificationService();

        if (searchOptions === 0 && value) {
            setIsLoading(true);
            visaService
                .getApplicantDetail(value, "Visa-Verification")
                .then((res) => {
                    setIsLoading(false);
                    setDetailData(res?.data);

                })
                .catch((err) => {
                    //setLoading(false);
                    toast.current.show({ severity: 'error', summary: 'Incorrect Visa Number', detail: "Please check visa number and try again", life: 3000 });
                    setIsLoading(false);
                    setDetailData(null);

                })
                .finally((err) => {
                    setIsLoading(false);
                });
        } else {

            setIsLoading(true);
            visaService
                .getApplicantReference(value, "Visa-Verification")
                .then((res) => {
                    setIsLoading(false);
                    setDetailData(res.data);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.current.show({ severity: 'error', summary: 'Incorrect Reference Number', detail: "Please check reference number and try again", life: 3000 })
                    setDetailData(null);

                })
                .finally((err) => {
                    setIsLoading(false);
                })
                ;
        }

    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">


            <span className="field  md:mt-2 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    value={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13)
                            SearchAll(e.target.value);

                    }}
                    placeholder="Search..."
                />
            </span>
            {/* <span>
                <Dropdown value={searchOptions} options={Options} onChange={(e) => setSearchOptions(e.value)} optionLabel="name" placeholder="Select Search Options" />
                
                <span className="field  md:mt-2 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) SearchAll(e.target.value);
                        }
                        }

                        placeholder="Search..."
                    />
                </span>
            </span> */}
        </div>
    );
    return (
        <div>
            <Dialog header="Update  Applicant Entry Status" visible={displayResponsive}
                onHide={() => onHide("displayResponsive")} breakpoints={{ "960px": "75vw" }}
                style={{ width: "35vw", height: "20vh" }} footer={renderFooter("displayResponsive")}>
                <h5>  </h5>
                {/* //<InputTextarea value={  } onChange={(e) =>  (e.target.value)} rows={8} cols={70} autoResize /> */}
            </Dialog>

            <div className="grid">
                {isLoading ? <SnipperModal /> : <> {null}</>}
                <Toast ref={toast} />

                <div className="col-12" value>
                    <div className="card">
                        <h4>
                            <Message severity="info" text="Valid search terms include Reference Number and Visa Number. " />

                        </h4>

                        <div className="formgrid grid">
                            <span>
                                <Dropdown value={searchOptions} options={Options} onChange={(e) => setSearchOptions(e.value)} optionLabel="name" placeholder="Select Search Options" />
                                <span className="field  md:mt-2 p-input-icon-left ml-2">
                                    <i className="pi pi-search" />
                                    <InputText
                                        value={searchValue}
                                        onChange={(e) => {
                                            setSearchValue(e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) SearchAll(e.target.value);

                                        }}
                                        placeholder="Search..."
                                    />
                                </span>
                            </span>
                        </div>
                    </div>
                    {detailData && detailData != null && detailData !== undefined ? <div>
                        <div className="card">
                            <h4>
                                <b>Traveller Profile</b>
                            </h4>


                            <div className="formgrid grid">
                                {detailData?.attachments?.map((attach) =>
                                    attach.isProfilePhoto ? (
                                        <div className="field lg:col-2 md:col-4 sm:col-12" key={attach.id}>
                                            <Image className="pp" src={attach?.attachmentPath} width="100%" alt="Profile Photo" preview />
                                        </div>
                                    ) : null
                                )}
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Name</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">
                                            {formatString(detailData?.firstName)} {formatString(detailData?.surName)}
                                            {/* {console.log("detail",detailData.length)} */}
                                        </div>
                                        {/* <div className="company-name">Sur Name</div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.surName}</div> */}
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Gender</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{formatString(detailData?.gender)}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Email</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{detailData?.email}</div><br />
                                        <div className="company-name">
                                        </div>

                                        <Button label="Allow Entry" className="p p-button-success" style={{
                                            display: "inline-block", marginBottom: "6px",
                                            marginLeft: "6px", paddingRight: "99px", paddingLeft: "103px"
                                        }} onClick={() => onClick("displayResponsive", 0)}></Button>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Birth Date</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{(detailData?.birthDate)}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Citizenship</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.birthCountryDescription}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Country of Birth</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{detailData?.issuingAuthority}</div><br />
                                        <div className="company-name">

                                            <Button label="Depart" className="p p-button-warning"
                                                style={{
                                                    display: "inline-block", marginBottom: "6px",
                                                    marginLeft: "6px", paddingRight: "99px", paddingLeft: "103px"
                                                }} onClick={() => onClick("displayResponsive", 1)}></Button>

                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Place of Birth</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.birthCountryDescription}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Occupation</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.occupation}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Phone Number</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.phoneNumber}</div>
                                        <div className="company-name">
                                            <Button label="Deny Entry" className="p p-button-danger"
                                                style={{ display: "inline-block", marginBottom: "6px", marginLeft: "6px", paddingRight: "99px", paddingLeft: "103px" }} onClick={() => onClick("displayResponsive", 2)}></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h4>
                                <b>Request Details</b>
                            </h4>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Request Date</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.startDate)}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Arrival Date</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.arrivalDate)}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Entry Type</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.visaTypeValidity?.name}</div>
                                        {/* <div className="company-name">
                                        <p className="text-700 text-lg p-0">VISA Fee</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold ">{detailData?.visaApplicationFee}</div> */}
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Visa Issue Date</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.visaIssueDate)}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Reference Number</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.referenceNumber}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Payment Status</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.paymentStatusDescription}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Duration of Stay</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{detailData?.gender}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Entry Status</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{approvalStatus[detailData?.entryStatus]}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Visa Expiry Date</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.visaExpiryDate)}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Last Departure Country</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.departureCountryDescription}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Last Departure City</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.departureCountryDescription}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Request Status</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{detailData?.visaStatusDescription}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h4>
                                <b>Passport Details</b>
                            </h4>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Passport Type</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.passportTypeDescription}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Passport Number</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{detailData?.passportNumber}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Country of Issue</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{detailData?.issuingCountryDescription}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Issuing Authority</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{detailData?.issuingAuthority}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Passport Issue Date</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.passportIssueDate)}</div>
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Passport Expiration Date</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{formatDate(detailData?.passportExpireDate)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h4>
                                <b>Address in Ethiopia</b>
                            </h4>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Hotel Accomodation Name</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{detailData?.accommodationName}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Street Address</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{detailData?.streetAddress}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">City</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{detailData?.accommodationCity}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Phone Number</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{detailData?.accommodationPhonenumber}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div >
                                <div >
                                    <div className="scrollpanel-demo">
                                        <h4>
                                            <b>Visa Documents</b>
                                        </h4>
                                        <ScrollPanel style={{ height: "300px" }} className="custombar1">

                                            {detailData?.attachments?.map((attach) =>
                                                attach?.isProfilePhoto ? null : (
                                                    <div className="col-12" key={attach?.attachmentPath} >
                                                        <div className="card mb-4">
                                                            {attach.fileType === ".pdf" ? (
                                                                <div className="">
                                                                    <h4>{attach?.attachmentType}</h4>
                                                                    <a href={attach.attachmentPath} download="passport">
                                                                        <Button className="bg-pink-700" icon="pi pi-file-pdf  " iconPos="left" tooltip={attach.attachmentType}></Button>
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="card">
                                                                        <h4>{attach?.attachmentType}</h4>
                                                                        <Image src={attach?.attachmentPath} alt="attachment" width={250} preview />

                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </ScrollPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <></>}

                </div>
            </div>

        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(VisaDetail, comparisonFn);
