import HttpService from "../shared/HttpService";


export default class CompanyCategoryService {

    getAll = (claim) => {
        let relativePath = "companyservice/api/v1/CompanyCategory/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    delete = (id, claim) => {
        let relativePath = "companyservice/api/v1/CompanyCategory/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim)
    }
    create = (requestData, claim) => {
        let relativePath = "companyservice/api/v1/CompanyCategory/Create";
        return HttpService.postService(requestData, relativePath, "", "", "", claim)
    }
    edit = (requestData, claim) => {
        let relativePath = "companyservice/api/v1/CompanyCategory/Update";
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    activateDeactivate = (requestData, claim) => {
        let relativePath = "companyservice/api/v1/CompanyCategory/ActivateDeactivateCompanyCategory";
        return HttpService.postService(requestData, relativePath, "", "", "", claim)
    }
}
