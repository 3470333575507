import HttpService from "../shared/HttpService";


export default class NotificationService {

    getAll = (claim) => {
        let relativePath = "masterdataservice/api/v1/NotificationMessages/GetAll";
        return HttpService.getService(relativePath, "", claim)
    }
    create = (requestData, claim) => {
        let relativePath = "masterdataservice/api/v1/NotificationMessages/Create";
        return HttpService.postService(requestData, relativePath, "", "", "", claim)
    }
    edit = (requestData, id, claim) => {
        let relativePath = "masterdataservice/api/v1/NotificationMessages/Update?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    activateDeactivate = (id,requestData, claim) => {
        debugger
        let relativePath = "masterdataservice/api/v1/NotificationMessages/UpdateStatus?id=" + id;
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
    deleteByactivateDeactivate = (requestData, claim) => {
        let relativePath = "masterdataservice/api/v1/NotificationMessages/UpdateStatus?id=" + requestData?.id;
        return HttpService.putService(requestData, relativePath, "", "", "", claim)
    }
   
    
}