import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Moment from "moment";
import VisaFeeService from "../../../service/master-data/visaFeeService";
import MasterDataService from "../../../service/master-data/MasterDataService";
import VisaService from "../../../service/VisaValidity/VisaService";
import SnipperModal from "../../modal/SnipperModal";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../utilities/authContext";

const VisaFee = () => {
    let emptyvisaFee = {
        id: 0,
        visaTypeValiditiesId: 129,
        percentage: 0,
        serviceFee: 0,
        effectiveDateTo: 0,
        effectiveDateFrom: 0,
        noofExtensionFrequency: 0,
        maximumnoofDaysforExtenstion: 0,
        maximumnoofDaysOverStayAllowed: 0,
        overStayPenalityPerDay: 0,
        recordStatus: 0,
    };
    const activateDeactivateBody = {
        status: 0,
    };
    const deleteBody = {
        id: 0,
        status: 3,
    };
    let [loading, setLoading] = useState(false);
    const [visaFees, setvisaFees] = useState(null);
    const [visatypes, setvisaVisaTypes] = useState(null);
    const [nationalities, setNationalities] = useState(null);
    const [visaValidity, setvisaValidities] = useState(null);

    const [selectedVisatype, SetSelectedVisatype] = useState({ id: 0, name: "" });
    const [selectedvisavalidity, setselectedvisavalidity] = useState({ id: 0, name: "" });
    const [selectedNationality, SetSelectedNationality] = useState({ id: 0, name: "" });
    const [accommodationUpdateDialog, setAccommodationUpdateDialogDialog] = useState(false);
    const [visaFeeDialog, setvisaFeeDialog] = useState(false);
    const [deletevisaFeeDialog, setDeletevisaFeeDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);
    const [visaFee, setvisaFee] = useState(emptyvisaFee);
    const [selectedAccommodations, setSelectedAccommodations] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        recordStatus: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });
    const toast = useRef(null);
    const dt = useRef(null);
    const roles = useAuth()

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [date, setDate] = useState(new Date());
    const { register, handleSubmit, errors } = useForm();
    useEffect(() => {
        const visaFeeService = new VisaFeeService();
        setLoading(true);
        visaFeeService
            .getAllExtensionFee("VisaExtensionFee-View")
            .then((res) => {
                setLoading(false);
                setvisaFees(res.data.payload);

            })
            .catch((error) => {
                setLoading(false)
                toast.current.show({ severity: "error", summary: "Error", detail: "Error fetching visa extension fees.", life: 3000 });
            });

        const visaService = new VisaService();
        visaService.getVisaType("VisaExtensionFee-View").then((res) => {
            setvisaVisaTypes(res.data);
        });

        visaService.getExtensionVisaValidity("VisaExtensionFee-View").then((res) => {
            setvisaValidities(res.data);

        });
    }, []);
    const openNew = () => {
        setvisaFee(emptyvisaFee);
        setSubmitted(false);
        setvisaFeeDialog(true);
    };

    const hidevisaFeeDialog = () => {
        setSubmitted(false);
        setvisaFeeDialog(false);
    };
    const hideDialog = () => {
        setSubmitted(false);
        setAccommodationUpdateDialogDialog(false);
    };

    const hideDeletevisaFeeDialog = () => {
        setDeletevisaFeeDialog(false);
    };
    const hideActivateDeactivatevisaFeeDialog = () => {
        setActivateDeactivateDialog(false);
    };

    const SubmitVisaFee = () => {
        setSubmitted(true);

        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };
        _visaFee.effectiveDateFrom = String(_visaFee?.effectiveDateFrom).split("T")[0];
        _visaFee.effectiveDateTo = String(_visaFee?.effectiveDateTo).split("T")[0];
        const visaFeeService = new VisaFeeService();
        setLoading(true);

        visaFeeService
            .createExtensionFee(_visaFee, "VisaExtensionFee-Add")
            .then((res) => {
                setLoading(false);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Visa Extension Fee Created", life: 3000 });
                refreshVisaFee();
            })
            .catch((err) => {
                setLoading(false);
                toast.current.show({ severity: "error", summary: "Error Message", detail: "Visa Extension Fee already Registered ", life: 3000 });
                refreshVisaFee();
            });
        setvisaFees(_visaFees);
        setvisaFeeDialog(false);
        setvisaFee(emptyvisaFee);
    };
    const UpdatevisaFee = () => {
        setSubmitted(true);

        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };
       
        _visaFee.effectiveDateFrom = String(_visaFee?.effectiveDateFrom).split("T")[0];
        _visaFee.effectiveDateTo = String(_visaFee?.effectiveDateTo).split("T")[0];
        if (visaFee.id) {
            const index = findIndexById(visaFee.id);
            _visaFees[index] = _visaFee;

            const visaFeeService = new VisaFeeService();
            setLoading(true);
            visaFeeService
                .updateExtensionFee(_visaFee, "VisaExtensionFee-Edit")
                .then((res) => {
                    setLoading(false);
                    toast.current.show({ severity: "success", summary: "Successful", detail: "visa fees Updated", life: 3000 });
                    refreshVisaFee();
                    // history.push('/roles')
                })
                .catch((err) => {
                    setLoading(false);
                    toast.current.show({ severity: "error", summary: "Error Occured", detail: err.response.data.errors, life: 3000 });
                    
                });
        } else {
            toast.current.show({ severity: "error", summary: "Error Occured", detail: "Id can not be null", life: 3000 });
        }

        setvisaFees(_visaFees);
        setAccommodationUpdateDialogDialog(false);
        setvisaFee(emptyvisaFee);
    };
    const editAccommodation = (visaFee) => {
        setvisaFee({ ...visaFee });
        setAccommodationUpdateDialogDialog(true);
    };

    const confirmDeleteVisaFee = (visaFee) => {
        setvisaFee(visaFee);
        setDeletevisaFeeDialog(true);
    };

    const confirmactivateDeactivateVisaFee = (visaFee) => {
        setvisaFee(visaFee);
        setActivateDeactivateDialog(true);
    };
    var newDate = new Date();
    var today = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
    const deleteVisaFee = () => {
        let _visaFees =visaFees.filter((val) => val.id !== visaFee.id);
        deleteBody.id = visaFee?.id
        const visaFeeService = new VisaFeeService();
        setLoading(true);
        visaFeeService
            .deleteByactivateDeactivate(deleteBody, "VisaExtensionFee-Delete")
            .then((res) => {
                setLoading(false);
                setvisaFees(_visaFees);
                setDeletevisaFeeDialog(false);
                setvisaFee(emptyvisaFee);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Visa Extension Fee Deleted", life: 3000 });
            })
            .catch((err) => {
                setLoading(false);
                toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
            });
    };
    const refreshVisaFee = () => {
        const visaFeeService = new VisaFeeService();
        visaFeeService
            .getAllExtensionFee("VisaExtensionFee-View")
            .then((res) => {
                setvisaFees(res.data.payload);
            })
            .catch((error) => { });
    };
    const activateDeactivateVisaFee = () => {
        setSubmitted(true);

        let _visaFees = [...visaFees];
        let _visaFee = { ...visaFee };
        if (visaFee.id) {
            const index = findIndexById(visaFee.id);

            _visaFees[index] = _visaFee;
            const visaFeeService = new VisaFeeService();
            activateDeactivateBody.id = visaFee.id;
            if (visaFee.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            } else activateDeactivateBody.status = 1;
            setLoading(true);
            visaFeeService
                .activateDeactivateExtension(activateDeactivateBody, "VisaExtensionFee-ChangeStatus")
                .then((res) => {
                    setLoading(false);
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Visa Fee Activated" : "Visa Fee Deactivated", life: 3000 });
                    refreshVisaFee();
                })
                .catch((err) => {
                    setLoading(false);
                    toast.current.show({ severity: "error", summary: "Error Occured", detail: err.message, life: 3000 });
                });
        }
        setvisaFees(_visaFees);
        setActivateDeactivateDialog(false);
        setvisaFee(emptyvisaFee);
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < visaFees.length; i++) {
            if (visaFees[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const onInputChange = (value, name) => {
        const val = value;
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = val;
        setvisaFee(_visaFee);
    };
    const onVisaValididtyChange = (e, name) => {
        let _visaFee = { ...visaFee };
        _visaFee[`${name}`] = e.value;
        setvisaFee(_visaFee);
        let selectedVisaType = visaValidity.find((c) => c.id === e.value);
        setselectedvisavalidity(selectedVisaType);
    };
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("VisaExtensionFee-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    };
    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Extension Frequency</span>
                {rowData.noofExtensionFrequency}
            </>
        );
    };
    const serviceFeeamouBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {rowData.serviceFee}
            </>
        );
    };
    
     const MaxDayallowedBodyTemplate = (rowData) => {
        return <>{rowData.maximumnoofDaysforExtenstion}</>;
    };
    const OneDayPenalityBodyTemplate = (rowData) => {
        return <>{rowData.oneDayOverStayPenality}</>;
    };
    const MaxOverStayDayallowedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Visa Application Fee</span>

                {rowData.maximumnoofDaysOverStayAllowed}
            </>
        );
    };

    const PercentageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                {rowData.percentage}%
            </>
        );
    };
    const VisaTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                {rowData?.visaTypeValidities?.visaType?.name}
            </>
        );
    };
    const visaTypeValidityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                {rowData?.visaTypeValidities?.visaValidity?.name}
            </>
        );
    };
    const EffectiveStartDate = (rowData) => {
        return (
            <>
                <span className="p-column-title">End Date</span>
                {rowData?.effectiveDateFrom.split("T")[0]}
            </>
        );
    };
    const EffectiveEndDate = (rowData) => {
        return (
            <>
                <span className="p-column-title">End Date</span>
                {rowData?.effectiveDateTo.split("T")[0]}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("VisaExtensionFee-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editAccommodation(rowData)} /> : <></>}
                 {roles.privileges.includes("VisaExtensionFee-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteVisaFee(rowData)} /> : <></>}
                {roles.privileges.includes("VisaExtensionFee-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmactivateDeactivateVisaFee(rowData)} /> : <></>}
            </div>
        );
    };
    const status = [
        { name: "Active", value: "2" },
        { name: "Inactive", value: "1" },
    ];

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus;

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }}>Inactive</span>;
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }}>Active</span>;
        }
    };
const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Extension Visa Fees</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const updatevisaFeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdatevisaFee} />
        </>
    );

    const visaFeeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hidevisaFeeDialog} />
            <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitVisaFee} />
        </>
    );

    const deletevisaFeeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideDeletevisaFeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteVisaFee} />
        </>
    );
 
    const activateDeactivatevisaFeeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivatevisaFeeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateVisaFee} />
        </>
    );

    return (
        <div className="grid crud-demo">
            {loading ? <SnipperModal /> : <> {null}</>}
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput}></Toolbar>
                    <DataTable
                        ref={dt}
                        value={visaFees}
                        selection={selectedAccommodations}
                        onSelectionChange={(e) => setSelectedAccommodations(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        filters={filters}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} visa fees"
                        globalFilter={globalFilter}
                        emptyMessage="No visa extension fees found."
                        scrollable
                        // header={header}
                        responsiveLayout="scroll"
                    >
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="noofExtensionFrequency" header="Extension Frequency" sortable body={amountBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="serviceFee" header="Service Fee" sortable body={serviceFeeamouBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="maximumnoofDaysforExtenstion" header="Max Day of Extension" sortable body={MaxDayallowedBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="maximumnoofDaysOverStayAllowed" header="Max OverStay Day Allowed" sortable body={MaxOverStayDayallowedBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="oneDayOverStayPenality" header="OneDay OverStay Penality" sortable body={OneDayPenalityBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="percentage" header="Percentage" sortable body={PercentageBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="visaTypeValidities.visaType.name" header="Visa Type" sortable body={VisaTypeBodyTemplate} style={{ minWidth: "14rem" }} headerStyle={{width:"14%" , minWidth: "14rem" }}></Column>
                        <Column field="visaTypeValidities.visaValidity.name" header="Visa Validity" sortable body={visaTypeValidityBodyTemplate} style={{ minWidth: "10rem" }} headerStyle={{ width: "14%", minWidth: "10rem"  }}></Column>
                        <Column field="Effective StartDate" header="Effective StartDate" sortable body={EffectiveStartDate} style={{ minWidth: "14rem" }} headerStyle={{ width: "14%", minWidth: "14rem" }}></Column>
                        <Column field="Effective EndDate" header="Effective EndDate" body={EffectiveEndDate} sortable style={{ minWidth: "14rem" }} headerStyle={{ minWidth: "14rem" }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable style={{ minWidth: "10rem" }} headerStyle={{ minWidth: "10rem" }}></Column>

                        {(roles.privileges.includes("VisaExtensionFee-Edit") === true ||
                            roles.privileges.includes("VisaExtensionFee-Delete") === true
                        ) ? <Column body={actionBodyTemplate} header="Action" frozen alignFrozen="right" style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column> : <></>}
                    </DataTable>

                    <Dialog visible={visaFeeDialog} style={{ width: "450px" }} header="Create Visa Fee" modal className="p-fluid" footer={visaFeeDialogFooter} onHide={hidevisaFeeDialog}>
                        <form onSubmit={handleSubmit(SubmitVisaFee)}>
                            <div className="field">
                                <label htmlFor="name">Service Fee in USD</label>
                                <InputNumber id="visaApplicationFee" value={visaFee.serviceFee} onValueChange={(e) => onInputChange(e.value, "serviceFee")} autoFocus />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Maximum Day of Extension</label>
                                <InputNumber id="servicePrice" value={visaFee.maximumnoofDaysforExtenstion} onValueChange={(e) => onInputChange(e.value, "maximumnoofDaysforExtenstion")} />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Maximum OverStay Day Allowed</label>
                                <InputNumber id="servicePrice" value={visaFee.maximumnoofDaysOverStayAllowed} onValueChange={(e) => onInputChange(e.value, "maximumnoofDaysOverStayAllowed")} />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Number of Extension</label>
                                <InputNumber id="servicePrice" value={visaFee.noofExtensionFrequency} onValueChange={(e) => onInputChange(e.value, "noofExtensionFrequency")} />
                            </div>
                            <div className="field">
                                <label htmlFor="name">One Day Penality</label>
                                <InputNumber id="servicePrice" value={visaFee.overStayPenalityPerDay} onValueChange={(e) => onInputChange(e.value, "overStayPenalityPerDay")} />
                            </div>
                            
                            <div className="field">
                                <label htmlFor="name">Percentage</label>
                                <InputNumber id="servicePrice" value={visaFee.percentage} onValueChange={(e) => onInputChange(e.value, "percentage")} mode="decimal" minFractionDigits={3} maxFractionDigits={5} />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Effective StartDate</label>
                                <Calendar
                                    value={startDate}
                                    minDate={today}
                                    dateFormat="dd/mm/yy"
                                    onChange={(e) => {
                                        setStartDate(e.value);
                                        onInputChange(Moment(e.value).format(), "effectiveDateFrom");
                                    }}
                                    showIcon

                                />
                            </div>
                            <div className="field">
                                <label htmlFor="name">Effective EndDate</label>
                                <Calendar
                                    value={endDate}
                                    minDate={startDate}
                                    dateFormat="dd/mm/yy"
                                    onChange={(e) => {
                                        setEndDate(e.value);
                                        onInputChange(Moment(e.value).format(), "effectiveDateTo");
                                    }}
                                    showIcon

                                />
                            </div>
                            <div className="field">
                                <Dropdown optionValue="id" optionLabel="name" value={selectedvisavalidity.id} optionName={visaFee.visaValidityId} options={visaValidity} onChange={(e) => onVisaValididtyChange(e, "visaTypeValiditiesId")} placeholder="Select Visa Validity" />
                            </div>
                            {/* <div className="flex justify-content-end mt-2">
                                <Button label="Cancel" icon="pi pi-times" className="p-button-raised w-4 mr-4" onClick={hidevisaFeeDialog} />
                                <Button type="submit" label="Submit" icon="pi pi-check" className="p-button-raised w-4" />
                            </div> */}
                        </form>
                    </Dialog>

                    {/* Update Dialog  */}

                    <Dialog visible={accommodationUpdateDialog} style={{ width: "450px" }} header="Update Extension Fee Date" modal className="p-fluid" footer={updatevisaFeeDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Effective EndDate</label>
                            <Calendar
                                value={new Date(visaFee.effectiveDateTo)}
                                minDate={new Date(visaFee.effectiveDateFrom)}
                                dateFormat="dd/mm/yy"
                                onChange={(e) => {
                                    setEndDate(e.value);
                                    onInputChange(Moment(e.value).format(), "effectiveDateTo");
                                }}
                                showIcon
                            />
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: "450px" }} header="Confirm" modal footer={activateDeactivatevisaFeeDialogFooter} onHide={hideActivateDeactivatevisaFeeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {visaFee && (
                                <span>
                                    Are you sure you want to activate Fee amount <b>{visaFee.feeAmount}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                    <Dialog visible={deletevisaFeeDialog} style={{ width: "450px" }} header="Confirm" modal footer={deletevisaFeeDialogFooter} onHide={hideDeletevisaFeeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {visaFee && (
                                <span>
                                    Are you sure you want to delete visa  Extension  fee <b>{visaFee.feeAmount}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog> 


                </div>
            </div>
        </div>
    );
};

export default VisaFee;
