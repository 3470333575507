import axios from "axios";
import { useEffect } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const Urlheader = window['env']['API_SERVICE_BASE_URL'] + '/';
const clientId = window['env']['API_SERVICE_CLIENT_ID']
const clientSecret = window['env']['API_SERVICE_CLIENT_SECRECT']

const baseURL = Urlheader ? Urlheader : "https://api.dev.dev.gov.et/";

export const SystemToken = () => {
    const Url = baseURL + "identity-server/api/v1/Client/login";
    useEffect(async () => {
        getSystemToken();
    }, []);

    const getSystemToken = () => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken === null || accessToken === "") {
            axios
                .post(Url, {
                    clientId: clientId,
                    clientsecret: clientSecret,
                })
                .then(function (response) {
                    localStorage.setItem("accessToken", response.data.accessToken);
                    localStorage.setItem("refreshToken", response.data.refreshToken);
                })
                .catch(function (error) {
                    logoutUser()
                });
        }
    };
};

export const refreashSystemToken = () => {
    const Url = baseURL + "identity-server/api/v1/Client/login";
    // const accessToken = localStorage.getItem("accessToken");
    // if (accessToken === null || accessToken === "") {
    axios
        .post(Url, {
            clientId: clientId,
            clientsecret: clientSecret,
        })
        .then(function (response) {
            localStorage.setItem("accessToken", response.data.accessToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);
        })
        .catch(function (error) {
            logoutUser();
        });
    // }
};


export const refreshToken = () => {
    const re = localStorage.getItem("refreash")
    if (re === "false") {
        return
    }
    localStorage.setItem("refreash", false);
    axiosInstance
        .post(
            `${baseURL}identity-server/api/v1/User/RefreshToken`,
            { token: localStorage.getItem("userRefreashToken") },
            { headers: { accessToken: localStorage.getItem("accessToken") } })
        .then((response) => {
            localStorage.setItem("userRefreashToken", response.data?.payload.refreshToken)
            localStorage.setItem("idToken", response.data?.payload.idToken)
            window.location.reload();
        })
        .catch((error) => {
            logoutUser();
        })
        .finally(() => {
            localStorage.setItem("refreash", true)
        });

    // localStorage.getItem("fullname");
    // localStorage.getItem("username");
    // localStorage.getItem("roles");

    // auth.togglePrivileges(localStorage.getItem("roles")?.split(","))
    // auth.toggleFullname(localStorage.getItem("fullname"));
    // auth.toggleUsername(localStorage.getItem("username"));
};

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        "Access-Control-Allow-Credentials": true,
        "access-control-allow-origin": "*",
        "Access-Control-Allow-Headers": "accesstoken, clientclaim"
    },
});

const logoutUser = () => {
    localStorage.removeItem("userRefreashToken")
    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("tokenCollection");
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("fullname");
    localStorage.removeItem("username");
    localStorage.removeItem("roles")
    window.location.hash = "#/access"
}

axiosInstance.interceptors.response.use(
    (response) =>
        new Promise((resolve, reject) => {
            resolve(response);
        }),
    (error) => {
        if (!error.response) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
        if (error.response.data.message === null) {
            logoutUser();
        }
        if (error.response.data.message === "101") {
            refreashSystemToken();
        } else if (error.response.data.message === "103") {
            refreshToken();
        } else if (error.response.data.message === "102" || error.response.data.message === "104") {
            window.location.hash = "#/access"
        }
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);

export default axiosInstance;