import React, { useState, useEffect } from 'react';
import { BarChartDemo } from './requestBar'
import { DoughnutChartDemo } from './chart'
import { Panel } from 'primereact/panel';
import { Chart } from 'primereact/chart';


const Dashboard = (props) => {
    //  
    const [chartOptions, setChartOptions] = useState(null)
    const [products, setProducts] = useState(null);
    const [events, setEvents] = useState(null);

    const applyLightTheme = () => {
        const chartOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setChartOptions(chartOptions)
    }

    const [basicData] = useState({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Visa Request',
                backgroundColor: '#42A5F5',
                data: [65, 59, 80, 81, 56, 55, 40, 55, 70, 99, 22, 25]
            },
            {
                label: 'Visa Extensions',
                backgroundColor: '#FFA726',
                data: [28, 48, 40, 19, 86, 27, 90, 60, 43, 22, 87, 55]
            }
        ]
    });

    const applyDarkTheme = () => {
        const chartOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setChartOptions(chartOptions)
    }

    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }

    }, [props.colorMode]);


    return (
        <div className="grid dashboard">
            {/* <div className="col-12 md:col-3">
                <div className="overview-box overview-box-1">
                    <h1 className='font-bold' style={{ fontSize: "1.2rem" }}>Tourist Visa</h1>
                    <div className="overview-value">25,620</div>
                    <img src="assets/layout/images/dashboard/graph-blue.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="col-12 md:col-3">
                <div className="overview-box overview-box-2">
                    <h1 className='font-bold' style={{ fontSize: "1.2rem" }}>Investement Visa</h1>
                    <div className="overview-value">9521</div>
                    <img src="assets/layout/images/dashboard/graph-green.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="col-12 md:col-3">
                <div className="overview-box overview-box-3">
                    <h1 className='font-bold' style={{ fontSize: "1.2rem" }}>Government Institutions Short Task Visa</h1>
                    <div className="overview-value">452</div>
                    <img src="assets/layout/images/dashboard/graph-yellow.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="col-12 md:col-3">
                <div className="overview-box overview-box-4">
                    <h1 className='font-bold' style={{ fontSize: "1.2rem" }}>Foreign Business Firm Employment Visa</h1>
                    <div className="overview-value">65922</div>
                    <img src="assets/layout/images/dashboard/graph-red.svg" alt="apollo-layout" />
                </div>
            </div> */}

            <div className="col-12 card">
                {/* <Panel header="Visa Status" className="circle-panel"> */}
                <div className="grid grid-nogutter">
                    <div className="col-12 lg:col-3 md:col-6">
                        <div className="status-title" style={{ color: '#6ebc3b' }}>New Requests</div>
                        <div className="circle1">
                            <i className="pi pi-question-circle"></i>
                            <span>75</span>
                        </div>
                    </div>
                    <div className="col-12 lg:col-3 md:col-6">
                        <div className="status-title" style={{ color: '#f6a821' }}>Approved Visas</div>
                        <div className="circle2">
                            <i className="pi pi-fw pi-check"></i>
                            <span>25</span>
                        </div>
                    </div>
                    <div className="col-12 lg:col-3 md:col-6">
                        <div className="status-title" style={{ color: '#039ade' }}>Incomplete Visas</div>
                        <div className="circle3">
                            <i className="pi pi-fw pi-filter"></i>
                            <span>50</span>
                        </div>
                    </div>
                    <div className="col-12 lg:col-3 md:col-6">
                        <div className="status-title" style={{ color: '#d66351' }}>Denied Visas</div>
                        <div className="circle4">
                            <i className="pi pi-ban"></i>
                            <span>25</span>
                        </div>
                    </div>
                </div>
                {/* </Panel> */}
            </div>

            {/* <BarChartDemo /> */}
            <div className='col-12 grid card'>
                {/* <Panel header="Yearly Request Status" className="circle-panel p-fluid"> */}
                <div className="col-8">
                    <BarChartDemo />
                </div>
                <div className='col-4'>
                    <DoughnutChartDemo />
                </div>
                {/* </Panel> */}
            </div>

        </div >
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
