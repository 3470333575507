import React, { useState, useEffect, useRef } from 'react';

import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import CardTypeService from '../../../service/CardType/CardTypeService';
import SnipperModal from "../../modal/SnipperModal";
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { useAuth } from '../../../utilities/authContext';

const CardType = () => {
    let emptycardType = {
        id: null,
        name: '',
        description: '',
    };
    const cardtypeBody = {
        name: '',
        description: '',
    };
    const activateDeactivateBody = {
        status: 0,
    };

    let [loading, setLoading] = useState(false);
    const [cardTypes, setcardTypes] = useState(null);

    const [cardUpdateDialog, setCardTypeUpdateDialog] = useState(false);
    const [cardtypeDialog, setcardTypeDialog] = useState(false);
    const [deleteCardDialog, setDeleteCardDialog] = useState(false);
    const [activateDeactivateDialog, setActivateDeactivateDialog] = useState(false);

    const [performingAction, setPerformingAction] = useState(false);
    const [cardstype, setcardstype] = useState(emptycardType);
    const [selectedCardTypes, setselectedvisaCards] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const [filters, setFilters] = useState({
        'recordStatus': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    });

    const toast = useRef(null);
    const dt = useRef(null);

    const roles = useAuth()

    useEffect(() => {
        const visaService = new CardTypeService();
        setLoading(true)
        visaService.getCard("CardType-View")
            .then(res => {
                setcardTypes(res.data)
            }).catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Error while fetching card types", life: 4000 });
            }).finally(() => {
                setLoading(false);
            });
    }, []);

    const openNew = () => {
        setcardstype(emptycardType);
        setSubmitted(false);
        setcardTypeDialog(true);
    }

    const hideCardDialog = () => {
        setSubmitted(false);
        setcardTypeDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCardTypeUpdateDialog(false);
    }

    const hideDeleteCardtypeDialog = () => {
        setDeleteCardDialog(false);
    }
    const hideActivateDeactivatecardDialog = () => {
        setActivateDeactivateDialog(false);
    }

    const SubmitVisaValidity = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _cardtypes = [...cardTypes];
        let _cardtype = { ...cardstype };

        cardtypeBody.name = _cardtype.name;
        cardtypeBody.description = _cardtype.description;
        const visaService = new CardTypeService();

        visaService.createCard(cardtypeBody, "", "CardType-Add")
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Card Type Created', life: 3000 });
                refresh();
            })
            .catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Card Type already Registered ", life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setcardTypes(_cardtypes);
                setcardTypeDialog(false);
                setcardstype(emptycardType);
            })
    }

    const UpdateVisaValidity = () => {
        setPerformingAction(true);
        setSubmitted(true);

        if (cardstype.name.trim()) {
            let _cardtypes = [...cardTypes];
            let _cardtype = { ...cardstype };
            if (cardstype.id) {
                const index = findIndexById(cardstype.id);

                _cardtypes[index] = _cardtype;
                const visaService = new CardTypeService();
                cardtypeBody.name = _cardtype.name;
                cardtypeBody.description = _cardtype.description;

                visaService.updateCard(cardtypeBody, _cardtype.id, "", "CardType-Edit")
                    .then(() => {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Card Type Updated Successfully', life: 3000 });
                    })
                    .catch(() => {
                        toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Error While Updating Card Type", life: 3000 });
                    }).finally(() => {
                        setPerformingAction(false);
                        setcardTypes(_cardtypes);
                        setCardTypeUpdateDialog(false);
                        setcardstype(emptycardType);
                    })
            }
            else {

            }
        }
    }
    const editCountry = (cardstype) => {
        setcardstype({ ...cardstype });
        setCardTypeUpdateDialog(true);
    }

    const confirmDeleteCountry = (cardstype) => {
        setcardstype(cardstype);
        setDeleteCardDialog(true);
    }

    const confirmActivateDeactivateCountry = (cardstype) => {
        setcardstype(cardstype);
        setActivateDeactivateDialog(true);
    }

    const deleteCounrty = () => {
        setPerformingAction(true)
        let _cardtypes = cardTypes.filter(val => val.id !== cardstype.id);

        const visaService = new CardTypeService();
        visaService.deleteCard(cardstype.id, "CardType-Delete")
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Card type Deleted', life: 3000 });
            }).catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Error while deleting card type", life: 3000 });
            }).finally(() => {
                setPerformingAction(false);
                setcardTypes(_cardtypes);
                setDeleteCardDialog(false);
                setcardstype(emptycardType);
            });

    }
    const activateDeactivateCountryname = () => {
        setPerformingAction(true);
        setSubmitted(true);

        let _cardtypes = [...cardTypes];
        let _cardtype = { ...cardstype };
        if (cardstype.id) {
            const index = findIndexById(cardstype.id);

            _cardtypes[index] = _cardtype;
            const visaService = new CardTypeService();

            if (cardstype.recordStatus === 1) {
                activateDeactivateBody.status = 2;
            }
            else
                activateDeactivateBody.status = 1;

            visaService.activateDeactivateCard(cardstype.id, activateDeactivateBody, "clienttoken", "CardType-ChangeStatus")
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Successful", detail: res.data.recordStatus === 2 ? "Card Type Activated" : "Card type Deactivated", life: 3000 });
                    refresh();
                })
                .catch(() => {
                    toast.current.show({ severity: 'error', summary: 'Error Occured', detail: "Error while changing status of card type", life: 3000 });
                }).finally(() => {
                    setPerformingAction(false)
                    setcardTypes(_cardtypes);
                    setActivateDeactivateDialog(false);
                    setcardstype(emptycardType);
                });
        }

    }
    const refresh = () => {
        const visaService = new CardTypeService();
        visaService.getCard("CardType-View")
            .then((res) => {
                setcardTypes(res.data);
            })
            .catch((error) => { });
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < cardTypes.length; i++) {
            if (cardTypes[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _cardtype = { ...cardstype };
        _cardtype[`${name}`] = val;
        setcardstype(_cardtype);
    }
    const leftToolbarTemplate = () => {
        let template;
        template = roles?.privileges.includes("CardType-Add") === true ?
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
            : <></>
        return template
    }
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Card Name</span>
                {rowData.name}
            </>
        );
    }
    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Card Description </span>
                {rowData.description}
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {roles.privileges.includes("CardType-Edit") === true ?
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editCountry(rowData)} /> : <></>}
                {roles.privileges.includes("CardType-Delete") === true ?
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteCountry(rowData)} /> : <></>}
                {roles.privileges.includes("CardType-ChangeStatus") === true ?
                    <Button icon={rowData.recordStatus && rowData.recordStatus === 1 ? "pi pi-lock-open" : "pi pi-lock"} className="p-button-rounded p-button-info" onClick={() => confirmActivateDeactivateCountry(rowData)} /> : <></>}
            </div>
        );
    }

    const statuses = [
        { name: "Active", value: '2' },
        { name: "Inactive", value: '1' }
    ]

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} optionLabel={"name"} onChange={(e) => options.filterCallback(e.value, options.index)} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const recordStatusBody = (rowData) => {
        const status = rowData.recordStatus

        if (status === 1) {
            return <span style={{ backgroundColor: "#FFCDD2", color: "#C63737", padding: "0.25em 0.5rem", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Inactive</span>
        } else {
            return <span style={{ backgroundColor: "#C8E6C9", color: "#256029", padding: "0.25em 0.5rem", borderRadius: "2px", fontWeight: 700, fontSize: "12px", letterSpacing: ".3px" }} >Active</span>
        }
    }
    const searchInput = () => {
        return (
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Card Type</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const updateCardDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Update" icon="pi pi-check" className="p-button-raised" onClick={UpdateVisaValidity} />}
        </>
    );

    const counrtyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-raised" onClick={hideCardDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Submit" icon="pi pi-check" className="p-button-raised" onClick={SubmitVisaValidity} />}
        </>
    );

    const deleteCardtypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={() => setDeleteCardDialog(false)} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={deleteCounrty} />}
        </>
    );

    const activateDeactivatecardtypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-raised" onClick={hideActivateDeactivatecardDialog} />
            {performingAction ? <Button label="Submitting" icon="pi pi-spin pi-spinner" ></Button> :
                <Button label="Yes" icon="pi pi-check" className="p-button-raised" onClick={activateDeactivateCountryname} />}
        </>
    );
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={searchInput} ></Toolbar>
                    {loading ? <SnipperModal /> : <> {null}</>}

                    <DataTable ref={dt} filters={filters} value={cardTypes} selection={selectedCardTypes} onSelectionChange={(e) => setselectedvisaCards(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Card Type"
                        globalFilter={globalFilter} emptyMessage="No Card Types found." scrollable responsiveLayout="scroll">

                        <Column field="name" header="Card Type Name" sortable body={nameBodyTemplate}
                            style={{ minWidth: "8rem" }} headerStyle={{ minWidth: "8rem" }}></Column>
                        <Column field="description" header="Card Type description" sortable body={descriptionBodyTemplate}
                            style={{ minWidth: "8rem" }} headerStyle={{ minWidth: "8rem" }}></Column>
                        <Column field="recordStatus" header="Status" body={recordStatusBody} sortable style={{ minWidth: "5rem" }} filter filterElement={statusFilterTemplate} headerStyle={{ minWidth: "5rem" }}></Column>

                        {(roles.privileges.includes("CardType-Edit") === true ||
                            roles.privileges.includes("CardType-Delete") === true ||
                            roles.privileges.includes("CardType-ChangeStatus") === true) ? <Column header="Actions" body={actionBodyTemplate} style={{ minWidth: "12rem" }} headerStyle={{ minWidth: "12rem" }}></Column> : <></>}
                    </DataTable>
                    <Dialog visible={cardtypeDialog} style={{ width: "30vw", height: "45vh" }} header="Create Card Type"
                        modal className="p-fluid" footer={counrtyDialogFooter} onHide={hideCardDialog}>

                        <div className="field">
                            <label htmlFor="name">Card Type Name</label>
                            <InputText id="name" value={cardstype.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !cardstype.name })} />
                            {submitted && !cardstype.name && <small className="p-error">Card type name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Card description</label>
                            <InputTextarea id="description" value={cardstype.description} onChange={(e) => onInputChange(e, 'description')} required
                                rows={5} cols={20} className={classNames({ 'p-invalid': submitted && !cardstype.description })} />
                            {submitted && !cardstype.description && <small className="p-error text-red">Card description is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={cardUpdateDialog} style={{ width: "30vw", height: "45vh" }} header="Update Card Type"
                        modal className="p-fluid" footer={updateCardDialogFooter} onHide={hideDialog}>

                        <div className="field">
                            <label htmlFor="name">Card Type Name</label>
                            <InputText id="name" value={cardstype.name} onChange={(e) => onInputChange(e, 'name')}
                                required autoFocus className={classNames({ 'p-invalid': submitted && !cardstype.name })} />
                            {submitted && !cardstype.name && <small className="p-invalid"> Card  Type Name  is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="dadescriptionys">Card Type description</label>
                            <InputTextarea id="description" value={cardstype.description} onChange={(e) => onInputChange(e, 'description')}
                                required rows={5} cols={20} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCardDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCardtypeDialogFooter} onHide={hideDeleteCardtypeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {cardstype && <span>Are you sure you want to <b>Delete {cardstype.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={activateDeactivateDialog} style={{ width: '450px' }} header="Confirm" modal footer={activateDeactivatecardtypeDialogFooter} onHide={hideActivateDeactivatecardDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {cardstype && <span>Are you sure you want to activate <b>{cardstype.name}</b>?</span>}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

export default CardType;