import HttpService from "../shared/HttpService";

export default class DiscountService {
    getDisCounted = (claim) => {
        let relativePath = "visaservice/api/v1/DisCountedPenalityFees/GetAll";
        return HttpService.getService(relativePath, "", claim);
    };

    deleteDisCounted = (id, claim) => {
        let relativePath = "visaservice/api/v1/DisCountedPenalityFees/Delete?id=" + id;
        return HttpService.deleteService(relativePath, "", claim);
    };
    createDisCounted = (requestData, payload, claim) => {
        let relativePath = "visaservice/api/v1/DisCountedPenalityFees/Create";
        return HttpService.postService(requestData, relativePath, "", "", payload, claim);
    };
    updateDisCounted = (requestData, claim) => {
        let relativePath = "visaservice/api/v1/DisCountedPenalityFees/Update";
        return HttpService.putService(requestData, relativePath,"", "", "",claim);
    };
    activateDeactivateDisCounted = (countryActivateBody, claim) => {
        let relativePath = "visaservice/api/v1/DisCountedPenalityFees/UpdateStatus";
        return HttpService.activateDeactivateService(countryActivateBody, relativePath,"", "", "", claim);
    };
}
