import * as React from "react";
import Dashboard from "../components/Dashboard/Dashboard";
import User from "../components/user-management/account/User";
import CompanyUser from "../components/user-management/account/CompanyUser";
import Role from "../components/user-management/role/Role";
import ChangePassword from "../components/user-management/change-password/ChangePassword";
import AttachementType from "../components/master-data/attachement-type/AttachementType";
import Nationality from "../components/master-data/Nationality/Index";
import Region from "../components/master-data/Region/Index";
import PassportType from "../components/master-data/passport-type/PassportType";
import NotificationMessages from "../components/master-data/NotificationMessages/Index";
import DetailByReference from "../components/CompanyApplication/DetailByReference";
import AccommodationIndex from "../components/master-data/accommodation-type/AccommodationType";
import CompanyCategory from "../components/master-data/company-category/index";
import VisaFee from "../components/master-data/visa-fee/index";
import CompanyAttachment from "../components/master-data/company-attachment/index";
import Country from "../components/master-data/Country/Index";
import VisaType from "../components/master-data/VisaType/Index";
import VisaValidity from "../components/master-data/VisaValidity/Index";
import CardType from "../components/master-data/CardType/Index";
import CompanyApplication from "../components/CompanyApplication/Index";
import RepliedCompany from "../components/CompanyApplication/Replied";
import RequestedApplication from "../components/CompanyApplication/Requested";
import ApprovedApplication from "../components/CompanyApplication/Approved";
import DeniedApplication from "../components/CompanyApplication/Denied";
import IncompleteApplication from "../components/CompanyApplication/Incomplete";
import CompanyApplicationDetails from "../components/CompanyApplication/Detail";
import ExtensionDetail from "../components/EvisaExtensions/ExtensionDetail";
import VisaReport from "../components/eVisaReport/VisaReport";
import VisaApplicationDetails from "../components/EvisaApplication/VisaApplicationDetail";
import ApprovalHistoryDetail from "../components/EvisaApplication/ApprovalHistory";
import VisaAttachment from "../components/VisaAttachment/index";
import VisaApplcation from "../components/EvisaApplication/Index";
import Emailnotifications from "../components/EmailNotification/AllNotification"

import NewApplication from "../components/EvisaApplication/NewRequest";
import WaitingApplication from "../components/EvisaApplication/WaitingRequest";
import ApprovedVisaApplication from "../components/EvisaApplication/ApprovedRequest";
import DeniedvisaApplication from "../components/EvisaApplication/DeniedRequest";
import IncompleteVisaRequest from "../components/EvisaApplication/incompleteRequests";
import AllEvisaExtension from "../components/EvisaExtensions/Index";
import NewExtensionApplication from "../components/EvisaExtensions/NewRequest";
import ApprovedExtensionApplication from "../components/EvisaExtensions/ApprovedRequest";
import IncompleteExtensionApplication from "../components/EvisaExtensions/IncompleteRequest";
import DeniedExtensionApplication from "../components/EvisaExtensions/DeniedRequest";
import visaExtensionFee from "../components/master-data/visaExtensionFee/index";
import VerificationDetail from "../components/EvisaVerification/VerificationDetail";
import UnpaidExtension from "../components/UnpaidVisa/UnpaidExtension";
import UnpaidVisa from "../components/UnpaidVisa/UnpaidVisaRequest";
import UnpaidVisaDetail from "../components/UnpaidVisa/UnpaidVisaDetail";
import RepliedApplication from "../components/EvisaApplication/RepliedRequests";
import UnpaidVisaExtensionDetail from "../components/UnpaidVisa/UnpaidVisaExtensionDetail";
import RepliedExtension from "../components/EvisaExtensions/RepliedRequests";
import DiscountedNationality from "../components/master-data/Discounted-Nationality";
import ApprovalUserLogReport from "../components/eVisaReport/ApprovalUserLogReport";
import VisaApprovalReport from "../components/eVisaReport/VisaApprovalReport";

import FAQ from "../components/master-data/FAQ/index";
import { NotFound } from "../pages/NotFound";
//import Emailnotifications from "../components/EmailNotification/AllNotification"
import GuardedRoute from "./route_guard";


const Router = () => {
    const routes = [
        "/dashboard",
        "/account-management/roles",
        "/account-management/users",
        "/account-management/company-users",
        "/account-management/change-password",
        "/masterdata/attachement-type",
        "/masterdata/attachement-type",
        "/masterdata/NotificationMessages",
        "/masterdata/passport-type",
        "/masterdata/accommodation-type",
        "/masterdata/nationality",
        "/masterdata/nationality",
        "/masterdata/region",
        "/masterdata/company-categories",
        "/masterdata/visa-fees",
        "/masterdata/visa-extension-fees",
        "/masterdata/company-attachments",
        "/masterdata/country",
        "/masterdata/visa-Validity",
        "/masterdata/visa-Type",
        "/masterdata/card-type",
        "/masterdata/visa-Attachment",
        "/masterdata/FAQ",
        "/Report/VisaReport",
        "/company-application/all-applications",
        "/company-application/new-applications",
        "/company-application/approved-applications",
        "/company-application/denied-applications",
        "/company-application/replied-applications",
        "/company-application/incomplete-applications",
        "/company-application/detail",
        "/company-application/DetailByReference",
        "/visa-extension/Extension-Detail",
        "/visa-requests/Visa-detail",
        "/visa-requests/all-requests",
        "/visa-requests/New-requests",
        "/visa-requests/Incomplete-requests",
        "/visa-requests/Waiting-requests",
        "/visa-requests/Replied-requests",
        "/visa-requests/Approved-requests",
        "/visa-requests/Denied-requests",
        "/visa-extension/New-Extensions",
        "/visa-extension/Approved-Extensions",
        "/visa-extension/Incomplete-Extensions",
        "/visa-extension/Denied-Extensions",
        "/visa-extension/All-Extensions",
        "/visa-extension/Replied-Extensions",
        "/visa-Verfication/Verification-Detail",
        "/unpaid-visa/unpaidVisa",
        "/unpaid-visa/unpaidVisaExtension",
        "/unpaid-visa/unPaidVisaExtensionDetail",
        "/unpaid-visa/unPaidVisaDetail",
        "/masterdata/Discounted-Nationality",
        "/Report/ApprovalUserLogReport" ,
        "/Report/ApprovalUserLogReport" ,
        "/EmailNotification/All-Notification",
        "/Report/VisaApprovalReport" ,
        "/visa-requests/ApprovalHistoryDetail"
    ];

    const checkRoute = (value) => {
        const lowerCaseRoutes = [];
        routes.forEach((value) => {
            lowerCaseRoutes.push(value.toLowerCase());
        });
        return !lowerCaseRoutes.includes(value?.toLowerCase());
    };

    return (
        <div className="layout-content-container">
            <GuardedRoute path="/dashboard" exact component={Dashboard} priv="Dashboard-View" />
            <GuardedRoute path="/account-management/roles" exact component={Role} priv="Roles-View" />
            <GuardedRoute path="/account-management/users" exact component={User} priv="Users-View" />
            <GuardedRoute path="/account-management/company-users" exact component={CompanyUser} priv="CompanyUsers-View" />
            <GuardedRoute path="/account-management/change-password" exact component={ChangePassword} priv="Users-ChangePassword" />

            <GuardedRoute path="/masterdata/attachement-type" exact component={AttachementType} priv="AttachementType-View" />
            <GuardedRoute path="/masterdata/passport-type" exact component={PassportType} priv="PassportType-View" />
            <GuardedRoute path="/masterdata/accommodation-type" exact component={AccommodationIndex} priv="AccommodationType-View" />
            <GuardedRoute path="/masterdata/nationality" exact component={Nationality} priv="Nationality-View" />
            <GuardedRoute path="/masterdata/region" exact component={Region} priv="Region-View" />
            <GuardedRoute path="/masterdata/company-categories" exact component={CompanyCategory} priv="CompanyCategory-View" />
            <GuardedRoute path="/masterdata/visa-fees" exact component={VisaFee} priv="VisaFee-View" />
            <GuardedRoute path="/masterdata/visa-extension-fees" component={visaExtensionFee} priv="VisaExtensionFee-View" />
            <GuardedRoute path="/masterdata/company-attachments" exact component={CompanyAttachment} priv="CompanyAttachement-View" />
            <GuardedRoute path="/masterdata/country" exact component={Country} priv="Country-View" />
            <GuardedRoute path="/masterdata/visa-Validity" exact component={VisaValidity} priv="VisaValidity-View" />
            <GuardedRoute path="/masterdata/visa-Type" exact component={VisaType} priv="VisaTypes-View" />
            <GuardedRoute path="/masterdata/card-type" exact component={CardType} priv="CardType-View" />
            <GuardedRoute path="/masterdata/visa-Attachment" exact component={VisaAttachment} priv="VisaAttachment-View" />
            <GuardedRoute path="/masterdata/FAQ" exact component={FAQ} priv="FAQ-View" />
            <GuardedRoute path="/masterdata/Discounted-Nationality" exact component={DiscountedNationality} priv="DiscountedNationality-View" />
            <GuardedRoute path="/masterdata/NotificationMessages" exact component={NotificationMessages} priv="MarkAsIncomplete-View" />

            <GuardedRoute path="/company-application/all-applications" exact component={CompanyApplication} priv="AllCompany-View" />
            <GuardedRoute path="/company-application/new-applications" exact component={RequestedApplication} priv="NewCompany-View" />
            <GuardedRoute path="/company-application/approved-applications" exact component={ApprovedApplication} priv="ApprovedCompany-View" />
            <GuardedRoute path="/company-application/replied-applications" exact component={RepliedCompany} priv="RepliedCompany-View" />
            <GuardedRoute path="/company-application/denied-applications" exact component={DeniedApplication} priv="DeniedCompany-View" />
            <GuardedRoute path="/company-application/incomplete-applications" exact component={IncompleteApplication} priv="InCompleteCompany-View" />

            <GuardedRoute path="/company-application/DetailByReference" exact component={DetailByReference} priv="CompanyApplication-Detail" />
            <GuardedRoute path="/company-application/detail" exact component={CompanyApplicationDetails} priv="CompanyApplication-Detail" />

            <GuardedRoute path="/visa-extension/Extension-Detail" exact component={ExtensionDetail} priv="VisaExtension-Detail" />
            <GuardedRoute path="/visa-requests/Visa-detail" exact component={VisaApplicationDetails} priv="VisaApplication-Detail" />
            <GuardedRoute path="/visa-requests/ApprovalHistoryDetail" exact component={ApprovalHistoryDetail} priv="GetApprovalHistoryDetail-View" />
            <GuardedRoute path="/unpaid-visa/unPaidVisaDetail" component={UnpaidVisaDetail} priv="UnpaidVisa-Detail" />
            <GuardedRoute path="/unpaid-visa/unPaidVisaExtensionDetail" component={UnpaidVisaExtensionDetail} priv="UnpaidVisaExtension-Detail" />

            <GuardedRoute path="/visa-requests/all-requests" exact component={VisaApplcation} priv="AllVisaApplication-View" />
            <GuardedRoute path="/EmailNotification/All-Notification" exact component={Emailnotifications} priv="AllNotification-View" />

          
            <GuardedRoute path="/visa-requests/all-notification" exact component={VisaApplcation} priv="AllVisaApplication-View" />
            <GuardedRoute path="/visa-requests/New-requests" exact component={NewApplication} priv="NewVisaApplication-View" />
            <GuardedRoute path="/visa-requests/Incomplete-requests" exact component={IncompleteVisaRequest} priv="InCompleteVisaApplication-View" />
            <GuardedRoute path="/visa-requests/Waiting-requests" exact component={WaitingApplication} priv="WaitingVisaApplication-View" />
            <GuardedRoute path="/visa-requests/Approved-requests" exact component={ApprovedVisaApplication} priv="ApprovedVisaApplication-View" />
            <GuardedRoute path="/visa-requests/Denied-requests" exact component={DeniedvisaApplication} priv="DeniedVisaApplication-View" />
            <GuardedRoute path="/visa-requests/Replied-requests" exact component={RepliedApplication} priv="RepliedVisaApplication-View" />

            <GuardedRoute path="/visa-extension/New-Extensions" exact component={NewExtensionApplication} priv="NewVisaExtension-View" />
            <GuardedRoute path="/visa-extension/Approved-Extensions" exact component={ApprovedExtensionApplication} priv="ApprovedVisaExtension-View" />
            <GuardedRoute path="/visa-extension/Incomplete-Extensions" exact component={IncompleteExtensionApplication} priv="InCompleteVisaExtension-View" />
            <GuardedRoute path="/visa-extension/Replied-Extensions" exact component={RepliedExtension} priv="RepliedExtension-View" />
            <GuardedRoute path="/visa-extension/Denied-Extensions" exact component={DeniedExtensionApplication} priv="DeniedVisaExtension-View" />
            <GuardedRoute path="/visa-extension/All-Extensions" exact component={AllEvisaExtension} priv="AllVisaExtension-View" />

            <GuardedRoute path="/visa-Verfication/Verification-Detail" component={VerificationDetail} priv="Visa-Verification" />
            <GuardedRoute path="/unpaid-visa/unpaidVisa" component={UnpaidVisa} priv="UnpaidVisa-View" />
            <GuardedRoute path="/unpaid-visa/unpaidVisaExtension" component={UnpaidExtension} priv="UnpaidVisaExtension-View" />
            
            <GuardedRoute path="/Report/VisaReport" component={VisaReport} priv="VisaReport-View" />
            <GuardedRoute path="/Report/ApprovalUserLogReport" component={ApprovalUserLogReport} priv="ApprovalUserLogReport-View" />
            <GuardedRoute path="/Report/VisaApprovalReport" component={VisaApprovalReport} priv="VisaReport-View" />

            {checkRoute(window.location.hash.slice(1)) ? <GuardedRoute path="*" priv="Dashboard-View" component={NotFound} /> : ""}
            {/* {console.log(String(window.location.hash.slice(1)).split("?")[0])} */}
        </div>
    );
};

export default Router;
