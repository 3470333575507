import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import extensionService from "../../service/extensionService/extensionService";
import "./detail.css";
import SnipperModal from "../modal/SnipperModal";
import { Divider } from "primereact/divider";

import { Toast } from "primereact/toast";
import { useAuth } from "../../utilities/authContext";
import { Checkbox } from "primereact/checkbox";
import MasterDataService from "../../service/master-data/MasterDataService";


const Invoice = () => {
    const [displayResponsive, setDisplayResponsive] = useState(false);
    const [remark, setRemark] = useState(" ");
    const [approvalStatusValue, setApprovalStatusValue] = useState(0);
    const [detailData, setDetailData] = useState([]);
    const [selectedReason, setSelectReason] = useState(null);
    const [selectedReasonEnum, setSelectReasonEnum] = useState(0);
    const [attachements, setAttachments] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [historyDialog, setHistoryDialog] = useState(false);
    const [performingAction, setPerformingAction] = useState(false);
    const [performingActionreciept, setperformingActionreciept] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [AirportHisDialog, setAirportHisDialog] = useState(false);
    const [AirportHisData, setAirportHisData] = useState([]);
    const [PdfRecieptResponse, setPdfRecieptResponse] = useState();
    const [pdfResponsevisa, setPdfResponsevisa] = useState();
    const [incompleteReasons, setIncompleteReasons] = useState();
    const location = useLocation();
    const toast = useRef(null);
    const roles = useAuth();
    const history = useHistory();

    if (location.state !== null && location.state !== undefined) {
        // console.log("ssss", location.state.selected);
        localStorage.removeItem("selectedTravellerId");
        localStorage.setItem("selectedTravellerId", location.state.selected.id);
    }
    const approvalStatus = {
        0: "Default",
        1: "Requested",
        2: "Approved",
        3: "Incomplete",
        4: "Deny",
        5: "NotPaid",
        6: "Paid",
    };

    const Reasonsyy = {
        0: "None",
        1: "Note Verbale Not Available",
        2: "Support Letter Not Found",
        3: "Passport is not valid for 6 month",
        4: "Business License Not Found",
        5: "Business License expired",
        6: "other",
    };
    const onReasonChange = (e) => {
        setSelectReason(e.value);

        setSelectReasonEnum(e.value);
    };
    const Reasons = [
        { name: "None", value: 0 },
        { name: "Note Verbale Not Available", value: 1 },
        { name: "Support Letter Not Found", value: 2 },
        { name: "Passport is not valid for 6 month", value: 3 },
        { name: "Business License Not Found", value: 4 },
        { name: "Business License expired", value: 5 },
        { name: "other", value: 6 },
    ];
    useEffect(() => {
        const applicationService = new extensionService();

        applicationService
            .getDetail(localStorage.selectedTravellerId)
            .then((res) => {
                setDetailData(res.data);
                // console.log("extension history", res.data)
                setAirportHisData(res.data?.visaRequest?.entryStatusHistories);
            })
            .catch((error) => {
                // if (error?.response.status === 403) {
                //     history.pushState('/access')
                // }
            });
    }, []);

    const ApprovalHistory = () => {
        const applicationService = new extensionService();
        applicationService
            .searchHistory(localStorage.selectedTravellerId)
            .then((res) => {
                setHistoryData(res.data);
            })

            .catch((error) => {});
    };
    const fetchIncompleteReasons = () => {
        const masterdataservice = new MasterDataService();
        masterdataservice.getIncompeleteReasons(2, 2, "VisaApplication-InComplete").then((res) => {
            setIncompleteReasons(res.data);
            setIsLoading(false);
        });
    };
    const refreshData = () => {
        setIsLoading(true);
        const applicationService = new extensionService();

        applicationService
            .getDetail(localStorage.selectedTravellerId)
            .then((res) => {
                setDetailData(res.data);
                // console.log("extension history", res.data)
                setAirportHisData(res.data?.visaRequest?.entryStatusHistories);
            })
            .catch((error) => {
                // if (error?.response.status === 403) {
                //     history.pushState('/access')
                // }
            })
            .finally(() => {
                setIsLoading(false);
            });
        applicationService
            .getRecieptAttachments(localStorage.selectedTravellerId)
            .then((res) => {
                setPdfRecieptResponse(res.data.payload?.visaDocument);
            })
            .catch((error) => {});
        applicationService
            .getvisaAttachments(localStorage.selectedTravellerId)
            .then((res) => {
                setPdfResponsevisa(res.data.payload?.visaDocument);
            })
            .catch((error) => {});

        applicationService
            .searchHistory(localStorage.selectedTravellerId)
            .then((res) => {
                setHistoryData(res.data);
                // console.log("extension response", res.data);
            })

            .catch((error) => {});
    };

    const dialogFuncMap = {
        displayResponsive: setDisplayResponsive,
    };

    const formatString = (string) => {
        const arr = string?.split(" ");

        for (var i = 0; i < arr?.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const formattedString = arr?.join(" ");
        return formattedString;
    };

    const formatDate = (date) => {
        return new Date(date).toDateString();
    };

    const onClick = (name, value) => {
        setApprovalStatusValue(value);
        dialogFuncMap[`${name}`](true);
        fetchIncompleteReasons();
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const showAirportHistory = () => {
        setAirportHisDialog(true);
    };
    const hideAirportHistoryDialog = () => {
        setAirportHisDialog(false);
    };
   
    function downloadRecieptPDF() {
        setperformingActionreciept(true);
        const applicationService = new extensionService();
        applicationService
            .getRecieptAttachments(localStorage.selectedTravellerId)
            .then((res) => {
                setperformingActionreciept(false);
                const linkSource = "data:application/pdf;base64," + res.data.payload?.visaDocument;
                const downloadLink = document.createElement("a");
                const fileName = "Visa-Reciept_" + detailData?.visaRequest?.passportNumber + "_" + detailData?.visaRequest?.referenceNumber + ".pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            })
            .catch((error) => {})
            .finally(() => {
                setperformingActionreciept(false);
            });
    }
    function downloadPDF() {
        setPerformingAction(true);
        const applicationService = new extensionService();
        applicationService
            .getvisaAttachments(localStorage.selectedTravellerId)
            .then((res) => {
                
                setPerformingAction(false);
                const linkSource = "data:application/pdf;base64," +res.data.payload?.visaDocument;
                const downloadLink = document.createElement("a");
                const fileName = "Visa_" + detailData?.visaRequest?.passportNumber + "_" + detailData?.visaRequest?.referenceNumber + ".pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setPerformingAction(false);
            })
            .catch((error) => {})
            .finally(() => {
                setperformingActionreciept(false);
            });
    }
    const changeApplicationStatus = () => {
        dialogFuncMap[`displayResponsive`](false);

        const activateDeactivateBody = {
            id: Number.parseInt(localStorage.selectedTravellerId),
            approvalRemark: remark,
        };

        // console.log("extnesion active", selectedReasonEnum);
        const activateDeactivateIncomplete = {
            reason: selectedReasonEnum,
        };

        setIsLoading(false);
        switch (approvalStatusValue) {
            case 2:
                approveExtension(activateDeactivateBody);
                break;
            case 3:
                incompleteExtension(activateDeactivateBody, activateDeactivateIncomplete);
                break;
            case 4:
                denyExtension(activateDeactivateBody);
                break;
            default:
                break;
        }
    };
    const incompleteExtension = (activateDeactivateBody, activateDeactivateIncomplete) => {
        const applicationService = new extensionService();
        setPerformingAction(true);
        setIsLoading(true);

        applicationService
            .incompleteVisaExtension(activateDeactivateBody.id, activateDeactivateBody.approvalRemark, activateDeactivateIncomplete.reason, "clienttoken", "accesstoken", "")
            .then((res) => {
                // setIsLoading(false);
                toast.current.show({ severity: "success", summary: "Successfull", detail: "Successfully made the request Incomplete", life: 3000 });
            })
            .catch((err) => {
                // setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error Occured", detail: "Error Occured while making the request Incomplete", life: 3000 });
            })
            .finally(() => {
                setPerformingAction(false);
                setIsLoading(false);
                refreshData();
            });
    };

    const denyExtension = (activateDeactivateBody) => {
        setPerformingAction(true);
        setIsLoading(true);

        const applicationService = new extensionService();

        applicationService
            .denyVisaExtension(activateDeactivateBody.id, activateDeactivateBody.approvalRemark, "clienttoken", "accesstoken", "")
            .then((res) => {
                // setIsLoading(false);
                toast.current.show({ severity: "success", summary: "Successfull.", detail: "Visa extension denied", life: 3000 });
            })
            .catch((err) => {
                // setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error Occured while denying visa extension.", detail: "Error Occured while denying visa extension", life: 3000 });
            })
            .finally(() => {
                setPerformingAction(false);
                setIsLoading(false);
                refreshData();
            });
    };

    const approveExtension = (activateDeactivateBody) => {
        setPerformingAction(true);
        setIsLoading(true);

        const applicationService = new extensionService();

        applicationService
            .approveVisaExtension(activateDeactivateBody.id, activateDeactivateBody.approvalRemark, "clienttoken", "accesstoken", "")
            .then((res) => {
                // setIsLoading(false);
                toast.current.show({ severity: "success", summary: "Visa extension approved.", detail: res.message, life: 4000 });
            })
            .catch((err) => {
                // setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error Occured while approving visa extesnion.", detail: err.message, life: 4000 });
            })
            .finally(() => {
                setPerformingAction(false);
                setIsLoading(false);
                refreshData();
            });
    };

    const renderFooterIncomplete = (name) => {
        return (
            <div>
                <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={() => {
                        onHide(name);
                        setRemark("");
                        setSelectedIncompleteReasons([]);
                    }}
                    className="p-button-raised"
                />
                {performingAction ? (
                    <Button label="Submitting" icon="pi pi-spin pi-spinner"></Button>
                ) : (
                    <Button type="submit" label={approvalStatusValue === 3 ? "Incomplete" : "Deny"} icon="pi pi-check" form="remark-form" disabled={remark == "" ? true : false} className="p-button-raised" onClick={() => changeApplicationStatus()} />
                )}
            </div>
        );
    };
    const showHistory = () => {
        setHistoryDialog(true);
        ApprovalHistory();
    };
    const hideHistoryDialog = () => {
        setHistoryDialog(false);
    };

    const renderFooterApprove = (name) => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-raised" />
                <Button type="submit" label={approvalStatusValue === 2 ? "Approve" : "Deny"} icon="pi pi-check" disabled={approvalStatusValue === 2 ? false : remark === " " ? true : false} onClick={() => changeApplicationStatus()} className="p-button-raised" />
            </div>
        );
    };

    const [selectedIncompleteReasons, setSelectedIncompleteReasons] = useState([]);

    const onIncompleteReasonChange = (e) => {
        let _selectedIncompleteReasons = [...selectedIncompleteReasons];
        if (e.checked) _selectedIncompleteReasons.push(e.value);
        else _selectedIncompleteReasons = _selectedIncompleteReasons.filter((incompleteReason) => incompleteReason.id !== e.value.id);

        setSelectedIncompleteReasons(_selectedIncompleteReasons);
        let remark = "";
        remark += _selectedIncompleteReasons?.map((reason) => reason?.text);
        setRemark(remark);
    };

    return (
        <div>
            {isLoading ? <SnipperModal /> : <> {null}</>}
            {approvalStatusValue === 3 || approvalStatusValue === 4 ? (
                <Dialog
                    header="Update Status Of Traveller"
                    visible={displayResponsive}
                    onHide={() => {
                        onHide("displayResponsive");
                        setRemark("");
                        setSelectedIncompleteReasons([]);
                    }}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "40vw" }}
                    footer={renderFooterIncomplete("displayResponsive")}
                >
                    <div className="grid">
                        <div className="col-12">
                            <div className="scrollpanel-demo">
                                <ScrollPanel style={{ width: "100%", height: "120px" }} className="custombar1">
                                    {incompleteReasons?.map((incompleteReason) => {
                                        return (
                                            <div key={incompleteReason["id"]} className="flex align-items-center">
                                                <Checkbox inputId={incompleteReason["id"]} name="Incomplete Reason" value={incompleteReason} onChange={onIncompleteReasonChange} checked={selectedIncompleteReasons?.some((item) => item.id === incompleteReason.id)} style={{ marginBottom: "0.5rem" }} />
                                                <label htmlFor={incompleteReason["id"]} className="ml-2" style={{ marginBottom: "0.5rem" }}>
                                                    {incompleteReason["text"]}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </ScrollPanel>
                            </div>
                            <h5>Remark</h5>
                            <InputTextarea value={remark} onChange={(e) => setRemark(e.target.value)} rows={5} cols={70} autoResize />
                        </div>
                        {/* <div className="col-8">
                            <h5>Incomplete Reason</h5>
                            <Dropdown optionLabel="name" value={selectedReason} options={Reasons} onChange={onReasonChange} placeholder="Select Incomplete reason" />
                        </div> */}
                    </div>
                </Dialog>
            ) : (
                <Dialog header="Update Status Of Traveller" visible={displayResponsive} onHide={() => onHide("displayResponsive")} breakpoints={{ "960px": "75vw" }} style={{ width: "35vw", minHeight: "10vh" }} footer={renderFooterApprove("displayResponsive")}>
                    {
                        <>
                            <h5>Remark</h5>
                            <InputTextarea value={remark} onChange={(e) => setRemark(e.target.value)} rows={8} cols={70} autoResize />
                        </>
                    }
                </Dialog>
            )}

            <Dialog visible={historyDialog} style={{ width: "600px", minHeight: "300px" }} header="Approval History " modal className="p-fluid" onHide={hideHistoryDialog}>
                {historyData?.map((data) => (
                    <div className="card">
                        <div className="formgrid grid">
                            <div className="field col">
                                <div classNameName="invoice-company">
                                    <div classNameName="company-name">Status</div>
                                    <div className="text-cyan-500 font-bold mb-3">{data?.status}</div>
                                    <div classNameName="company-name">Remark</div>
                                    <div className="text-cyan-500 font-bold ">{data?.remark}</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div classNameName="invoice-company">
                                    {data?.visaStatus === 3 ? (
                                        <div classNameName="company-name">Approved Date</div>
                                    ) : data?.visaStatus === 2 ? (
                                        <div classNameName="company-name">InCompleted Date</div>
                                    ) : data.visaStatus === 4 ? (
                                        <div classNameName="company-name">Denied Date</div>
                                    ) : data.visaStatus === 1 || data.visaStatus === 0 ? (
                                        <div classNameName="company-name">Registered Date</div>
                                    ) : null}
                                    <div className="text-cyan-500 font-bold mb-3">{data.registeredDate?.split("T").join(" | ").split(".")[0]} GMT</div>
                                    <div classNameName="company-name">Issued by</div>
                                    <div className="text-cyan-500 font-bold ">{data.registeredBy}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Dialog>
            <Dialog visible={AirportHisDialog} style={{ width: "600px", minHeight: "300px" }} header="Airport Verification History " modal className="p-fluid" onHide={hideAirportHistoryDialog}>
                {AirportHisData?.length === 0 || AirportHisData?.length === undefined ? (
                    <div className="field col">
                        <div className="invoice-company text-center">
                            <div className="company-name text-center">
                                <h4>No Data Found!</h4>
                            </div>
                        </div>
                    </div>
                ) : (
                    AirportHisData?.map((data) => (
                        <div className="card" key={data?.id}>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">Entry Status</div>
                                        <div className="text-cyan-500 font-bold mb-3">{data?.entryStatusDescription}</div>
                                        <div className="company-name">Registered By</div>
                                        <div className="text-cyan-500 font-bold ">{data?.registeredBy}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">Registered Date</div>
                                        <div className="text-cyan-500 font-bold ">{data.registeredDate?.split("T").join(" | ").split(".")[0]} GMT </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </Dialog>

            <div className="grid">
                <Toast ref={toast} />

                <div className="col-12">
                    <div className="card">
                        <h4>
                            <b>Traveller Profile</b>
                        </h4>
                        <div className="formgrid grid">
                            {detailData?.visaRequest?.attachments?.map((attach) =>
                                attach.isProfilePhoto ? (
                                    <div className="field lg:col-2 md:col-4 sm:col-12">
                                        <Image className="pp" src={attach?.attachmentPath} width="100%" alt="Profile Photo" preview />
                                    </div>
                                ) : null
                            )}
                            <div className="field col-5">
                                <div className="invoice-company ml-2">
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Name</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">
                                        {formatString(detailData.visaRequest?.firstName)} {formatString(detailData.visaRequest?.surName)}
                                    </div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Nationality</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaRequest?.citizenshipDescription}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Birth Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData.visaRequest?.birthDate)}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Gender</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold">{formatString(detailData.visaRequest?.gender)}</div>
                                </div>
                            </div>
                            <div className="field col ">
                                <div className="invoice-company ">
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Occupation</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData.visaRequest?.occupation}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Phone Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData.visaRequest?.phoneNumber}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Email</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold">{detailData.visaRequest?.email}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <h3 className="font-bold mt-4 ml-2">Request Details</h3>
                        <div className="formgrid grid">
                            <div className="field col-6">
                                <div className="invoice-company ml-2">
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Request Date</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{formatDate(detailData?.startDate)}</div>
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Reference Number</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.referenceNumber}</div>
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Payment Status</p>
                                    </div>

                                    <div className="text-cyan-500 font-bold">{detailData?.paymentStatusDescription}</div>
                                </div>
                            </div>
                            <div className="field col-5">
                                <div className="invoice-company">
                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Extended Days</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.noofExtendedDays}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Visa Extension Status</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaStatusDescription?.toUpperCase()}</div>

                                    <div className="traveller-name">
                                        <p className="text-700 text-lg p-0">Visa Extension Fee</p>
                                    </div>
                                    <div className="text-cyan-500 font-bold mb-3">{detailData?.visaExtensionFee} USD</div>
                                </div>
                            </div>
                            <div className="field col">
                                <div className="invoice-company"></div>
                            </div>
                        </div>
                    </div>
           
                    {detailData?.paymentStatus === 2 ? (
                        <div className="card">
                            <h4>
                                <b>e-Visa Extension Detail</b>
                            </h4>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Issue Date</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{formatDate(detailData?.visaIssuedDate)}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Valid From</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{formatDate(detailData?.startDate)}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        <div className="company-name">
                                            <p className="text-700 text-lg p-0">Valid Until</p>
                                        </div>
                                        <div className="text-cyan-500 font-bold ">{formatDate(detailData?.visaExpairyDate)}</div>
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        {(detailData?.visaStatus === 3) ? (
                                            <div>
                                                <div className="text-cyan-500 font-bold ">
                                                    {performingAction ? (
                                                        <Button label="Downloading" icon="pi pi-spin pi-spinner"></Button>
                                                    ) : (
                                                        <Button
                                                            icon="pi pi-download"
                                                            label="Download Visa"
                                                            style={{ backgroundColor: "#0d4d99" }}
                                                            onClick={() => {
                                                                downloadPDF();
                                                            }}
                                                        ></Button>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null} 
                                        {/* ) : null} */}
                                    </div>
                                </div>
                                <div className="field col">
                                    <div className="invoice-company">
                                        {( detailData?.paymentStatus === 2) ? ( 
                                        <div>
                                            {/* <div className="company-name"><p className="text-700 text-lg p-0">Reciept</p></div> */}
                                            <div className="text-cyan-500 font-bold ">
                                                {performingActionreciept ? (
                                                    <Button label="Downloading" icon="pi pi-spin pi-spinner"></Button>
                                                ) : (
                                                    <Button
                                                        icon="pi pi-download"
                                                        style={{ backgroundColor: "#0d4d99" }}
                                                        label="Download Reciept"
                                                        onClick={() => {
                                                            downloadRecieptPDF();
                                                        }}
                                                    ></Button>
                                                )}
                                            </div>
                                        </div>
                                        ) : null} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="card col-12">
                        <div className="form-grid grid">
                            <div className="field lg:col-7 col-12">
                                <div className="scrollpanel-demo">
                                    <h3 className="font-bold mt-4 ml-2">Traveller Documents</h3>

                                    <ScrollPanel style={{ height: "300px" }} className="custombar1">
                                        {detailData?.visaRequest?.attachments?.length ? <h5 className="font-bold mt-4 ml-5 text-cyan-500">Visa Request Documents</h5> : <></>}
                                        {detailData?.visaRequest?.attachments?.map((attach) =>
                                            attach?.isProfilePhoto ? null : (
                                                <div className="col-12" key={attach?.attachmentPath}>
                                                    <div className="mb-4">
                                                        {attach.fileType === ".pdf" ? (
                                                            <div className="card">
                                                                <h4>{attach?.attachmentType}</h4>
                                                                <a href={attach.attachmentPath} download="passport">
                                                                    <Button className="bg-pink-700" icon="pi pi-file-pdf  " iconPos="left" tooltip={attach.attachmentType}></Button>
                                                                </a>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="card">
                                                                    <h4>{attach?.attachmentType}</h4>
                                                                    <Image src={attach?.attachmentPath} alt="attachment" width={250} preview />{" "}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        {detailData?.attachments?.length ? <h5 className="font-bold mt-4 ml-5 text-cyan-500">Visa Extension Documents</h5> : <></>}

                                        {detailData?.attachments?.map((attach) =>
                                            attach?.isProfilePhoto ? null : (
                                                <div className="col-12" key={attach?.attachmentPath}>
                                                    <div className="mb-4">
                                                        {attach.fileType === ".pdf" ? (
                                                            <div className="card">
                                                                <h4>{attach?.attachmentType}</h4>
                                                                <a href={attach.attachmentPath} download="passport">
                                                                    <Button className="bg-pink-700" icon="pi pi-file-pdf  " iconPos="left" tooltip={attach.attachmentType}></Button>
                                                                </a>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="card">
                                                                    <h4>{attach?.attachmentType}</h4>
                                                                    <Image src={attach?.attachmentPath} alt="attachment" width={250} preview />{" "}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </ScrollPanel>
                                </div>
                            </div>
                            <div className="field col">
                                <h3 className="font-bold mt-4 ml-2">Passport Details</h3>
                                <div className="row flex">
                                    <div className="col">
                                        <div>
                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Passport Number</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold mb-3">{detailData.visaRequest?.passportNumber}</div>
                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Passport Type</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold ">{detailData.visaRequest?.passportTypeDescription}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div>
                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Issuer Country</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold mb-3">{detailData.visaRequest?.issuingCountryDescription}</div>

                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Issuing Authority</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold">{detailData.visaRequest?.issuingAuthority}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row flex">
                                    <div className="col">
                                        <div>
                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Issue Date</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold ">{formatDate(detailData.visaRequest?.passportIssueDate)}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div>
                                            <div className="traveller-name">
                                                <p className="text-700 text-lg p-0">Passport Expiration Date</p>
                                            </div>
                                            <div className="text-cyan-500 font-bold ">{formatDate(detailData.visaRequest?.passportExpireDate)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="field col-4">
                                    {detailData?.visaStatus === 0 || (detailData?.visaStatus === 5 && (roles?.privileges.includes("VisaExtension-Incomplete") === true || roles?.privileges.includes("VisaExtension-Approve") === true || roles?.privileges.includes("VisaExtension-Deny") === true)) ? (
                                        <div>
                                            <br></br>
                                            <br></br>
                                            <h4 className="flex font-bold">Visa Extension Actions</h4>
                                            <div className="flex flex-gap">
                                                {roles?.privileges.includes("VisaExtension-Approve") === true ? (
                                                    <Button label="Approve" className="p p-button-success" icon="pi pi-check-circle" style={{ display: "block", minWidth: 150 }} onClick={() => onClick("displayResponsive", 2)}></Button>
                                                ) : (
                                                    <></>
                                                )}
                                                {roles?.privileges.includes("VisaExtension-Incomplete") === true ? (
                                                    <Button label="Incomplete" className="p p-button-warning" icon="pi pi-pencil" style={{ display: "block", minWidth: 150 }} onClick={() => onClick("displayResponsive", 3)}></Button>
                                                ) : (
                                                    <></>
                                                )}
                                                {roles?.privileges.includes("VisaExtension-Deny") === true ? <Button label="Deny" className="p p-button-danger" icon="pi pi-ban" style={{ display: "block", minWidth: 150 }} onClick={() => onClick("displayResponsive", 4)}></Button> : <></>}
                                            </div>
                                        </div>
                                    ) : detailData?.visaStatus === 2 && (roles?.privileges.includes("VisaExtension-Approve") === true || roles?.privileges.includes("VisaExtension-Deny") === true) ? (
                                        <div>
                                            <h4 className="flex font-bold">Visa Extension Actions</h4>
                                            {roles?.privileges.includes("VisaExtension-Approve") === true ? (
                                                <Button label="Approve" className="p p-button-success" icon="pi pi-check-circle" style={{ display: "inline-block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 2)}></Button>
                                            ) : (
                                                <></>
                                            )}
                                            {roles?.privileges.includes("VisaExtension-Deny") === true ? (
                                                <Button label="Deny" className="p p-button-danger" icon="pi pi-ban" style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }} onClick={() => onClick("displayResponsive", 4)}></Button>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-start">
                {detailData?.visaStatus === 0 ? (
                    <></>
                ) : (
                    <div className="field  mt-5">
                        <Button label="View Approval History" style={{ backgroundColor: "#0d4d99" }} onClick={showHistory}></Button>
                    </div>
                )}
                {detailData?.visaStatus === 0 ? (
                    <></>
                ) : (
                    <div className="field ml-2 mt-5">
                        <Button label="View Airport Verification" style={{ backgroundColor: "#0d4d99" }} onClick={showAirportHistory}></Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Invoice, comparisonFn);
